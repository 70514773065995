import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';

import { SessionGuardState } from 'src/components/route-guard/route-guard.types';
import { sessionGuardSelector } from 'src/components/route-guard/store/route-guards.selectors';
import { validateSessionStart } from 'src/core/session/session.actions';
import { mapDispatchers } from 'src/utils';

import { SessionGuardProps } from './route-guard.types';

// Validation triggered against epic
export class SessionGuardComponent extends React.Component<SessionGuardProps, SessionGuardState> {
  // tslint:disable-next-line
  static defaultProps: Partial<SessionGuardProps> = {
    onSuccess: props => props.children,
    onError: props => props.children,
    onVerifying: props => props.children,
  };

  public state = {
    isVerifying: true,
  };

  public componentDidMount() {
    const { token, isSessionValid, validateSession } = this.props;
    if (isSessionValid && token) {
      validateSession();
      this.setState({
        isVerifying: false,
      });
    }
  }

  public componentWillReceiveProps(nextProps) {
    const { isSessionValid, error, token, validateSession } = nextProps;
    const hasNewToken = this.props.token !== token;

    if (hasNewToken || isSessionValid || error) {
      validateSession();
      this.setState({
        isVerifying: false,
      });
    }
  }

  public render() {
    const { isVerifying } = this.state;
    const { onSuccess, onError, onVerifying, error, isSessionValid } = this.props;

    if (isVerifying) {
      return onVerifying(this.props);
    }
    if (error || !isSessionValid) {
      return onError(this.props);
    }
    return onSuccess(this.props);
  }
}

export const SessionGuard = compose<SessionGuardProps, Partial<SessionGuardProps>>(
  connect(
    sessionGuardSelector,
    mapDispatchers({
      validateSession: validateSessionStart,
      goTo: (path: string) => push(path),
    }),
  ),
)(SessionGuardComponent);
