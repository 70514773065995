import * as React from 'react';
import { OIDCProps } from 'src/domains/authentication/scenes/root/root.types';
import { getDynamicConfig } from 'src/utils';

const {
  REACT_APP_ACCESS_ENDPOINT,
  REACT_APP_GIGYA_TOKEN,
  REACT_APP_OIDC_CLIENT_ID,
  REACT_APP_URL,
} = getDynamicConfig();

export class OIDCRoot extends React.Component<OIDCProps, any> {
  public componentDidMount() {
    const { goTo, routes, isSessionValid, isSignedOut } = this.props;
    const redirectUri =
      `${REACT_APP_ACCESS_ENDPOINT}/oidc/authorize?` +
      `apiKey=${REACT_APP_GIGYA_TOKEN}` +
      '&client_id=' +
      encodeURIComponent(REACT_APP_OIDC_CLIENT_ID) +
      '&redirect_uri=' +
      encodeURIComponent(`${REACT_APP_URL}/auth/success`);

    if (!isSessionValid || isSignedOut) {
      window.location.replace(redirectUri);
    } else {
      goTo(routes.dashboard.patient);
    }
  }
  public render() {
    return <div />;
  }
}
