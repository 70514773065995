import * as React from 'react';
import { createRoot } from 'react-dom/client';
import '@jello/ds/main';

import { App } from './app';
import './polyfills';

import { Manifest } from './modules/load-modules';

Manifest.setupModules();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <>
    <App />
    <div id="modal-root" />
  </>,
);
