import { FormControlOption } from 'src/components/forms/forms.types';

import { UserProfile } from '../store/profile.types';

import { ProfileForm } from './profile-edit.types';
import {
  constructBirthDayDropdownOptions,
  constructBirthYearDropdownOptions,
} from './profile-edit.utils';

export const INITIAL_PROFILE_EDIT_STATE: UserProfile = {
  associatedProfessional: '',
  birthDay: '',
  birthMonth: '',
  birthYear: '',
  country: '',
  diagnosisDate: '',
  city: '',
  address: '',
  postalCode: '',
  region: '',
  diabetesType: '',
  email: '',
  firstName: '',
  gender: '',
  healthCareId: '',
  language: '',
  lastName: '',
  mobile: '',
  photo: '',
  profileType: '',
};

export const ProfileEditFormName = 'patientProfileEdit';
export const ProfileEditFormModel: ProfileForm<UserProfile> = {
  firstName: 'firstName',
  lastName: 'lastName',
  birthDay: 'birthDay',
  birthMonth: 'birthMonth',
  birthYear: 'birthYear',
  country: 'country',
  city: 'city',
  address: 'address',
  postalCode: 'postalCode',
  region: 'region',
  email: 'email',
  gender: 'gender',
  healthCareId: 'healthCareId',
  language: 'language',
  mobile: 'mobile',
  photo: 'photo',
  diagnosisDate: 'diagnosisDate',
  diabetesType: 'diabetesType',
  associatedProfessional: 'associatedProfessional',
  profileType: 'profileType',
};

export const PROFILE_GENDERS = {
  MALE: 'm',
  FEMALE: 'f',
  DEFAULT: 'u',
};

export const PROFILE_EDIT_GENDERS: FormControlOption[] = [
  { label: 'profile.fields.gender.m', value: PROFILE_GENDERS.MALE },
  { label: 'profile.fields.gender.f', value: PROFILE_GENDERS.FEMALE },
  { label: 'profile.fields.gender.u', value: PROFILE_GENDERS.DEFAULT },
];

const CURRENT_YEAR = new Date().getFullYear();
const DAYS_TO_DISPLAY = 31;
const YEARS_TO_DISPLAY = 120;

export const PROFILE_EDIT_BIRTH_DAYS = constructBirthDayDropdownOptions(DAYS_TO_DISPLAY);

export const PROFILE_EDIT_BIRTH_YEARS = constructBirthYearDropdownOptions(
  YEARS_TO_DISPLAY,
  CURRENT_YEAR,
);

export const PROFILE_EDIT_BIRTH_MONTHS: FormControlOption[] = [
  { label: 'dates.months.full.jan', value: '1' },
  { label: 'dates.months.full.feb', value: '2' },
  { label: 'dates.months.full.mar', value: '3' },
  { label: 'dates.months.full.apr', value: '4' },
  { label: 'dates.months.full.may', value: '5' },
  { label: 'dates.months.full.jun', value: '6' },
  { label: 'dates.months.full.jul', value: '7' },
  { label: 'dates.months.full.aug', value: '8' },
  { label: 'dates.months.full.sep', value: '9' },
  { label: 'dates.months.full.oct', value: '10' },
  { label: 'dates.months.full.nov', value: '11' },
  { label: 'dates.months.full.dec', value: '12' },
];

export const PROFILE_EDIT_REGIONS = [{ value: 'ON', label: 'TBD: Data not found' }];
