import { Location } from 'history';
import * as React from 'react';

import { Div } from 'src/components/div';

import { NavigationBar } from 'src/components/navigation-bar';
import { NavigationRoutes } from 'src/navigation/store/navigation.types';
import { spacing } from 'src/theme/spacing';

export type AppNavigationProps = {
  readonly children?: React.ReactNode;
  readonly isDisplayed: boolean;
  readonly onLogout: () => void;
  readonly profileName: string;
  readonly profilePhoto: string;
  readonly routes: NavigationRoutes;
  readonly language: string;
  readonly openDTCModal: () => void;
  readonly openPIModal: () => void;
  readonly location: Location;
  readonly show: boolean;
};

export const AppNavigation: React.FunctionComponent<AppNavigationProps> = ({
  children,
  isDisplayed,
  onLogout,
  profileName,
  profilePhoto,
  routes,
  language,
  openDTCModal,
  openPIModal,
  show,
}) => (
  <Div>
    {isDisplayed && (
      <NavigationBar
        routes={routes}
        onLogout={onLogout}
        profileName={profileName}
        profilePhoto={profilePhoto}
        language={language === '-' ? 'en' : language}
        openDTCModal={openDTCModal}
        openPIModal={openPIModal}
        show={show}
      />
    )}
    <div style={{ marginTop: spacing.five, display: isDisplayed ? 'block' : 'none' }} />
    <Div pt={isDisplayed ? 4 : 0}>{children}</Div>
  </Div>
);
