import styled from 'styled-components';

import { createStyledComponent } from 'src/theme';
import {
  AnimationHelper,
  AnimationHelperInline,
  fadeIn,
  grow,
  rotate180ThenHold,
  rotate180WithDelay,
} from 'src/theme/animations';
import { borderRadius } from 'src/theme/border-radius';
import { colors } from 'src/theme/colors';
import { transitionEasing, transitionSpeed } from 'src/theme/transitions';
import { convertPxToRem } from 'src/utils/styles/rem-calc.utils';

import { LoadingRingDelay, LoadingRingSize, RingProps } from './loading-ring.types';

const borderWidthDefault = 3;
const colorActive = colors.brandBlue;

// Loader should spin for total duration, minus time required to show checkmark
export const calculateSpinDuration = (duration, infinite) =>
  infinite ? duration : duration - parseInt(transitionSpeed.slow, 10);

export const LoadingRingOuterDiv = createStyledComponent<'div', { size: LoadingRingSize }>('div')`
  position: relative;
  width: ${props => convertPxToRem(props.size)};
  height: ${props => convertPxToRem(props.size)};
  border-radius: ${borderRadius[4]};
  background-color: ${colors.clear};
  overflow: hidden;
`;
LoadingRingOuterDiv.displayName = 'LoadingRingOuterDiv';

export const LoadingRingIconSpan = styled(AnimationHelperInline)`
  color: ${colors.white};
`;
LoadingRingIconSpan.displayName = 'LoadingRingIconSpan';

export const LoadingRingContentDiv = createStyledComponent<'div', { delay: LoadingRingDelay }>(
  'div',
)`
  align-items: center;
  animation: ${fadeIn} ${transitionSpeed.slow} ${transitionEasing.enter} ${props => props.delay}ms;
  animation-fill-mode: forwards;
  background-color: ${colorActive};
  border-radius: ${borderRadius[4]};
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0;
  width: 100%;

  ${LoadingRingIconSpan} {
    animation: ${grow} ${transitionSpeed.slow} ${transitionEasing.enter} ${props => props.delay}ms;
    animation-fill-mode: forwards;
  }
`;
LoadingRingContentDiv.displayName = 'LoadingRingContentDiv';

export const LoadingRingSplitDiv = createStyledComponent<'div', RingProps>('div')`
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 50%;
`;
LoadingRingSplitDiv.displayName = 'LoadingRingSplitDiv';

export const LoadingRingProgressDiv = styled<any>(AnimationHelper)`
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;

  animation-delay: ${props => props.delay}ms;
  animation-duration: ${props => props.duration}ms;
  animation-fill-mode: forwards;
  animation-iteration-count: ${props => (props.infinite ? 'infinite' : '1')};
  border: ${convertPxToRem(borderWidthDefault)} solid ${colorActive};
  bottom: 0;
  top: 0;
`;
LoadingRingProgressDiv.displayName = 'LoadingRingProgressDiv';

export const LoadingRingLeftDiv = styled(LoadingRingSplitDiv)`
  left: 0;
  ${LoadingRingProgressDiv} {
    animation-name: ${rotate180WithDelay};
    animation-timing-function: ${transitionEasing.enter};
    border-bottom-right-radius: ${props => convertPxToRem(props.size)};
    border-left: 0;
    border-top-right-radius: ${props => convertPxToRem(props.size)};
    left: 100%;
    transform-origin: center left;
  }
`;
LoadingRingLeftDiv.displayName = 'LoadingRingLeftDiv';

export const LoadingRingRightDiv = styled<RingProps>(LoadingRingSplitDiv)`
  right: 0;
  ${LoadingRingProgressDiv} {
    animation-name: ${rotate180ThenHold};
    animation-timing-function: ${transitionEasing.exit};
    border-bottom-left-radius: ${props => convertPxToRem(props.size)};
    border-top-left-radius: ${props => convertPxToRem(props.size)};
    border-right: 0;
    left: -100%;
    transform-origin: center right;
  }
`;
LoadingRingRightDiv.displayName = 'LoadingRingRightDiv';
