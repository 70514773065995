import { map, pathOr, pick } from 'ramda';

import { CountriesPayload, Country } from 'src/core/countries/countries.types';
import { getDynamicConfig, getJSON } from 'src/utils';

import { createService } from '../service';

import { CountryOptions } from './country.types';

const {
  REACT_APP_API_MULE,
  REACT_APP_GIGYA_TOKEN,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
} = getDynamicConfig();

export const countriesTransformer = countryData => {
  const rawCountries = pathOr([], ['model'], countryData);
  const countries = map(pick(['id', 'name', 'isoCode']), rawCountries);
  return countries as Country[];
};

const countryRequest = ({ accessToken }: CountryOptions): Promise<CountriesPayload> =>
  getJSON(`${REACT_APP_API_MULE}/settings/api/v1/countries`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ApiKey: REACT_APP_GIGYA_TOKEN,
      Accept: 'application/json',
      client_id: REACT_APP_CLIENT_ID,
      client_secret: REACT_APP_CLIENT_SECRET,
    },
  });

export const countryService = createService(countryRequest, countriesTransformer);
