import { Action } from 'src/app/store/app.types';

import { DateFilterState } from '../date-filters/store/date-filters.types';

export enum UIActionType {
  SHOW_APP_NAVIGATION = 'SHOW_APP_NAVIGATION',
  HIDE_APP_NAVIGATION = 'HIDE_APP_NAVIGATION',
}

export type ShowAppNavigationAction = Action<UIActionType.SHOW_APP_NAVIGATION>;

export type HideAppNavigationAction = Action<UIActionType.HIDE_APP_NAVIGATION>;

export type ToggleState = {
  readonly isDisplayed: boolean;
};

export type UIState = {
  readonly toggles: ToggleState;
  readonly dateFilters: DateFilterState;
};

export type UIActions = ShowAppNavigationAction | HideAppNavigationAction;

export type UIReducerActions = UIActions;
