import * as React from 'react';
import { omitStylesFromTestEnv } from 'src/utils/config';
import styled, { css } from 'styled-components';

import { BaseStyleProps } from '../component.types';

import { GRID_DEFAULT_COLUMNS, GRID_DEFAULT_SPACING_SMALL, GRID_SUPPORT } from './grid.constants';
import { calculateWidthFromGridSpan, createGridSpan } from './grid.utils';

type GridItemProps = BaseStyleProps & {
  readonly columns?: number;
  readonly span: number;
};

export const GridItemComponent: React.FunctionComponent<GridItemProps> = ({
  children,
  className,
}) => <div className={className}>{children}</div>;

// Code Coverage seems incapable of parsing functions inside styled component CSS
// Unit tests are included in grid.utils
/* istanbul ignore next */
const GridSupportStyles = css`
  @supports (${GRID_SUPPORT}) {
    display: block;
    flex: none;
    grid-column: ${(props: GridItemProps) => createGridSpan(props.span)};
    height: 100%;
    max-width: 100%;
    padding: 0;
  }
`;

// CSS Grid child - item column width set by prop 'span'
export const GridItem = styled(GridItemComponent)`
  padding: ${GRID_DEFAULT_SPACING_SMALL};
  flex: 1 1
    ${({ columns = GRID_DEFAULT_COLUMNS, span }: GridItemProps) =>
      calculateWidthFromGridSpan(span, columns)};
  flex-wrap: wrap;
  max-width: ${({ columns = GRID_DEFAULT_COLUMNS, span }: GridItemProps) =>
    calculateWidthFromGridSpan(span, columns)};

  ${omitStylesFromTestEnv(GridSupportStyles)};
`;
GridItem.displayName = 'GridItem';
