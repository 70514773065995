import { isNil, reject } from 'ramda';
import { getDynamicConfig, putJSON } from 'src/utils';

import { createService } from '../../service';
import { transformISOToJavaSimpleDateFormat } from '../../transformers/date.transformers';

import {
  PutStripsDeliveryData,
  PutStripsDeliveryOptions,
  PutStripsDeliveryService,
} from './put-strips-delivery.types';

const {
  REACT_APP_API_MULE,
  REACT_APP_GIGYA_TOKEN,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
} = getDynamicConfig();

const putStripsDeliveryTransform = data => data.model;

export const removeEmptyFields = reject(isNil);

const putStripsDeliveryRequest = ({
  patientId,
  accessToken,
  status = null,
  deliveryDate = null,
}: PutStripsDeliveryOptions): Promise<PutStripsDeliveryData> => {
  const payload = removeEmptyFields({
    deliveryDate: deliveryDate ? transformISOToJavaSimpleDateFormat(deliveryDate) : null,
    status,
  });
  return putJSON(
    `${REACT_APP_API_MULE}/strips/api/v1/delivery/patients/${patientId}/home-delivery`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ApiKey: REACT_APP_GIGYA_TOKEN,
        client_id: REACT_APP_CLIENT_ID,
        client_secret: REACT_APP_CLIENT_SECRET,
      },
      body: payload as any,
    },
  );
};

export const putStripsDeliveryService: PutStripsDeliveryService = createService(
  putStripsDeliveryRequest,
  putStripsDeliveryTransform,
);
