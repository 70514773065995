import { combineEpics, Epic } from 'redux-observable';

import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { State } from 'src/app/store/app.types';
import {
  fetchAssociatedProfessionalSuccess,
  fetchPatientInfoError,
  fetchPatientInfoSuccess,
} from 'src/core/patient-info/patient-info.actions';
import { selectPatientId } from 'src/core/patient-info/patient-info.selectors';
import {
  PatientInfoActions,
  PatientInfoActionType,
} from 'src/core/patient-info/patient-info.types';
import { selectAccessToken } from 'src/core/session/oidc/oidc.selector';
import { SessionActions } from 'src/core/session/session.types';
import { associatedProfessionalService } from 'src/services/associated-professional';
import { AssociatedProfessionalService } from 'src/services/associated-professional/associated-professional.types';
import { patientInfoService } from 'src/services/patient-info';
import { PatientInfoService } from 'src/services/patient-info/patient-info.types';

export const fetchPatientInfoEpic = (
  service: PatientInfoService,
): Epic<PatientInfoActions | SessionActions, State> => (action$, store$) =>
  action$
    .ofType(PatientInfoActionType.FETCH_PATIENT_INFO_START)
    .debounceTime(1000)
    .switchMap(() => {
      const accessToken = selectAccessToken(store$.getState());
      return Observable.fromPromise(service({ accessToken }))
        .map(data => fetchPatientInfoSuccess(data))
        .pipe(catchError(err => Observable.of(fetchPatientInfoError(err))));
    });

export const fetchAssociatedProfessionalEpic = (
  service: AssociatedProfessionalService,
): Epic<PatientInfoActions, State> => (action$, store$) =>
  action$
    .ofType(PatientInfoActionType.FETCH_PATIENT_INFO_SUCCESS)
    .debounceTime(1000)
    .switchMap(() => {
      const accessToken = selectAccessToken(store$.getState());
      const patientId = selectPatientId(store$.getState());
      return Observable.fromPromise(service({ patientId, accessToken }))
        .map(data => fetchAssociatedProfessionalSuccess(data))
        .pipe(catchError(err => Observable.of(fetchPatientInfoError(err))));
    });

export const patientInfoEpics = combineEpics(
  fetchPatientInfoEpic(patientInfoService),
  fetchAssociatedProfessionalEpic(associatedProfessionalService),
);
