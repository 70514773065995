export const UPDATE_STRIPS_DELIVERY_MODAL_ROOT = 'dashboard/patient';
export const UPDATE_STRIPS_DELIVERY_WIZARD_ROOT = `${UPDATE_STRIPS_DELIVERY_MODAL_ROOT}/strip-delivery`;

const addWizardRoot = (url: string): string => `${UPDATE_STRIPS_DELIVERY_WIZARD_ROOT}/${url}`;

export const UPDATE_STRIPS_DELIVERY_WIZARD_ROUTES = {
  START: addWizardRoot('change-or-pause-delivery'),
  RESUME: addWizardRoot('resume-delivery'),
  RESUME_RESULT: addWizardRoot('resume-delivery-result'),
  PAUSE: addWizardRoot('pause-delivery'),
  PAUSE_RESULT: addWizardRoot('pause-delivery-result'),
  CHANGE_DATE: addWizardRoot('change-delivery'),
  CHANGE_DATE_CONFIRM: addWizardRoot('change-delivery-confirm'),
  CHANGE_DATE_RESULT: addWizardRoot('change-delivery-result'),
};
