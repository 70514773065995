import { includes, path, valuesIn } from 'ramda';
import { createSelector } from 'reselect';
import { PERMISSIONS } from 'src/core/permissions/permissions.constants';

import { transformPermissions } from './permissions.utils';

export const selectPermissionState = path(['permissions']);
export const selectCurrentPermissions = createSelector(selectPermissionState, permissions =>
  transformPermissions(permissions),
);

export const selectIsEMR = createSelector(selectPermissionState, permissions =>
  includes(PERMISSIONS.ELECTRONIC_MEDICAL_RECORD, valuesIn(permissions)),
);

const visitManagement = PERMISSIONS.ROLE_VISIT_MANAGEMENT;
const rpmProgramme = PERMISSIONS.ROLE_RPM_PROGRAM;
const etapesProgramme = PERMISSIONS.ROLE_ETAPES_PROGRAM;

export const selectIsPatientAllowedVisitModule = createSelector(
  selectPermissionState,
  permissions => {
    const hasVisitManagementEnabled = includes(visitManagement, valuesIn(permissions));
    const enrolledInRPMProgramme = includes(rpmProgramme, valuesIn(permissions));
    const enrolledInEtapesProgramme = includes(etapesProgramme, valuesIn(permissions));

    const enrolledInSupportedProgramme = enrolledInRPMProgramme || enrolledInEtapesProgramme;

    return hasVisitManagementEnabled && enrolledInSupportedProgramme;
  },
);
