import {
  AssociatedProfessional,
  PatientInfoDetails,
  PatientInfoUser,
} from 'src/core/patient-info/patient-info.types';

export const defaultPatientInfoDetails: PatientInfoDetails = {
  id: '',
  clinicalRecordNumber: '',
  healthCareSystemId: '',
  deviceType: '',
  diagnosticDate: '',
  deviceTypeLabel: '',
  timeZone: '',
  pregnant: false,
  nursingHome: false,
  professionalList: [],
  relevantClinicalData: '',
  units: '',
  expectedDeliverDate: '',
  downloadNoticePeriod: '',
  diabetesType: '',
  lastGlucoseDate: '',
  patientDevices: [],
  treatmentName: '',
  deviceTypeIcon: '',
  multilingualDeviceTypeLabel: '',
};
export const defaultAssociatedProfessional: AssociatedProfessional[] = [
  {
    id: '',
    name: '',
    surname: '',
    surname2: '',
  },
];
export const defaultPatientUser: PatientInfoUser = {
  id: '',
  name: '',
  surname: '',
  surname2: '',
  address: {
    id: '',
    address: '',
    postalCode: '',
    city: '',
    province: '',
    country: {
      id: '',
      name: '',
      isoCode: '',
      labelProperty: '',
      labelText: '',
      language: {
        id: '',
        languageId: '',
        labelProperty: '',
        labelText: '',
        isoCode: '',
        key: '',
        value: '',
      },
      timeZone: '',
      profilesList: '',
      currency: '',
      prefix: '',
      key: '',
      value: '',
    },
  },
  fullname: '',
  inactivate: false,
  gender: '',
  phone: '',
  birthday: '',
  email: '',
  loginId: '',
  language: '',
  languageId: '',
  languageIsoCode: '',
  languageCountryIsoCode: '',
  locale: '',
  role: '',
  departmentProfileId: '',
  profile: '',
  countryId: '',
  inactivateReason: '',
  inactivateComment: '',
  departmentId: '',
  centerId: '',
  areaId: '',
  locked: false,
  lastAccess: '',
  passwordReset: false,
  viewNotifications: false,
  countryIsoCode: '',
  departmentName: '',
  centerName: '',
  accessEnum: '',
  profileId: '',
  gigyaUid: '',
  activeInGigya: '',
  hcpIsAccessible: false,
  fakeMail: false,
  headerWelcome: '',
  languageIsoCodeComplete: '',
  noPhone: false,
};
