import { flex } from 'styled-system';

import { Div } from '../div/div.component';
import styled from 'styled-components';

export const Column = styled(Div)`
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  ${flex};
`;
