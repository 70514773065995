import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { signoutStart } from 'src/core/session/session.actions';
import { selectCombinedRoutes } from 'src/navigation/store/navigation.selectors';
import { mapDispatchers } from 'src/utils';

import { LoginSystemErrorModal } from './login-system-error.modal';
import {
  LoginSystemErrorContainerProps,
  LoginSystemErrorModalProps,
} from './login-system-error.types';

const loginSystemErrorModalConnector = createStructuredSelector({
  routes: selectCombinedRoutes,
});

const dispatchers = mapDispatchers({
  onRetry: signoutStart,
  goTo: (path: string) => push(path),
});

export const LoginSystemErrorContainer = compose<
  LoginSystemErrorModalProps,
  LoginSystemErrorContainerProps
>(
  connect(
    loginSystemErrorModalConnector,
    dispatchers,
  ),
)(LoginSystemErrorModal);
