import * as React from 'react';

import { colors } from 'src/theme/colors';

import { SvgIcon } from '../icon.component';

type Props = {
  height?: number;
  width?: number;
  fillColor?: string;
};

const originalWidth = 71;
const originalHeight = 71;
const aspectRatio = originalWidth / originalWidth;

export const HeartIcon = ({ fillColor = colors.blueMarine, height = originalHeight }: Props) => (
  <SvgIcon
    width={height * aspectRatio}
    height={height}
    originalWidth={originalWidth}
    originalHeight={originalHeight}
  >
    <g fill="none" fillRule="evenodd">
      <circle fill="#5D8FDF" cx={35.338} cy={35.812} r={34.861} />
      <path
        d="M35.235 27.844c1.394-3.306 4.58-5.616 8.287-5.616 4.993 0 8.588 4.271 9.04 9.361 0 0 .244 1.264-.293 3.539-.731 3.098-2.45 5.85-4.768 7.95L35.235 54.023l-12.06-10.945c-2.318-2.1-4.037-4.853-4.768-7.951-.537-2.275-.293-3.538-.293-3.538.452-5.09 4.047-9.361 9.04-9.361 3.707 0 6.686 2.31 8.08 5.616z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </SvgIcon>
);
