import { createStyledComponent, theme } from 'src/theme';
import { applyRatioToRem, convertPxToRem, hexToRGBA } from 'src/utils/styles';

const colorDisabled = theme.colors.silverLight;
const sizeDefault = convertPxToRem(42);

const borderRatio = {
  default: 1 / 9,
  large: 1 / 15,
};

const getBorderRatioBySize = size => (size > 60 ? borderRatio.large : borderRatio.default);

const calculateBorderSize = size =>
  applyRatioToRem(convertPxToRem(size), getBorderRatioBySize(size));

export const constructWrapperBackground = color => hexToRGBA(color, 0.3);

type BadgeWrapperSpanProps = {
  bgColor: string;
  emptyInnerCircle: boolean;
  disabled: boolean;
  size: number;
};

export const BadgeWrapperSpan = createStyledComponent<'span', BadgeWrapperSpanProps>('span')`
  background-color: ${props =>
    props.emptyInnerCircle
      ? 'transparent'
      : props.disabled
        ? constructWrapperBackground(colorDisabled)
        : constructWrapperBackground(props.bgColor)};
  border-radius: 50%;
  border-style: ${props => (props.emptyInnerCircle ? 'solid' : 'inherit')};
  border-width: ${props => (props.emptyInnerCircle ? calculateBorderSize(props.size) : 'inherit')};
  border-color: ${props =>
    props.emptyInnerCircle ? constructWrapperBackground(props.bgColor) : 'inherit'};
  display: inline-block;
  padding: ${props =>
    props.emptyInnerCircle
      ? 0
      : props.size
        ? calculateBorderSize(props.size)
        : calculateBorderSize(sizeDefault)};
  transition: ${props => props.theme.transitions.default};
`;
BadgeWrapperSpan.displayName = 'BadgeWrapperSpan';

type BadgeIconSpanProps = BadgeWrapperSpanProps;

export const BadgeIconSpan = createStyledComponent<'span', BadgeIconSpanProps>('span')`
  align-items: center;
  background-color: ${props =>
    props.emptyInnerCircle
      ? 'transparent'
      : props.bgColor && !props.disabled
        ? props.bgColor
        : colorDisabled};
  border-radius: 50%;
  color: ${props => props.theme.colors.white};
  display: flex;
  height: ${props => (props.size ? convertPxToRem(props.size) : sizeDefault)};
  justify-content: center;
  transition: ${props => props.theme.transitions.slow};
  width: ${props => (props.size ? convertPxToRem(props.size) : sizeDefault)};
  will-change: transform;
  color: ${props => props.theme.colors.brandBlue};
`;
BadgeIconSpan.displayName = 'BadgeIconSpan';
