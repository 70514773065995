import React, { FunctionComponent, useState } from 'react';
import { HelpIcon, HomeIcon, LogoutIcon, UploadIcon } from 'src/assets/icons';
import { Avatar } from 'src/components/avatar';
import { Block } from 'src/components/block';
import { Button, ButtonSizes, ButtonStyles } from 'src/components/button';
import { LocalizedText } from 'src/components/localized-text';
import { Span } from 'src/components/span';
import VisitModule from 'src/modules/visit-module/visit-module';
import { NavigationRoutes } from 'src/navigation/store/navigation.types';
import { colors } from 'src/theme/colors';
import { spacing } from 'src/theme/spacing';
import { zIndexScale } from 'src/theme/z-index';
import { NavigationBarDropdown } from './components/navigation-bar-dropdown';

import {
  Divider,
  LogoutIconShadow,
  NavBarOverlay,
  NavigationWithDropdownLabel,
  NavigationWithDropdownContainer,
  NavigationBarLink,
  NavigationBarLinkLabel,
  NavigationBarLinkLabelName,
  NavigationBarLinksList,
  NavigationBarLinksListItem,
  NavigationBarModalLink,
  NavigationBarProfileContainer,
  NavigationBarProfileLink,
  NavigationBarWrapper,
} from './navigation-bar.style';

type NavigationBarProps = {
  readonly children?: React.ReactNode;
  readonly onLogout: () => void;
  readonly profileName: string;
  readonly profilePhoto: string;
  readonly routes: NavigationRoutes;
  readonly language: string;
  readonly openDTCModal: () => void;
  readonly openPIModal: () => void;
  readonly show: boolean;
};

export const NavigationBar: FunctionComponent<NavigationBarProps> = ({
  onLogout,
  profileName,
  profilePhoto,
  routes,
  language,
  openDTCModal,
  openPIModal,
}) => {

  const [show, setShow] = useState(false);

  return (
    <NavigationBarWrapper>
      <Block position="absolute" bottom="0" left="0" right="0">
        <NavBarOverlay />
        <VisitModule />
      </Block>
      <Block py={3} px={4} display="flex">
        <NavigationBarLinksList>
          <NavigationBarLinksListItem>
            <NavigationBarLink to={routes.home}>
              <Span mr={3}>
                <HomeIcon fillColor={colors.white} />
              </Span>
              <NavigationBarLinkLabel>
                <LocalizedText label={'navigation.home'} />
              </NavigationBarLinkLabel>
              <Span ml={4} mr={4}>
                <Divider />
              </Span>
            </NavigationBarLink>
          </NavigationBarLinksListItem>
          <NavigationBarLinksListItem>
            <NavigationWithDropdownContainer onClick={()=> setShow(!show)}>
              <NavigationWithDropdownLabel>
                <Span mr={3}>
                  <HelpIcon fillColor={colors.white} />
                </Span>
                <NavigationBarLinkLabel>
                  <LocalizedText label={'navigation.help'} />
                </NavigationBarLinkLabel>
                <Span ml={4} mr={4}>
                  <Divider />
                </Span>
              </NavigationWithDropdownLabel>
              <NavigationBarDropdown language={language} show={show} openPIModal={openPIModal}/>
            </NavigationWithDropdownContainer>
          </NavigationBarLinksListItem>
          <NavigationBarLinksListItem>
            <NavigationBarModalLink onClick={openDTCModal}>
              <Span mr={3}>
                <UploadIcon fillColor={colors.white} />
              </Span>
              <NavigationBarLinkLabel>
                <LocalizedText label={'navigation.DTC'} />
              </NavigationBarLinkLabel>
            </NavigationBarModalLink>
          </NavigationBarLinksListItem>
        </NavigationBarLinksList>
      </Block>
      <Block display="flex" px={4} justifySelf="flex-end" ml="auto" mr={3} py={3}>
        {profileName && (
          <NavigationBarProfileContainer>
            <NavigationBarProfileLink to={routes.viewProfile}>
              <Block width={spacing.four} mr={3}>
                <Avatar
                  image={profilePhoto}
                  title={profileName}
                  placeholderFontSize="1rem"
                  placeholderIsReversed
                />
              </Block>
              <NavigationBarLinkLabelName>{profileName}</NavigationBarLinkLabelName>
            </NavigationBarProfileLink>
          </NavigationBarProfileContainer>
        )}
      </Block>
      <Block display="flex" mr={4} zIndex={zIndexScale.ten} position="relative">
        <Button buttonStyle={ButtonStyles.IMG} size={ButtonSizes.XSMALL} onClick={onLogout}>
          <LogoutIconShadow>
            <LogoutIcon fillColor={colors.white} />
          </LogoutIconShadow>
        </Button>
      </Block>
    </NavigationBarWrapper>
  );
};
