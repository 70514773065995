import { pipe } from 'ramda';
import { convertISO, toFormat, toString, toUTC } from 'src/utils/dates';

// Leverage Luxon library to parse and convert Java Date to JS DateStr
// Ex. 2018-06-27T00:00:00+0000 -> 2018-06-27T00:00:00.000Z

const JAVA_SIMPLE_DATE_FORMAT = `yyyy-MM-dd'T'HH:mm:ssZZZ`;

export const transformJavaSimpleDateFormatToISO = (dateString: string): string =>
  pipe(
    convertISO,
    toUTC,
    toString,
  )(dateString);

export const transformISOToJavaSimpleDateFormat = (dateString): string =>
  pipe(
    convertISO,
    toUTC,
    toFormat(JAVA_SIMPLE_DATE_FORMAT),
  )(dateString);
