import * as React from 'react';

import { SvgIcon } from '../icon.component';
import { DEFAULT_ICON_COLOR } from '../icon.constants';
import { calculateAspectRatio } from '../icon.utils';

type IconProps = {
  height?: number;
  strokeColor?: string;
  strokeWeight?: number;
};

export const CheckmarkIcon: React.FunctionComponent<IconProps> = ({
  height = 9,
  strokeColor = DEFAULT_ICON_COLOR,
  strokeWeight = 1.7,
}) => {
  const originalWidth = 11;
  const originalHeight = 9;
  const aspectRatio = calculateAspectRatio(originalWidth, originalHeight);

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <path
        stroke={strokeColor}
        strokeWidth={strokeWeight}
        d="M1.806 4.393l2.797 3.858L10.237 1.8"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};
