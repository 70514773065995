import * as React from 'react';
import { withRouter } from 'react-router-dom';

import { AuthenticationNavigation } from './authentication/authentication.navigation';
import { PatientDashboardNavigation } from './patient-dashboard/patient-dashboard.navigation';
import { ProfileNavigation } from './profile/profile.navigation';

export const Navigation = withRouter(() => (
  <React.Fragment>
    <AuthenticationNavigation key="authentication-navigation" />
    <PatientDashboardNavigation key="dashboard-navigation" />
    <ProfileNavigation key="profile-navigation" />
  </React.Fragment>
));
