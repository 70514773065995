import * as queryString from 'qs';
import * as React from 'react';

export class OIDCSuccessComponent extends React.Component<any, any> {
  public static displayName = 'OIDCSuccessComponent';
  public componentDidMount() {
    const qs = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true });
    this.props.fetchOidcTokens(qs.code);
  }

  public componentWillReceiveProps(nextProps) {
    if (nextProps.accessToken && nextProps.accessToken !== this.props.accessToken) {
      this.props.onLoginSuccess();
    }
  }

  public render() {
    return <div />;
  }
}
