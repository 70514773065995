import { Guard, isNumber, isString, nullable } from 'io-guard';
import { pathOr } from 'ramda';

import { Language, LanguageOption } from 'src/core/languages/languages.types';
import { getDynamicConfig, getJSON } from 'src/utils';

import { createService } from '../service';

import { LanguageData, LanguageOptions } from './language.types';

const {
  REACT_APP_API_MULE,
  REACT_APP_GIGYA_TOKEN,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
} = getDynamicConfig();

export const isValidLanguageAPIData = Guard<Language>({
  id: nullable(isNumber),
  languageId: isString,
  labelProperty: isString,
  labelText: isString,
  isoCode: isString,
  key: isString,
  value: isString,
  languageCountryIsoCode: isString,
  locale: isString,
});

const transformLanguage = (language: Language): LanguageOption | undefined =>
  isValidLanguageAPIData(language)
    ? { value: language.locale, label: language.labelText }
    : undefined;

export const languagesTransformer = languageData =>
  pathOr([], ['model'])(languageData)
    .map(transformLanguage)
    .filter(languageOption => !!languageOption);

const languageRequest = ({ accessToken }: LanguageOptions): Promise<LanguageData> =>
  getJSON(`${REACT_APP_API_MULE}/settings/api/v1/associations/languages/active`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ApiKey: REACT_APP_GIGYA_TOKEN,
      Accept: 'application/json',
      client_id: REACT_APP_CLIENT_ID,
      client_secret: REACT_APP_CLIENT_SECRET,
    },
  });

export const languageService = createService(languageRequest, languagesTransformer);
