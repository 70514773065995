import { multiply, pipe } from 'ramda';

import { BASE_FONT_SIZE_PX } from 'src/theme/typography';

// Decorate a number with the "rem" unit of measurement
export const addRemUnit = (value: number): string => `${value}rem`;

// Remove a unit of measurement (rem, em, px) from a number
export const stripUnit = (value: string): number => {
  const numericValue = parseFloat(value.toString().replace(/[^\d.-]/g, ''));
  return !isNaN(numericValue) ? numericValue : 0;
};

// Convert a font size in px to rems
// Default to unitless 0 if not available
export const convertPxToRem = (value: number, base: number = BASE_FONT_SIZE_PX): string =>
  value ? addRemUnit(value / base) : '0';

// Internal use only - tally a combination of rem values
const combineRemsReducer = (total: number, value: string): number => total + stripUnit(value);

// Add up a set of rem values and return as an "rem" unit
// Use to combine values from the spacing system for edge cases
export const combineRems = (...values: string[]): string =>
  addRemUnit(values.reduce(combineRemsReducer, 0));

// Return the inverse of a given rem value
// Useful for creating responsive negative margins
export const invertRem = (value: string): string => addRemUnit(stripUnit(value) * -1);

export const applyRatioToRem = (value: string, ratio: number): string =>
  pipe(
    stripUnit,
    multiply(ratio),
    addRemUnit,
  )(value);
