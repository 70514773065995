import * as React from 'react';
import { StyledSystemProps } from 'src/theme/theme.types';
import { omitStylesFromTestEnv } from 'src/utils/config';
import styled, { css } from 'styled-components';

import { BaseStyleProps } from '../component.types';

import { GRID_DEFAULT_SPACING_SMALL, GRID_SUPPORT } from './grid.constants';
import { createGridSpacing, createGridTracks } from './grid.utils';

type GridContainerProps = BaseStyleProps & {
  readonly columns?: number;
  readonly gap?: string;
  readonly minHeight?: string;
  readonly rows?: number;
} & StyledSystemProps;

export const GridContainerComponent: React.FunctionComponent<GridContainerProps> = ({
  children,
  className,
}) => <div className={className}>{children}</div>;

// Code Coverage seems incapable of parsing functions inside styled component CSS
// Unit tests are included in grid.utils
/* istanbul ignore next */
const GridSupportStyles = css`
  @supports (${GRID_SUPPORT}) {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: ${(props: GridContainerProps) => createGridSpacing(props.gap)};
    grid-template-columns: ${(props: GridContainerProps) => createGridTracks(props.columns)};
    grid-template-rows: ${(props: GridContainerProps) => createGridTracks(props.rows)};
    margin-left: 0;
    margin-right: 0;
  }
`;

export const GridContainer = styled(GridContainerComponent)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 ${GRID_DEFAULT_SPACING_SMALL};
  min-height: ${({ minHeight }) => minHeight || 'auto'};
  min-width: 100%;

  ${omitStylesFromTestEnv(GridSupportStyles)};
`;
GridContainer.displayName = 'GridContainer';
