import * as React from 'react';

import { SvgIcon } from '../icon.component';
import { DEFAULT_ICON_COLOR } from '../icon.constants';
import { calculateAspectRatio } from '../icon.utils';

type IconProps = {
  height?: number;
  fillColor?: string;
};

export const LogoutIcon: React.FunctionComponent<IconProps> = ({
  height = 25,
  fillColor = DEFAULT_ICON_COLOR,
}) => {
  const originalWidth = 32;
  const originalHeight = 32;
  const aspectRatio = calculateAspectRatio(originalWidth, originalHeight);

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <path
        d="M24.054 16.641L22.6 18.095a.75.75 0 0 0 1.06 1.061l2.745-2.744a.75.75 0 0 0 0-1.06l-2.744-2.745a.75.75 0 1 0-1.06 1.06l1.473 1.474h-6.835a.75.75 0 0 0 0 1.5h6.815zM16 0c8.822 0 16 7.178 16 16s-7.178 16-16 16S0 24.822 0 16 7.178 0 16 0zm3.679 19.334v2.251h-8.864v-11.29h8.87v2.182c0 .374.35.677.782.677.433 0 .783-.303.783-.677v-2.86c0-.373-.35-.677-.783-.677H10.033c-.433 0-.783.304-.783.678v12.645c0 .374.35.677.783.677h10.429c.432 0 .782-.303.782-.677v-2.93c0-.373-.35-.677-.782-.677-.433 0-.783.304-.783.678z"
        fill={fillColor}
      />
    </SvgIcon>
  );
};
