import { getDynamicConfig, getJSON } from 'src/utils';

import { createService } from 'src/services/service';

import { GetAccountInfoService } from './get-account-info.types';

const {
  REACT_APP_API_GATEWAY,
  REACT_APP_GIGYA_TOKEN,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
} = getDynamicConfig();

const getAccountInfo = (url: string) => ({ accessToken }) =>
  getJSON(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ApiKey: REACT_APP_GIGYA_TOKEN,
      client_id: REACT_APP_CLIENT_ID,
      client_secret: REACT_APP_CLIENT_SECRET,
    },
    body: {
      apiKey: REACT_APP_GIGYA_TOKEN,
      token: accessToken,
      extraProfileFields: 'languages,address,phones,locale',
      include: 'profile,data,loginIDs',
    } as any,
  });

const accountInfoTransform = account => account;

type DevMode = {
  devMode: boolean;
};

export const getAccountInfoService = ({ devMode = false }: DevMode): GetAccountInfoService => {
  const url = `${REACT_APP_API_GATEWAY}/Gigya/v1/gigya/getAccountInfo`;
  if (devMode) {
    // @ts-ignore
    return Promise.resolve({ data: {} });
  }
  return createService(getAccountInfo(url), accountInfoTransform);
};
