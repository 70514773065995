import * as React from 'react';

import { Div } from 'src/components/div';

import { GradientStrip, OuterShadow, WhiteDot, WhiteRing } from './gradient-line.style';

export const GradientLine: React.FunctionComponent<{}> = () => (
  <GradientStrip display="flex" justifyContent="center">
    <Div display="flex">
      <WhiteDot alignSelf="center" />
    </Div>
    <Div display="flex" justifyContent="center" width="60%">
      <OuterShadow alignSelf="center">
        <WhiteRing>
          <WhiteDot m="0 auto" alignSelf="center" />
        </WhiteRing>
      </OuterShadow>
    </Div>
    <Div display="flex">
      <WhiteDot alignSelf="center" />
    </Div>
  </GradientStrip>
);
