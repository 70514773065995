import * as React from 'react';
import { translate } from 'react-i18next';

import { CalendarOutlineIcon } from 'src/assets/icons';
import { Button, ButtonSizes } from 'src/components/button';
import { Div } from 'src/components/div';
import { LocalizedText } from 'src/components/localized-text';
import { Span } from 'src/components/span';
import { colors } from 'src/theme/colors';

import { fontWeights } from 'src/theme/weight';

import { I18nTranslate } from 'src/i18n/types';
import {
  ModalButtonDiv,
  ModalContentDiv,
  ModalDividerDiv,
  ModalHalfColumn,
  ModalHeading,
  ModalSubheading,
} from 'src/ui/modal/components';

type ChangePauseStripsDeliveryProps = {
  formattedNextDeliveryDate: string;
  goToPauseDelivery: any;
  goToChangeDelivery: any;
  t: I18nTranslate;
};

export const ChangePauseStripsDeliveryComponent: React.FunctionComponent<ChangePauseStripsDeliveryProps> = ({
  formattedNextDeliveryDate,
  goToPauseDelivery,
  goToChangeDelivery,
  t,
}) => (
  <Div display="flex">
    <ModalHalfColumn borderRight="1px solid" borderColor={colors.silverDark}>
      <ModalHeading>
        <LocalizedText label="stripsDelivery.titles.changeDelivery" />
      </ModalHeading>

      <ModalDividerDiv />

      <ModalContentDiv>
        <ModalSubheading>
          <LocalizedText label="stripsDelivery.labels.deliveryDate" />
        </ModalSubheading>
        <Div display="flex" alignItems="flex-end">
          <CalendarOutlineIcon fillColor={colors.brandBlue} />
          <Span pl={3} fontWeight={fontWeights.semiBold}>
            {formattedNextDeliveryDate}
          </Span>
        </Div>
      </ModalContentDiv>

      <ModalDividerDiv />

      <ModalButtonDiv>
        <Button
          onClick={goToChangeDelivery}
          label={t('stripsDelivery.labels.changeDeliveryDate')}
          size={ButtonSizes.LARGE}
        />
      </ModalButtonDiv>
    </ModalHalfColumn>
    <ModalHalfColumn>
      <ModalHeading>
        <LocalizedText label="stripsDelivery.titles.pauseDelivery" />
      </ModalHeading>

      <ModalDividerDiv />

      <ModalContentDiv>
        <Span>
          <LocalizedText label="stripsDelivery.labels.pauseDeliveryDetails" />
          <Span fontWeight={fontWeights.bold}>{' ' + formattedNextDeliveryDate}</Span>
        </Span>
      </ModalContentDiv>

      <ModalDividerDiv />

      <ModalButtonDiv>
        <Button
          onClick={goToPauseDelivery}
          label={t('stripsDelivery.labels.pauseDelivery')}
          size={ButtonSizes.LARGE}
        />
      </ModalButtonDiv>
    </ModalHalfColumn>
  </Div>
);

export const ChangePauseStripsDelivery = translate()(ChangePauseStripsDeliveryComponent);
