import * as React from 'react';

import { IFrame } from 'src/components/iframe';
import { LocalizedText } from 'src/components/localized-text';
import { AuthenticationPage } from 'src/domains/authentication/components/authentication-page.component';
import {
  DescriptionColumn,
  DescriptionContainer,
  DescriptionHeader,
  FormColumn,
} from 'src/domains/authentication/components/authentication.style';

import { getDynamicConfig } from 'src/utils';

export const ResetPasswordPatientComponent = ({ lang, pwrt }) => (
  <AuthenticationPage>
    <FormColumn flex={3} pl="0px" justifyContent="center" height="100vh">
      <IFrame
        title="resetPassword"
        scrolling="no"
        width="500px"
        height="550px"
        resizeOnLoad={false}
        src={`${getDynamicConfig().REACT_APP_ACCESS_ENDPOINT}/v1/gigya/resetPassword?apiKey=${
          getDynamicConfig().REACT_APP_GIGYA_TOKEN
        }&pwrt=${pwrt}&lng=${lang}`}
      />
    </FormColumn>
    <DescriptionColumn>
      <DescriptionContainer>
        <DescriptionHeader>
          <LocalizedText label="login.description" />
        </DescriptionHeader>
      </DescriptionContainer>
    </DescriptionColumn>
  </AuthenticationPage>
);
