import { combineEpics, Epic } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/mergeMap';
import { continueWhereYouLeftOffWorkaroundEpic } from 'src/core/continue-where-you-left-off-workaround/chrome-workaround.epics';
import { countriesEpics } from 'src/core/countries/countries.epics';
import { languageEpics } from 'src/core/languages/languages.epics';
import { patientInfoEpics } from 'src/core/patient-info/patient-info.epics';
import { permissionsEpics } from 'src/core/permissions/permissions.epics';
import { oidcEpics } from 'src/core/session/oidc/oidc.epics';
import { signoutStart } from 'src/core/session/session.actions';
import { sessionEpics } from 'src/core/session/session.epics';
import { userActivityEpics } from 'src/core/user-activity/user-activity.epics';
import { userEpics } from 'src/core/user/user.epics';
import { UserActionType } from 'src/core/user/user.types';
import { uiEpics } from 'src/ui/store/ui.epics';

import { Action, PayloadAction, State } from './app.types';

export const rootEpic = combineEpics<Action<string> | PayloadAction<string, any>, State>(
  userEpics,
  sessionEpics,
  oidcEpics,
  uiEpics,
  permissionsEpics,
  patientInfoEpics,
  userActivityEpics,
  languageEpics,
  countriesEpics,
  continueWhereYouLeftOffWorkaroundEpic({
    START_EVENT: UserActionType.FETCH_ACCOUNT_INFO_SUCCESS,
    terminationEventCreator: signoutStart,
  }),
);

const epic$ = new BehaviorSubject(rootEpic);

export const appEpic: Epic<Action<string>, State> = (action$, store) =>
  epic$.mergeMap(epic => epic(action$, store, null));

export const injectEpic = (asyncEpic: Epic<Action<string, any>, State>) => epic$.next(asyncEpic);
