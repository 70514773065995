import { LOCATION_CHANGE } from 'react-router-redux';
import { combineEpics, Epic } from 'redux-observable';
import { Observable } from 'rxjs';

import { State } from 'src/app/store/app.types';
import { removeModalByKey, showExpiringModal } from 'src/core/modal/modal.actions';
import { ModalTypes } from 'src/core/modal/modal.types';
import { refreshOidcTokensStart } from 'src/core/session/oidc/oidc.action';
import { selectTtl, selectTtlLength } from 'src/core/session/oidc/oidc.selector';
import { OidcActionTypes, OidcSuccessActions } from 'src/core/session/oidc/oidc.types';
import { SessionActions, SessionActionType } from 'src/core/session/session.types';

import {
  checkLastActive,
  lastActive,
  resetLastActive,
  startActivityListener,
  stopActivityListener,
} from './user-activity.actions';
import {
  TOKEN_EXPIRATION_BUFFER,
  USER_ACTIVITY_LISTENER_INTERVAL,
} from './user-activity.constants';
import { selectLastActive } from './user-activity.selectors';
import { UserActivityActions, UserActivityActionTypes } from './user-activity.types';

export const startListenerEpic = (): Epic<
  UserActivityActions | SessionActions | OidcSuccessActions,
  State
> => action$ =>
  action$
    .ofType(
      OidcActionTypes.OIDC_FETCH_TOKENS_SUCCESS,
      OidcActionTypes.REFRESH_OIDC_TOKENS_SUCCESS,
      SessionActionType.LOGIN_SUCCESS,
      SessionActionType.VALIDATE_SESSION_SUCCESS,
    )
    .mapTo(startActivityListener());

export const removeExpirationModalEpic = (): Epic<
  OidcSuccessActions | UserActivityActions,
  State
> => action$ =>
  action$
    .ofType(OidcActionTypes.REFRESH_OIDC_TOKENS_SUCCESS)
    .mapTo(removeModalByKey({ key: ModalTypes.TTL_EXPIRING }));

export const stopListenerEpic = (): Epic<SessionActions | UserActivityActions, State> => action$ =>
  action$.ofType(SessionActionType.SIGN_OUT_SUCCESS).map(() => stopActivityListener());

export const resetLastActiveEpic = (): Epic<
  UserActivityActions | OidcSuccessActions,
  State
> => action$ =>
  action$
    .ofType(OidcActionTypes.OIDC_FETCH_TOKENS_SUCCESS, OidcActionTypes.REFRESH_OIDC_TOKENS_SUCCESS)
    .switchMap(() => [resetLastActive(), checkLastActive()]);

export const setLastActiveEpic = (): Epic<
  UserActivityActions | SessionActions,
  State
> => action$ => {
  return action$
    .ofType(SessionActionType.VALIDATE_SESSION_START, LOCATION_CHANGE)
    .mapTo(lastActive());
};

export const lastActiveListenerEpic = (): Epic<UserActivityActions, State> => action$ =>
  action$.ofType(UserActivityActionTypes.START_ACTIVITY_LISTENER).switchMap(() =>
    Observable.interval(USER_ACTIVITY_LISTENER_INTERVAL)
      .takeUntil(action$.ofType(UserActivityActionTypes.STOP_ACTIVITY_LISTENER))
      .map(() => checkLastActive()),
  );

/**
 * Now: 9:00
 * Last Active: 8:42
 * ttlLength: 20 min
 * Buffer: 1 min
 *
 *  secondsSinceLastAction = 9 - 8.4 = 18 (min in sec)
 *  sessionLength = ttlLength - BUFFER = 19 (min in sec)
 *
 *  secondsSinceLastAction >= sessionDuration = showModal
 *
 *  otherwise you've been active, so let's check the actual TTL of your token
 *  if you're close to expiring, let's refresh it for you
 *
 *  Otherwise just close the loop by making the epic do nothing at all
 */
export const checkLastActiveEpic = (): Epic<UserActivityActions, State> => (action$, store$) =>
  action$.ofType(UserActivityActionTypes.CHECK_LAST_ACTIVE).switchMap(() => {
    const ttlLength = selectTtlLength(store$.getState());
    const ttl = selectTtl(store$.getState());
    const lastActiveTime = selectLastActive(store$.getState());

    const secondsSinceLastActive = Math.floor((Date.now() - lastActiveTime) / 1000);
    // In seconds (not an ms comparison, because gigya store it's length in seconds)
    const sessionDuration = ttlLength - TOKEN_EXPIRATION_BUFFER;
    // If you are within the buffer zone of the session duration show the modal
    if (secondsSinceLastActive >= sessionDuration) {
      return [
        showExpiringModal({ key: ModalTypes.TTL_EXPIRING, data: null }),
        stopActivityListener(),
      ];
    }

    // If your token is close to expiration, the previous  just refresh it and reset the session timer
    // The success callback will invoke a different epic above that closes the loop
    if (ttl <= Date.now()) {
      return [refreshOidcTokensStart()];
    }

    // Don't log any action otherwise
    return Observable.empty();
  });

export const userActivityEpics = combineEpics(
  startListenerEpic(),
  setLastActiveEpic(),
  resetLastActiveEpic(),
  lastActiveListenerEpic(),
  checkLastActiveEpic(),
  stopListenerEpic(),
  removeExpirationModalEpic(),
);
