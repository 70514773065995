import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { mapDispatchers } from 'src/utils';

import { resetLocalNextDeliveryDate as resetLocalNextDeliveryDateAction } from '../../store/strips-delivery.actions';

import { UPDATE_STRIPS_DELIVERY_MODAL_ROOT } from './update-strips-delivery.constants';
import { UpdateStripsDeliveryModal } from './update-strips-delivery.modal';
import {
  UpdateStripsDeliveryContainerProps,
  UpdateStripsDeliveryModalProps,
} from './update-strips-delivery.types';

const dispatchers = mapDispatchers({
  resetLocalNextDeliveryDate: resetLocalNextDeliveryDateAction,
});

// TODO: Create a redux router method to reset wizard on modal close
const resetWizardPath = history => history.push(`/${UPDATE_STRIPS_DELIVERY_MODAL_ROOT}`);

export const onModalCloseHandler = ({ resetLocalNextDeliveryDate, close, history }) => () => {
  resetLocalNextDeliveryDate();
  resetWizardPath(history);
  close();
};

const withModalResetHandlers = withHandlers({
  onModalClose: onModalCloseHandler,
});

const updateStripsDeliveryConnector = createStructuredSelector({});

export const UpdateStripsDeliveryContainer = compose<
  UpdateStripsDeliveryModalProps,
  UpdateStripsDeliveryContainerProps
>(
  connect(
    updateStripsDeliveryConnector,
    dispatchers,
  ),
  withModalResetHandlers,
)(UpdateStripsDeliveryModal);
