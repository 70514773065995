import * as React from 'react';
import { StyledSystemProps } from 'src/theme/theme.types';

import { BaseStyleProps } from '../component.types';

import { CardDiv } from './card.style';

type CardProps = BaseStyleProps & StyledSystemProps;

export const Card: React.FunctionComponent<CardProps> = ({ children, ...cardProps }) => (
  <CardDiv {...cardProps}>{children}</CardDiv>
);
