import * as React from 'react';

import { SvgIcon } from '../icon.component';
import { DEFAULT_ICON_COLOR } from '../icon.constants';
import { calculateAspectRatio } from '../icon.utils';

type IconProps = {
  height?: number;
  fillColor?: string;
};

export const PdfIcon: React.FunctionComponent<IconProps> = ({
  height = 25,
  fillColor = DEFAULT_ICON_COLOR,
}) => {
  const originalWidth = 16;
  const originalHeight = 16;
  const aspectRatio = calculateAspectRatio(originalWidth, originalHeight);

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g fill={fillColor} fillRule="evenodd">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15 5.5V4.5H11V11.5H12V8.5H14.5V7.5H12V5.5H15ZM4 4.5H1V11.5H2V9H4C4.55228 9 5 8.55228 5 8V5.5C5 4.94772 4.55228 4.5 4 4.5ZM4 8H2V5.5H4V8ZM8 11.5H6V4.5H8C9.10457 4.5 10 5.39543 10 6.5V9.5C10 10.6046 9.10457 11.5 8 11.5ZM7 10.5H8C8.55228 10.5 9 10.0523 9 9.5V6.5C9 5.94772 8.55228 5.5 8 5.5H7V10.5Z"
          fill="#0066CC"
        />
      </g>
    </SvgIcon>
  );
};
