import * as React from 'react';

import { LocalizedText } from 'src/components/localized-text';

import { PdfIcon, HelpIcon } from 'src/assets/icons';

import {
  PopoverListItem,
  PopoverListItemExternalLink,
  RowWrapper,
  PopoverListItemLinkTextSpan,
  PopoverContainerDiv,
  PopoverRectangleContainer,
  PopoverRectangleContainerTop,
  PopoverListItemNoLink,
  PopoverList,
} from '../navigation-bar.style';
import { getDynamicConfig } from 'src/utils';

type NavigationBarDropdownProps = {
  readonly children?: React.ReactNode;
  readonly language: string;
  readonly show: boolean;
  readonly openPIModal: () => void;
};

const { REACT_APP_DOWNLOAD_BUCKET } = getDynamicConfig();

const HELP_PDF_URL = (lang: string): string =>
  // eslint-disable-next-line no-script-url
  !lang ? 'javascript:void(0)' : `${REACT_APP_DOWNLOAD_BUCKET}/${lang}/help-${lang}.pdf`;

const Popover = ({ show, children }) => (
  <PopoverContainerDiv style={{ display: show ? 'inherit' : 'none' }}>
    <PopoverRectangleContainerTop />
    <PopoverRectangleContainer>{children}</PopoverRectangleContainer>
  </PopoverContainerDiv>
);

export const NavigationBarDropdown: React.FunctionComponent<NavigationBarDropdownProps> = ({
  language,
  show,
  openPIModal,
}) => (
  <Popover show={show}>
    <PopoverList>
      <PopoverListItem>
        <PopoverListItemExternalLink href={HELP_PDF_URL(language)} target={'_blank'}>
          <RowWrapper>
            <PopoverListItemLinkTextSpan>
              <PdfIcon height={16} fillColor={'none'} />
              <LocalizedText label={'navigation.menu.pdf'} />
            </PopoverListItemLinkTextSpan>
          </RowWrapper>
        </PopoverListItemExternalLink>
      </PopoverListItem>
      <PopoverListItem onClick={openPIModal}>
        <PopoverListItemNoLink>
          <RowWrapper>
            <PopoverListItemLinkTextSpan>
              <HelpIcon height={16} fillColor={'#0066CC'} />
              <LocalizedText label={'navigation.menu.productInfo'} />
            </PopoverListItemLinkTextSpan>
          </RowWrapper>
        </PopoverListItemNoLink>
      </PopoverListItem>
    </PopoverList>
  </Popover>
);
