import * as React from 'react';
import { SingleDatePicker } from 'react-dates';
import { translate } from 'react-i18next';

import { Button, ButtonStyles } from 'src/components/button';

import {
  DatePickerNext,
  DatePickerPrev,
} from 'src/components/date-picker/date-picker-arrows.component';
import { DEFAULT_DATE_FORMATTING } from 'src/components/date-picker/date-picker.constants';
import { DoneCancelButtonWrapper } from 'src/components/date-picker/date-picker.style';
import {
  DatePickerDate,
  SingleDateProps,
  SingleDateState,
} from 'src/components/date-picker/date-picker.types';
import {
  convertJSDateToMomentOrNull,
  convertMomentToJSDate,
  convertMomentToJSDateOrNull,
  isDateOutOfRange,
} from 'src/components/date-picker/date-picker.utils';
import { I18nTranslate } from 'src/i18n/types';

export class DatePickerSingleComponent extends React.Component<SingleDateProps, SingleDateState> {
  public static defaultProps: Partial<SingleDateProps> = {
    alwaysOpen: false,
    checkIfDateOutOfRange: isDateOutOfRange,
    disabled: false,
    displayFormat: DEFAULT_DATE_FORMATTING,
    readOnly: true,
  };
  // @ts-ignore
  private wrapper: HTMLDivElement | null;

  constructor(props: SingleDateProps) {
    super(props);
    this.state = {
      date: convertJSDateToMomentOrNull(props.date),
      initialMonth: convertJSDateToMomentOrNull(props.date),
      focused: true,
    };
  }

  public render() {
    const {
      alwaysOpen,
      checkIfDateOutOfRange,
      displayFormat,
      earliestDate,
      lastDate,
      readOnly,
      t,
    } = this.props;
    const { initialMonth, date, focused } = this.state;

    return (
      <div ref={node => (this.wrapper = node)}>
        <SingleDatePicker
          id="SingleDatePicker"
          date={date}
          displayFormat={displayFormat}
          focused={focused}
          initialVisibleMonth={initialMonth ? () => initialMonth : null}
          isOutsideRange={dateToValidate =>
            checkIfDateOutOfRange
              ? checkIfDateOutOfRange({
                  earliestDate,
                  lastDate,
                  dateToValidate: convertMomentToJSDate(dateToValidate),
                  currentDate: convertMomentToJSDateOrNull(date),
                })
              : false
          }
          keepOpenOnDateSelect={alwaysOpen}
          navNext={DatePickerNext}
          navPrev={DatePickerPrev}
          numberOfMonths={1}
          onDateChange={this.onDateChange}
          onFocusChange={({ focused: isFocused }) => {
            const nextFocusState = this.props.alwaysOpen || isFocused;
            this.setState({ focused: nextFocusState });
          }}
          readOnly={readOnly}
          renderCalendarInfo={this.renderDatePickerWrapper(t)}
          noBorder
        />
      </div>
    );
  }

  public collapseCalendar = () => {
    this.setState({
      focused: false,
    });
  };

  public onClickCancel = () => {
    const { onCancel = this.collapseCalendar } = this.props;
    onCancel();
  };

  public onSelectDate = () => {
    const { alwaysOpen, onDateChange } = this.props;
    const { date } = this.state;
    onDateChange(convertMomentToJSDateOrNull(date));
    if (!alwaysOpen) {
      this.collapseCalendar();
    }
  };

  public onDateChange = (date: DatePickerDate) => {
    this.setState({
      date,
    });
  };

  public renderDatePickerWrapper = (t: I18nTranslate) => () => {
    return (
      <DoneCancelButtonWrapper>
        <Button
          buttonStyle={ButtonStyles.CLEAR}
          label={t('datePicker.cancel')}
          onClick={this.onClickCancel}
        />
        <Button
          buttonStyle={ButtonStyles.PRIMARY}
          label={t('datePicker.done')}
          onClick={this.onSelectDate}
        />
      </DoneCancelButtonWrapper>
    );
  };
}

export const DatePickerSingle = translate()(DatePickerSingleComponent);
