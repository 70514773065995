import { Location } from 'history';

import { State } from 'src/app/store/app.types';
import { getIn } from 'src/utils/ramda';

export const selectIsAppNavigationDisplayed = (state: State) => {
  const isToggled = getIn(['ui', 'toggles', 'isDisplayed'], state);
  const passwordResetPath = getIn(['navigation', 'authentication', 'resetPassword'], state);
  const location = getIn(['router', 'location'], state) as Location;
  const currentPath = location.pathname;
  return isToggled && currentPath !== passwordResetPath;
};
