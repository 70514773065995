import * as React from 'react';
import { translate } from 'react-i18next';

import { ButtonStyles } from 'src/components/button';
import { LocalizedText } from 'src/components/localized-text';
import { Span } from 'src/components/span';
import { colors } from 'src/theme/colors';
import { fontSize } from 'src/theme/typography';

import {
  ModalButtonDiv,
  ModalColumn,
  ModalDividerDiv,
  ModalHeading,
  ModalOptionButton,
  ModalWideContentDiv,
} from 'src/ui/modal/components';

import { ConfirmChangeDeliveryDateProps } from 'src/domains/strips-delivery/components/change-delivery-date/change-delivery-date.types';

export const ConfirmChangeDeliveryDateComponent: React.FunctionComponent<ConfirmChangeDeliveryDateProps> = ({
  cancelChangeDelivery,
  formattedNextDeliveryDateToConfirm,
  onConfirmNextDeliveryDate,
  t,
}) => (
  <ModalColumn>
    <ModalHeading>
      <LocalizedText label="stripsDelivery.titles.confirmDeliveryDate" />
    </ModalHeading>

    <ModalDividerDiv />
    <ModalWideContentDiv>
      <Span color={colors.charcoal} fontSize={fontSize.subheading}>
        <strong>
          <LocalizedText
            label="stripsDelivery.labels.confirmDate"
            values={{ date: formattedNextDeliveryDateToConfirm }}
          />
        </strong>
      </Span>
    </ModalWideContentDiv>
    <ModalDividerDiv />
    <ModalButtonDiv>
      <ModalOptionButton
        buttonStyle={ButtonStyles.CLEAR}
        onClick={cancelChangeDelivery}
        label={t('stripsDelivery.labels.no')}
      />
      <ModalOptionButton
        buttonStyle={ButtonStyles.PRIMARY}
        onClick={onConfirmNextDeliveryDate}
        label={t('stripsDelivery.labels.yes')}
      />
    </ModalButtonDiv>
  </ModalColumn>
);

export const ConfirmChangeDeliveryDate = translate()(ConfirmChangeDeliveryDateComponent);
