import { combineEpics, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { State } from 'src/app/store/app.types';
import {
  PatientInfoActions,
  PatientInfoActionType,
} from 'src/core/patient-info/patient-info.types';
import { selectAccessToken } from 'src/core/session/oidc/oidc.selector';
import { selectUID } from 'src/core/user/user.selectors';
import { permissionsService } from 'src/services';
import { PermissionsService } from 'src/services/permissions/permissions.types';

import { fetchPermissionsError, fetchPermissionsSuccess } from './permissions.actions';
import { PermissionActions, PermissionActionType } from './permissions.types';

export const permissionsEpic = (
  service: PermissionsService,
): Epic<PermissionActions | PatientInfoActions, State> => (action$, store$) =>
  action$
    .ofType(
      PermissionActionType.FETCH_PERMISSIONS_START,
      PatientInfoActionType.FETCH_PATIENT_INFO_SUCCESS,
    )
    .debounceTime(2000)
    .switchMap(() => {
      const gigyaUId = selectUID(store$.getState());
      return Observable.fromPromise(
        service({
          gigyaUId,
          accessToken: selectAccessToken(store$.getState()),
        }),
      )
        .map(data => fetchPermissionsSuccess(data))
        .pipe(catchError(error => Observable.of(fetchPermissionsError(error))));
    });

export const permissionsEpics = combineEpics(permissionsEpic(permissionsService));
