import styled from 'styled-components';

import { Column } from 'src/components/column/index';
import { Span } from 'src/components/span';
import { spacing } from 'src/theme/spacing';

export const TtlExpiringModalContentDiv = styled(Column)`
  margin: ${spacing.five} 0 ${spacing.five} 0;
  flex: 1 0 auto;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSize.p};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;
TtlExpiringModalContentDiv.displayName = 'TtlExpiringModalContentDiv';

export const TtlExpiringHighlight = styled(Span)`
  color: ${({ theme }) => theme.colors.grayDark};
`;
TtlExpiringHighlight.displayName = 'TtlExpiringHighlight';

export const TtlExpiringMessage = styled(Span)`
  color: ${({ theme }) => theme.colors.grayMedium};
`;
TtlExpiringMessage.displayName = 'TtlExpiringMessage';
