import styled from 'styled-components';

export const PwModalContent = styled.div`
  max-width: 600px;
  width: 100%;
  padding: ${props => props.theme.spacing.four};
  ${props => props.theme.spacing.six};
  display: flex;
  flex-wrap: wrap;
`;
PwModalContent.displayName = 'PwModalContent';

export const PwModalIconColumn = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
PwModalIconColumn.displayName = 'PwModalIconColumn';

export const PwModalTextColumn = styled.div`
  width: 100%;
  padding-left: 86px;
`;
PwModalTextColumn.displayName = 'PwModalTextColumn';

export const PwModalBoldText = styled.div`
  font-weight: ${props => props.theme.fontWeights.bold};
  padding-left: ${props => props.theme.spacing.three};
`;
PwModalBoldText.displayName = 'PwModalBoldText';

export const PwModalText = styled.div`
  & span {
    font-weight: ${props => props.theme.fontWeights.bold};
  }
`;
PwModalText.displayName = 'PwModalText';

export const PwModalSuccessBadgeWrapper = styled.div``;
PwModalSuccessBadgeWrapper.displayName = 'PwModalSuccessBadgeWrapper';
