import { combineEpics, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { State } from 'src/app/store/app.types';
import { addModal } from 'src/core/modal/modal.actions';
import { AddModalAction, ModalTypes } from 'src/core/modal/modal.types';
import { selectPatientId } from 'src/core/patient-info/patient-info.selectors';
import { selectAccessToken } from 'src/core/session/oidc/oidc.selector';
import { getStripsDeliveryService, putStripsDeliveryService } from 'src/services/strips-delivery';
import { GetStripsDeliveryService } from 'src/services/strips-delivery/get-strips-delivery/get-strips-delivery.types';
import { PutStripsDeliveryService } from 'src/services/strips-delivery/put-strips-delivery/put-strips-delivery.types';

import {
  fetchStripsDeliveryError,
  fetchStripsDeliverySuccess,
  updateStripsDeliveryDateSuccess,
  updateStripsDeliveryStatusError,
  updateStripsDeliveryStatusSuccess,
} from './strips-delivery.actions';
import {
  StripsDeliveryActions,
  StripsDeliveryActionType,
  UpdateStripsDeliveryDateStart,
  UpdateStripsDeliveryStatusStart,
} from './strips-delivery.types';

export const fetchStripsDeliveryEpic = (
  service: GetStripsDeliveryService,
): Epic<StripsDeliveryActions, State> => (action$, store$) =>
  action$
    .ofType(StripsDeliveryActionType.FETCH_STRIPS_DELIVERY_START)
    .debounceTime(1000)
    .flatMap(action =>
      Observable.fromPromise(
        service({
          patientId: selectPatientId(store$.getState()),
          accessToken: selectAccessToken(store$.getState()),
        }),
      )
        .map(data => fetchStripsDeliverySuccess(data))
        .pipe(catchError(err => Observable.of(fetchStripsDeliveryError(err)))),
    );

export const updateStripsDeliveryStatusEpic = (
  service: PutStripsDeliveryService,
): Epic<StripsDeliveryActions, State> => (action$, store$) =>
  action$
    .ofType(StripsDeliveryActionType.UPDATE_STRIPS_DELIVERY_STATUS_START)
    .debounceTime(1000)
    .switchMap(
      ({ payload: { status, deliveryDate, triggerOnSuccess } }: UpdateStripsDeliveryStatusStart) =>
        Observable.fromPromise(
          service({
            patientId: selectPatientId(store$.getState()),
            accessToken: selectAccessToken(store$.getState()),
            status,
            deliveryDate,
          }),
        )
          .map(() => {
            triggerOnSuccess();
            return updateStripsDeliveryStatusSuccess({ status, nextDeliveryDate: deliveryDate });
          })
          .pipe(catchError(err => Observable.of(updateStripsDeliveryStatusError(err)))),
    );

export const updateStripsDeliveryDateEpic = (
  service: PutStripsDeliveryService,
): Epic<StripsDeliveryActions | AddModalAction, State> => (action$, store$) =>
  action$
    .ofType(StripsDeliveryActionType.UPDATE_STRIPS_DELIVERY_DATE_START)
    .debounceTime(1000)
    .switchMap(({ payload: { deliveryDate, triggerOnSuccess } }: UpdateStripsDeliveryDateStart) =>
      Observable.fromPromise(
        service({
          patientId: selectPatientId(store$.getState()),
          accessToken: selectAccessToken(store$.getState()),
          deliveryDate,
        }),
      )
        .map(() => {
          triggerOnSuccess();
          return updateStripsDeliveryDateSuccess({ nextDeliveryDate: deliveryDate });
        })
        .pipe(
          catchError(err => {
            return Observable.of(
              addModal({
                key: ModalTypes.GENERIC_INFO,
                data: {
                  message: 'ERROR',
                },
              }),
            );
          }),
        ),
    );

export const stripsDeliveryEpic = combineEpics(
  fetchStripsDeliveryEpic(getStripsDeliveryService),
  updateStripsDeliveryStatusEpic(putStripsDeliveryService),
  updateStripsDeliveryDateEpic(putStripsDeliveryService),
);
