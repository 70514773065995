import { TtlExpiringModalContainer } from 'src/domains/authentication/components/oidc/ttl-expiring-modal.container';
import { UpdateStripsDeliveryContainer } from 'src/domains/strips-delivery/components/update-strips-delivery/update-strips-delivery.container';
import { LoginSystemErrorContainer } from 'src/ui/modal/components/login-system-error/login-system-error.container';

import { DTCModal } from './components/dtc';
import { PIModalComponentContainer } from './components/product-info';
import { GenericInfoContainer } from './components/generic-info/generic-info.container';
import { ChangePasswordConfirmationModal } from './components/password-change-confirmation';

export const ModalMap = {
  stripDelivery: UpdateStripsDeliveryContainer,
  DTC: DTCModal,
  ttlExpiring: TtlExpiringModalContainer,
  loginError: LoginSystemErrorContainer,
  changePasswordConfirmation: ChangePasswordConfirmationModal,
  genericInfo: GenericInfoContainer,
  productInfo: PIModalComponentContainer,
};
