export const space = ['0', '0.25rem', '0.5rem', '1rem', '2rem', '4rem', '8rem'];

export const spacing = {
  zer0: '0', // 0px
  one: '0.25rem', // 4px
  two: '0.5rem', // 8px
  three: '1rem', // 16px
  four: '2rem', // 32px
  five: '4rem', // 64px
  six: '8rem', // 128px
};

export const CONTENT_MAX_WIDTH = 1280;
