import {
  BrandButton,
  ButtonReset,
  ButtonSmall,
  ButtonSolid,
} from 'src/components/button/button.style';
import styled from 'styled-components';

export const ProfileSpacedTitle = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.medium};
  margin: 0 auto;
  text-transform: uppercase;
`;
ProfileSpacedTitle.displayName = 'ProfileSpacedTitle';

const ListReset = styled.ul`
  list-style: none;
  margin: 0 auto;
  padding: 0;
`;
ListReset.displayName = 'ListReset';

export const ProfileName = styled(ProfileSpacedTitle.withComponent('h1'))`
  color: ${({ theme }) => theme.colors.charcoal};
  font-size: ${({ theme }) => theme.fontSize.title};
  text-align: center;
  text-transform: uppercase;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;
ProfileName.displayName = 'ProfileName';

export const ProfileCardTitle = styled(ProfileSpacedTitle)`
  color: ${({ theme }) => theme.colors.brandBlue};
  font-size: ${({ theme }) => theme.fontSize.p};
  margin-bottom: ${({ theme }) => theme.spacing.four};
`;
ProfileCardTitle.displayName = 'ProfileCardTitle';

export const ProfileCardList = ListReset;
ProfileCardList.displayName = 'ProfileCardList';

export const ProfileCardListItem = styled.li`
  font-size: ${({ theme }) => theme.fontSize.p};
  line-height: 1.6em;
  margin: 0 auto ${({ theme }) => theme.spacing.three};
  overflow-wrap: break-word;
  word-wrap: break-word;
`;
ProfileCardListItem.displayName = 'ProfileCardListItem';

export const ProfileCardLabel = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  padding-right: ${({ theme }) => theme.spacing.one};
`;
ProfileCardLabel.displayName = 'ProfileCardLabel';

export const ProfileCardAddressListItem = styled(ProfileCardListItem)`
  margin-bottom: 0;
`;
ProfileCardAddressListItem.displayName = 'ProfileCardAddressListItem';

export const CookieBtn = styled('button')<any>`
  ${ButtonReset}
  ${BrandButton}
  ${ButtonSolid}
  ${ButtonSmall}
  padding: 0;
`;
