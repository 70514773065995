import { pipe } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';
import { State } from 'src/app/store/app.types';
import { selectNavigationRoutes } from 'src/navigation/store/navigation.selectors';
import { getIn, propOr } from 'src/utils';
import { convertISO, toFormat, toUTC } from 'src/utils/dates';

const DATE_FORMATS = {
  DAY: 'd',
  MONTH: 'MMMM',
  YEAR: 'yyyy',
};

export const selectStripsDelivery = (state: State) => getIn(['stripsDelivery'], state);

export const selectStripsDeliveryContainers = createSelector(
  [selectStripsDelivery],
  stripsDeliveryData => propOr(undefined, 'containers', stripsDeliveryData),
);

export const selectStripsDeliveryNextDeliveryDate = createSelector(
  [selectStripsDelivery],
  stripsDeliveryData => propOr(undefined, 'nextDeliveryDate', stripsDeliveryData),
);

export const selectStripsDeliveryNextDeliveryDateForConfirmation = createSelector(
  [selectStripsDelivery],
  stripsDeliveryData => propOr(undefined, 'localNextDeliveryDate', stripsDeliveryData),
);

export const selectStripsDeliveryNextDeliveryDayMonth = createSelector(
  [selectStripsDeliveryNextDeliveryDate],
  date => {
    if (date) {
      const nextDeliveryDateTime = pipe(
        convertISO,
        toUTC,
      )(date);
      const getValueFromNextDelivery = (format: string) => toFormat(format)(nextDeliveryDateTime);
      return {
        day: getValueFromNextDelivery(DATE_FORMATS.DAY),
        month: getValueFromNextDelivery(DATE_FORMATS.MONTH),
        year: getValueFromNextDelivery(DATE_FORMATS.YEAR),
      };
    }
    return null;
  },
);

export const selectStripsDeliveryStatus = createSelector(
  [selectStripsDelivery],
  stripsDeliveryData => propOr(undefined, 'status', stripsDeliveryData),
);

export const selectStripsDeliveryTotal = createSelector(
  [selectStripsDelivery],
  stripsDeliveryData => propOr(undefined, 'total', stripsDeliveryData),
);

export const stripsDeliveryConnector = createStructuredSelector({
  containers: selectStripsDeliveryContainers,
  nextDeliveryDate: selectStripsDeliveryNextDeliveryDayMonth,
  status: selectStripsDeliveryStatus,
  total: selectStripsDeliveryTotal,
  routes: selectNavigationRoutes,
});
