import styled, { css } from 'styled-components';
import { zIndex } from 'styled-system';

import { theme as AppTheme } from 'src/theme';
import { convertPxToRem } from 'src/utils/styles';

import { ButtonProps, ButtonSizes, ButtonStyles } from './button.types';

export const ButtonReset = css`
  appearance: none;
  background: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: block;
  margin: 0;
  outline: 0;
  padding: 0;

  &[disabled] {
    pointer-events: none;
  }
`;

const BUTTON_BORDER_WIDTH = convertPxToRem(1.5);
const BUTTON_BLUE_SHADOW = '0 2px 5px 0 rgba(0, 102, 204, 0.3)';

const calculateButtonPadding = (withBorder: boolean): string =>
  withBorder
    ? `${convertPxToRem(13)} ${AppTheme.spacing.three}`
    : `${convertPxToRem(14.5)} ${AppTheme.spacing.three}`;

export const ButtonSolid = css`
  background-color: ${({ theme }) => theme.colors.brandBlue};
  color: ${({ theme }) => theme.colors.white};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.brandBlueDark};
  }

  &[disabled] {
    opacity: 0.3;
  }
`;

const ButtonSecondary = css`
  background-color: ${({ theme }) => theme.colors.blueMarineAlpha27};
  color: ${({ theme }) => theme.colors.brandBlue};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover,
  &:focus {
    box-shadow: ${BUTTON_BLUE_SHADOW};
  }
`;

const ButtonBlueGradient = css`
  background: ${({ theme }) => theme.colors.secondaryGradient};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  &:hover,
  &:focus {
    box-shadow: ${BUTTON_BLUE_SHADOW};
  }
`;

const ButtonLight = css`
  background-color: ${({ theme }) => theme.colors.blueMarineAlpha10};
  color: ${({ theme }) => theme.colors.brandBlue};

  &:hover,
  &:focus {
    box-shadow: ${BUTTON_BLUE_SHADOW};
  }
  &[disabled] {
    opacity: 0.35;
  }
`;

const ButtonOutline = css`
  background-color: ${({ theme }) => theme.colors.clear};
  border: ${BUTTON_BORDER_WIDTH} solid currentColor;
  color: ${({ theme }) => theme.colors.brandBlue};
  padding: ${calculateButtonPadding(true)};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.brandBlueDark};
  }
`;

const ButtonImg = css`
  background-color: ${({ theme }) => theme.colors.clear};
  border: none;
`;

export const buttonStyleMap = {
  [ButtonStyles.PRIMARY]: ButtonSolid,
  [ButtonStyles.SECONDARY]: ButtonSecondary,
  [ButtonStyles.LIGHT]: ButtonLight,
  [ButtonStyles.BLUE_GRADIENT]: ButtonBlueGradient,
  [ButtonStyles.CLEAR]: ButtonOutline,
  [ButtonStyles.IMG]: ButtonImg,
};

const ButtonXSmall = css`
  min-width: ${convertPxToRem(35)};
`;

export const ButtonSmall = css`
  min-width: ${convertPxToRem(108)};
`;

const ButtonMedium = css`
  min-width: ${convertPxToRem(140)};
`;

const ButtonLarge = css`
  min-width: ${convertPxToRem(208)};
`;

const ButtonXLarge = css`
  min-width: ${convertPxToRem(243)};
`;
export const buttonSizeMap = {
  [ButtonSizes.XSMALL]: ButtonXSmall,
  [ButtonSizes.SMALL]: ButtonSmall,
  [ButtonSizes.MEDIUM]: ButtonMedium,
  [ButtonSizes.LARGE]: ButtonLarge,
  [ButtonSizes.XLARGE]: ButtonXLarge,
};

export const BrandButton = css`
  border-radius: ${convertPxToRem(20.5)};
  font-size: ${({ theme }) => theme.fontSize.caption};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.small};
  line-height: 1;
  padding: ${calculateButtonPadding(false)};
  transition: ${({ theme }) => theme.transitions.default};
  text-transform: uppercase;
`;

export const styleButtonComponent = (
  ButtonComponent: React.FunctionComponent<ButtonProps>,
) => styled(ButtonComponent)`
  ${ButtonReset};
  ${BrandButton};
  ${({ buttonStyle = ButtonStyles.PRIMARY }: ButtonProps) => buttonStyleMap[buttonStyle]};
  ${({ size = ButtonSizes.SMALL }: ButtonProps) => buttonSizeMap[size]};
  ${zIndex};
`;
