import * as React from 'react';
import { translate } from 'react-i18next';

import { CheckmarkIcon } from 'src/assets/icons';
import { ButtonSizes } from 'src/components/button';
import { ConcentricCircles } from 'src/components/concentric-circles';
import { Div } from 'src/components/div';
import { LocalizedText } from 'src/components/localized-text';
import { Span } from 'src/components/span';

import { colors } from 'src/theme/colors';

import { I18nTranslate } from 'src/i18n/types';
import {
  ModalButtonDiv,
  ModalColumn,
  ModalDividerDiv,
  ModalHeading,
  ModalOptionButton,
  ModalWideContentDiv,
} from 'src/ui/modal/components';

type ResumeStripsDeliveryProps = {
  onClickResumeDelivery: () => void;
  formattedNextDeliveryDate: string;
  t: I18nTranslate;
};

export const ResumeStripsDeliveryComponent: React.FunctionComponent<ResumeStripsDeliveryProps> = ({
  onClickResumeDelivery,
  formattedNextDeliveryDate,
  t,
}) => (
  <ModalColumn>
    <ModalHeading>
      <LocalizedText label="stripsDelivery.titles.resumeDelivery" />
    </ModalHeading>

    <ModalDividerDiv />
    <ModalWideContentDiv>
      <LocalizedText
        label="stripsDelivery.labels.resumeConfirmationMessage"
        values={{
          date: formattedNextDeliveryDate,
        }}
      />
    </ModalWideContentDiv>

    <ModalDividerDiv />

    <ModalButtonDiv>
      <ModalOptionButton
        onClick={onClickResumeDelivery}
        label={t('stripsDelivery.labels.resumeDelivery')}
        size={ButtonSizes.LARGE}
      />
    </ModalButtonDiv>
  </ModalColumn>
);

export const ResumeStripsDelivery = translate()(ResumeStripsDeliveryComponent);

type ResumeStripsDeliveryResultProps = {
  close: any;
  formattedNextDeliveryDate: string;
  t: I18nTranslate;
};

export const ResumeStripsDeliveryResultComponent: React.FunctionComponent<ResumeStripsDeliveryResultProps> = ({
  close,
  formattedNextDeliveryDate,
  t,
}) => (
  <ModalColumn>
    <ModalHeading>
      <LocalizedText label="stripsDelivery.titles.resumeDelivery" />
    </ModalHeading>

    <ModalDividerDiv />
    <ModalWideContentDiv>
      <Div display="flex" alignItems="center">
        <ConcentricCircles
          height={56}
          innerCircleColor={colors.trafficGreen}
          borderOpacity={0.3}
          borderThickness={4}
        >
          <CheckmarkIcon strokeColor={colors.white} strokeWeight={1} height={23} />
        </ConcentricCircles>
        <Span pl={3}>
          <LocalizedText
            label="stripsDelivery.labels.resumeSuccess"
            values={{ date: formattedNextDeliveryDate }}
          />
        </Span>
      </Div>
    </ModalWideContentDiv>

    <ModalDividerDiv />

    <ModalButtonDiv>
      <ModalOptionButton
        onClick={close}
        label={t('stripsDelivery.labels.close')}
        size={ButtonSizes.SMALL}
      />
    </ModalButtonDiv>
  </ModalColumn>
);

export const ResumeStripsDeliveryResult = translate()(ResumeStripsDeliveryResultComponent);
