/* eslint-disable no-useless-escape */
import * as React from 'react';
import { connect } from 'react-redux';
import { selectAccessToken } from 'src/core/session/oidc/oidc.selector';
import { selectFHIRPatientId, selectFullName, selectLocale } from 'src/core/user/user.selectors';
import { ModuleLoader } from 'src/modules/load-modules';
import { hasAllRequiredProps } from 'src/modules/visit-module/visit-module';
import { getDynamicConfig } from 'src/utils';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'visit-module-anchor-video': VisitModuleVideoAnchorAttributes;
    }

    interface VisitModuleVideoAnchorAttributes extends React.Attributes {
      'patient-id': string;
      'access-token': string;
      'api-key': string;
      'client-id': string;
      'client-secret': string;
      'api-url': string;
      'patient-language': string;
    }
  }
}

interface Props {
  patientLanguage: string;
  patientName: string;
  accessToken: string;
  patientId: string;
  apiKey: string;
  clientId: string;
  clientSecret: string;
  apiUrl: string;
}

export const MEETING_ID_QUERY_PARAMETER_NAME = 'id';
export const MEETING_ID_SESSION_STORAGE_KEY = 'meetingId';

export const saveMeetingIdToSessionStorage = (url: string) => {
  const meetingId = getParameterByName(MEETING_ID_QUERY_PARAMETER_NAME, url);
  if (meetingId !== null && meetingId !== '') {
    window.sessionStorage.setItem(MEETING_ID_SESSION_STORAGE_KEY, meetingId);
  }
};

export const shouldGoToVirtualMeetings = () => {
  const meetingId = window.sessionStorage.getItem(MEETING_ID_SESSION_STORAGE_KEY);
  return meetingId != null;
};
export const VIRTUAL_MEETINGS_ROUTE = '/virtual-meetings';

const VisitModuleVideoComponent = (props: Props) => {
  const requiredProps = [
    'patientId',
    'accessToken',
    'apiKey',
    'apiUrl',
    'clientId',
    'clientSecret',
  ];

  if (!hasAllRequiredProps(props, requiredProps)) {
    return null;
  }

  ModuleLoader.emitLoadModuleEvent('visit-module-pp-client');
  ModuleLoader.emitLoadModuleEvent('rdcp-pp-vc-client-module');
  return (
    <visit-module-anchor-video
      patient-id={props.patientId}
      patient-language={props.patientLanguage}
      patient-name={props.patientName}
      api-key={props.apiKey}
      api-url={props.apiUrl}
      access-token={props.accessToken}
      client-id={props.clientId}
      client-secret={props.clientSecret}
    />
  );
};

const mapStateToProps = (state, ownProps): Props => {
  const config = getDynamicConfig();

  return {
    patientId: selectFHIRPatientId(state),
    accessToken: selectAccessToken(state),
    patientLanguage: selectLocale(state),
    patientName: selectFullName(state),
    apiKey: config.REACT_APP_GIGYA_TOKEN,
    clientId: config.REACT_APP_CLIENT_ID,
    clientSecret: config.REACT_APP_CLIENT_SECRET,
    apiUrl: config.REACT_APP_API_MULE,
    ...ownProps,
  };
};

const getParameterByName = (name, url) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const VisitModuleVideo = connect(mapStateToProps)(VisitModuleVideoComponent);
export default VisitModuleVideo;
