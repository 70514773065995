import * as React from 'react';

import { SvgIcon } from '../icon.component';
import { DEFAULT_ICON_COLOR } from '../icon.constants';
import { calculateAspectRatio } from '../icon.utils';

type IconProps = {
  height?: number;
  fillColor?: string;
};

export const HelpIcon: React.FunctionComponent<IconProps> = ({
  height = 25,
  fillColor = DEFAULT_ICON_COLOR,
}) => {
  const originalWidth = 26;
  const originalHeight = 26;
  const aspectRatio = calculateAspectRatio(originalWidth, originalHeight);

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g fill={fillColor} fillRule="evenodd">
        <path
          d="M12.89 25.272c-6.772 0-12.263-5.49-12.263-12.264C.627 6.235 6.117.745 12.891.745c6.773 0 12.263 5.49 12.263 12.263s-5.49 12.264-12.263 12.264zm0-1.389c6.007 0 10.876-4.869 10.876-10.875S18.896 2.133 12.89 2.133c-6.007 0-10.875 4.87-10.875 10.875 0 6.006 4.868 10.875 10.875 10.875z"
          fillRule="nonzero"
        />
        <path d="M12.89 18.913a.978.978 0 0 1-.618-.19c-.164-.128-.245-.319-.245-.573v-6.457c0-.255.081-.448.245-.58a.951.951 0 0 1 .619-.2c.248 0 .451.067.61.2.158.132.237.325.237.58v6.457c0 .254-.08.445-.237.573-.159.127-.362.19-.61.19zm0-9.753c-.327 0-.587-.095-.779-.286-.192-.19-.288-.441-.288-.751s.096-.558.288-.742c.192-.185.452-.278.78-.278.316 0 .573.093.77.278.198.184.297.432.297.742s-.096.56-.288.751c-.192.19-.452.286-.78.286z" />
      </g>
    </SvgIcon>
  );
};
