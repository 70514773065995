import { push } from 'react-router-redux';
import { combineEpics, Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { State } from 'src/app/store/app.types';
import { removeModalByKey } from 'src/core/modal/modal.actions';
import { ModalTypes } from 'src/core/modal/modal.types';
import { SessionActionType } from 'src/core/session/session.types';
import {
  shouldGoToVirtualMeetings,
  VIRTUAL_MEETINGS_ROUTE,
} from 'src/modules/visit-module/visit-module-video';
import { AUTHENTICATION_ROUTES } from 'src/navigation/authentication/authentication.navigation.constants';
import { DASHBOARD_ROUTES } from 'src/navigation/patient-dashboard/patient-dashboard.navigation.constants';
import { oidcRefreshService, oidcTokensService } from 'src/services/oidc';

import { signoutStart } from '../session.actions';

import {
  fetchOidcTokensError,
  fetchOidcTokensSuccess,
  refreshOidcTokensError,
  refreshOidcTokensSuccess,
} from './oidc.action';
import { selectRefreshToken } from './oidc.selector';
import { OidcActionTypes, OidcTokensServiceImplType } from './oidc.types';

export const getOidcTokensEpic: (
  oidcTokensService: OidcTokensServiceImplType,
) => Epic<any, State> = tokensService => action$ =>
  action$.ofType(OidcActionTypes.OIDC_FETCH_TOKENS_START).switchMap(({ payload }) =>
    Observable.fromPromise(tokensService(payload))
      .map(res => fetchOidcTokensSuccess(res))
      .pipe(catchError(err => Observable.of(fetchOidcTokensError(err)))),
  );
export const oidcTokensSuccessEpic: () => Epic<any, State> = () => actions$ => {
  const path = shouldGoToVirtualMeetings() ? VIRTUAL_MEETINGS_ROUTE : DASHBOARD_ROUTES.patient;
  return actions$.ofType(SessionActionType.LOGIN_SUCCESS).switchMap(() => [push(path)]);
};

export const refreshTokensEpic: (
  refreshTokensService: OidcTokensServiceImplType,
) => Epic<any, State> = refreshTokensService => (actions$, store$) =>
  actions$.ofType(OidcActionTypes.REFRESH_OIDC_TOKENS_START).switchMap(() =>
    Observable.fromPromise(refreshTokensService(selectRefreshToken(store$.getState())))
      .switchMap(res => [
        refreshOidcTokensSuccess(res),
        removeModalByKey({ key: ModalTypes.TTL_EXPIRING }),
      ])
      .pipe(catchError(err => Observable.of(refreshOidcTokensError(err)))),
  );

export const oidcTtlRefreshTokensSuccessEpic: () => Epic<any, State> = () => (actions$, store$) =>
  actions$
    .ofType(OidcActionTypes.REFRESH_OIDC_TOKENS_SUCCESS)
    .map(() => removeModalByKey({ key: ModalTypes.TTL_EXPIRING }));

export const oidcLoginErrorEpic: () => Epic<any, State> = () => (actions$, store$) =>
  actions$
    .ofType(OidcActionTypes.OIDC_FETCH_TOKENS_ERROR)
    .map(() => push(AUTHENTICATION_ROUTES.error));

export const oidcRefreshErrorEpic: () => Epic<any, State> = () => (actions$, store$) =>
  actions$.ofType(OidcActionTypes.REFRESH_OIDC_TOKENS_ERROR).map(() => signoutStart());

export const oidcEpics = combineEpics(
  getOidcTokensEpic(oidcTokensService({ devMode: false })),
  refreshTokensEpic(oidcRefreshService({ devMode: false })),
  oidcTokensSuccessEpic(),
  oidcTtlRefreshTokensSuccessEpic(),
  oidcLoginErrorEpic(),
  oidcRefreshErrorEpic(),
);
