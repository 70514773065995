import { createStructuredSelector } from 'reselect';

import { selectPatientId } from 'src/core/patient-info/patient-info.selectors';
import { selectIsSessionValid } from 'src/core/session/oidc/oidc.selector';
import { selectIsSignedOut } from 'src/core/session/session.selectors';
import { selectCombinedRoutes } from 'src/navigation/store/navigation.selectors';
import { selectIsAppNavigationDisplayed } from 'src/ui/store';

export const protectedRouteConnector = createStructuredSelector({
  isSessionValid: selectIsSessionValid,
  patientId: selectPatientId,
  isSignedOut: selectIsSignedOut,
  routes: selectCombinedRoutes,
  isNavigationVisible: selectIsAppNavigationDisplayed,
});
