import * as React from 'react';

import { SvgIconProps } from './icon.types';

export const SvgIcon = ({
  children,
  height,
  minX = 0,
  minY = 0,
  originalHeight,
  originalWidth,
  title = '',
  width,
}: SvgIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={`${minX} ${minY} ${originalWidth} ${originalHeight}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    {title !== '' && <title>{title}</title>}
    {children}
  </svg>
);
