import styled from 'styled-components';

import { Div } from 'src/components/div';
import { Span } from 'src/components/span';
import { colors } from 'src/theme/colors';

import { FaceProps, TickProps } from './strips-stock.types';

const TICKS_TOTAL = 64;
const TICK_HIGHLIGHT_WIDTH = 3;

export const GraphContainer = styled(Div)`
  margin: auto;
  position: relative;
`;
GraphContainer.displayName = 'GraphContainer';

export const CountDisplayWrapper = styled(Div)`
  position: relative;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
`;
CountDisplayWrapper.displayName = 'CountDisplayWrapper';

export const CountDisplay = styled(Span)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const ContentWrapper = styled(Div)`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
`;
ContentWrapper.displayName = 'ContentWrapper';

export const RemainingStripsLabel = styled.h2`
  color: ${({ theme }) => theme.colors.charcoal};
  font-size: ${({ theme }) => theme.fontSize.p};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.small};
  margin: 0 0 ${({ theme }) => theme.spacing.three};
  text-align: center;
  text-transform: uppercase;
`;
RemainingStripsLabel.displayName = 'RemainingStripsLabel';

export const styleFaceComponent = (FaceComponent: React.FunctionComponent<FaceProps>) => styled(
  FaceComponent,
)`
  width: ${({ diameter = 300 }) => diameter + 'px'};
  height: ${({ diameter = 300 }) => diameter + 'px'};
  position: relative;
  border-radius: 50%;
  margin: 0 auto;
`;

export const tickBackgroundColor = (index, activeIndex) => {
  if (index === 0) {
    return activeIndex === 0 ? colors.stripsRemainingGreen : colors.red;
  } else if (index >= activeIndex) {
    return colors.stripsRemainingGreen;
  } else {
    return colors.silverDark;
  }
};

const isFinalTick = ({ activeIndex, tickIndex }): boolean => tickIndex === 0 && activeIndex !== 0;

export const styleTickComponent = (TickComponent: React.FunctionComponent<TickProps>) => styled(
  TickComponent,
)`
  background-color: ${(props: any) => tickBackgroundColor(props.tickIndex, props.activeIndex)};
  border-color: ${props =>
    props.isHighlighted ? (isFinalTick(props) ? colors.red : colors.stripsRemainingGreen) : ''};
  border-style: solid;
  border-radius: ${props => (props.isHighlighted ? `${TICK_HIGHLIGHT_WIDTH}px` : '0')};
  border-width: ${props => (props.isHighlighted ? `${TICK_HIGHLIGHT_WIDTH}px` : '0')};
  bottom: ${props => props.diameter / 2 + 'px'};
  height: ${props => props.diameter / (props.isHighlighted ? 9 : 19) + 'px'};
  left: ${props => props.diameter / 2 + 'px'};
  margin-left: ${props => (props.isHighlighted ? `-${TICK_HIGHLIGHT_WIDTH / 2}px` : '0')};
  position: absolute;
  transform-origin: center bottom;
  transform: ${props =>
    'rotate(' +
    props.tickIndex * (360 / TICKS_TOTAL) +
    'deg) translateY(-' +
    (props.isHighlighted ? 330 : 750) +
    '%)'};
  width: 0.25em;
`;
