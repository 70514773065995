import { createSelector } from 'reselect';

import { State } from 'src/app/store/app.types';
import { getIn, propOr } from 'src/utils/index';

export const selectSession = (state: State) => getIn(['session'], state);

export const selectLegacyToken = createSelector([selectSession], session =>
  propOr('', 'legacyToken', session),
);

export const selectSessionError = createSelector([selectSession], session =>
  propOr(false, 'error', session),
);

export const selectIsSignedOut = createSelector([selectSession], session =>
  propOr(true, 'isSignedOut', session),
);
