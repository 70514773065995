import * as React from 'react';
import { Bundle } from 'src/components/bundle';

export const ProfileBundle: React.FunctionComponent = () => (
  <Bundle
    bundleWillLoad={async () => {
      const { ProfileContainer } = await import(
        /* webpackChunkName: "profile" */ 'src/domains/profile'
      );
      return {
        component: ProfileContainer,
      };
    }}
    bundleDidLoad={Component => <Component />}
  />
);

export const ProfileEditBundle: React.FunctionComponent = () => (
  <Bundle
    bundleWillLoad={async () => {
      const { ProfileEditContainer, profileEpics, profileReducer } = await import(
        /* webpackChunkName: "profile" */ 'src/domains/profile'
      );
      return {
        component: ProfileEditContainer,
        rootEpic: profileEpics,
        reducerEntry: {
          key: 'profile',
          reducer: profileReducer,
        },
      };
    }}
    bundleDidLoad={Component => <Component />}
  />
);
