import * as React from 'react';
import { translate } from 'react-i18next';

import { XIcon } from 'src/assets/icons';
import { DTCConnect } from 'src/assets/images/dtc-connect';
import { DTCTray } from 'src/assets/images/dtc-tray';
import { ButtonSizes } from 'src/components/button';
import { Column } from 'src/components/column';
import { LocalizedText } from 'src/components/localized-text';
import { colors } from 'src/theme/colors';
import { spacing } from 'src/theme/spacing';
import { combineRems } from 'src/utils/styles/rem-calc.utils';

import {
  GradientTopBarDiv,
  ModalBody,
  ModalCloseButton,
  ModalColumn,
  ModalDividerDiv,
  ModalHeading,
  ModalOptionButton,
} from 'src/ui/modal/components/modal.style';

import {
  DTCConnectBlockDiv,
  DTCConnectWrapperDiv,
  DTCDownloadButtonDiv,
  DTCDownloadHref,
  DTCHelpMessage,
  DTCModalSubheading,
  DTCSubheadline,
  DTCSubheadSubline,
} from 'src/ui/modal/components/dtc/dtc.style';

import { I18nTranslate } from 'src/i18n/types';
import { generateDTCDownloadURL } from 'src/ui/modal/components/dtc/dtc.utils';

type DTCModalProps = {
  close: any;
  t: I18nTranslate;
};

const DTC_DOWNLOAD_URL = generateDTCDownloadURL();

export const DTCModalComponent: React.FunctionComponent<DTCModalProps> = ({ close, t }) => (
  <ModalBody>
    <GradientTopBarDiv />
    <ModalCloseButton onClick={close}>
      <XIcon fillColor={colors.black} height={13} />
    </ModalCloseButton>

    <ModalColumn>
      <ModalHeading>
        <LocalizedText label="DTC.textOne" />
      </ModalHeading>
      <ModalDividerDiv />
      <Column
        alignItems="center"
        pt={combineRems(spacing.four, spacing.three)}
        pb={2}
        pl={4}
        pr={4}
      >
        <DTCModalSubheading>
          <LocalizedText label="DTC.textTwo" />
        </DTCModalSubheading>

        <DTCSubheadline>
          <DTCSubheadSubline>{t('DTC.textThree')}</DTCSubheadSubline>
          <DTCSubheadSubline>{t('DTC.textFour')}</DTCSubheadSubline>
        </DTCSubheadline>

        <DTCDownloadButtonDiv>
          <DTCDownloadHref href={DTC_DOWNLOAD_URL} download>
            <ModalOptionButton label={t('DTC.textFive')} size={ButtonSizes.LARGE} />
          </DTCDownloadHref>
        </DTCDownloadButtonDiv>
      </Column>

      <Column alignItems="center" pt={2} pb={2} pl={4} pr={4}>
        <DTCConnectWrapperDiv>
          <DTCConnectBlockDiv>
            <LocalizedText label="DTC.textSix" />
          </DTCConnectBlockDiv>
          <DTCConnectBlockDiv>
            <DTCConnect height={91} />
          </DTCConnectBlockDiv>
        </DTCConnectWrapperDiv>
      </Column>

      <Column alignItems="center" pt={2} pb={3} pl={4} pr={4}>
        <DTCConnectWrapperDiv>
          <DTCConnectBlockDiv>
            <LocalizedText label="DTC.textSeven" />
          </DTCConnectBlockDiv>
          <DTCConnectBlockDiv>
            <DTCTray height={80} />
          </DTCConnectBlockDiv>
          <DTCConnectBlockDiv>
            <LocalizedText label="DTC.textEight" />
          </DTCConnectBlockDiv>
        </DTCConnectWrapperDiv>
      </Column>

      <ModalDividerDiv />

      <Column alignItems="center" pt={4} pb={3}>
        <DTCDownloadButtonDiv>
          <ModalOptionButton onClick={close} label={t('DTC.textEleven')} size={ButtonSizes.LARGE} />
        </DTCDownloadButtonDiv>
      </Column>
      <DTCHelpMessage>
        <LocalizedText label="DTC.textTwelve" />
      </DTCHelpMessage>
    </ModalColumn>
  </ModalBody>
);

export const DTCModal = translate()(DTCModalComponent);
