import { borderRadius } from 'src/theme/border-radius';
import * as styledComponents from 'styled-components';

import { animations } from './animations';
import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { space, spacing } from './spacing';
import { transitionEasing, transitions, transitionSpeed } from './transitions';
import { fontSize, fontSizes, letterSpacing } from './typography';
import { fontWeights } from './weight';
import { zIndexes } from './z-index';

const {
  default: styled,
  css,
  injectGlobal,
  keyframes,
  ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<IThemeInterface>;

export interface IThemeInterface {
  animations: { [key: string]: string };
  borderRadius: string[];
  breakpoints: number[];
  colors: { [key: string]: string };
  fontSize: { [key: string]: string };
  fontSizes: number[];
  fontWeights: { [key: string]: number };
  letterSpacing: { [key: string]: string };
  spacing: { [key: string]: string };
  space: string[];
  textStyles: any;
  transitions: { [key: string]: string };
  transitionEasing: { [key: string]: string };
  transitionSpeed: { [key: string]: string };
  zIndexes: { [key: string]: number };
}

export const theme = {
  animations,
  borderRadius,
  breakpoints,
  colors,
  fontSize,
  fontSizes,
  fontWeights,
  letterSpacing,
  spacing,
  space,
  textStyles: {
    caps: {
      textTransform: 'uppercase',
    },
    italic: {
      fontStyle: 'italic',
    },
  },
  transitions,
  transitionEasing,
  transitionSpeed,
  zIndexes,
};

export const createStyledComponent = <Tag extends keyof JSX.IntrinsicElements, Props>(tag: Tag) =>
  styled<Props, Tag>(tag);

export default styled;
export { css, injectGlobal, keyframes, ThemeProvider };
