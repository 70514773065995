import * as React from 'react';
import { ButtonSizes } from 'src/components/button';
import { Column } from 'src/components/column';
import { LocalizedText } from 'src/components/localized-text';

import { SuccessBadge } from 'src/components/badge';

import {
  GradientTopBarDiv,
  ModalBody,
  ModalColumn,
  ModalDividerDiv,
  ModalHeading,
  ModalOptionButton,
} from '../modal.style';

import {
  PwModalBoldText,
  PwModalContent,
  PwModalIconColumn,
  PwModalSuccessBadgeWrapper,
  PwModalText,
  PwModalTextColumn,
} from './change-password-confirmation.style';

type ChangePasswordConfirmationModalProps = {
  data: {
    onLogout: () => void;
  };
};

export const ChangePasswordConfirmationModal: React.FunctionComponent<ChangePasswordConfirmationModalProps> = ({
  data,
}) => (
  <ModalBody>
    <GradientTopBarDiv />
    <ModalColumn>
      <ModalHeading>
        <LocalizedText label="Password change was successful" />
      </ModalHeading>
      <ModalDividerDiv />

      <PwModalContent>
        <PwModalIconColumn>
          <PwModalSuccessBadgeWrapper>
            <SuccessBadge size={56} />
          </PwModalSuccessBadgeWrapper>
          <PwModalBoldText>You have successfully changed your password.</PwModalBoldText>
        </PwModalIconColumn>
        <PwModalTextColumn>
          <PwModalText>
            Please <span>sign in</span> with your new password to access your profile.
          </PwModalText>
        </PwModalTextColumn>
      </PwModalContent>
      <ModalDividerDiv />
      <Column alignItems="center" pt={4} pb={0}>
        <ModalOptionButton onClick={data.onLogout} label="Sign in" size={ButtonSizes.LARGE} />
      </Column>
    </ModalColumn>
  </ModalBody>
);
