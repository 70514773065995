import { createSelector } from 'reselect';

import { State } from 'src/app/store/app.types';

import { getIn, isNotEmpty } from 'src/utils/index';

export const selectModals = (state: State) => getIn(['modals'], state);
export const selectIsOpen = createSelector(selectModals, isNotEmpty);
export const selectCurrentModal = createSelector(
  selectModals,
  modals => (isNotEmpty(modals) ? modals[modals.length - 1] : { key: null, data: {} }),
);
export const selectCurrentModalType = createSelector(
  selectCurrentModal,
  modal => (modal ? modal.key : null),
);

export const selectCurrentModalData = createSelector(selectCurrentModal, modal => modal.data);
