import * as React from 'react';

import { SvgIcon } from '../icon.component';
import { DEFAULT_ICON_COLOR } from '../icon.constants';
import { calculateAspectRatio } from '../icon.utils';

type IconProps = {
  height?: number;
  width?: number;
  fillColor?: string;
};

export const ExclamationIcon: React.FunctionComponent<IconProps> = ({
  height = 30,
  width = 30,
  fillColor = DEFAULT_ICON_COLOR,
}) => {
  const originalWidth = 18;
  const originalHeight = 18;
  const minX = 0.4;

  const aspectRatio = calculateAspectRatio(originalWidth, originalHeight);
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  return (
    <SvgIcon
      width={calculatedWidth}
      minX={minX}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <path
        d="M9.003 10.882a.503.503 0 0 1-.14-.336l-.42-5.935c-.028-.262.04-.474.203-.638.163-.163.38-.245.651-.245.261 0 .474.082.637.245.163.164.231.376.203.638l-.42 5.935a.53.53 0 0 1-.126.343.384.384 0 0 1-.294.119.403.403 0 0 1-.294-.126m-.343 2.604a.856.856 0 0 1-.259-.644c0-.261.086-.478.26-.65a.866.866 0 0 1 .636-.26c.252 0 .462.087.63.26a.893.893 0 0 1 .252.65.87.87 0 0 1-.252.644.855.855 0 0 1-.63.252.879.879 0 0 1-.637-.252"
        fill={fillColor}
      />
    </SvgIcon>
  );
};
