import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { mapDispatchers } from 'src/utils';

import { stripsStockConnector } from 'src/domains/strips-stock/store';
import { fetchStripsStockStart } from 'src/domains/strips-stock/store';

import { StripsStock } from './strips-stock.component';
import {
  StripsStockConnectProps,
  StripsStockExternalProps,
  StripsStockProps,
} from './strips-stock.types';

const dispatchers = mapDispatchers({
  fetchStripsStock: () => fetchStripsStockStart(),
});

export const StripsStockContainer = compose<StripsStockConnectProps, StripsStockExternalProps>(
  connect(
    stripsStockConnector,
    dispatchers,
  ),
  lifecycle<StripsStockProps, void>({
    componentDidMount() {
      this.props.fetchStripsStock();
    },
  }),
)(StripsStock);
