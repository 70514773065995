import { getDynamicConfig, getJSON } from 'src/utils';

import { createService } from '../service';

import {
  AssociatedProfessionalData,
  AssociatedProfessionalOptions,
  AssociatedProfessionalService,
} from './associated-professional.types';

const {
  REACT_APP_API_MULE,
  REACT_APP_GIGYA_TOKEN,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
} = getDynamicConfig();

const associatedProfessionalTransform = associatedProfessionalData =>
  associatedProfessionalData.model;

/**
 * @param {string} token
 * @returns {Promise<AssociatedProfessionalData>}
 *
 * Note: the token is without bearer attached to it because EC6 directly expect it without
 */
const associatedProfessionalRequest = ({
  patientId,
  accessToken,
}: AssociatedProfessionalOptions): Promise<AssociatedProfessionalData> =>
  getJSON(`${REACT_APP_API_MULE}/customers/api/v1/patients/${patientId}/practitioners`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ApiKey: REACT_APP_GIGYA_TOKEN,
      client_id: REACT_APP_CLIENT_ID,
      client_secret: REACT_APP_CLIENT_SECRET,
    },
  });

export const associatedProfessionalService: AssociatedProfessionalService = createService(
  associatedProfessionalRequest,
  associatedProfessionalTransform,
);
