import { getDynamicConfig, getJSON } from 'src/utils';

import { createService } from '../service';
import { transformJavaSimpleDateFormatToISO } from '../transformers';

import { PatientInfoData, PatientInfoOptions, PatientInfoService } from './patient-info.types';

const {
  REACT_APP_API_MULE,
  REACT_APP_GIGYA_TOKEN,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
} = getDynamicConfig();

const patientInfoTransform = patientInfoData => {
  const {
    user,
    diagnosticDate,
    expectedDeliverDate,
    lastGlucoseDate,
    ...details
  } = patientInfoData.model;
  return {
    user,
    details: {
      ...details,
      diagnosticDate: diagnosticDate && transformJavaSimpleDateFormatToISO(diagnosticDate),
      expectedDeliverDate:
        expectedDeliverDate && transformJavaSimpleDateFormatToISO(expectedDeliverDate),
      lastGlucoseDate: lastGlucoseDate && transformJavaSimpleDateFormatToISO(lastGlucoseDate),
    },
  };
};

/**
 * @param {string} token
 * @returns {Promise<PatientInfoData>}
 */
const patientInfoRequest = ({ accessToken }: PatientInfoOptions): Promise<PatientInfoData> =>
  getJSON(`${REACT_APP_API_MULE}/ciam/api/v1/identity/current-user/patient`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ApiKey: REACT_APP_GIGYA_TOKEN,
      client_id: REACT_APP_CLIENT_ID,
      client_secret: REACT_APP_CLIENT_SECRET,
    },
  });

export const patientInfoService: PatientInfoService = createService(
  patientInfoRequest,
  patientInfoTransform,
);
