import { combineReducers } from 'redux';

import { setIn } from 'src/utils';

import { dateFiltersReducer } from '../date-filters/store/date-filters.reducers';

import { ToggleState, UIActionType, UIReducerActions, UIState } from './ui.types';

export const initialToggleState: ToggleState = {
  isDisplayed: false,
};

export const uiTogglesReducer = (
  state: ToggleState = initialToggleState,
  action: UIReducerActions,
): ToggleState => {
  switch (action.type) {
    case UIActionType.SHOW_APP_NAVIGATION:
      return setIn(['isDisplayed'], true, state);
    case UIActionType.HIDE_APP_NAVIGATION:
      return setIn(['isDisplayed'], false, state);
    default:
      return state;
  }
};

export const uiReducers = combineReducers<UIState>({
  toggles: uiTogglesReducer,
  dateFilters: dateFiltersReducer,
});
