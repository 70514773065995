import { Div } from 'src/components/div';
import { Span } from 'src/components/span';
import styled from 'styled-components';

import { spacing } from 'src/theme/spacing';
import { ModalButtonDiv } from 'src/ui/modal/components/modal.style';
import { combineRems } from 'src/utils/styles';

export const DTCModalSubheading = styled.h2`
  margin: 0;
  padding-bottom: ${({ theme }) => theme.spacing.three};
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.charcoal};
`;
DTCModalSubheading.displayName = 'DTCModalSubheading';

export const DTCConnectWrapperDiv = styled(Div)`
  background-color: rgba(93, 143, 223, 0.05);
  display: inline-block;
  padding: ${spacing.two} ${spacing.four} ${spacing.two} ${spacing.four};
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.blueMarineAlpha27};
  border-radius: ${({ theme }) => theme.borderRadius[0]};
`;
DTCConnectWrapperDiv.displayName = 'DTCConnectWrapperDiv';

export const DTCConnectBlockDiv = styled(Div)`
  display: block;
  text-align: center;
  font-size: 14px;
  padding-top: ${spacing.two};
  padding-bottom: ${spacing.one};
  max-width: 33rem;
`;
DTCConnectBlockDiv.displayName = 'DTCConnectBlockDiv';

export const DTCDownloadMessageDiv = styled.div`
  padding: ${combineRems(spacing.three, spacing.two)};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.charcoal};
  font-size: ${({ theme }) => theme.fontSize.subheading};
`;
DTCDownloadMessageDiv.displayName = 'DTCDownloadMessageDiv';

export const DTCDownloadButtonDiv = styled(ModalButtonDiv)`
  padding-top: 0;
  padding-bottom: ${spacing.two};
  font-size: 14px;
`;
DTCDownloadButtonDiv.displayName = 'DTCDownloadButtonDiv';

export const DTCDownloadHref = styled.a`
  text-decoration: none;
`;
DTCDownloadHref.displayName = 'DTCDownloadHref';

export const DTCSubheadline = styled.p`
  color: ${({ theme }) => theme.colors.grayDark};
  margin: 0 auto;
  margin: 0 auto ${spacing.three};
  text-align: center;
`;

export const DTCSubheadSubline = styled.span`
  display: block;
  font-size: 14px;
`;
DTCSubheadSubline.displayName = 'DTCSubheadSubline';

export const DTCHelpMessage = styled(Span)`
  color: ${({ theme }) => theme.colors.grayDark};
  font-size: 12px;
  text-align: center;
`;
DTCHelpMessage.displayName = 'DTCHelpMessage';
