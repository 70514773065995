import { createSelector } from 'reselect';

import { selectSession } from 'src/core/session/session.selectors';
import { propOr } from 'src/utils';

export const selectAccessToken = createSelector([selectSession], session =>
  propOr('', 'accessToken', session),
);

export const selectRefreshToken = createSelector([selectSession], session =>
  propOr('', 'refreshToken', session),
);

export const selectTtl = createSelector([selectSession], session =>
  propOr(Date.now(), 'ttl', session),
);

export const selectTtlLength = createSelector([selectSession], session =>
  propOr(-1, 'ttlLength', session),
);

export const selectIsTtlValid = createSelector([selectTtl], ttl => ttl > Date.now());

export const selectIsSessionValid = createSelector(
  [selectAccessToken, selectRefreshToken, selectIsTtlValid],
  (accessToken, refreshToken, ttlIsValid) => Boolean(accessToken && refreshToken) && ttlIsValid,
);
