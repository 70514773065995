import { Action, PayloadAction } from 'src/app/store/app.types';
import { Diff } from 'src/utils';

export enum PatientInfoActionType {
  FETCH_PATIENT_INFO_START = 'FETCH_PATIENT_INFO_START',
  FETCH_PATIENT_INFO_SUCCESS = 'FETCH_PATIENT_INFO_SUCCESS',
  FETCH_PATIENT_INFO_ERROR = 'FETCH_PATIENT_INFO_ERROR',
  FETCH_ASSOCIATED_PROFESSIONAL_START = 'FETCH_ASSOCIATED_PROFESSIONAL_START',
  FETCH_ASSOCIATED_PROFESSIONAL_SUCCESS = 'FETCH_ASSOCIATED_PROFESSIONAL_SUCCESS',
  FETCH_ASSOCIATED_PROFESSIONAL_ERROR = 'FETCH_ASSOCIATED_PROFESSIONAL_ERROR',
  RESET_PATIENT_INFO = 'RESET_PATIENT_INFO',
}

export type PatientInfoPayload = PatientInfo;

export type FetchPatientInfoStart = Action<PatientInfoActionType.FETCH_PATIENT_INFO_START>;
export type FetchPatientInfoSuccess = PayloadAction<
  PatientInfoActionType.FETCH_PATIENT_INFO_SUCCESS,
  PatientInfoPayload
>;
// TODO: Error state payload
export type FetchPatientInfoError = PayloadAction<
  PatientInfoActionType.FETCH_PATIENT_INFO_ERROR,
  any
>;

export type FetchAssociatedProfessionalStart = Action<
  PatientInfoActionType.FETCH_ASSOCIATED_PROFESSIONAL_START
>;
export type FetchAssociatedProfessionalSuccess = PayloadAction<
  PatientInfoActionType.FETCH_ASSOCIATED_PROFESSIONAL_SUCCESS,
  AssociatedProfessional[]
>;
// TODO: Error state payload
export type FetchAssociatedProfessionalError = PayloadAction<
  PatientInfoActionType.FETCH_ASSOCIATED_PROFESSIONAL_ERROR,
  any
>;

export type ResetPatientInfo = Action<PatientInfoActionType.RESET_PATIENT_INFO>;

export type PatientInfoDetails = {
  id: string;
  clinicalRecordNumber: string;
  healthCareSystemId: string;
  deviceType: string;
  diagnosticDate: string;
  deviceTypeLabel: string;
  timeZone: string;
  pregnant: boolean;
  nursingHome: boolean;
  professionalList: string[];
  relevantClinicalData: string;
  units: string;
  expectedDeliverDate: string;
  downloadNoticePeriod: 'NO_WARNING' | 'THIRTY' | 'SIXTY' | 'NINETY' | '';
  diabetesType:
    | 'DIABETES_TYPE1'
    | 'DIABETES_TYPE2'
    | 'DIABETES_GESTATIONAL'
    | 'PENDING_TO_IDENTIFY'
    | '';
  lastGlucoseDate: string;
  patientDevices: string[];
  treatmentName: string;
  deviceTypeIcon: string;
  multilingualDeviceTypeLabel: string;
};

export type PatientInfoUser = {
  id: string;
  name: string;
  surname: string;
  surname2: string;
  address: {
    id: string;
    address: string;
    postalCode: string;
    city: string;
    province: string;
    country: {
      id: string;
      name: string;
      isoCode: string;
      labelProperty: string;
      labelText: string;
      language: {
        id: string;
        languageId: string;
        labelProperty: string;
        labelText: string;
        isoCode: string;
        key: string;
        value: string;
      };
      timeZone: string;
      profilesList: string;
      currency: string;
      prefix: string;
      key: string;
      value: string;
    };
  };
  fullname: string;
  inactivate: boolean;
  gender: string;
  phone: string;
  birthday: string;
  email: string;
  loginId: string;
  language: string;
  languageId: string;
  languageIsoCode: string;
  languageCountryIsoCode: string;
  locale: string;
  role: string;
  departmentProfileId: string;
  profile: string;
  countryId: string;
  inactivateReason: string;
  inactivateComment: string;
  departmentId: string;
  centerId: string;
  areaId: string;
  locked: boolean;
  lastAccess: string;
  passwordReset: boolean;
  viewNotifications: boolean;
  countryIsoCode: string;
  departmentName: string;
  centerName: string;
  accessEnum: string;
  profileId: string;
  gigyaUid: string;
  activeInGigya: string;
  hcpIsAccessible: boolean;
  fakeMail: boolean;
  headerWelcome: string;
  languageIsoCodeComplete: string;
  noPhone: boolean;
};

export type AssociatedProfessional = {
  id: string;
  name: string;
  surname: string;
  surname2: string;
};

export type PatientInfo = {
  details: PatientInfoDetails;
  user: PatientInfoUser;
};

// State and action combinations
export type PatientInfoState = {
  isLoading?: boolean;
  error?: any;
  associatedProfessional: AssociatedProfessional[];
  details: PatientInfoDetails;
  user: PatientInfoUser;
};

export type PatientInfoEpicActions = FetchPatientInfoStart | FetchAssociatedProfessionalStart;

export type PatientInfoActions =
  | FetchPatientInfoStart
  | FetchPatientInfoSuccess
  | FetchPatientInfoError
  | FetchAssociatedProfessionalStart
  | FetchAssociatedProfessionalSuccess
  | FetchAssociatedProfessionalError
  | ResetPatientInfo;

export type PatientInfoReducerActions = Diff<PatientInfoActions, PatientInfoEpicActions>;
