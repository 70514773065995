import { getDynamicConfig, getJSON } from 'src/utils';

import { createService } from 'src/services/service';

import { LogoutService } from './logout.types';

const {
  REACT_APP_API_GATEWAY,
  REACT_APP_GIGYA_TOKEN,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
} = getDynamicConfig();

const logout = ({ accessToken }) =>
  getJSON(`${REACT_APP_API_GATEWAY}/Gigya/v1/gigya/logout`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ApiKey: REACT_APP_GIGYA_TOKEN,
      client_id: REACT_APP_CLIENT_ID,
      client_secret: REACT_APP_CLIENT_SECRET,
    },
    body: {
      apiKey: REACT_APP_GIGYA_TOKEN,
      token: accessToken,
    } as any,
  });

type DevMode = {
  devMode: boolean;
};

export const logoutService = ({ devMode = false }: DevMode): LogoutService => {
  if (devMode) {
    // @ts-ignore
    return Promise.resolve({ data: {} });
  }
  return createService(logout, data => data);
};
