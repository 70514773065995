import { ensureNever } from 'src/utils';

import {
  StripsStockActionType,
  StripsStockReducerActions,
  StripsStockState,
} from './strips-stock.types';

export const initialStripsStockState: StripsStockState = {
  remaining: void 0,
  startAmount: void 0,
};

export const stripsStockReducer = (
  state = initialStripsStockState,
  action: StripsStockReducerActions,
): StripsStockState => {
  switch (action.type) {
    case StripsStockActionType.SET_STRIPS_STOCK:
      return action.payload;
    case StripsStockActionType.FETCH_STRIPS_STOCK_ERROR:
    case StripsStockActionType.FETCH_STRIPS_STOCK_SUCCESS:
      return state;
    case StripsStockActionType.RESET_STRIPS_STOCK:
      return initialStripsStockState;
    default:
      ensureNever(action);
      return state;
  }
};
