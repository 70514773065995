import * as React from 'react';

import { ArrowIcon } from 'src/assets/icons';

import {
  ArrowLeftSpan,
  ArrowRightSpan,
  DatePickerNavigationArrowContainer,
} from './date-picker.style';

export const DatePickerPrev = (
  <DatePickerNavigationArrowContainer>
    <ArrowLeftSpan>
      <ArrowIcon height={8} />
    </ArrowLeftSpan>
  </DatePickerNavigationArrowContainer>
);

export const DatePickerNext = (
  <DatePickerNavigationArrowContainer>
    <ArrowRightSpan>
      <ArrowIcon height={8} />
    </ArrowRightSpan>
  </DatePickerNavigationArrowContainer>
);
