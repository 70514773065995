import * as React from 'react';

import { colors } from 'src/theme/colors';
import { SvgIcon } from '../icon.component';

type IconProps = {
  width?: number;
  height?: number;
  fillColor?: string;
};

export const MedicalDeviceIcon: React.FunctionComponent<IconProps> = ({
  width = 32,
  height = 20,
  fillColor = colors.black,
}) => {
  const originalWidth = 32;
  const originalHeight = 20;

  return (
    <SvgIcon
      width={width}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g clipPath="url(#clip0_10322_23231)">
        <path d="M32 0.0264893H0V19.9736H32V0.0264893Z" fill={fillColor} />
        <path d="M30.8724 1.1543H1.12793V18.9164H30.8724V1.1543Z" fill="white" />
        <path
          d="M5.77964 5.45386H9.44484L10.8545 10.9517L12.2642 5.45386H15.9294V14.5464H13.6034V7.63888L11.8413 14.5464H9.72678L7.96466 7.63888V14.5464H5.63867V5.45386H5.77964Z"
          fill={fillColor}
        />
        <path
          d="M17.8322 5.45386H21.9908C22.8366 5.45386 23.471 5.59483 23.9644 5.80628C24.4578 6.01773 24.8807 6.37016 25.2331 6.79306C25.5855 7.21597 25.797 7.70936 25.9379 8.27324C26.0789 8.83712 26.1494 9.40099 26.1494 10.0354C26.1494 11.0221 26.0084 11.727 25.797 12.2909C25.5855 12.8547 25.3036 13.2776 24.8807 13.6301C24.4578 13.9825 24.0348 14.2644 23.6119 14.3349C22.9776 14.4759 22.4137 14.6169 21.9203 14.6169H17.7617V5.45386H17.8322ZM20.6516 7.49791V12.4318H21.3564C21.9203 12.4318 22.3432 12.4318 22.6252 12.2204C22.9071 12.0794 23.0481 11.868 23.189 11.5155C23.33 11.1631 23.4005 10.6697 23.4005 9.96487C23.4005 9.26003 23.2595 8.34373 22.9071 7.9913C22.5547 7.63888 22.0613 7.42743 21.3564 7.42743H20.6516V7.49791Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_10322_23231">
          <rect width={width} height={height} fill="white" transform="translate(0 0.0264893)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
