import * as React from 'react';

import { Avatar } from 'src/components/avatar';
import { Block } from 'src/components/block';
import { Card } from 'src/components/card';
import { convertPxToRem } from 'src/utils/styles/rem-calc.utils';

import { ProfileBadgeName } from './profile-badge.style';

type ProfileBadgeProps = {
  readonly name: string;
  readonly photo: string;
};

export const ProfileBadge: React.FunctionComponent<ProfileBadgeProps> = ({ name, photo }) => (
  <Block>
    <Block mb={3}>
      <Avatar image={photo} title={name} placeholderFontSize={convertPxToRem(130)} />
    </Block>
    <Card>
      <ProfileBadgeName>{name}</ProfileBadgeName>
    </Card>
  </Block>
);
