import { resetBGStatistics } from '@roche/patient-portal-graphs/domains/diagnostics/bg-statistics/store/bg-statistics.actions';
import { combineEpics, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { Action, State } from 'src/app/store/app.types';
import { VALIDATION_INTERVAL } from 'src/core/gigya/gigya.constants';
import { removeAllModals } from 'src/core/modal/modal.actions';
import { resetPatientInfo } from 'src/core/patient-info/patient-info.actions';
import { resetPermissions } from 'src/core/permissions/permissions.actions';
import { resetAccountInfo } from 'src/core/user/user.actions';
import { resetStripsDelivery } from 'src/domains/strips-delivery';
import { resetStripsStock } from 'src/domains/strips-stock';
import { logoutService } from 'src/services/logout';
import { LogoutService } from 'src/services/logout/logout.types';
import { resetDateRangeFilter } from 'src/ui/date-filters/store/date-filters.actions';
import { hideAppNavigation } from 'src/ui/store';

import { selectAccessToken, selectIsSessionValid } from './oidc/oidc.selector';
import {
  signoutError,
  signoutStart,
  signoutSuccess,
  validateSessionError,
  validateSessionStart,
  validateSessionSuccess,
} from './session.actions';
import { SessionActions, SessionActionType } from './session.types';

export const validateSessionEpic: Epic<SessionActions, State> = (action$, store) =>
  action$
    .ofType(SessionActionType.VALIDATE_SESSION_START)
    .debounceTime(1000)
    .switchMap<SessionActions, any>(() => {
      const oidcSessionIsValid = selectIsSessionValid(store.getState());
      return !oidcSessionIsValid
        ? [
            validateSessionError({
              error: 'Something went wrong with your gigya validation',
            }),
            signoutStart(),
          ]
        : [validateSessionSuccess()];
    });

export const signOutEpic = (service: LogoutService): Epic<Action<any>, State> => (
  action$,
  store$,
) =>
  action$
    .ofType(SessionActionType.SIGN_OUT_START)
    .debounceTime(1000)
    .switchMap(() => {
      const accessToken = selectAccessToken(store$.getState());
      return Observable.fromPromise(service({ UID: accessToken, accessToken }))
        .map(() => [signoutSuccess()])
        .pipe(catchError(() => [signoutError()]));
    })
    .switchMap(() => {
      return [
        resetPatientInfo(),
        resetAccountInfo(),
        resetStripsStock(),
        resetStripsDelivery(),
        resetPermissions(),
        resetDateRangeFilter(),
        removeAllModals(),
        resetBGStatistics(),
        removeAllModals(),
        signoutSuccess(),
        hideAppNavigation(),
      ];
    });

export const validateSessionIntervalEpic: Epic<SessionActions, State> = action$ =>
  action$
    .ofType(SessionActionType.VALIDATE_SESSION_SUCCESS)
    .delay(1000 * VALIDATION_INTERVAL)
    .mapTo(validateSessionStart());

export const sessionEpics = combineEpics(
  validateSessionEpic,
  validateSessionIntervalEpic,
  signOutEpic(logoutService({ devMode: false })),
);
