/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import RocheLogo from 'src/assets/images/roche-logo.svg';

export const Consent = () => {
  return (
    <div
      style={{
        position: 'relative',
        textAlign: 'right',
        margin: '40px 40px',
      }}
    >
      <img src={RocheLogo} />
    </div>
  );
};
