import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { fetchPatientInfoStart } from 'src/core/patient-info/patient-info.actions';
import { validateSessionStart } from 'src/core/session/session.actions';
import { mapDispatchers } from 'src/utils';

import { PatientGuardProps } from './route-guard.types';
import { patientGuardSelector } from './store/route-guards.selectors';

// Only verify session when the component mounts
export class PatientGuardComponent extends React.Component<PatientGuardProps> {
  // tslint:disable-next-line
  static defaultProps: Partial<PatientGuardProps> = {
    onSuccess: props => props.children,
    onError: props => props.children,
    onVerifying: () => <div />,
  };
  public state = {
    isVerifying: true,
  };

  public componentWillMount() {
    if (!this.props.patientId) {
      this.props.fetchPatientInfo();
    }
  }

  public componentDidMount() {
    if (this.props.patientId) {
      this.setState({
        isVerifying: false,
      });
    }
  }

  public componentWillReceiveProps(nextProps) {
    if (this.props.patientId !== nextProps.patientId || nextProps.error) {
      this.setState({
        isVerifying: false,
      });
    }
  }

  public render() {
    const { isVerifying } = this.state;
    const { onSuccess, onError, onVerifying, patientId, error } = this.props;
    if (isVerifying) {
      return onVerifying(this.props);
    }
    if (!patientId && error) {
      return onError(this.props);
    }
    return onSuccess(this.props);
  }
}

export const PatientGuard = compose<PatientGuardProps, Partial<PatientGuardProps>>(
  connect(
    patientGuardSelector,
    mapDispatchers({
      fetchPatientInfo: fetchPatientInfoStart,
      validateSession: validateSessionStart,
    }),
  ),
)(PatientGuardComponent);
