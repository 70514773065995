import { getDynamicConfig, getJSON } from 'src/utils';

import { createService } from '../service';

import { StripsStockData, StripsStockOptions, StripsStockService } from './strips-stock.types';

const {
  REACT_APP_API_MULE,
  REACT_APP_GIGYA_TOKEN,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
} = getDynamicConfig();

const stripsStockTransform = data => ({
  remaining: data.model.remaining,
  startAmount: data.model.total,
});

// possible error response: {resultDescription: 'getDeliveryStockERROR', error:[{ error: 'This patient has not a configured delivery program'}]}
const stripsStockRequest = ({
  patientId,
  accessToken,
}: StripsStockOptions): Promise<StripsStockData> =>
  getJSON(`${REACT_APP_API_MULE}/strips/api/v1/stocks/patients/${patientId}/home-delivery`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ApiKey: REACT_APP_GIGYA_TOKEN,
      client_id: REACT_APP_CLIENT_ID,
      client_secret: REACT_APP_CLIENT_SECRET,
    },
  });

export const stripsStockService: StripsStockService = createService(
  stripsStockRequest,
  stripsStockTransform,
);
