import { formatDateString, formatJSDate, isJSDateValid } from 'src/utils/dates';

export const PROFILE_DATE_PLACEHOLDER = '-';
export const PROFILE_DATE_FORMAT = 'dd / LL / yyyy';

export const formatProfileDate = (date: Date): string =>
  isJSDateValid(date)
    ? formatJSDate({
        date,
        format: PROFILE_DATE_FORMAT,
      })
    : PROFILE_DATE_PLACEHOLDER;

export const formatProfileDateString = (dateString: string): string =>
  formatDateString({
    dateString,
    errorString: PROFILE_DATE_PLACEHOLDER,
    format: PROFILE_DATE_FORMAT,
  });
