import styled from 'styled-components';
import { Div } from '../../../../components/div';

export const MainDialogContainer = styled(Div)`  
  & .header-product-text {
      margin-top: 0;
  }
  & .subheader-product-text {
    margin-top: 16px;
}
}
`;
MainDialogContainer.displayName = 'MainDialogContainer';

export const GeneralTextContainer = styled(Div)`
  margin: 16px 0 16px 0;

  & .no-margin-product-text {
    margin: 0;
  }
`;
GeneralTextContainer.displayName = 'GeneralTextContainer';

export const TextWithIconContainer = styled(Div)`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  gap: 18px;
  max-width: 436px;

  & .no-margin-product-text {
    margin: 0;
  }
`;
TextWithIconContainer.displayName = 'TextWithIconContainer';


export const IconContainer = styled(Div)`
  min-width: 32px;
  min-height: 20px;
`;
IconContainer.displayName = 'IconContainer';


export const DoubleLogoTextContainer = styled(Div)`
  display: grid;
  gap: 14px;
  max-width: 560px;
  grid-template-columns: 45% 50%;

  & .no-margin-product-text {
    margin: 0;
  }
`;
DoubleLogoTextContainer.displayName = 'DoubleLogoTextContainer';
