import * as React from 'react';
import { translate } from 'react-i18next';
import { StyledSystemProps } from 'src/theme/theme.types';

import { Span } from '../span';

type LocalizedTextValues = {
  [key: string]: string;
};
export type LocalizedTextProps = {
  t: (k: string, LocalizedTextValues) => string;
  className: string;
  label: string;
  tReady: boolean;
  values: LocalizedTextValues;
};

export const LocalizedTextComponent: React.FunctionComponent<LocalizedTextProps &
  StyledSystemProps> = ({ className, label = '', t, tReady, values }) => (
  <Span className={className}>
    {!tReady && '-'}
    {tReady && t(label, values)}
  </Span>
);

export const LocalizedText = translate()(LocalizedTextComponent);
LocalizedText.displayName = 'LocalizedText';
