import { createSelector, OutputSelector } from 'reselect';

import { State } from 'src/app/store/app.types';
import { getIn } from 'src/utils/ramda';

import { CombinedProfileRoutes, NavigationRoutes } from './navigation.types';

export const selectCombinedRoutes = (state: State) => getIn(['navigation'], state);

export const selectNavigationRoutes: OutputSelector<
  any,
  any,
  (res: any) => NavigationRoutes
> = createSelector(selectCombinedRoutes, routes => ({
  help: '#',
  home: getIn(['dashboard', 'patient'], routes),
  viewProfile: getIn(['profile', 'view'], routes),
}));

export const selectProfileRoutes: OutputSelector<
  any,
  any,
  (res: any) => CombinedProfileRoutes
> = createSelector(selectCombinedRoutes, routes => ({
  ...getIn(['profile'], routes),
}));

export const selectLocationPathname = (state: State) => {
  const location = getIn(['router', 'location'], state);
  return location !== null ? location.pathname : '/';
};
