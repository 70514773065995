import {
  PatientDashboardRouteDefinitions,
  PatientDashboardRoutes,
} from './patient-dashboard.navigation.types';

export const DASHBOARD_ROUTES: PatientDashboardRoutes = { patient: '/dashboard/patient' };

export const DASHBOARD_ROUTE_DEFINITIONS: PatientDashboardRouteDefinitions = {
  patient: '/dashboard/patient/:modal?/:id?',
};
