export const DEFAULT_DATE_FORMATTING = 'MMMM DD, YYYY';

// keys from pp-translations/index
// values from moment/min/locales
export const localesMomentMap = {
  'en-GB': 'en-gb',
  'es-ES': 'es',
  'pt-PT': 'pt',
  'pt-BR': 'pt-br',
  'fr-FR': 'fr',
  'pl-PL': 'pl',
  'ca-ES': 'ca',
  'da-DK': 'da',
  'nl-NL': 'nl-nl',
  'fi-FI': 'fi',
  'de-CH': 'de-ch',
  'de-DE': 'de-de',
  'it-IT': 'it-it',
  'no-NO': 'nb_NO',
  'es-AR': 'es-ar',
  'sv-SE': 'sv-se',
  'ru-RU': 'ru',
};
