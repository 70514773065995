import { Action, PayloadAction } from 'src/app/store/app.types';

export type Country = {
  id: number | null;
  name: string;
  isoCode: string;
};

export type CountryOption = {
  value: string;
  label: string;
};

export enum CountriesActionType {
  FETCH_COUNTRIES_START = 'FETCH_COUNTRIES_START',
  FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS',
  FETCH_COUNTRIES_ERROR = 'FETCH_COUNTRIES_ERROR',
}

export type CountriesPayload = Country[];

export type FetchCountriesStart = Action<CountriesActionType.FETCH_COUNTRIES_START>;
export type FetchCountriesSuccess = PayloadAction<
  CountriesActionType.FETCH_COUNTRIES_SUCCESS,
  CountriesPayload
>;
export type FetchCountriesError = PayloadAction<CountriesActionType.FETCH_COUNTRIES_ERROR, any>;

export type CountriesState = {
  isLoading: boolean;
  countries: Country[];
  error: any;
};

export type CountriesEpicActions = FetchCountriesStart;

export type CountriesActions = FetchCountriesStart | FetchCountriesSuccess | FetchCountriesError;

export type CountriesReducerActions =
  | FetchCountriesStart
  | FetchCountriesSuccess
  | FetchCountriesError;
