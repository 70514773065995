import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { addModal } from 'src/core/modal/modal.actions';
import { ModalTypes } from 'src/core/modal/modal.types';
import { signoutStart } from 'src/core/session/session.actions';
import { mapDispatchers } from 'src/utils';

import { profileConnector } from '../store/profile.selectors';

import { ChangePasswordPatientComponent } from './change-password.component';

const dispatchers = mapDispatchers({
  goTo: path => push(path),
  onLogout: signoutStart,
  showChangePasswordConfirmationModal: ({ onLogout }) => {
    return addModal({
      key: ModalTypes.CHANGE_PASSWORD_CONFIRMATION,
      data: { onLogout },
    });
  },
});

export const ChangePassword = compose(
  connect(
    profileConnector,
    dispatchers,
  ),
)(ChangePasswordPatientComponent);
