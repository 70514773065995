import * as React from 'react';
import { translate } from 'react-i18next';
import { Block } from 'src/components/block';
import { Button, ButtonSizes } from 'src/components/button';
import { Card } from 'src/components/card';
import { GridContainer, GridItem } from 'src/components/grid';
import { IFrame } from 'src/components/iframe';
import { gigyaLangCodeTransformer } from 'src/core/gigya/gigya.utils';
import { ProfileBadge } from 'src/domains/profile/components/profile-badge';
import i18nConfig from 'src/i18n';
import { FALLBACK_LOCALE } from 'src/i18n/constants';
import { ProfileRoutes } from 'src/navigation/profile/profile.navigation.types';
import { CONTENT_MAX_WIDTH } from 'src/theme/spacing';
import { getDynamicConfig, propOr } from 'src/utils';

import { ChangePasswordMessageEvent, ChangePasswordProps } from './change-password.types';

const PostMessageData = {
  GIGYA_CHANGED_PASSWORD: 'gigya-changed-password',
  GIGYA_CANCEL_CHANGE_PASSWORD: 'gigya-cancel-change-password',
};

export class ChangePasswordPatient extends React.Component<ChangePasswordProps> {
  public componentDidMount() {
    const { routes, goTo } = this.props;

    window.addEventListener('message', this.receiveMessage(routes, goTo));
  }

  public receiveMessage = (routes: ProfileRoutes, goTo: ChangePasswordProps['goTo']) => (
    event: ChangePasswordMessageEvent,
  ) => {
    const { showChangePasswordConfirmationModal, onLogout } = this.props;

    if (event.data === PostMessageData.GIGYA_CHANGED_PASSWORD) {
      showChangePasswordConfirmationModal({
        onLogout,
      });
    } else if (event.data === PostMessageData.GIGYA_CANCEL_CHANGE_PASSWORD) {
      goTo(routes.view);
    }
  };
  // Fixed iframe height values do the Cross Origin iFrame limitations
  // TODO: figure out how to resize iframe without changing the content
  public render() {
    const {
      user: { photo, firstName, lastName },
      goTo,
      routes,
      t,
    } = this.props;

    const langCode = propOr(FALLBACK_LOCALE, 'language', i18nConfig);

    return (
      <Block py={3} px={4} maxWidth={`${CONTENT_MAX_WIDTH}px`} m="0 auto">
        <Block display="flex" justifyContent="flex-end" pb={3} mb={4}>
          <Button
            label={t('profile.edit.title', '')}
            onClick={() => goTo(routes.edit)}
            size={ButtonSizes.MEDIUM}
          />
          <Block pl={3}>
            <Button
              label={t('profile.edit.password', '')}
              onClick={() => goTo(routes.changePassword)}
              size={ButtonSizes.MEDIUM}
              disabled
            />
          </Block>
        </Block>
        <GridContainer columns={12}>
          <GridItem span={4}>
            <ProfileBadge photo={photo} name={`${firstName} ${lastName}`} />
          </GridItem>
          <GridItem span={8}>
            <Card minHeight="550px">
              <IFrame
                title="changePassword"
                width="500px"
                height="550px"
                resizeOnLoad={false}
                src={`${
                  getDynamicConfig().REACT_APP_ACCESS_ENDPOINT
                }/v1/gigya/changePassword?apiKey=${
                  getDynamicConfig().REACT_APP_GIGYA_TOKEN
                }&lang=${gigyaLangCodeTransformer(langCode)}`}
              />
            </Card>
          </GridItem>
        </GridContainer>
      </Block>
    );
  }
}

export const ChangePasswordPatientComponent = translate()(ChangePasswordPatient);
