import * as React from 'react';
import styled, { css } from 'styled-components';

import { Div } from 'src/components/div';
import { Span } from 'src/components/span';
import { constructHeroGradient } from 'src/theme/colors';

import { AvatarStyleProps } from './avatar.types';

const FillParentContainer = css`
  height: 100%;
  width: 100%;
`;

// TODO: Add lazy loading logic for images
export const AvatarWrapperDiv = styled.div`
  ${FillParentContainer};

  left: 0;
  position: absolute;
  top: 0;
`;
AvatarWrapperDiv.displayName = 'AvatarWrapperDiv';

export const AvatarPlaceholderDiv = styled.div`
  ${FillParentContainer};

  align-items: center;
  display: flex;
  justify-content: center;
`;
AvatarPlaceholderDiv.displayName = 'AvatarPlaceholderDiv';

export const AvatarPlaceholderSpan = styled(Span)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  opacity: 0.8;
  text-align: center;
`;
AvatarPlaceholderSpan.displayName = 'AvatarPlaceholderSpan';

export const AvatarPhotoDiv = styled(Div)`
  ${FillParentContainer};

  background-position: center center;
  background-size: cover;
`;
AvatarPhotoDiv.displayName = 'AvatarPhotoDiv';

export const styleAvatarBadgeComponent = (
  AvatarWrapperComponent: React.FunctionComponent<AvatarStyleProps>,
) => styled(AvatarWrapperComponent)`
  background: ${({ isReversed, theme }: AvatarStyleProps) =>
    isReversed ? theme.colors.white : constructHeroGradient(134)};
  border-radius: ${({ theme }) => theme.borderRadius[5]};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  font-size: 0;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;

  ${AvatarPlaceholderSpan} {
    color: ${({ isReversed, theme }: AvatarStyleProps) =>
      isReversed ? theme.colors.blueMarine : ''};
  }
`;
