import { ensureNever } from 'src/utils';

import {
  Permission,
  PermissionActionType,
  PermissionReducerActions,
  PermissionState,
} from './permissions.types';

export const INITIAL_PERMISSIONS_STATE: Partial<Permission[]> = [];
export const permissionsReducer = (
  state = INITIAL_PERMISSIONS_STATE,
  action: PermissionReducerActions,
): PermissionState => {
  switch (action.type) {
    case PermissionActionType.FETCH_PERMISSIONS_SUCCESS:
      return action.payload;
    case PermissionActionType.FETCH_PERMISSIONS_ERROR:
      return INITIAL_PERMISSIONS_STATE;
    case PermissionActionType.RESET_PERMISSIONS:
      return INITIAL_PERMISSIONS_STATE;
    default:
      ensureNever(action);
      return state;
  }
};
