import { combineEpics, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';

import { State } from 'src/app/store/app.types';
import { selectAccessToken } from 'src/core/session/oidc/oidc.selector';

import { stripsStockService } from 'src/services/strips-stock';
import { StripsStockService } from 'src/services/strips-stock/strips-stock.types';

import { selectPatientId } from 'src/core/patient-info/patient-info.selectors';

import {
  fetchStripsStockError,
  fetchStripsStockSuccess,
  setStripsStock,
} from './strips-stock.actions';
import {
  FetchStripsStockSuccess,
  StripsStockActions,
  StripsStockActionType,
} from './strips-stock.types';

export const fetchStripsStockEpic = (
  service: StripsStockService,
): Epic<StripsStockActions, State> => (action$, store$) =>
  action$
    .ofType(StripsStockActionType.FETCH_STRIPS_STOCK_START)
    .debounceTime(1000)
    .switchMap(() =>
      Observable.fromPromise(
        service({
          patientId: selectPatientId(store$.getState()),
          accessToken: selectAccessToken(store$.getState()),
        }),
      )
        .map(data => fetchStripsStockSuccess(data))
        .pipe(catchError(err => Observable.of(fetchStripsStockError(err)))),
    );

export const updateStripsStockAfterFetchEpic: Epic<StripsStockActions, State> = (action$, store$) =>
  action$
    .ofType<FetchStripsStockSuccess>(StripsStockActionType.FETCH_STRIPS_STOCK_SUCCESS)
    .map(a => setStripsStock(a.payload));

export const stripsStockEpic = combineEpics(
  fetchStripsStockEpic(stripsStockService),
  updateStripsStockAfterFetchEpic,
);
