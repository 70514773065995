import { createAction, createPayloadAction } from '../../../app/store/app.actions';

import {
  DateRangeFilterActionType,
  DateRangeFilterPayload,
  ResetDateRangeFilterAction,
  SetDateRangeFilterAction,
} from './date-filters.types';

export const setDateRangeFilter = (payload: DateRangeFilterPayload): SetDateRangeFilterAction =>
  createPayloadAction(DateRangeFilterActionType.SET_DATE_RANGE_FILTER, payload);

export const resetDateRangeFilter = (): ResetDateRangeFilterAction =>
  createAction(DateRangeFilterActionType.RESET_DATE_RANGE_FILTER);
