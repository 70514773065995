import { convertJSDateToString } from 'src/utils/dates';

import { DEFAULT_DATE_RANGE_FILTER } from '../date-filters.constants';

import {
  DateFilterState,
  DateRangeFilterActionType,
  DateRangeFilterReducerActions,
} from './date-filters.types';

export const initialDateFiltersState: DateFilterState = {
  activeFilterId: DEFAULT_DATE_RANGE_FILTER.id,
  startDate: convertJSDateToString(DEFAULT_DATE_RANGE_FILTER.startDate),
  endDate: convertJSDateToString(DEFAULT_DATE_RANGE_FILTER.endDate),
};

export const dateFiltersReducer = (
  state: DateFilterState = initialDateFiltersState,
  action: DateRangeFilterReducerActions,
): DateFilterState => {
  switch (action.type) {
    case DateRangeFilterActionType.SET_DATE_RANGE_FILTER:
      const { endDate, startDate, filterId } = action.payload;
      return {
        ...state,
        endDate,
        startDate,
        activeFilterId: filterId,
      };
    case DateRangeFilterActionType.RESET_DATE_RANGE_FILTER:
      return initialDateFiltersState;
    default:
      return state;
  }
};
