import { DateTime, Duration, Settings } from 'luxon';
import { always, identity, ifElse, isNil, pipe } from 'ramda';
import { FALLBACK_LOCALE } from 'src/i18n/constants';

import {
  DateFormat,
  DateTimeOptions,
  IANATimeZone,
  LuxonDateTime,
  TimeZoneOptions,
} from './dates.types';

// Create all new dates using EN locale for optimal processing speed
// In non-EN browsers, Luxon reroutes calculations through the native internationalization API
// Please do not edit until a full I18n solution has been found
Settings.defaultLocale = 'en';

// Conversion
/**
 * Luxon is 1-based month system, meaning January is 1 instead of 0 like normal date objects
 * https://moment.github.io/luxon/docs/manual/moment.html
 */

export const convertHTTP = (httpDateString: string, opts?: DateTimeOptions): LuxonDateTime =>
  DateTime.fromHTTP(httpDateString, opts);
export const convertISO = (isoDateString: string, opts?: DateTimeOptions): LuxonDateTime =>
  DateTime.fromISO(isoDateString, opts);
export const convertJSDate = (jsDate: Date, opts?: DateTimeOptions): LuxonDateTime =>
  DateTime.fromJSDate(jsDate, opts);
export const convertMillis = (millis: number, opts?: DateTimeOptions): LuxonDateTime =>
  DateTime.fromMillis(millis, opts);
export const convertStringToJSDate = (dateString: string): Date => new Date(dateString);
export const convertJSDateGMT = (
  jsDate: Date,
  opts: DateTimeOptions = { zone: 'Etc/GMT+0' },
): LuxonDateTime => DateTime.fromJSDate(jsDate, opts);

// Getters
export const getZoneName = (date: LuxonDateTime): string => date.zoneName;
export const getOffset = (date: LuxonDateTime): number => date.offset;
export const getOffsetNameShort = (date: LuxonDateTime): string => date.offsetNameShort;
export const getOffsetNameLong = (date: LuxonDateTime): string => date.offsetNameLong;
export const isOffsetFixed = (date: LuxonDateTime): boolean => date.isOffsetFixed;
export const isInDST = (date: LuxonDateTime): boolean => date.isInDST;

// Output
export const now = (): LuxonDateTime => DateTime.local();
export const toString = (date: LuxonDateTime): string => date.toString();
export const toLocaleString = (format: DateFormat) => (date: LuxonDateTime): string =>
  date.toLocaleString(format);
export const toJSDate = (date: LuxonDateTime): Date => date.toJSDate();
export const toEpochTime = (date: LuxonDateTime): number => date.valueOf();
export const toISO = (date: LuxonDateTime): string => date.toISO();
export const toFormat = (format: string, locale: string = FALLBACK_LOCALE) => (
  date: LuxonDateTime,
): string => date.setLocale(locale).toFormat(format);

// Timezone
export const setTimezone = (
  iana: IANATimeZone,
  options: TimeZoneOptions = { keepLocalTime: false },
) => (dateTime: LuxonDateTime) => dateTime.setZone(iana, options);

export const toUTC = (date: LuxonDateTime): LuxonDateTime => date.setZone('Etc/GMT+0');

/**
 * Utilities
 */

// Compare
export const isBefore = (d1: LuxonDateTime, d2: LuxonDateTime): boolean => d1 < d2;
export const isAfter = (d1: LuxonDateTime, d2: LuxonDateTime): boolean => d1 > d2;
export const isSameOrBefore = (d1: LuxonDateTime, d2: LuxonDateTime): boolean => d1 <= d2;
export const isSameOrAfter = (d1: LuxonDateTime, d2: LuxonDateTime): boolean => d1 >= d2;
export const isEqual = (d1: LuxonDateTime, d2: LuxonDateTime): boolean =>
  d1.hasSame(d2, 'millisecond');
export const isBetween = (date, startDate, endDate): boolean =>
  isSameOrBefore(startDate, date) && isSameOrAfter(endDate, date);
export const hasSame = (comparator, d1: LuxonDateTime, d2: LuxonDateTime): boolean =>
  d1.hasSame(d2, comparator);
export const daysInMonth = (date: LuxonDateTime): number => date.daysInMonth;
export const daysInYear = (date: LuxonDateTime): number => date.daysInYear;

// Duration
export const daysToHours = days => Duration.fromObject({ days }).as('hours');
export const minutesToHours = minutes => Duration.fromObject({ minutes }).as('hours');

// Math
export const subtractMinutes = (minutes: number) => (date: LuxonDateTime): LuxonDateTime =>
  date.plus({ minutes: -minutes });
export const subtractQuarters = (quarters: number) => (date: LuxonDateTime): LuxonDateTime =>
  date.plus({ months: -3 * quarters });
export const subtractHours = (hours: number) => (date: LuxonDateTime): LuxonDateTime =>
  date.plus({ hours: -hours });
export const subtractDays = (days: number) => (date: LuxonDateTime): LuxonDateTime =>
  date.plus({ days: -days });
export const subtractWeeks = (weeks: number) => date => date.plus({ weeks: -weeks });
export const subtractMonths = (months: number) => (date: LuxonDateTime): LuxonDateTime =>
  date.plus({ months: -months });
export const addQuarters = (quarters: number) => (date: LuxonDateTime): LuxonDateTime =>
  date.plus({ months: 3 * quarters });
export const addMonths = (months: number) => (date: LuxonDateTime): LuxonDateTime =>
  date.plus({ months });
export const addWeeks = (weeks: number) => (date: LuxonDateTime): LuxonDateTime =>
  date.plus({ weeks });
export const addDays = (days: number) => (date: LuxonDateTime): LuxonDateTime =>
  date.plus({ days });
export const addHours = (hours: number) => (date: LuxonDateTime): LuxonDateTime =>
  date.plus({ hours });
export const addMinutes = (minutes: number) => (date: LuxonDateTime): LuxonDateTime =>
  date.plus({ minutes });
export const diffDays = (d1: LuxonDateTime, d2: LuxonDateTime) => d1.diff(d2, 'days');
export const diffHours = (d1: LuxonDateTime, d2: LuxonDateTime) => d1.diff(d2, 'hours');

// Formatting
export const toDayOfMonthFormat = (date: LuxonDateTime): string => toFormat('d')(date);
export const toAbbreviatedMonthFormat = (date: LuxonDateTime): string => toFormat('LLL')(date);
export const toYearFormat = (date: LuxonDateTime): string => toFormat('yyyy')(date);
export const toDayOfWeekNumFormat = (date: LuxonDateTime): string => toFormat('c')(date);
export const toDayOfYearFormat = (date: LuxonDateTime): string => toFormat('o')(date);
export const toStartOfDay = (date: LuxonDateTime): LuxonDateTime => date.startOf('day');
export const toEndOfDay = (date: LuxonDateTime): LuxonDateTime => date.endOf('day');

export const toStartOfMonth = (date: LuxonDateTime): LuxonDateTime => date.startOf('month');
export const toEndOfMonth = (date: LuxonDateTime): LuxonDateTime => date.endOf('month');
export const toStartOfISOWeek = (date: LuxonDateTime): LuxonDateTime => date.startOf('week');
export const toEndOfISOWeek = (date: LuxonDateTime): LuxonDateTime => date.endOf('week');

// Day of the Week
export const toDayOfWeekNum = (date: LuxonDateTime): number =>
  parseInt(toDayOfWeekNumFormat(date), 10);
export const isWeekend = (date: LuxonDateTime): boolean => toDayOfWeekNum(date) > 5;

// Validation
export const isDateValid = (date: LuxonDateTime): boolean => date.isValid;

export const isJSDateValid = (date: Date): boolean =>
  date instanceof Date && !isNaN(date.getTime());

export const isDateStringValid = (dateString: string): boolean =>
  ifElse(
    isNil,
    always(false),
    pipe(
      convertStringToJSDate,
      convertJSDate,
      isDateValid,
    ),
  )(dateString);

export const formatJSDate = ({ date, format }: { date: Date; format: string }) =>
  pipe(
    convertJSDateGMT,
    toFormat(format),
  )(date);

// Formatting
export const formatDateString = ({
  dateString,
  errorString = '',
  format,
  timeZone,
}: {
  dateString: string;
  errorString?: string;
  format: string;
  timeZone?: IANATimeZone;
}) =>
  isDateStringValid(dateString)
    ? pipe(
        convertStringToJSDate,
        convertJSDateGMT,
        isNil(timeZone) ? identity : setTimezone(timeZone),
        toFormat(format),
      )(dateString)
    : errorString;

export const convertDateStringToJSDate = (dateString: string): Date =>
  pipe(
    convertISO,
    toJSDate,
  )(dateString);

export const convertJSDateToString = (date: Date): string =>
  pipe(
    (jsDate: Date) => convertJSDateGMT(jsDate, {}),
    toISO,
  )(date);

export const createNewJSDate = (): Date => toJSDate(now());
