import * as React from 'react';

import styled from 'styled-components';

import { Button } from 'src/components/button';
import { convertPxToRem } from 'src/utils/styles/rem-calc.utils';

import { StripsDeliveryProps } from 'src/domains/strips-delivery/scenes/strips-delivery.types';

export const ChangeDeliveryStatusButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
ChangeDeliveryStatusButton.displayName = 'ChangeDeliveryStatusButton';

export const ChangeDeliveryStatusButtonTextSpan = styled.span`
  padding-left: ${({ theme }) => theme.spacing.two};
  flex-grow: 1;
`;
ChangeDeliveryStatusButtonTextSpan.displayName = 'ChangeDeliveryStatusButtonTextSpan';

export const styleDeliveryComponent = (
  StripsDeliveryComponent: React.FunctionComponent<StripsDeliveryProps>,
) => styled(StripsDeliveryComponent)`
  min-height: ${convertPxToRem(320)};
  text-align: center;
`;
