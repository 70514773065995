import * as React from 'react';

import { SvgIcon } from '../icon.component';
import { DEFAULT_ICON_COLOR } from '../icon.constants';
import { calculateAspectRatio } from '../icon.utils';

type IconProps = {
  height?: number;
  fillColor?: string;
};

export const UploadIcon: React.FunctionComponent<IconProps> = ({
  height = 25,
  fillColor = DEFAULT_ICON_COLOR,
}) => {
  const originalWidth = 32;
  const originalHeight = 32;
  const aspectRatio = calculateAspectRatio(originalWidth, originalHeight);

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g fill="#FFF" fillRule="evenodd">
        <path
          d="M15.76 31.394C7.09 31.394.061 24.366.061 15.697.062 7.027 7.09 0 15.759 0c8.67 0 15.697 7.028 15.697 15.697 0 8.67-7.027 15.697-15.697 15.697zm0-1.777c7.687 0 13.92-6.232 13.92-13.92s-6.233-13.92-13.92-13.92c-7.689 0-13.92 6.232-13.92 13.92s6.231 13.92 13.92 13.92z"
          fillRule="nonzero"
        />
        <path d="M16.584 8.95l5.102 5.168c.824.825-.411 2.062-1.235 1.237l-3.438-3.449c-.17-.17-.307-.111-.307.13v9.516a.873.873 0 1 1-1.747 0v-9.515c0-.241-.137-.3-.306-.13l-3.439 3.448c-.823.825-2.058-.412-1.235-1.237l5.069-5.161a1.074 1.074 0 0 1 1.536-.006z" />
      </g>
    </SvgIcon>
  );
};
