import * as React from 'react';
import { translate } from 'react-i18next';

import { XIcon } from 'src/assets/icons';
import { ExclamationIcon } from 'src/assets/icons';
import { ButtonSizes, ButtonStyles } from 'src/components/button';
import { Column } from 'src/components/column';
import { ConcentricCircles } from 'src/components/concentric-circles';
import { colors } from 'src/theme/colors';
import { spacing } from 'src/theme/spacing';
import { combineRems } from 'src/utils/styles/rem-calc.utils';

import {
  GradientTopBarDiv,
  ModalBody,
  ModalButtonDiv,
  ModalCloseButton,
  ModalColumn,
  ModalDividerDiv,
  ModalHeading,
  ModalOptionButton,
} from 'src/ui/modal/components/modal.style';

import { LocalizedText } from 'src/components/localized-text';
import {
  GenericInfoContentDiv,
  GenericInfoHighlight,
  GenericInfoMessage,
} from 'src/ui/modal/components/generic-info/generic-info.styles';
import { GenericInfoModalProps } from 'src/ui/modal/components/generic-info/generic-info.types';

export const GenericInfoModalComponent: React.FunctionComponent<GenericInfoModalProps> = ({
  close,
  t,
  data,
}) => (
  <ModalBody>
    <GradientTopBarDiv />
    <ModalCloseButton onClick={close}>
      <XIcon fillColor={colors.black} height={13} />
    </ModalCloseButton>

    <ModalColumn>
      <ModalHeading>
        <LocalizedText label={t(data.header)} />
      </ModalHeading>
      <ModalDividerDiv />
      <Column alignItems="center" pt={combineRems(spacing.four, spacing.three)} pl={4} pr={4}>
        <ConcentricCircles
          height={70}
          innerCircleColor={colors.trafficRed}
          borderOpacity={0.3}
          borderThickness={6}
        >
          <ExclamationIcon fillColor={colors.white} height={180} width={180} />
        </ConcentricCircles>
        <GenericInfoHighlight>
          <LocalizedText label={t(data.subheader)} />
        </GenericInfoHighlight>
      </Column>
      <GenericInfoContentDiv>
        <GenericInfoMessage>
          <LocalizedText label={t(data.message)} />
        </GenericInfoMessage>
      </GenericInfoContentDiv>
      <ModalDividerDiv />
      <ModalButtonDiv>
        <ModalOptionButton
          onClick={close}
          buttonStyle={ButtonStyles.PRIMARY}
          label={t('profile.edit.errors.closeModal')}
          size={ButtonSizes.SMALL}
        />
      </ModalButtonDiv>
    </ModalColumn>
  </ModalBody>
);

export const GenericInfoModal = translate()(GenericInfoModalComponent);
