import styled, { css } from 'styled-components';

import { Block } from 'src/components/block';
import { Span } from 'src/components/span';

import { borderRadius } from 'src/theme/border-radius';
import { colors } from 'src/theme/colors';
import { spacing } from 'src/theme/spacing';
import { transitions } from 'src/theme/transitions';
import { fontSize } from 'src/theme/typography';
import { fontWeights as weight } from 'src/theme/weight';
import { zIndexes } from 'src/theme/z-index';

// TODO: Refactor styles to use React Dates theme interface

export const DatePickerCustomArrow = styled(Block)`
  color: ${colors.metalBlue};
  font-size: ${fontSize.caption};
  font-weight: ${weight.semiBold};
  margin: 0 ${spacing.three} 0 2.5rem;
  text-transform: uppercase;
`;
DatePickerCustomArrow.displayName = 'DatePickerCustomArrow';

export const DatePickerNavigationArrowContainer = styled(Span)`
  display: block;
  padding: ${spacing.three} 0;

  svg path {
    transition: ${transitions.default};
  }
`;
DatePickerNavigationArrowContainer.displayName = 'DatePickerNavigationArrowContainer';

export const ArrowLeftSpan = styled(Span)`
  display: inline-block;
  transform: rotate(90deg);
`;
ArrowLeftSpan.displayName = 'ArrowLeftSpan';

export const ArrowRightSpan = styled(Span)`
  display: inline-block;
  transform: rotate(-90deg);
`;
ArrowRightSpan.displayName = 'ArrowRightSpan';

export const DoneButtonWrapper = styled.div`
  background: ${colors.white};
  border-top: none;
  text-align: right;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0 ${spacing.three} ${spacing.three};
  width: 100%;

  button {
    margin: 0 ${spacing.two};
  }
`;
DoneButtonWrapper.displayName = 'DoneButtonWrapper';

export const DoneCancelButtonWrapper = styled(DoneButtonWrapper)`
  justify-content: center;
`;
DoneCancelButtonWrapper.displayName = 'DoneCancelButtonWrapper';

// Date Picker style overrides
// tslint:disable
export const DatePickerStyles = css`
  .DateRangePicker_picker {
    z-index: ${zIndexes.popover};
  }
  .DateRangePicker_picker__openDown {
    left: 8rem;
    top: 2.75rem;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight {
    display: none;
  }

  .DayPicker__horizontal {
    border-radius: 0;
  }

  .DateRangePicker_picker {
    border-radius: ${borderRadius[2]};
  }

  .DayPickerNavigation_button__horizontal {
    background-color: transparent;
    position: absolute;
    top: ${spacing.two};

    &:first-child {
      left: ${spacing.two};
    }

    &:last-child {
      right: ${spacing.two};
    }

    &:hover,
    &:focus {
      outline: none;

      svg path {
        fill: ${colors.metalBlue};
      }
    }
  }

  .DayPicker__withBorder {
    border: 1px solid ${colors.skyBlue};
    border-radius: ${borderRadius[2]};
    box-shadow: 0 2px 4px 1px rgba(192, 192, 192, 0.5);
    overflow: hidden;
  }

  .DayPicker_weekHeader {
    border-top: 1px solid ${colors.silverDark};
    margin-top: -${spacing.two};
    padding-top: ${spacing.two};
  }

  .DayPicker_weekHeader_li {
    color: ${colors.grayMedium};
    font-weight: ${weight.bold};
    text-transform: uppercase;
  }

  .CalendarDay_button {
    font-size: ${fontSize.p};
  }

  .CalendarDay {
    transition: ${transitions.default};
    transition-delay: 0.15s;
    &:focus {
      outline: 1px solid ${colors.brandBlue};
    }
  }

  .CalendarDay__default {
    border: none;
    &:hover {
      background: ${colors.skyBlueAlpha13};
      color: ${colors.brandBlue};
    }
  }

  .CalendarDay__selected_span {
    background: ${colors.skyBlueAlpha13};
    color: ${colors.charcoal};
  }

  .CalendarDay__selected_span:hover,
  .CalendarDay__selected_span:active {
    background: ${colors.skyBlue};
    color: ${colors.white};
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span:active {
    background: ${colors.skyBlueAlpha13};
    color: ${colors.brandBlue};
  }

  .CalendarDay__selected_span {
    position: relative;
    z-index: 0;
    &::after {
      content: '';
      display: block;
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      right: -18px;
      background: ${colors.skyBlueAlpha13};
    }
  }

  .CalendarDay__selected_span:last-of-type,
  .CalendarDay__selected_start {
    &::after {
      display: none;
    }
  }

  .CalendarDay__selected_span.CalendarDay__firstDayOfWeek {
    &::after {
      display: block;
    }
  }

  .CalendarDay__selected_start + .CalendarDay__selected_span,
  .CalendarDay__selected_end + .CalendarDay__default {
    position: relative;
    z-index: 2;
    &::before {
      content: '';
      display: block;
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      background: ${colors.skyBlueAlpha13};
    }
    &::after {
      display: none;
    }
  }

  .CalendarDay__selected_start + .CalendarDay__selected_span {
    &::before {
      right: 100%;
    }
  }

  .CalendarDay__selected_end + .CalendarDay__default {
    &::before {
      right: 150%;
    }
  }

  .CalendarDay__selected_start.CalendarDay__selected_end + .CalendarDay__default {
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover,
  .CalendarDay__selected_end,
  .CalendarDay__selected_end:active,
  .CalendarDay__selected_end:hover,
  .CalendarDay__selected_start,
  .CalendarDay__selected_start:active,
  .CalendarDay__selected_start:hover {
    background: ${colors.skyBlue};
    border-radius: 50%;
    color: ${colors.white};
    position: relative;
    z-index: 3;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight {
    right: 9rem;
    bottom: -1.75rem;
    border: 1px solid ${colors.brandBlue};
    width: 1.5rem;
    height: 1.5rem;
    background-color: ${colors.white};
    border-radius: 0.75rem;
  }

  .DayPickerKeyboardShortcuts_showSpan {
    font-size: ${fontSize.p};
    font-weight: 700;
    color: ${colors.brandBlue};
  }

  .DayPickerKeyboardShortcuts_showSpan__bottomRight {
    right: 0.45rem;
    top: 0.125rem;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight:hover {
    border: 1px solid ${colors.brandBlue};
  }

  .DateInput_input__focused {
    color: ${colors.brandBlue};
  }

  .DateInput__withCaret:after,
  .DateInput__withCaret:before {
    content: none;
  }

  .CalendarMonth_caption {
    padding-bottom: 3rem;
    color: ${colors.metalBlue};
    font-size: ${fontSize.p};
  }

  .DayPicker_weekHeader {
    color: ${colors.charcoal};
  }

  .DayPicker_weekHeader_li small {
    font-weight: ${weight.semiBold};
  }

  .DateInput {
    background-color: transparent;
  }

  .DateInput_input {
    line-height: 3px;
    padding: ${spacing.two} ${spacing.three};
    font-size: 0.875rem;
    font-weight: 600;
    border-bottom: none;
  }

  .DateInput_input {
    border-radius: ${borderRadius[3]};
    border: 1px solid ${colors.blueMarineLight};
    color: ${colors.brandBlue};
    cursor: pointer;
    font-size: ${fontSize.caption};
    text-align: center;
    transition: ${transitions.default};
    width: 9.5rem;

    &.DateInput_input__focused,
    &:hover {
      transition: ${transitions.default};
      border-color: ${colors.blueMarine};
    }
  }

  .DateRangePickerInput {
    margin-top: ${spacing.one};
  }

  .DateRangePickerInput,
  .SingleDatePickerInput {
    background: transparent;
  }

  .DateRangePickerInput__withBorder {
    background-color: ${colors.white};
    border: 1px solid ${colors.silverDark};
    border-radius: 5px;
    overflow: auto;
  }

  .DateInput_fang {
    display: none;
  }
`;
