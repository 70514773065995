import {
  defaultAssociatedProfessional,
  defaultPatientInfoDetails,
  defaultPatientUser,
} from 'src/core/patient-info/patient-info.constants';
import { ensureNever } from 'src/utils';

import {
  PatientInfoActionType,
  PatientInfoReducerActions,
  PatientInfoState,
} from './patient-info.types';

export const initialPatientInfoState: PatientInfoState = {
  isLoading: true,
  details: defaultPatientInfoDetails,
  user: defaultPatientUser,
  associatedProfessional: defaultAssociatedProfessional,
  error: '',
};

export const patientInfoReducer = (
  state = initialPatientInfoState,
  action: PatientInfoReducerActions,
): PatientInfoState => {
  switch (action.type) {
    case PatientInfoActionType.FETCH_PATIENT_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: null,
      };
    case PatientInfoActionType.FETCH_ASSOCIATED_PROFESSIONAL_SUCCESS:
      return {
        ...state,
        associatedProfessional: action.payload,
      };
    case PatientInfoActionType.FETCH_PATIENT_INFO_ERROR:
    case PatientInfoActionType.FETCH_ASSOCIATED_PROFESSIONAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        details: defaultPatientInfoDetails,
        user: defaultPatientUser,
      };
    case PatientInfoActionType.RESET_PATIENT_INFO: {
      return initialPatientInfoState;
    }
    default:
      ensureNever(action);
      return state;
  }
};
