import * as React from 'react';

import { convertPxToRem } from 'src/utils/styles';

type IFrameProps = {
  title?: string;
  width?: string;
  height?: string;
  src: string;
  scrolling?: string;
  frameBorder?: number;
  resizeOnLoad?: boolean;
  onLoad?: (a: any) => void;
  styleProps?: any;
};

/* istanbul ignore next */
export class IFrame extends React.Component<IFrameProps, any> {
  public static displayName = 'IFrame';
  public iframe;
  public shouldComponentUpdate() {
    return false;
  }

  public render() {
    const {
      title,
      width = '100%',
      height,
      src,
      frameBorder = 0,
      resizeOnLoad = true,
      onLoad = () => null,
      styleProps = {},
      ...props
    } = this.props;

    return (
      <iframe
        ref={this.setIframeRef}
        style={{ width, height, ...styleProps }}
        onLoad={a => {
          if (resizeOnLoad) {
            this.resizeFrame(this.iframe);
          }
          onLoad(this.iframe);
        }}
        title={title}
        src={src}
        frameBorder={frameBorder}
        {...props}
      />
    );
  }

  private resizeFrame = iframe => {
    iframe.height = convertPxToRem(iframe.contentWindow.document.body.scrollHeight);
  };

  private setIframeRef = element => {
    this.iframe = element;
  };
}
