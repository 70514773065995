import styled, { keyframes } from 'styled-components';

const transformScaleBase = 5;
export const transformScale = {
  one: transformScaleBase * 1, // 5
  two: transformScaleBase * 2, // 10
  three: transformScaleBase * 4, // 20
  four: transformScaleBase * 8, // 40
  five: transformScaleBase * 16, // 80
  quarter: 25,
  half: 50,
  full: 100,
};

export const opacityScale = {
  hidden: 0,
  disabled: 0.25,
  default: 1,
};

// Convert values from the transform scale for use in transformations
export const toPercentage = (value: number): number => value / 100;
export const toPercentageStr = (value: number): string => `${value}%`;

export const AnimationHelper = styled.div`
  will-change: opacity, transform;
`;

export const AnimationHelperInline = styled(AnimationHelper)`
  display: inline-block;
`;

// ---- Generic animations  ----
// Fade in an element on first display - useful for lazy loading content
export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

// Fade out an element without modifiying display or visiblity styles
export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

export const rotate180ThenHold = keyframes`
0% {
  transform: rotate(0deg);
}
50% {
  transform: rotate(180deg);
}
100% {
  transform: rotate(180deg);
}
`;

export const rotate180WithDelay = keyframes`
0% {
  transform: rotate(0deg);
}
50% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(180deg);
}
`;

export const grow = keyframes`
from {
  transform: scale(${transformScale.three / 100});
}
to {
  transform: scale(${transformScale.full / 100});
}
`;

// ---- Animations for elements being introduced or removed  ----
// Fly large elements on the stage and fade in
export const slideOnscreenFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(${toPercentageStr(transformScale.one)});
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const slideDownFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-${toPercentageStr(transformScale.one)});
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const animations = {
  fadeIn,
  fadeOut,
  grow,
  rotate180ThenHold,
  rotate180WithDelay,
  slideDownFadeIn,
  slideOnscreenFadeIn,
};
