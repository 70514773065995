import styled from 'styled-components';

import { Column } from 'src/components/column/index';
import { Span } from 'src/components/span';
import { spacing } from 'src/theme/spacing';

export const GenericInfoContentDiv = styled(Column)`
  margin: ${spacing.three} 0 ${spacing.four} 0;
  flex: 1 0 auto;
  font-size: ${({ theme }) => theme.fontSize.p};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;
GenericInfoContentDiv.displayName = 'GenericInfoContentDiv';

export const GenericInfoHighlight = styled(Span)`
  color: ${({ theme }) => theme.colors.grayDark};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: ${spacing.three} 0;
`;
GenericInfoHighlight.displayName = 'GenericInfoHighlight';

export const GenericInfoMessage = styled(Span)`
  color: ${({ theme }) => theme.colors.grayMedium};
`;
GenericInfoMessage.displayName = 'GenericInfoMessage';
