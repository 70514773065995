import { Action, PayloadAction } from 'src/app/store/app.types';
import { Diff } from 'src/utils';

import { GetStripsDeliveryData } from 'src/services/strips-delivery/get-strips-delivery/get-strips-delivery.types';

export enum StripsDeliveryActionType {
  SET_STRIPS_DELIVERY = 'SET_STRIPS_DELIVERY',
  FETCH_STRIPS_DELIVERY_START = 'FETCH_STRIPS_DELIVERY_START',
  FETCH_STRIPS_DELIVERY_SUCCESS = 'FETCH_STRIPS_DELIVERY_SUCCESS',
  FETCH_STRIPS_DELIVERY_ERROR = 'FETCH_STRIPS_DELIVERY_ERROR',
  UPDATE_STRIPS_DELIVERY_STATUS_START = 'UPDATE_STRIPS_DELIVERY_STATUS_START',
  UPDATE_STRIPS_DELIVERY_STATUS_SUCCESS = 'UPDATE_STRIPS_DELIVERY_STATUS_SUCCESS',
  UPDATE_STRIPS_DELIVERY_STATUS_ERROR = 'UPDATE_STRIPS_DELIVERY_STATUS_START',
  UPDATE_STRIPS_DELIVERY_DATE_START = 'UPDATE_STRIPS_DELIVERY_DATE_START',
  UPDATE_STRIPS_DELIVERY_DATE_SUCCESS = 'UPDATE_STRIPS_DELIVERY_DATE_SUCCESS',
  UPDATE_STRIPS_DELIVERY_DATE_ERROR = 'UPDATE_STRIPS_DELIVERY_DATE_ERROR',
  SET_LOCAL_NEXT_DELIVERY_DATE = 'SET_LOCAL_NEXT_DELIVERY_DATE',
  RESET_LOCAL_NEXT_DELIVERY_DATE = 'RESET_LOCAL_NEXT_DELIVERY_DATE',
  RESET_STRIPS_DELIVERY = 'RESET_STRIPS_DELIVERY',
}

export enum StripsDeliveryStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  BLOCKED = 'BLOCKED',
  UNCONFIGURED = 'UNCONFIGURED',
}

export type StripsDeliveryState = {
  containers?: number;
  nextDeliveryDate?: string;
  status?: StripsDeliveryStatus;
  total?: number;
  localNextDeliveryDate?: string;
};

export type FetchStripsDeliveryStart = Action<StripsDeliveryActionType.FETCH_STRIPS_DELIVERY_START>;
export type FetchStripsDeliverySuccess = PayloadAction<
  StripsDeliveryActionType.FETCH_STRIPS_DELIVERY_SUCCESS,
  GetStripsDeliveryData
>;
export type FetchStripsDeliveryError = PayloadAction<
  StripsDeliveryActionType.FETCH_STRIPS_DELIVERY_ERROR,
  any
>;

export type UpdateStripsDeliveryStatusStartPayload = {
  status?: StripsDeliveryStatus.ACTIVE | StripsDeliveryStatus.PAUSED;
  deliveryDate?: string;
  triggerOnSuccess: () => void;
};

export type UpdateStripsDeliveryStatusStart = PayloadAction<
  StripsDeliveryActionType.UPDATE_STRIPS_DELIVERY_STATUS_START,
  UpdateStripsDeliveryStatusStartPayload
>;

export type UpdateStripsDeliveryStatusSuccessPayload = {
  status?: StripsDeliveryStatus.ACTIVE | StripsDeliveryStatus.PAUSED;
  nextDeliveryDate?: string;
};
export type UpdateStripsDeliveryStatusSuccess = PayloadAction<
  StripsDeliveryActionType.UPDATE_STRIPS_DELIVERY_STATUS_SUCCESS,
  UpdateStripsDeliveryStatusSuccessPayload
>;
export type UpdateStripsDeliveryStatusError = PayloadAction<
  StripsDeliveryActionType.UPDATE_STRIPS_DELIVERY_STATUS_ERROR,
  any
>;

export type UpdateStripsDeliveryDateStartPayload = {
  deliveryDate: string;
  triggerOnSuccess: () => void;
};

export type UpdateStripsDeliveryDateStart = PayloadAction<
  StripsDeliveryActionType.UPDATE_STRIPS_DELIVERY_DATE_START,
  UpdateStripsDeliveryDateStartPayload
>;

export type UpdateStripsDeliveryDateSuccessPayload = {
  nextDeliveryDate: string;
};

export type UpdateStripsDeliveryDateSuccess = PayloadAction<
  StripsDeliveryActionType.UPDATE_STRIPS_DELIVERY_DATE_SUCCESS,
  UpdateStripsDeliveryDateSuccessPayload
>;

export type UpdateStripsDeliveryDateError = PayloadAction<
  StripsDeliveryActionType.UPDATE_STRIPS_DELIVERY_DATE_ERROR,
  any
>;

export type SetLocalNextDeliveryDatePayload = { nextDeliveryDate: string };
export type SetLocalNextDeliveryDate = PayloadAction<
  StripsDeliveryActionType.SET_LOCAL_NEXT_DELIVERY_DATE,
  SetLocalNextDeliveryDatePayload
>;

export type ResetLocalNextDeliveryDate = Action<
  StripsDeliveryActionType.RESET_LOCAL_NEXT_DELIVERY_DATE
>;

export type ResetStripsDelivery = Action<StripsDeliveryActionType.RESET_STRIPS_DELIVERY>;

// epic only actions
export type StripsDeliveryEpicActions = FetchStripsDeliveryStart | UpdateStripsDeliveryStatusStart;

export type StripsDeliveryActions =
  | FetchStripsDeliveryStart
  | FetchStripsDeliverySuccess
  | FetchStripsDeliveryError
  | UpdateStripsDeliveryStatusStart
  | UpdateStripsDeliveryStatusSuccess
  | UpdateStripsDeliveryStatusError
  | UpdateStripsDeliveryDateStart
  | UpdateStripsDeliveryDateSuccess
  | UpdateStripsDeliveryDateError
  | SetLocalNextDeliveryDate
  | ResetLocalNextDeliveryDate
  | ResetStripsDelivery;

export type StripsDeliveryReducerActions = Diff<StripsDeliveryActions, StripsDeliveryEpicActions>;
