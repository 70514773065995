import { GRID_DEFAULT_SPACING } from './grid.constants';

// Convert grid item width to a percentage for use in Flexbox fallback
// Ex. span=3 will cover 3 / 12 columns
export const calculateWidthFromGridSpan = (span, columns) => {
  const percentageWidth = span && columns ? (span / columns) * 100 : 100;
  return `${percentageWidth}%`;
};

export const createGridTracks = (tracks?: number): string =>
  tracks ? `repeat(${tracks}, 1fr)` : 'initial';

export const createGridSpacing = (spacing?: string): string => spacing || GRID_DEFAULT_SPACING;

export const createGridSpan = (span: number): string => `span ${span}`;
