import { Observable } from 'rxjs/Rx';

import { checkInFromOpenBrowser } from './chrome-workaround.actions';
import { MAX_PERIOD_WITHOUT_CHECK_INS } from './chrome-workaround.constants';
import { getLastTimeBrowserWasOpen } from './chrome-workaround.selectors';
export const continueWhereYouLeftOffWorkaroundEpic = ({ START_EVENT, terminationEventCreator }) => (
  action$,
  store,
) =>
  action$.ofType(START_EVENT).switchMap(() => {
    if (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) {
      return startInterval(store, terminationEventCreator);
    } else {
      return Observable.empty();
    }
  });

function startInterval(store, terminationEventCreator) {
  return Observable.create(subscriber => {
    const interval = window.setInterval(() => {
      check({ subscriber, store, interval, terminationEventCreator });
    }, 5000);
    check({ subscriber, store, interval, terminationEventCreator });
  });
}

function check({ subscriber, store, interval, terminationEventCreator }) {
  const lastTimestamp = getLastTimeBrowserWasOpen(store.getState());
  const currentTimestamp = Date.now();
  const periodWithoutCheckIns = currentTimestamp - lastTimestamp;
  if (lastTimestamp === 0) {
    subscriber.next(checkInFromOpenBrowser(currentTimestamp));
  } else if (periodWithoutCheckIns >= MAX_PERIOD_WITHOUT_CHECK_INS) {
    window.clearInterval(interval);
    subscriber.next(terminationEventCreator());
    subscriber.complete();
  } else {
    subscriber.next(checkInFromOpenBrowser(currentTimestamp));
  }
}
