import { Action, PayloadAction } from 'src/app/store/app.types';
import { AccountInfo } from 'src/core/gigya/gigya.types';
import { Diff } from 'src/utils';

export enum SessionActionType {
  VALIDATE_SESSION_START = 'VALIDATE_SESSION_START',
  VALIDATE_SESSION_ERROR = 'VALIDATE_SESSION_ERROR',
  VALIDATE_SESSION_SUCCESS = 'VALIDATE_SESSION_SUCCESS',
  SIGN_OUT_START = 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS',
  SIGN_OUT_ERROR = 'SIGN_OUT_ERROR',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_START = 'LOGIN_START',
}

export type SessionState = {
  readonly legacyToken: string;
  readonly error: any;
  readonly ttl: number;
  readonly ttlLength: number;
  readonly accessToken: string;
  readonly refreshToken: string;
  readonly isSignedOut: boolean;
};

export type SessionValidationErrorPayload = {
  account?: AccountInfo;
  error: string;
};

export type ValidateSessionStartAction = Action<SessionActionType.VALIDATE_SESSION_START>;
export type ValidateSessionSuccessAction = Action<SessionActionType.VALIDATE_SESSION_SUCCESS>;
export type ValidateSessionErrorAction = PayloadAction<
  SessionActionType.VALIDATE_SESSION_ERROR,
  any
>;
export type SignoutStartAction = Action<SessionActionType.SIGN_OUT_START>;
export type SignoutSuccessAction = Action<SessionActionType.SIGN_OUT_SUCCESS>;
export type SignoutErrorAction = Action<SessionActionType.SIGN_OUT_ERROR>;
export type LoginSuccessAction = Action<SessionActionType.LOGIN_SUCCESS>;
export type LoginStartAction = Action<SessionActionType.LOGIN_START>;

export type SessionEpicOnlyActions =
  | ValidateSessionStartAction
  | LoginStartAction
  | SignoutStartAction;

export type SessionActions =
  | SessionEpicOnlyActions
  | ValidateSessionSuccessAction
  | ValidateSessionErrorAction
  | SignoutStartAction
  | SignoutSuccessAction
  | LoginSuccessAction
  | SignoutErrorAction;

export type SessionReducerActions = Diff<SessionActions, SessionEpicOnlyActions>;
