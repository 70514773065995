import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, lifecycle } from 'recompose';
import { hideAppNavigation } from 'src/ui/store';
import { mapDispatchers } from 'src/utils';

import { SmartRouteComponent } from './smart-route.component';
import { RouteContainerProps, RouteProps } from './smart-route.types';

const dispatchers = mapDispatchers({
  onHideAppNavigation: hideAppNavigation,
});

export const SmartRoute = compose<RouteContainerProps, RouteProps>(
  withRouter,
  connect(() => ({}), dispatchers),
  lifecycle<RouteContainerProps, any>({
    componentWillMount() {
      const { hideNavigation = false, onHideAppNavigation } = this.props;
      if (hideNavigation) {
        onHideAppNavigation();
      }
    },
  }),
)(SmartRouteComponent);
