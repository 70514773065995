import { Action, PayloadAction } from 'src/app/store/app.types';
import { Diff } from 'src/utils';

export enum OidcActionTypes {
  OIDC_FETCH_TOKENS_START = 'OIDC_FETCH_TOKENS_START',
  OIDC_FETCH_TOKENS_SUCCESS = 'OIDC_FETCH_TOKENS_SUCCESS',
  OIDC_FETCH_TOKENS_ERROR = 'OIDC_FETCH_TOKENS_ERROR',
  REFRESH_OIDC_TOKENS_START = 'REFRESH_OIDC_TOKENS_START',
  REFRESH_OIDC_TOKENS_SUCCESS = 'REFRESH_OIDC_TOKENS_SUCCESS',
  REFRESH_OIDC_TOKENS_ERROR = 'REFRESH_OIDC_TOKENS_ERROR',
}

export type OidcTokensPayload = {
  accessToken: string;
  refreshToken: string;
  ttl: number;
  ttlLength: number;
};

export type FetchOidcTokensStart = PayloadAction<OidcActionTypes.OIDC_FETCH_TOKENS_START, string>;

export type FetchOidcTokensSuccess = PayloadAction<
  OidcActionTypes.OIDC_FETCH_TOKENS_SUCCESS,
  OidcTokensPayload
>;

export type RefreshOidcTokensStart = Action<OidcActionTypes.REFRESH_OIDC_TOKENS_START>;

export type RefreshOidcTokensError = PayloadAction<OidcActionTypes.REFRESH_OIDC_TOKENS_ERROR, any>;

export type RefreshOidcTokensSuccess = PayloadAction<
  OidcActionTypes.REFRESH_OIDC_TOKENS_SUCCESS,
  OidcTokensPayload
>;

export type FetchOidcTokensError = PayloadAction<OidcActionTypes.OIDC_FETCH_TOKENS_ERROR, any>;

export type OidcSuccessEpicOnlyActions = FetchOidcTokensStart;

export type OidcSuccessActions =
  | OidcSuccessEpicOnlyActions
  | FetchOidcTokensSuccess
  | FetchOidcTokensError
  | RefreshOidcTokensError
  | RefreshOidcTokensSuccess;

export type OidcTokensServiceImplType = (token: string) => Promise<any>;

export type OidcReducerActions = Diff<OidcSuccessActions, OidcSuccessEpicOnlyActions>;
