import { createStructuredSelector } from 'reselect';

import {
  selectPatientId,
  selectPatientInfoError,
} from 'src/core/patient-info/patient-info.selectors';
import {
  selectIsSignedOut,
  selectLegacyToken,
  selectSessionError,
} from 'src/core/session/session.selectors';

import {
  selectAccessToken,
  selectIsSessionValid,
  selectTtl,
} from 'src/core/session/oidc/oidc.selector';
import { selectCombinedRoutes } from 'src/navigation/store/navigation.selectors';

export const sessionGuardSelector = createStructuredSelector({
  isSessionValid: selectIsSessionValid,
  tokenTimeout: selectTtl,
  token: selectAccessToken,
  error: selectSessionError,
  routes: selectCombinedRoutes,
});

export const patientGuardSelector = createStructuredSelector({
  isSessionValid: selectIsSessionValid,
  isSignedOut: selectIsSignedOut,
  token: selectLegacyToken,
  patientId: selectPatientId,
  error: selectPatientInfoError,
});
