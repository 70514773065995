import { toUpper } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';
import { State } from 'src/app/store/app.types';
import { selectCountries } from 'src/core/countries/countries.selectors';
import { selectLanguages } from 'src/core/languages/languages.selectors';
import {
  selectAssociatedProfessional,
  selectDiabetesType,
  selectDiagnosisDate,
  selectPatientInfo,
  selectPatientUserProfileType,
} from 'src/core/patient-info/patient-info.selectors';
import { selectIsEMR } from 'src/core/permissions/permissions.selectors';
import { selectAccessToken } from 'src/core/session/oidc/oidc.selector';
import {
  selectAddress,
  selectBirthDay,
  selectBirthMonth,
  selectBirthYear,
  selectCity,
  selectCountryCode,
  selectEmail,
  selectFirstName,
  selectGender,
  selectHealthCareId,
  selectLastName,
  selectLocale,
  selectPhoneNumber,
  selectPhotoUrl,
  selectPostalCode,
  selectProvince,
  selectUser,
} from 'src/core/user/user.selectors';
import { selectProfileRoutes } from 'src/navigation/store/navigation.selectors';
import { getIn } from 'src/utils/ramda';

import { PROFILE_GENDERS } from '../edit/profile-edit.constants';

import { formatProfileDateString } from './profile.utils';

export const transformCountryCode = (countryCode: string): string =>
  countryCode ? toUpper(countryCode) : '';

export const selectIsSubmitting = (state: State): boolean => getIn(['profile', 'isLoading'], state);

export const selectAddressInfo = createSelector(
  [selectAddress, selectPostalCode, selectCity, selectProvince, selectCountryCode],
  (address, postalCode, city, province, countryCode) => ({
    address,
    postalCode,
    city,
    country: transformCountryCode(countryCode),
    region: province,
  }),
);

export const selectUserInfo = createSelector(
  [
    selectBirthDay,
    selectBirthMonth,
    selectBirthYear,
    selectEmail,
    selectFirstName,
    selectLastName,
    selectGender,
    selectPhoneNumber,
    selectPhotoUrl,
    selectLocale,
  ],
  (
    birthDay,
    birthMonth,
    birthYear,
    email,
    firstName,
    lastName,
    gender,
    phone,
    photoUrl,
    locale,
  ) => ({
    birthDay,
    birthMonth,
    birthYear,
    email,
    firstName,
    lastName,
    gender: gender || PROFILE_GENDERS.DEFAULT,
    language: locale,
    mobile: phone,
    photo: photoUrl,
  }),
);

export const selectMedicalInformation = createSelector(
  [selectDiabetesType, selectDiagnosisDate, selectHealthCareId, selectAssociatedProfessional],
  (diabetesType, diagnosisDate, healthCareId, associatedProfessional) => ({
    diabetesType: diabetesType ? `profile.fields.diabetesType.${diabetesType}` : '',
    diagnosisDate: formatProfileDateString(diagnosisDate),
    healthCareId,
    associatedProfessional,
  }),
);

export const selectLoadingState = createSelector(
  [selectUser, selectPatientInfo],
  (user, patient) => user.isLoading || patient.isLoading,
);
export const selectUserCombined = createSelector(
  [selectUserInfo, selectAddressInfo, selectMedicalInformation, selectPatientUserProfileType],
  (user, address, medical, profileType) => ({
    ...user,
    ...address,
    ...medical,
    profileType,
  }),
);

export const profileConnector = createStructuredSelector({
  routes: selectProfileRoutes,
  isLoading: selectLoadingState,
  user: selectUserCombined,
});

export const profileEditFormConnector = createStructuredSelector({
  user: selectUserCombined,
  routes: selectProfileRoutes,
  isSubmitting: selectIsSubmitting,
  languageOptions: selectLanguages,
  countriesOptions: selectCountries,
  accessToken: selectAccessToken,
  isEMR: selectIsEMR,
});
