import { ensureNever, setIn } from 'src/utils';

import { LanguagesActionType, LanguagesReducerActions, LanguagesState } from './languages.types';

export const initialLanguagesState: LanguagesState = {
  isLoading: true,
  languages: [],
  error: '',
};

export const languagesReducer = (
  state = initialLanguagesState,
  action: LanguagesReducerActions,
): LanguagesState => {
  switch (action.type) {
    case LanguagesActionType.FETCH_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: action.payload,
        isLoading: false,
      };
    case LanguagesActionType.FETCH_LANGUAGES_ERROR:
      return setIn(['error'], action.payload, state);
    default:
      ensureNever(action);
      return state;
  }
};
