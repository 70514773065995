import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { selectIsSessionValid } from 'src/core/session/oidc/oidc.selector';
import { selectIsSignedOut } from 'src/core/session/session.selectors';
import { OIDCRoot } from 'src/domains/authentication/scenes/root/root.component';
import { selectCombinedRoutes } from 'src/navigation/store/navigation.selectors';
import { mapDispatchers } from 'src/utils';

const connector = createStructuredSelector({
  isSessionValid: selectIsSessionValid,
  isSignedOut: selectIsSignedOut,
  routes: selectCombinedRoutes,
});

const dispatchers = mapDispatchers({
  goTo: path => push(path),
});

export const OIDCRootContainer = compose(
  connect(
    connector,
    dispatchers,
  ),
  withRouter,
)(OIDCRoot);
