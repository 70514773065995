import { ensureNever, pathOr } from 'src/utils';
import { getIn, setIn } from 'src/utils/ramda';

import {
  StripsDeliveryActionType,
  StripsDeliveryReducerActions,
  StripsDeliveryState,
  StripsDeliveryStatus,
} from './strips-delivery.types';

export const initialStripsDeliveryState: StripsDeliveryState = {
  status: StripsDeliveryStatus.UNCONFIGURED,
  containers: 0,
  nextDeliveryDate: '',
  total: 0,
  localNextDeliveryDate: '',
};

export const stripsDeliveryReducer = (
  state = initialStripsDeliveryState,
  action: StripsDeliveryReducerActions,
): StripsDeliveryState => {
  switch (action.type) {
    case StripsDeliveryActionType.FETCH_STRIPS_DELIVERY_SUCCESS:
      const { containers, nextDeliveryDate, status, total } = action.payload;
      return {
        ...state,
        containers,
        nextDeliveryDate,
        status,
        total,
      };
    case StripsDeliveryActionType.FETCH_STRIPS_DELIVERY_ERROR:
      return {
        ...state,
      };
    case StripsDeliveryActionType.UPDATE_STRIPS_DELIVERY_STATUS_SUCCESS:
      return {
        ...state,
        status: getIn(['payload', 'status'], action),
        nextDeliveryDate: pathOr(state.nextDeliveryDate, ['payload', 'nextDeliveryDate'], action),
      };
    case StripsDeliveryActionType.UPDATE_STRIPS_DELIVERY_DATE_SUCCESS:
      return setIn(['nextDeliveryDate'], action.payload.nextDeliveryDate, state);
    case StripsDeliveryActionType.UPDATE_STRIPS_DELIVERY_DATE_ERROR:
      return state;
    case StripsDeliveryActionType.SET_LOCAL_NEXT_DELIVERY_DATE:
      return setIn(['localNextDeliveryDate'], action.payload.nextDeliveryDate, state);
    case StripsDeliveryActionType.RESET_LOCAL_NEXT_DELIVERY_DATE:
      return setIn(['localNextDeliveryDate'], '', state);
    case StripsDeliveryActionType.UPDATE_STRIPS_DELIVERY_DATE_START:
      return state;
    case StripsDeliveryActionType.RESET_STRIPS_DELIVERY:
      return initialStripsDeliveryState;
    default:
      ensureNever(action);
      return state;
  }
};
