import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { addModal } from 'src/core/modal/modal.actions';
import { ModalTypes } from 'src/core/modal/modal.types';
import {
  fetchStripsDeliveryStart,
  stripsDeliveryConnector,
} from 'src/domains/strips-delivery/store';
import { mapDispatchers } from 'src/utils';

import { updateStripsDeliveryStatusStart as updateStatus } from '../store';

import { StripsDelivery } from './strips-delivery.component';
import {
  StripsDeliveryConnectProps,
  StripsDeliveryExternalProps,
  StripsDeliveryProps,
} from './strips-delivery.types';

const dispatchers = mapDispatchers({
  fetchStripsDelivery: () => fetchStripsDeliveryStart(),
  openStripDeliveryModal: ({ nextDeliveryDate, updateStripsDeliveryStatusStart, status, routes }) =>
    addModal({
      key: ModalTypes.STRIP_DELIVERY,
      data: {
        nextDeliveryDate,
        updateStripsDeliveryStatusStart,
        status,
        routes,
      },
    }),
  updateStripsDeliveryStatusStart: updateStatus,
});

export const StripsDeliveryContainer = compose<
  StripsDeliveryConnectProps,
  StripsDeliveryExternalProps
>(
  connect(
    stripsDeliveryConnector,
    dispatchers,
  ),
  lifecycle<StripsDeliveryProps, void>({
    componentDidMount() {
      this.props.fetchStripsDelivery();
    },
  }),
)(StripsDelivery);
