import * as React from 'react';
import { translate as t } from 'src/i18n/translate';
import { colors } from 'src/theme/colors';
import {
  GeneralTextContainer,
  MainDialogContainer,
  TextWithIconContainer,
  DoubleLogoTextContainer,
  IconContainer,
} from './productInfo.style';
import { CECodeIcon, MedicalDeviceIcon, MedicalDeviceIdIcon } from '../../../../assets/icons/index';

export type PIModalProps = {
  isOpen: boolean;
  appVersion: string;
  rdcpPPManufactureDate: string;
  rdcpPPManufactureDateVC: string;
  rdcpPPModuleVersion: string;
};

export const PIModalComponent: React.FunctionComponent<PIModalProps> = ({
  isOpen,
  appVersion,
  rdcpPPManufactureDate,
  rdcpPPManufactureDateVC,
  rdcpPPModuleVersion,
}) => (
  <jello-dialog
    id="jello-dialog-productInfo"
    header-icon-name=""
    header-title={`${t('productInfo.header')} ${appVersion ? `v${appVersion}` : ''}`}
    show-close-button=""
    visible={isOpen}
    close-on-action={true}
  >
    <MainDialogContainer>
      <GeneralTextContainer className="header-product-text">
        <jello-text size="XL" weight="semibold">
          {t('productInfo.title')}
        </jello-text>
      </GeneralTextContainer>
      <GeneralTextContainer>
        <jello-text size="S">
          <p className="no-margin-product-text">{t('productInfo.text.1.1')}</p>
          <p className="no-margin-product-text">{`${t('productInfo.text.1.2')} ${appVersion}`}</p>
          <p className="no-margin-product-text">{t('productInfo.text.1.3')}</p>
        </jello-text>
      </GeneralTextContainer>
      <GeneralTextContainer>
        <jello-text size="L" weight="semibold">
          {t('productInfo.subtitle.1')}
        </jello-text>
      </GeneralTextContainer>
      <GeneralTextContainer>
        <jello-text size="S">{t('productInfo.text.2')}</jello-text>
      </GeneralTextContainer>
      <TextWithIconContainer>
        <jello-icon
          size="M"
          icon-name="warning--alt"
          class="warning-alt-icon"
          style={{ color: colors.brandBlue }}
        />
        <jello-text size="S">{t('productInfo.text.3')}</jello-text>
      </TextWithIconContainer>
      <DoubleLogoTextContainer>
        <TextWithIconContainer>
          <IconContainer>
            <MedicalDeviceIcon />
          </IconContainer>
          <jello-text size="S">{t('productInfo.text.4')}</jello-text>
        </TextWithIconContainer>
        <TextWithIconContainer>
          <IconContainer>
            <MedicalDeviceIdIcon />
          </IconContainer>
          <jello-text size="S">
            {t('productInfo.text.5')}
            {appVersion ? appVersion : ''}
          </jello-text>
        </TextWithIconContainer>
      </DoubleLogoTextContainer>
      <DoubleLogoTextContainer>
        <TextWithIconContainer>
          <IconContainer>
            <jello-icon
              size="M"
              icon-name="manufacture"
              class="manufacture-icon"
              style={{ color: colors.black }}
            />
          </IconContainer>
          <jello-text
            size="S"
            dangerouslySetInnerHTML={{
              __html: t('productInfo.text.6'),
            }}
          ></jello-text>
        </TextWithIconContainer>
        <TextWithIconContainer>
          <IconContainer>
            <jello-icon size="M" icon-name="manufacturer--date" class="manufacturer-date-icon" />
          </IconContainer>
          <jello-text size="S">
            <jello-text
              size="S"
              dangerouslySetInnerHTML={{
                __html: t('productInfo.text.7'),
              }}
            ></jello-text>
            <p className="no-margin-product-text">{rdcpPPManufactureDate}</p>
          </jello-text>
        </TextWithIconContainer>
      </DoubleLogoTextContainer>
      <DoubleLogoTextContainer>
        <TextWithIconContainer>
          <IconContainer>
            <CECodeIcon />
          </IconContainer>
          <jello-text size="S">{t('productInfo.text.8')}</jello-text>
        </TextWithIconContainer>
        <TextWithIconContainer>
          <IconContainer>
            <jello-icon
              size="M"
              icon-name="additional-information"
              class="additional-information-icon"
            />
          </IconContainer>
          <jello-text
            size="S"
            dangerouslySetInnerHTML={{
              __html: t('productInfo.text.9'),
            }}
          ></jello-text>
        </TextWithIconContainer>
      </DoubleLogoTextContainer>
      <GeneralTextContainer className="subheader-product-text">
        <jello-text size="M" weight="semibold">
          {`${t('productInfo.subtitle.2')} ${rdcpPPModuleVersion ? `v${rdcpPPModuleVersion}` : ''}`}
        </jello-text>
      </GeneralTextContainer>
      <DoubleLogoTextContainer>
        <TextWithIconContainer>
          <IconContainer>
            <jello-icon
              size="M"
              icon-name="manufacture"
              class="manufacture-icon"
              style={{ color: colors.black }}
            />
          </IconContainer>
          <jello-text
            size="S"
            dangerouslySetInnerHTML={{
              __html: t('productInfo.text.10'),
            }}
          ></jello-text>
        </TextWithIconContainer>
        <TextWithIconContainer>
          <IconContainer>
            <jello-icon size="M" icon-name="manufacturer--date" class="manufacturer-date-icon" />
          </IconContainer>
          <jello-text size="S">
            <jello-text
              size="S"
              dangerouslySetInnerHTML={{
                __html: t('productInfo.text.11'),
              }}
            ></jello-text>
            <p className="no-margin-product-text">{rdcpPPManufactureDateVC}</p>
          </jello-text>
        </TextWithIconContainer>
      </DoubleLogoTextContainer>
    </MainDialogContainer>
  </jello-dialog>
);
