import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getDynamicConfig } from '../../../../utils';
import { PIModalComponent, PIModalProps } from './productInfo.modal';

const {
  REACT_APP_RDCP_PP_MANUFACTURE_DATE,
  REACT_APP_PP_VC_MANUFACTURE_DATE,
  REACT_APP_PP_MODULE_VERSION,
} = getDynamicConfig();

const appVersion = process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : '';

const rdcpPPManufactureDate = REACT_APP_RDCP_PP_MANUFACTURE_DATE
  ? REACT_APP_RDCP_PP_MANUFACTURE_DATE
  : '';

const rdcpPPManufactureDateVC = REACT_APP_PP_VC_MANUFACTURE_DATE
  ? REACT_APP_PP_VC_MANUFACTURE_DATE
  : '';

const rdcpPPModuleVersion = REACT_APP_PP_MODULE_VERSION ? REACT_APP_PP_MODULE_VERSION : '';

export const PIModalComponentContainer = compose<PIModalProps, Partial<PIModalProps>>(
  connect(() => ({
    appVersion: appVersion,
    rdcpPPManufactureDate,
    rdcpPPManufactureDateVC,
    rdcpPPModuleVersion,
  })),
)(PIModalComponent);
