import * as platform from 'platform';
import { equals, pipe, replace } from 'ramda';
import { getDynamicConfig } from 'src/utils';

import { DTC_MODAL_QUERY } from './dtc.constants';

export const shouldShowDTCModal = (search: string): boolean =>
  pipe(replace('?', ''), equals(DTC_MODAL_QUERY))(search);

type OSMap = {
  name: 'mac' | 'win';
  extension: 'dmg' | 'exe';
};

const MAC_OSX_REGEX = /(mac)|(OS\sX)/gi;

export const transformPlatformToOSMap = ({ os }: any): OSMap => {
  const isMacOS = os && os.toString().match(MAC_OSX_REGEX);
  return {
    name: isMacOS ? 'mac' : 'win',
    extension: isMacOS ? 'dmg' : 'exe',
  };
};

const { REACT_APP_DOWNLOAD_BUCKET } = getDynamicConfig();

export const generateDTCDownloadURL = () => {
  const os = transformPlatformToOSMap(platform);
  return `${REACT_APP_DOWNLOAD_BUCKET}/${os.name}/Setup.${os.extension}`;
};
