import { findIndex, propEq, reject, update } from 'ramda';

import { ModalActionTypes, ModalsState } from './modal.types';

export const INITIAL_MODAL_STATE: ModalsState = [];

export const modalsReducer = (state = INITIAL_MODAL_STATE, action) => {
  switch (action.type) {
    case ModalActionTypes.ADD_MODAL:
      return [
        ...state,
        {
          key: action.payload.key,
          data: action.payload.data,
        },
      ];
    case ModalActionTypes.SHOW_EXPIRING_MODAL:
      return [
        ...state,
        {
          key: action.payload.key,
          data: action.payload.data,
        },
      ];
    case ModalActionTypes.REMOVE_ALL_MODALS:
      return [];
    case ModalActionTypes.REMOVE_MODAL_BY_KEY:
      return reject(propEq('key', action.payload.key), state);
    case ModalActionTypes.UPDATE_MODAL:
      const { key, data } = action.payload;
      const indexToReplace = findIndex(propEq('key', key), state);
      return indexToReplace >= 0 ? update(indexToReplace, { key, data }, state) : state;
    default:
      return state;
  }
};
