import * as React from 'react';

import { Bundle } from 'src/components/bundle';

export const PatientDashboardBundle = () => (
  <Bundle
    bundleWillLoad={async () => {
      const {
        PatientDashboardContainer,
      } = await import(/* webpackChunkName: "dashboard" */ 'src/dashboards/patient-dashboard');
      return {
        component: PatientDashboardContainer,
      };
    }}
    bundleDidLoad={Component => <Component />}
  />
);
