import { combineEpics, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';

import { State } from 'src/app/store/app.types';
import { selectAccessToken } from 'src/core/session/oidc/oidc.selector';
import { languageService } from 'src/services/language/language.service';
import { LanguageService } from 'src/services/language/language.types';

import { fetchLanguagesError, fetchLanguagesSuccess } from './languages.actions';
import { LanguagesActions, LanguagesActionType } from './languages.types';

export const fetchLanguagesEpic = (service: LanguageService): Epic<LanguagesActions, State> => (
  action$,
  store$,
) =>
  action$
    .ofType(LanguagesActionType.FETCH_LANGUAGES_START)
    .debounceTime(1000)
    .switchMap(() => {
      const accessToken = selectAccessToken(store$.getState());
      return Observable.fromPromise(service({ accessToken }))
        .map(data => fetchLanguagesSuccess(data))
        .pipe(catchError(err => Observable.of(fetchLanguagesError(err))));
    });

export const languageEpics = combineEpics(fetchLanguagesEpic(languageService));
