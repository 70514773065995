import { equals, isNil } from 'ramda';
import * as React from 'react';
import { CalendarIcon } from 'src/assets/icons';
import { Column } from 'src/components/column';
import { Div } from 'src/components/div';
import { LocalizedText } from 'src/components/localized-text';
import { Span } from 'src/components/span';
import { colors } from 'src/theme/colors';
import { fontWeights } from 'src/theme/weight';
import { RenderIf } from 'src/utils/markup/render-if.utils';

import { NextDeliveryDate } from 'src/domains/strips-delivery/scenes/strips-delivery.types';
import { StripsDeliveryStatus } from 'src/domains/strips-delivery/store/strips-delivery.types';

import {
  dateDisplayColorMap,
  DeliveryDateDayDisplay,
  DeliveryDateHeaderDiv,
  DeliveryDateMonthDisplay,
  DeliveryDateTitle,
  DeliveryDateTooltipDiv,
  TooltipContentWrapper,
  TooltipInfoWrapperDiv,
} from 'src/domains/strips-delivery/components/delivery-date-tooltip/delivery-date-tooltip.styles';
import { getMonthTranslationKey } from 'src/utils/i18n/i18n.utils';

type DeliveryDateTooltipProps = {
  readonly nextDeliveryDate?: NextDeliveryDate | null;
  readonly status: StripsDeliveryStatus;
};

export const TooltipDeliveryNotConfigured: React.FunctionComponent<{}> = () => (
  <TooltipInfoWrapperDiv pl={4} pr={4} pt={3} pb={3} maxWidth={250}>
    <LocalizedText label="stripsDelivery.labels.deliveryNotConfiguredMessage" />
  </TooltipInfoWrapperDiv>
);

export const TooltipBlocked: React.FunctionComponent<{}> = () => (
  <TooltipInfoWrapperDiv pl={4} pr={4} pt={3} pb={3} maxWidth={250}>
    <LocalizedText label="stripsDelivery.unblockInstructionText.textOne" />
    &nbsp;
    <Span fontWeight={fontWeights.bold}>
      <LocalizedText label="stripsDelivery.unblockInstructionText.textTwo" />
    </Span>
    &nbsp;
    <LocalizedText label="stripsDelivery.unblockInstructionText.textThree" />
  </TooltipInfoWrapperDiv>
);

export const TooltipLackOfData: React.FunctionComponent<{}> = () => (
  <TooltipInfoWrapperDiv pl={4} pr={4} pt={3} pb={3} maxWidth={250}>
    <LocalizedText label="stripsDelivery.labels.lackOfDataMessage" />
  </TooltipInfoWrapperDiv>
);

export const TooltipDateDisplay: React.FunctionComponent<{
  nextDeliveryDate: NextDeliveryDate;
  status: StripsDeliveryStatus;
}> = ({ nextDeliveryDate, status }) => (
  <Div>
    <DeliveryDateMonthDisplay color={dateDisplayColorMap[status]}>
      <LocalizedText
        label={`dates.months.full.${getMonthTranslationKey(nextDeliveryDate.month)}`}
      />
    </DeliveryDateMonthDisplay>
    <DeliveryDateDayDisplay color={dateDisplayColorMap[status]}>
      {nextDeliveryDate.day}
    </DeliveryDateDayDisplay>
  </Div>
);

const isBlocked = equals(StripsDeliveryStatus.BLOCKED);
const isUnconfigured = equals(StripsDeliveryStatus.UNCONFIGURED);

export const DeliveryDateTooltip: React.FunctionComponent<DeliveryDateTooltipProps> = ({
  nextDeliveryDate,
  status,
}) => {
  const deliveryNotConfigured = isUnconfigured(status);
  const deliveryBlocked = isBlocked(status);
  const shouldDisplayDate = !deliveryNotConfigured && !deliveryBlocked && !isNil(nextDeliveryDate);
  const shouldShowLackOfDataMessage =
    !deliveryNotConfigured && !deliveryBlocked && isNil(nextDeliveryDate);

  return (
    <Column>
      <DeliveryDateTooltipDiv borderRadius={2}>
        <DeliveryDateHeaderDiv>
          <CalendarIcon fillColor={colors.red} />
          <DeliveryDateTitle>
            <RenderIf validate={shouldDisplayDate}>
              <LocalizedText label="stripsDelivery.titles.nextDelivery" />
            </RenderIf>
            <RenderIf validate={!shouldDisplayDate}>
              <LocalizedText label="stripsDelivery.titles.lackOfData" />
            </RenderIf>
          </DeliveryDateTitle>
        </DeliveryDateHeaderDiv>
        <TooltipContentWrapper>
          <Div>
            <RenderIf validate={shouldDisplayDate}>
              <TooltipDateDisplay
                nextDeliveryDate={nextDeliveryDate as NextDeliveryDate}
                status={status}
              />
            </RenderIf>
            <RenderIf validate={deliveryNotConfigured}>
              <TooltipDeliveryNotConfigured />
            </RenderIf>
            <RenderIf validate={deliveryBlocked}>
              <TooltipBlocked />
            </RenderIf>
            <RenderIf validate={shouldShowLackOfDataMessage}>
              <TooltipLackOfData />
            </RenderIf>
          </Div>
        </TooltipContentWrapper>
      </DeliveryDateTooltipDiv>
    </Column>
  );
};
