import * as React from 'react';
import { translate } from 'react-i18next';

import { I18nTranslate } from 'src/i18n/types';

import logo from '../../images/logo.png';
import { SvgIcon } from '../icon.component';

import { AppLogo } from './app-logo-wordmark.component.style';

type AppLogoWordmarkComponentProps = {
  height?: number;
  t: I18nTranslate;
};

export const AppLogoWordmarkComponent: React.SFC<AppLogoWordmarkComponentProps> = ({
  height = 40,
  t,
}) => {
  const originalWidth = 241;
  const originalHeight = 59;

  const aspectRatio = originalWidth / originalHeight;

  const fill = { blue: '#005eaa', gray: '#878787' };

  return (
    <React.Fragment>
      <AppLogo src={logo} />
      <SvgIcon
        title={t('general.appName')}
        width={height * aspectRatio}
        height={height}
        originalWidth={originalWidth}
        originalHeight={originalHeight}
      >
        <path
          fill={fill.blue}
          d="M33.1,24.11c3.27,0,5.07,1.4,5.07,4.55,0,3.7-2.67,4.22-3.65,4.4a3.47,3.47,0,0,1,2.2,1.12A7.38,7.38,0,0,1,38,37.28l.25,1.22c.37,1.85.57,2.5,1.2,3.25H34.72c-.6-.42-.82-1.55-1.12-3l-.18-.88c-.35-1.74-.62-2.42-1.4-3a3.7,3.7,0,0,0-2.2-.52h-1.1v7.44H24.27V24.11ZM30.62,32c2.08,0,3.05-.83,3.05-2.88,0-2.4-1.45-2.67-3.2-2.67H28.72V32Z"
        />
        <path
          fill={fill.blue}
          d="M46,42.08c-5,0-6.37-2.58-6.37-6.27,0-2.88.42-6.88,6.7-6.88,5.62,0,6.12,3.35,6.12,6.38C52.47,40.3,50.27,42.08,46,42.08ZM48.37,35c0-2.78-.57-4.13-2.22-4.13s-2.4,1.13-2.4,4.5v1.2c0,2,.47,3.65,2.3,3.65s2.32-1.65,2.32-4.43Z"
        />
        <path
          fill={fill.blue}
          d="M62,33.73c0-1.22-.3-2.9-1.92-2.9-2.08,0-2.25,2.68-2.25,4.75,0,1.67,0,4.57,2.12,4.57,1.2,0,2-.9,2-2.87h4c0,2.85-1.92,4.8-6.17,4.8-3.73,0-6-1.83-6-6.6,0-6.52,5-6.55,6.42-6.55,4.48,0,5.8,2.15,5.8,4.8Z"
        />
        <path
          fill={fill.blue}
          d="M75.42,41.75V33.61c0-2.18-.82-2.5-1.72-2.5a1.92,1.92,0,0,0-2,2.12v8.52H67.6V24.11h4.05v6.55A6,6,0,0,1,75.77,29c3.35,0,3.7,2.32,3.7,4.2v8.54Z"
        />
        <path
          fill={fill.blue}
          d="M85.25,35.78v.45c0,1.68.17,3.92,2.25,3.92a2.13,2.13,0,0,0,2.07-2.4h4c0,2.28-1.77,4.33-6.1,4.33-5.07,0-6.27-2.7-6.27-6.4,0-5.45,3-6.75,6.45-6.75,5.35,0,5.9,3.38,5.9,5.85v1Zm4.27-1.72c0-1.28-.2-3.23-2-3.23-1.25,0-2.18,1-2.25,3.23Z"
        />
        <path
          fill={fill.blue}
          d="M106.26,24.23a11.23,11.23,0,0,1,6.83,1.82c1.62,1.22,2.43,3.1,2.43,5.66a10.05,10.05,0,0,1-1.08,4.63,8.69,8.69,0,0,1-3.37,3.5,11.77,11.77,0,0,1-3.89,1.49,24.15,24.15,0,0,1-4.7.42H96l.24-.91a7,7,0,0,0,1.2-.18,1.56,1.56,0,0,0,.72-.39,1.78,1.78,0,0,0,.42-.73,12,12,0,0,0,.28-1.19l2-10.61c.06-.29.1-.54.14-.75a3.3,3.3,0,0,0,0-.6.93.93,0,0,0-.44-.93,4.53,4.53,0,0,0-1.58-.31l.24-.92Zm-3.75,13.71a6.69,6.69,0,0,0-.16,1.25,1.26,1.26,0,0,0,.43,1.05,1.63,1.63,0,0,0,.66.29,4.52,4.52,0,0,0,.74.06,6.23,6.23,0,0,0,3-.73,7.39,7.39,0,0,0,2.35-2A9.22,9.22,0,0,0,111.07,35a11.52,11.52,0,0,0,.54-3.55,8.71,8.71,0,0,0-.31-2.31,5.88,5.88,0,0,0-.94-1.93,4.37,4.37,0,0,0-1.6-1.34,5,5,0,0,0-2.31-.5,2.17,2.17,0,0,0-1.43.32,1.22,1.22,0,0,0-.24.43,6.34,6.34,0,0,0-.25,1Z"
        />
        <path
          fill={fill.blue}
          d="M123.05,39.11a10.44,10.44,0,0,1-2.79,2.28,5,5,0,0,1-2,.69,1,1,0,0,1-.73-.29,1.48,1.48,0,0,1-.29-1.06,6,6,0,0,1,0-.69,4.82,4.82,0,0,1,.17-.88l1.41-6a3.37,3.37,0,0,0,.1-.75c0-.13,0-.19-.1-.19a1.71,1.71,0,0,0-.68.28,9.25,9.25,0,0,0-1,.66l-.38-.75a13.49,13.49,0,0,1,2.82-2.12,4.87,4.87,0,0,1,2.1-.74c.5,0,.75.39.75,1.18a7,7,0,0,1-.21,1.49l-1.54,6.26a2.67,2.67,0,0,0-.11.7c0,.24.06.36.19.36a1.75,1.75,0,0,0,.66-.27,6.68,6.68,0,0,0,1.07-.81Zm-1.16-15a1.32,1.32,0,0,1,1.15.49,1.8,1.8,0,0,1,.37,1.1,2.13,2.13,0,0,1-.59,1.46,1.88,1.88,0,0,1-1.47.65,1.36,1.36,0,0,1-1.09-.45,1.89,1.89,0,0,1-.42-1.23,1.87,1.87,0,0,1,.14-.67,2.49,2.49,0,0,1,.39-.66,1.82,1.82,0,0,1,.63-.5A1.91,1.91,0,0,1,121.89,24.15Z"
        />
        <path
          fill={fill.blue}
          d="M137.12,38.86a13,13,0,0,1-1.4,1.45,11.3,11.3,0,0,1-1.34,1,6.58,6.58,0,0,1-1.16.58,2.85,2.85,0,0,1-.88.19,1,1,0,0,1-.66-.25c-.19-.16-.28-.53-.28-1.1,0-.2,0-.42,0-.66a6,6,0,0,1,.14-.83l.3-1.43h-.06a14.79,14.79,0,0,1-1.4,1.69c-.47.48-.89.88-1.27,1.2a9.68,9.68,0,0,1-1.39.95,3.12,3.12,0,0,1-1.5.43,1.59,1.59,0,0,1-1.35-.85,4.59,4.59,0,0,1-.54-2.45,8.62,8.62,0,0,1,.22-1.94,9.62,9.62,0,0,1,.6-1.86,9.17,9.17,0,0,1,.94-1.68,8.18,8.18,0,0,1,1.21-1.4,11.41,11.41,0,0,1,1.11-.84,9.5,9.5,0,0,1,1.41-.78,9.92,9.92,0,0,1,1.61-.57,6.9,6.9,0,0,1,1.68-.21,6.09,6.09,0,0,1,.82.05l.9.13a7.19,7.19,0,0,0,1.57.19l.16.11c-.29,1.19-.58,2.49-.89,3.9s-.6,2.92-.89,4.53a5.27,5.27,0,0,0-.08.7c0,.23.05.35.16.35a1.86,1.86,0,0,0,.76-.42,8.85,8.85,0,0,0,1-.88Zm-8.69.79a1.62,1.62,0,0,0,.92-.49A10.35,10.35,0,0,0,130.49,38c.39-.44.75-.88,1.07-1.32a6.31,6.31,0,0,0,.65-1c.07-.34.15-.72.25-1.15l.3-1.26c.1-.43.19-.82.26-1.18s.12-.64.16-.84a4,4,0,0,0-.73-.27,3.56,3.56,0,0,0-.84-.1,1.93,1.93,0,0,0-.63.08,2,2,0,0,0-.55.27,3.84,3.84,0,0,0-1.13,1.28,9.9,9.9,0,0,0-.79,1.78,12.58,12.58,0,0,0-.65,3.88C127.86,39.14,128.05,39.65,128.43,39.65Z"
        />
        <path
          fill={fill.blue}
          d="M139.36,23.74c.67-.07,1.28-.16,1.82-.27s1-.21,1.45-.32l1-.27a2.77,2.77,0,0,1,.63-.11c.19,0,.29.13.29.38a1.59,1.59,0,0,1-.08.51l-1.06,4.65c-.36,1.53-.72,3.1-1.1,4.72h.06a12.7,12.7,0,0,1,2.93-2.66,4.85,4.85,0,0,1,2.44-.87,1.88,1.88,0,0,1,1.61.9,4.31,4.31,0,0,1,.6,2.42,8.68,8.68,0,0,1-.28,2.2,9.91,9.91,0,0,1-.81,2.1A9.67,9.67,0,0,1,147.6,39a9.31,9.31,0,0,1-1.68,1.53,8.54,8.54,0,0,1-2.37,1.16,7.75,7.75,0,0,1-2.19.38,3.41,3.41,0,0,1-1.69-.38,2.83,2.83,0,0,1-1-.92,2.91,2.91,0,0,1-.43-1.7,8.36,8.36,0,0,1,.19-1.75q.54-2.76,1.12-5.46t1.2-5.8q.06-.27.09-.48a1.59,1.59,0,0,0,0-.36.51.51,0,0,0-.21-.49,2.16,2.16,0,0,0-.73-.13l-.7,0Zm3.51,17a1.8,1.8,0,0,0,1.08-.41,3.56,3.56,0,0,0,.83-1,10.57,10.57,0,0,0,.82-1.65,13.79,13.79,0,0,0,.63-2.08,10.38,10.38,0,0,0,.26-2.29,2.71,2.71,0,0,0-.23-1.27c-.15-.27-.33-.4-.53-.4a3,3,0,0,0-1.55.79,10.74,10.74,0,0,0-2.09,2.39,5.7,5.7,0,0,0-.23.89c-.08.42-.17.87-.26,1.35,0,.29-.09.56-.11.81s0,.48,0,.68a3.48,3.48,0,0,0,.13,1.06,2.2,2.2,0,0,0,.34.67,1.07,1.07,0,0,0,.45.33A1.18,1.18,0,0,0,142.87,40.73Z"
        />
        <path
          fill={fill.blue}
          d="M160.77,38.7A16.61,16.61,0,0,1,159,40.3a10.13,10.13,0,0,1-1.63,1,6.78,6.78,0,0,1-1.52.57,6.19,6.19,0,0,1-1.45.18,4.16,4.16,0,0,1-1.19-.19,2.68,2.68,0,0,1-1.06-.65,3.24,3.24,0,0,1-.77-1.24,5.55,5.55,0,0,1-.3-2,9.61,9.61,0,0,1,.2-1.91,9.24,9.24,0,0,1,.61-1.84,8.83,8.83,0,0,1,1-1.68,7.25,7.25,0,0,1,1.33-1.38,8.7,8.7,0,0,1,.95-.63,8.94,8.94,0,0,1,1.12-.55,6.94,6.94,0,0,1,1.19-.4,5.22,5.22,0,0,1,1.14-.14,2.88,2.88,0,0,1,2.15.7,2.26,2.26,0,0,1,.69,1.64,2.65,2.65,0,0,1-.7,1.81A6.63,6.63,0,0,1,159,35a14.12,14.12,0,0,1-2.27,1c-.81.27-1.55.5-2.21.68a2.77,2.77,0,0,0,0,.39v.31a3.61,3.61,0,0,0,.43,1.78,1.54,1.54,0,0,0,1.46.76,4.35,4.35,0,0,0,1.85-.53,7.94,7.94,0,0,0,2-1.44Zm-3.32-8a1.45,1.45,0,0,0-.81.27,2.82,2.82,0,0,0-.54.52,5.28,5.28,0,0,0-.58.94,9.16,9.16,0,0,0-.54,1.36,9,9,0,0,0-.36,1.82,7.62,7.62,0,0,0,3-1.52,2.72,2.72,0,0,0,.85-2,1.42,1.42,0,0,0-.38-1.11A1.11,1.11,0,0,0,157.45,30.74Z"
        />
        <path
          fill={fill.blue}
          d="M170.33,31.14l-2.51.14-1.4,6.77a7.45,7.45,0,0,0-.14,1.06q0,.54.27.54a1.15,1.15,0,0,0,.41-.12,4.62,4.62,0,0,0,.59-.33,6.48,6.48,0,0,0,.65-.46,5.64,5.64,0,0,0,.56-.5l.44.71a7.78,7.78,0,0,1-1,1.09,9.31,9.31,0,0,1-1.31,1,7,7,0,0,1-1.4.74,3.44,3.44,0,0,1-1.27.29,1.08,1.08,0,0,1-.88-.42,2.4,2.4,0,0,1-.33-1.45,10.68,10.68,0,0,1,.24-2l1.51-7H162.8l-.14-.27.76-1H165l.6-2.48,3.13-2,.35.24-1,4.21h3Z"
        />
        <path
          fill={fill.blue}
          d="M180.1,38.7a17.6,17.6,0,0,1-1.76,1.6,10.65,10.65,0,0,1-1.64,1,6.78,6.78,0,0,1-1.52.57,6.19,6.19,0,0,1-1.45.18,4.16,4.16,0,0,1-1.19-.19,2.68,2.68,0,0,1-1.06-.65,3.37,3.37,0,0,1-.77-1.24,5.55,5.55,0,0,1-.3-2,9.61,9.61,0,0,1,.2-1.91,9.24,9.24,0,0,1,.61-1.84,8.83,8.83,0,0,1,1-1.68,7.25,7.25,0,0,1,1.33-1.38,8.7,8.7,0,0,1,1-.63,8.94,8.94,0,0,1,1.12-.55,7.24,7.24,0,0,1,1.19-.4,5.22,5.22,0,0,1,1.14-.14,2.88,2.88,0,0,1,2.15.7,2.26,2.26,0,0,1,.69,1.64,2.61,2.61,0,0,1-.7,1.81A6.63,6.63,0,0,1,178.32,35a14.12,14.12,0,0,1-2.27,1c-.81.27-1.54.5-2.21.68a2.77,2.77,0,0,0,0,.39v.31a3.51,3.51,0,0,0,.44,1.78,1.52,1.52,0,0,0,1.45.76,4.31,4.31,0,0,0,1.85-.53,7.94,7.94,0,0,0,2-1.44Zm-3.32-8A1.45,1.45,0,0,0,176,31a2.82,2.82,0,0,0-.54.52,5.94,5.94,0,0,0-.58.94,9.16,9.16,0,0,0-.54,1.36,9,9,0,0,0-.36,1.82,7.62,7.62,0,0,0,3-1.52,2.69,2.69,0,0,0,.85-2,1.42,1.42,0,0,0-.38-1.11A1.11,1.11,0,0,0,176.78,30.74Z"
        />
        <path
          fill={fill.blue}
          d="M183.8,42.08a4.57,4.57,0,0,1-1.16-.16,3.37,3.37,0,0,1-1-.48,2.56,2.56,0,0,1-.72-.71,1.58,1.58,0,0,1-.27-.89,1.54,1.54,0,0,1,.27-.87,2.06,2.06,0,0,1,.63-.62,1,1,0,0,1,.54-.19.6.6,0,0,1,.43.25,7.21,7.21,0,0,0,1.56,1.44,2.84,2.84,0,0,0,1.54.55,1.36,1.36,0,0,0,.85-.25.84.84,0,0,0,.34-.72,1.36,1.36,0,0,0-.34-.84,7.68,7.68,0,0,0-1.36-1.18l-.95-.68a5.39,5.39,0,0,1-.87-.78,4.46,4.46,0,0,1-.65-.93,2.53,2.53,0,0,1-.26-1.15,3.44,3.44,0,0,1,.35-1.47,4.7,4.7,0,0,1,1.35-1.53,8,8,0,0,1,1.64-1,4.1,4.1,0,0,1,1.68-.39,2.84,2.84,0,0,1,1.8.54,1.55,1.55,0,0,1,.69,1.24,1.48,1.48,0,0,1-.24.79,2.23,2.23,0,0,1-.68.69.77.77,0,0,1-.4.14.5.5,0,0,1-.38-.19,5.59,5.59,0,0,0-1-1.08,2.15,2.15,0,0,0-1.39-.49,1,1,0,0,0-.77.27,1,1,0,0,0-.26.68,1.51,1.51,0,0,0,.55,1.06,8.9,8.9,0,0,0,1.34,1,11.31,11.31,0,0,1,1.89,1.57,2.65,2.65,0,0,1,.73,1.89,3.14,3.14,0,0,1-.53,1.73,5.32,5.32,0,0,1-1.32,1.4,7.05,7.05,0,0,1-1.75.95A5.42,5.42,0,0,1,183.8,42.08Z"
        />
        <path
          fill={fill.gray}
          d="M89,51.46v-.12c0-1.72-.62-2.45-1.79-2.45-1.43,0-2.18,1-2.18,4.69,0,3.47.85,4.11,2.07,4.11.91,0,1.85-.46,2-2.56H92c0,.64-.06,4.07-5.15,4.07-4,0-4.82-2.2-4.82-5.75,0-2.88.48-6.07,5.24-6.07,2.54,0,4.57.83,4.57,3.89v.19Z"
        />
        <path
          fill={fill.gray}
          d="M100.58,58.94l-1-3.73H96.4l-1.17,3.73H92.48l3.69-11.3h4.1l3.25,11.3ZM98.06,49.5l-1.21,4h2.3Z"
        />
        <path
          fill={fill.gray}
          d="M110.35,47.64c2.1,0,3.25.89,3.25,2.91a2.56,2.56,0,0,1-2.34,2.82,2.19,2.19,0,0,1,1.41.72,4.8,4.8,0,0,1,.79,2l.16.79a4.09,4.09,0,0,0,.76,2.08h-3c-.38-.27-.53-1-.72-1.94l-.11-.56c-.22-1.12-.4-1.55-.9-1.93a2.34,2.34,0,0,0-1.41-.34h-.7v4.77H104.7V47.64Zm-1.58,5.06c1.32,0,2-.53,2-1.84,0-1.54-.93-1.72-2-1.72h-1.12V52.7Z"
        />
        <path
          fill={fill.gray}
          d="M115.53,58.94V47.64h7V49.4H118.4v2.78h3.74V54H118.4v3.22h4.54v1.76Z"
        />
        <path
          fill={fill.gray}
          d="M131.77,58.94h-2.91V47.64h4.8c2.16,0,4,.27,4,3.58,0,2.66-1.44,3.62-3.92,3.62h-1.94Zm1-5.6c1.38,0,2-.39,2-2.18,0-1.33-.47-2-1.81-2h-1.19v4.2Z"
        />
        <path fill={fill.gray} d="M139,58.94V47.64H142v9.54h4.53v1.76Z" />
        <path
          fill={fill.gray}
          d="M154.9,58.94l-1-3.73h-3.15l-1.17,3.73H146.8l3.69-11.3h4.1l3.25,11.3Zm-2.52-9.44-1.21,4h2.3Z"
        />
        <path fill={fill.gray} d="M163.41,49.4v9.54h-2.9V49.4h-2.9V47.64h8.71V49.4Z" />
        <path fill={fill.gray} d="M170.41,49.4v3.15h3.7v1.76h-3.7v4.63H167.5V47.64h6.9V49.4Z" />
        <path
          fill={fill.gray}
          d="M180.73,59.2c-3.61,0-5.12-1.37-5.12-5.7,0-3.11.58-6.12,5.32-6.12,4,0,5,2.07,5,5.49C186,57.21,184.67,59.2,180.73,59.2Zm.13-10.31c-2.14,0-2.25,2.29-2.25,4.83,0,3.11.56,4,2.16,4S183,56.62,183,53C183,50.79,182.85,48.89,180.86,48.89Z"
        />
        <path
          fill={fill.gray}
          d="M193.47,47.64c2.1,0,3.25.89,3.25,2.91a2.56,2.56,0,0,1-2.34,2.82,2.19,2.19,0,0,1,1.41.72,4.8,4.8,0,0,1,.79,2l.16.79a4.09,4.09,0,0,0,.76,2.08h-3c-.38-.27-.53-1-.72-1.94l-.11-.56c-.23-1.12-.4-1.55-.9-1.93a2.34,2.34,0,0,0-1.41-.34h-.7v4.77h-2.85V47.64Zm-1.58,5.06c1.32,0,2-.53,2-1.84,0-1.54-.93-1.72-2.05-1.72h-1.12V52.7Z"
        />
        <path
          fill={fill.gray}
          d="M209.2,58.94s0-6.64,0-9.4c-.16.64-2.83,9.4-2.83,9.4h-2.15s-2.32-7.44-2.83-9.37c0,1.22-.05,8.62-.05,9.37h-2.66s.15-11.08.15-11.3h4.3l2.19,7.57,2.23-7.57h4.31c0,.22.09,11.3.09,11.3Z"
        />
      </SvgIcon>
    </React.Fragment>
  );
};

export const AppLogoWordmark = translate()(AppLogoWordmarkComponent);
