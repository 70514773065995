import * as React from 'react';
import * as ReactDOM from 'react-dom';

export class Portal extends React.Component<any, any> {
  public el;
  public root;
  constructor(props) {
    super(props);
    // Create a div that we'll render the modal into.
    this.el = document.createElement('div');
    this.root = document.getElementById(props.rootId);
  }

  public componentDidMount() {
    // Append the element into the DOM on mount
    if (this.root) {
      this.root.appendChild(this.el);
    }
  }

  public componentWillUnmount() {
    // Remove the element from the DOM when we unmount
    if (this.root) {
      this.root.removeChild(this.el);
    }
  }

  public render() {
    // Use a portal to render the children into the element
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}
