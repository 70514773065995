import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { addModal, removeModalByKey } from 'src/core/modal/modal.actions';
import { ModalTypes } from 'src/core/modal/modal.types';

import { mapDispatchers } from 'src/utils/map-dispatchers';

import { OIDCErrorComponent } from './error.component';
import { ErrorProps } from './error.types';

const errorConnector = createStructuredSelector({});

const dispatchers = mapDispatchers({
  addModal: () =>
    addModal({
      key: ModalTypes.LOGIN_ERROR,
      data: {},
    }),
  removeModal: () => removeModalByKey({ key: ModalTypes.LOGIN_ERROR }),
});

export const ErrorContainer = compose<ErrorProps, ErrorProps>(
  connect(
    errorConnector,
    dispatchers,
  ),
)(OIDCErrorComponent);
