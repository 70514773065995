import { max } from 'ramda';
import * as React from 'react';

import { LocalizedText } from 'src/components/localized-text';
import { BASE_FONT_SIZE_PX } from 'src/theme/typography';

import {
  ContentWrapper,
  CountDisplay,
  CountDisplayWrapper,
  GraphContainer,
  RemainingStripsLabel,
  styleFaceComponent,
  styleTickComponent,
} from './strips-stock.styles';
import { FaceProps, StripsStockProps, TickProps } from './strips-stock.types';

const TICKS_TOTAL = 64;

export const firstActiveIndex = (remaining, startAmount) =>
  Math.floor((1 - remaining / startAmount) * TICKS_TOTAL);

export const StripsStock: React.FunctionComponent<StripsStockProps> = ({
  remaining,
  startAmount,
  diameter,
}) => {
  const activeIndex = firstActiveIndex(remaining, startAmount);
  const countFontSize = Math.ceil(max(diameter / 4.5, BASE_FONT_SIZE_PX));
  return remaining === void 0 ? null : (
    <ContentWrapper>
      <GraphContainer>
        <Face diameter={diameter}>
          <CountDisplayWrapper>
            <CountDisplay fontSize={`${countFontSize}px`}>{remaining}</CountDisplay>
          </CountDisplayWrapper>
          {Array(TICKS_TOTAL)
            .fill(null)
            .map((_, i) => (
              <Tick
                key={`strips-remaining-tick-${i}`}
                diameter={diameter}
                tickIndex={i}
                activeIndex={activeIndex}
                isHighlighted={i === 0 || i === activeIndex}
              />
            ))}
        </Face>
      </GraphContainer>
      <RemainingStripsLabel>
        <LocalizedText label={'stripsStock.labels.remainingStrips'} />
      </RemainingStripsLabel>
    </ContentWrapper>
  );
};

export const FaceComponent: React.FunctionComponent<FaceProps> = ({
  children,
  diameter,
  className,
}) => <div className={className}>{children}</div>;

export const TickComponent: React.FunctionComponent<TickProps> = ({ className }) => (
  <div className={`tick ${className}`} />
);

const Face = styleFaceComponent(FaceComponent);
const Tick = styleTickComponent(TickComponent);
