import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, withHandlers } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { selectLegacyToken } from 'src/core/session/session.selectors';
import { Modal } from 'src/ui/modal/components/modal.component';
import { mapDispatchers } from 'src/utils';

import { removeModalByKey, removeAllModals } from 'src/core/modal/modal.actions';
import {
  selectCurrentModalData,
  selectCurrentModalType,
  selectIsOpen,
} from 'src/core/modal/modal.selectors';

const dispatchers = mapDispatchers({
  removeModalByKey,
  removeAllModals
});

const modalConnector = createStructuredSelector({
  token: selectLegacyToken,
  isOpen: selectIsOpen,
  type: selectCurrentModalType,
  data: selectCurrentModalData,
});

export const ModalContainer = compose<any, any>(
  connect(
    modalConnector,
    dispatchers,
  ),
  withHandlers<any, any>({
    close: props => () => props.removeModalByKey({ key: props.type }),
  }),
  withRouter,
)(Modal);
