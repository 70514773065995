import * as React from 'react';

import { SvgIcon } from '../icon.component';
import { DEFAULT_ICON_COLOR } from '../icon.constants';
import { calculateAspectRatio } from '../icon.utils';

type IconProps = {
  height?: number;
  fillColor?: string;
};

export const HomeIcon: React.FunctionComponent<IconProps> = ({
  height = 21,
  fillColor = DEFAULT_ICON_COLOR,
}) => {
  const originalWidth = 27;
  const originalHeight = 24;
  const aspectRatio = calculateAspectRatio(originalWidth, originalHeight);

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g fill={fillColor}>
        <path d="M21.287 10.72h1.35v10.739a2.175 2.175 0 0 1-2.175 2.175H6.772a2.175 2.175 0 0 1-2.174-2.175V10.72h1.35v10.739c0 .455.37.825.825.825h13.689c.456 0 .825-.37.825-.825V10.72z" />
        <path d="M1.25 11.242l-.856-1.044L12.238.48a2.175 2.175 0 0 1 2.759 0l11.844 9.72-.857 1.043-11.843-9.72a.825.825 0 0 0-1.047 0L1.25 11.243z" />
      </g>
    </SvgIcon>
  );
};
