import * as React from 'react';

import { SvgIcon } from '../icon.component';
import { DEFAULT_ICON_COLOR } from '../icon.constants';
import { calculateAspectRatio } from '../icon.utils';

type IconProps = {
  height?: number;
  fillColor?: string;
};

export const CalendarIcon: React.FunctionComponent<IconProps> = ({
  height = 15,
  fillColor = DEFAULT_ICON_COLOR,
}) => {
  const originalWidth = 15;
  const originalHeight = 15;
  const aspectRatio = calculateAspectRatio(originalWidth, originalHeight);

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g fill="none" fillRule="evenodd">
        <rect fill="#FFF" x={0.425} y={1.913} width={13.892} height={13} rx={1.411} />
        <path
          d="M14.315 3.576v.432H.425V1.002a.42.42 0 0 1 .42-.42h13.05a.42.42 0 0 1 .42.42v2.574z"
          fill={fillColor}
        />
      </g>
    </SvgIcon>
  );
};
