import { createAction, createPayloadAction } from 'src/app/store/app.actions';

import {
  FetchStripsStockError,
  FetchStripsStockStart,
  FetchStripsStockSuccess,
  ResetStripsStock,
  SetStripsStockAction,
  StripsStockActionType,
  StripsStockData,
} from './strips-stock.types';

export const setStripsStock = (payload: StripsStockData): SetStripsStockAction =>
  createPayloadAction(StripsStockActionType.SET_STRIPS_STOCK, payload);

export const fetchStripsStockStart = (): FetchStripsStockStart =>
  createAction(StripsStockActionType.FETCH_STRIPS_STOCK_START);

export const fetchStripsStockSuccess = (
  stripsStockData: StripsStockData,
): FetchStripsStockSuccess =>
  createPayloadAction(StripsStockActionType.FETCH_STRIPS_STOCK_SUCCESS, stripsStockData);

export const fetchStripsStockError = (error: Error): FetchStripsStockError =>
  createPayloadAction(StripsStockActionType.FETCH_STRIPS_STOCK_ERROR, error);

export const resetStripsStock = (): ResetStripsStock =>
  createAction(StripsStockActionType.RESET_STRIPS_STOCK);
