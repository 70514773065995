import { ensureNever, setIn } from 'src/utils';

import { UserActionType, UserReducerActions, UserState } from './user.types';

export const initialUserState: UserState = {
  isLoading: true,
  accountInfo: {
    UID: '',
    errorCode: -1,
    data: {
      HCPIsAccessible: false,
      HCPIsActive: false,
      billAddressCity: '',
      billAddressCountry: '',
      billAddressCountryCode: '',
      billAddressProvince: '',
      homeCountryCode: '',
      Cypher_Cloud_Gateway: '',
      billAddressStreet: '',
      nationalHealthNumber: '',
      SFDCaccountID: '',
      HCP_ID: '',
      billAddressPostalCode: '',
      homeCountry: '',
      FHIR_UserType: '',
      FHIR_Patient_ID: '',
    },
    profile: {
      firstName: '',
      lastName: '',
      birthDay: '',
      birthMonth: '',
      birthYear: '',
      email: '',
      gender: '',
      languages: '',
      locale: '',
      photoURL: '',
      phones: [
        {
          number: '',
        },
      ],
    },
    loginIDs: {
      emails: [],
      unverifiedEmails: [],
    },
  },
  error: '',
};

export const userReducer = (state = initialUserState, action: UserReducerActions): UserState => {
  switch (action.type) {
    case UserActionType.FETCH_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        accountInfo: action.payload,
        isLoading: false,
      };
    case UserActionType.FETCH_ACCOUNT_INFO_ERROR:
      return setIn(['error'], action.payload, state);
    case UserActionType.RESET_ACCOUNT_INFO:
      return initialUserState;
    default:
      ensureNever(action);
      return state;
  }
};
