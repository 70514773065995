export const fontSizes = [8, 12, 14, 16, 20, 24, 34, 45, 56, 112];
export const fontSize = {
  graphLabel: '0.5rem', // 8px
  label: '0.625rem',
  caption: '0.75rem', // 12px
  p: '0.875rem', // 14px
  subheading: '1rem', // 16px
  title: '1.25rem', // 20px
  headline: '1.5rem', // 24px
  display1: '2.125rem', // 34px
  display2: '3rem', // 48px
  display3: '3.75rem', // 60px
  display4: '7rem', // 112px
};
export const BASE_FONT_SIZE = fontSize.subheading;
export const BASE_FONT_SIZE_PX = 16;

export const letterSpacing = {
  small: '0.05em',
  medium: '0.075em',
  large: '0.1em',
};
