import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, lifecycle } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { addModal } from 'src/core/modal/modal.actions';
import { ModalTypes } from 'src/core/modal/modal.types';
import { signoutStart } from 'src/core/session/session.actions';
import { selectFullName, selectLanguage, selectPhotoUrl } from 'src/core/user/user.selectors';
import { selectNavigationRoutes } from 'src/navigation/store/navigation.selectors';
import { shouldShowDTCModal } from 'src/ui/modal/components/dtc/dtc.utils';
import { selectIsAppNavigationDisplayed } from 'src/ui/store/ui.selectors';
import { mapDispatchers } from 'src/utils';

import { AppNavigation, AppNavigationProps } from './ui-navigation-bar.component';

export const AppNavigationContainer = compose<AppNavigationProps, Partial<AppNavigationProps>>(
  connect(
    createStructuredSelector({
      isDisplayed: selectIsAppNavigationDisplayed,
      routes: selectNavigationRoutes,
      profileName: selectFullName,
      profilePhoto: selectPhotoUrl,
      language: selectLanguage,
    }),
    mapDispatchers({
      onLogout: signoutStart,
      openDTCModal: () =>
        addModal({
          key: ModalTypes.DTC,
          data: {
            onClickDownload: () => null,
          },
        }),
      openPIModal: () =>
        addModal({
          key: ModalTypes.PRODUCT_INFO,
          data: {},
        }),
    }),
  ),
  withRouter,
  lifecycle({
    componentDidMount() {
      const {
        location: { search },
        openDTCModal,
      } = this.props as AppNavigationProps;
      if (shouldShowDTCModal(search)) {
        openDTCModal();
      }
    },
  }),
)(AppNavigation);
