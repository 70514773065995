import { createAction, createPayloadAction } from '@roche/patient-portal-graphs/core/store';
import { BGStatisticsActionTypes, } from './bg-statistics.types';
export var fetchBGStatisticsStart = function (payload) {
    return createPayloadAction(BGStatisticsActionTypes.FETCH_BG_STATISTICS_START, payload);
};
export var fetchBGThresholdsSuccess = function (payload) {
    return createPayloadAction(BGStatisticsActionTypes.FETCH_BG_THRESHOLDS_SUCCESS, payload);
};
export var fetchTimeBlocksSuccess = function (payload) {
    return createPayloadAction(BGStatisticsActionTypes.FETCH_TIME_INTERVALS_SUCCESS, payload);
};
export var fetchBGMeasurementsSuccess = function (payload) {
    return createPayloadAction(BGStatisticsActionTypes.FETCH_BG_MEASUREMENTS_SUCCESS, payload);
};
export var fetchBGStatisticsDataGeneralError = function (error) {
    return createPayloadAction(BGStatisticsActionTypes.FETCH_BG_STATISTICS_DATA_GENERAL_ERROR, error);
};
export var resetBGStatistics = function () {
    return createAction(BGStatisticsActionTypes.RESET_BG_STATISTICS);
};
