import * as React from 'react';
import { translate } from 'react-i18next';

import { ExclamationIcon } from 'src/assets/icons';
import { ButtonSizes, ButtonStyles } from 'src/components/button';
import { Column } from 'src/components/column';
import { ConcentricCircles } from 'src/components/concentric-circles';
import { colors } from 'src/theme/colors';
import { spacing } from 'src/theme/spacing';
import { combineRems } from 'src/utils/styles/rem-calc.utils';

import {
  GradientTopBarDiv,
  ModalBody,
  ModalButtonDiv,
  ModalColumn,
  ModalDividerDiv,
  ModalHeading,
  ModalOptionButton,
} from 'src/ui/modal/components/modal.style';

import { LocalizedText } from 'src/components/localized-text';
import {
  LoginSystemErrorContentDiv,
  LoginSystemErrorHighlight,
  LoginSystemErrorMessage,
} from 'src/ui/modal/components/login-system-error/login-system-error.styles';
import { LoginSystemErrorModalProps } from 'src/ui/modal/components/login-system-error/login-system-error.types';

export const LoginSystemErrorModalComponent: React.FunctionComponent<LoginSystemErrorModalProps> = ({
  onRetry,
  goTo,
  routes,
  t,
}) => (
  <ModalBody>
    <GradientTopBarDiv />
    <ModalColumn>
      <ModalHeading>
        <LocalizedText label="oidc.system-login-error.heading" />
      </ModalHeading>
      <ModalDividerDiv />
      <Column alignItems="center" pt={combineRems(spacing.four, spacing.three)} pl={4} pr={4}>
        <ConcentricCircles
          height={70}
          innerCircleColor={colors.trafficRed}
          borderOpacity={0.3}
          borderThickness={6}
        >
          <ExclamationIcon fillColor={colors.white} height={180} width={180} />
        </ConcentricCircles>
        <LoginSystemErrorHighlight>
          <LocalizedText label="oidc.system-login-error.errorMessage" />
        </LoginSystemErrorHighlight>
      </Column>
      <LoginSystemErrorContentDiv>
        <LoginSystemErrorMessage>
          <LocalizedText label="oidc.system-login-error.body3" />
        </LoginSystemErrorMessage>
      </LoginSystemErrorContentDiv>
      <ModalDividerDiv />
      <ModalButtonDiv>
        <ModalOptionButton
          buttonStyle={ButtonStyles.PRIMARY}
          label={t('oidc.system-login-error.retry')}
          onClick={() => {
            goTo(routes.authentication.login);
            onRetry();
          }}
          size={ButtonSizes.SMALL}
        />
      </ModalButtonDiv>
    </ModalColumn>
  </ModalBody>
);

export const LoginSystemErrorModal = translate()(LoginSystemErrorModalComponent);
