import * as React from 'react';
import { translate } from 'react-i18next';

import { CheckmarkIcon } from 'src/assets/icons';
import { ButtonSizes, ButtonStyles } from 'src/components/button';
import { ConcentricCircles } from 'src/components/concentric-circles';
import { Div } from 'src/components/div';
import { LocalizedText } from 'src/components/localized-text';
import { Span } from 'src/components/span';

import { colors } from 'src/theme/colors';

import { I18nTranslate } from 'src/i18n/types';
import {
  ModalButtonDiv,
  ModalColumn,
  ModalDividerDiv,
  ModalHeading,
  ModalOptionButton,
  ModalWideContentDiv,
} from 'src/ui/modal/components';

type PauseStripsDeliveryProps = {
  close: any;
  onClickPauseDelivery: () => void;
  t: I18nTranslate;
};

export const PauseStripsDeliveryComponent: React.FunctionComponent<PauseStripsDeliveryProps> = ({
  close,
  onClickPauseDelivery,
  t,
}) => (
  <ModalColumn>
    <ModalHeading>
      <LocalizedText label="stripsDelivery.titles.pauseDelivery" />
    </ModalHeading>

    <ModalDividerDiv />
    <ModalWideContentDiv>
      <LocalizedText label="stripsDelivery.labels.pauseConfirmationMessage" />
    </ModalWideContentDiv>

    <ModalDividerDiv />

    <ModalButtonDiv>
      <ModalOptionButton
        onClick={onClickPauseDelivery}
        label={t('stripsDelivery.labels.yes')}
        size={ButtonSizes.SMALL}
      />
      <ModalOptionButton
        onClick={close}
        label={t('stripsDelivery.labels.no')}
        size={ButtonSizes.SMALL}
        buttonStyle={ButtonStyles.CLEAR}
      />
    </ModalButtonDiv>
  </ModalColumn>
);

export const PauseStripsDelivery = translate()(PauseStripsDeliveryComponent);

type PauseStripsDeliveryResultProps = {
  close: () => void;
  t: I18nTranslate;
};

export const PauseStripsDeliveryResultComponent: React.FunctionComponent<PauseStripsDeliveryResultProps> = ({
  close,
  t,
}) => (
  <ModalColumn>
    <ModalHeading>
      <LocalizedText label="stripsDelivery.titles.pauseDelivery" />
    </ModalHeading>

    <ModalDividerDiv />
    <ModalWideContentDiv>
      <Div display="flex" alignItems="center">
        <ConcentricCircles
          height={56}
          innerCircleColor={colors.trafficGreen}
          borderOpacity={0.3}
          borderThickness={4}
        >
          <CheckmarkIcon strokeColor={colors.white} strokeWeight={1} height={23} />
        </ConcentricCircles>
        <Span pl={3}>
          <LocalizedText label="stripsDelivery.labels.pauseSuccess" />
        </Span>
      </Div>
    </ModalWideContentDiv>

    <ModalDividerDiv />

    <ModalButtonDiv>
      <ModalOptionButton
        onClick={close}
        label={t('stripsDelivery.labels.close')}
        size={ButtonSizes.SMALL}
      />
    </ModalButtonDiv>
  </ModalColumn>
);

export const PauseStripsDeliveryResult = translate()(PauseStripsDeliveryResultComponent);
