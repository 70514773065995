import { createAction, createPayloadAction } from 'src/app/store/app.actions';

import {
  LoginSuccessAction,
  SessionActionType,
  SessionValidationErrorPayload,
  SignoutErrorAction,
  SignoutStartAction,
  SignoutSuccessAction,
  ValidateSessionErrorAction,
  ValidateSessionStartAction,
  ValidateSessionSuccessAction,
} from './session.types';

export const loginSuccess = (): LoginSuccessAction => createAction(SessionActionType.LOGIN_SUCCESS);

export const validateSessionStart = (): ValidateSessionStartAction =>
  createAction(SessionActionType.VALIDATE_SESSION_START);

export const validateSessionError = (
  payload: SessionValidationErrorPayload,
): ValidateSessionErrorAction =>
  createPayloadAction(SessionActionType.VALIDATE_SESSION_ERROR, payload);

export const validateSessionSuccess = (): ValidateSessionSuccessAction =>
  createAction(SessionActionType.VALIDATE_SESSION_SUCCESS);

export const signoutStart = (): SignoutStartAction =>
  createAction(SessionActionType.SIGN_OUT_START);

export const signoutSuccess = (): SignoutSuccessAction =>
  createAction(SessionActionType.SIGN_OUT_SUCCESS);

export const signoutError = (): SignoutErrorAction =>
  createAction(SessionActionType.SIGN_OUT_ERROR);
