import { Div } from 'src/components/div';
import { colors } from 'src/theme/colors';
import { spacing } from 'src/theme/spacing';
import styled from 'styled-components';

import { StripsDeliveryStatus } from '../../store/strips-delivery.types';

export const StatusLabelDiv = styled(Div)`
  border-radius: ${({ theme }) => theme.borderRadius[1]};
  padding: ${spacing.two} ${spacing.two};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${spacing.three};
`;
StatusLabelDiv.displayName = 'StatusLabelDiv';

export const StatusLabelIconCircle = styled(Div)`
  border-radius: 50%;
  height: ${spacing.three};
  position: relative;
  width: ${spacing.three};

  svg {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
StatusLabelIconCircle.displayName = 'StatusLabelIconCircle';

export const StatusLabelTextSpan = styled.span`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-left: ${spacing.two};
  font-size: ${({ theme }) => theme.fontSize.caption};
  color: ${colors.charcoal};
`;
StatusLabelTextSpan.displayName = 'StatusLabelTextSpan';

export const statusLabelBgColorMap = {
  [StripsDeliveryStatus.ACTIVE]: colors.mintAlpha17,
  [StripsDeliveryStatus.PAUSED]: colors.orangeAlpha20,
  [StripsDeliveryStatus.BLOCKED]: colors.trafficRedPastel,
};

export const statusLabelIconBgColorMap = {
  [StripsDeliveryStatus.ACTIVE]: colors.mint,
  [StripsDeliveryStatus.PAUSED]: colors.orange,
  [StripsDeliveryStatus.BLOCKED]: colors.trafficRed,
};
