import {
  CheckLastActiveAction,
  LastActiveAction,
  ResetLastActiveAction,
  StartActivityListenerAction,
  StopActivityListenerAction,
  UserActivityActionTypes,
} from './user-activity.types';

export const startActivityListener = (): StartActivityListenerAction => ({
  type: UserActivityActionTypes.START_ACTIVITY_LISTENER,
});

export const stopActivityListener = (): StopActivityListenerAction => ({
  type: UserActivityActionTypes.STOP_ACTIVITY_LISTENER,
});

export const lastActive = (): LastActiveAction => ({
  type: UserActivityActionTypes.LAST_ACTIVE,
});

export const checkLastActive = (): CheckLastActiveAction => ({
  type: UserActivityActionTypes.CHECK_LAST_ACTIVE,
});

export const resetLastActive = (): ResetLastActiveAction => ({
  type: UserActivityActionTypes.RESET_LAST_ACTIVE,
});
