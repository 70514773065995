import { actions } from 'react-redux-form';
import { compose, withHandlers, withState } from 'recompose';

export const updateValueHandler = ({ formDispatch }) => (model, value) =>
  formDispatch(actions.change(model, value));

// Skip testing library code
/* istanbul ignore next */
export const mergeValuesHandler = ({ formDispatch }) => (model, value) =>
  formDispatch(actions.merge(model, value));

export const addLocalFormDispatchHandlers = compose(
  // handlers to attach the RRF Local Form dispatcher
  withState('formDispatch', 'attachDispatcher', null),
  withHandlers({
    attachDispatch: ({ attachDispatcher }: any) => dispatch => attachDispatcher(() => dispatch),
    updateValue: updateValueHandler,
    mergeValues: mergeValuesHandler,
  }),
);
