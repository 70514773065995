import * as Cookies from 'cookies-js';
import { isNil } from 'ramda';
import { getDynamicConfig } from 'src/utils';

export const isConsentActivated = () => {
  const { REACT_APP_CONSENTS } = getDynamicConfig();
  return !isNil(REACT_APP_CONSENTS) && REACT_APP_CONSENTS !== ''
    ? JSON.parse(REACT_APP_CONSENTS)
    : false;
};

export const doesUserAcceptedCookies = () => (Cookies.get('OptanonAlertBoxClosed') ? true : false);
