import * as React from 'react';
import { Route as RRoute } from 'react-router-dom';

import { RouteProps } from './smart-route.types';

export const SmartRouteComponent: React.FunctionComponent<RouteProps> = ({
  exact,
  path,
  component: Component,
  ...props
}) => <RRoute path={path} exact={exact} render={() => <Component {...props} />} />;
