import styled from 'styled-components';

import { Div } from 'src/components/div';

import { colors } from 'src/theme/colors';

export const RGBAValues = {
  [colors.trafficGreen]: (opacity: number) => `rgba(126, 211, 33, ${opacity})`,
  [colors.trafficRed]: (opacity: number) => `rgba(207, 2, 27, ${opacity})`,
  [colors.grayMedium]: (opacity: number) => `rgba(155, 155, 155, ${opacity})`,
};

export const makeCircleBoxshadow = (color: string, opacity: number, thickness: number) => {
  return `0 0 0 ${thickness}px ${RGBAValues[color](opacity)}`;
};

export const CircleWithBorder = styled(Div)`
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
CircleWithBorder.displayName = 'CircleWithBorder';
