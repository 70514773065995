import { LocationChangeAction } from 'react-router-redux';

import { Action } from 'src/app/store/app.types';
import { AddModalAction, RemoveModalByKeyAction } from 'src/core/modal/modal.types';
import { RefreshOidcTokensStart } from 'src/core/session/oidc/oidc.types';
import { LoginSuccessAction } from 'src/core/session/session.types';

export enum UserActivityActionTypes {
  START_ACTIVITY_LISTENER = 'START_ACTIVITY_LISTENER',
  STOP_ACTIVITY_LISTENER = 'STOP_ACTIVITY_LISTENER',
  LAST_ACTIVE = 'USER_LAST_ACTIVE',
  RESET_LAST_ACTIVE = 'RESET_LAST_ACTIVE',
  CHECK_LAST_ACTIVE = 'CHECK_LAST_ACTIVE',
}

export type UserActivityState = {
  readonly lastActive: number;
};

export type LastActiveAction = Action<UserActivityActionTypes.LAST_ACTIVE>;
export type CheckLastActiveAction = Action<UserActivityActionTypes.CHECK_LAST_ACTIVE>;

export type ResetLastActiveAction = Action<UserActivityActionTypes.RESET_LAST_ACTIVE>;

export type StartActivityListenerAction = Action<UserActivityActionTypes.START_ACTIVITY_LISTENER>;
export type StopActivityListenerAction = Action<UserActivityActionTypes.STOP_ACTIVITY_LISTENER>;

export type UserActivityActions =
  | LastActiveAction
  | RefreshOidcTokensStart
  | ResetLastActiveAction
  | LoginSuccessAction
  | CheckLastActiveAction
  | StartActivityListenerAction
  | AddModalAction
  | StopActivityListenerAction
  | RemoveModalByKeyAction
  | LocationChangeAction;
