import { getDynamicConfig, getJSON } from 'src/utils';

import { createService } from '../../service';
import { transformJavaSimpleDateFormatToISO } from '../../transformers';

import { GetStripsDeliveryOptions } from './get-strips-delivery.types';
import { GetStripsDeliveryData, GetStripsDeliveryService } from './get-strips-delivery.types';

const {
  REACT_APP_API_MULE,
  REACT_APP_GIGYA_TOKEN,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
} = getDynamicConfig();

const getStripsDeliveryTransform = data => ({
  ...data.model,
  nextDeliveryDate:
    data.model.nextDeliveryDate && transformJavaSimpleDateFormatToISO(data.model.nextDeliveryDate),
});
const getStripsDeliveryRequest = ({
  patientId,
  accessToken,
}: GetStripsDeliveryOptions): Promise<GetStripsDeliveryData> =>
  getJSON(`${REACT_APP_API_MULE}/strips/api/v1/delivery/patients/${patientId}/home-delivery`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ApiKey: REACT_APP_GIGYA_TOKEN,
      client_id: REACT_APP_CLIENT_ID,
      client_secret: REACT_APP_CLIENT_SECRET,
    },
  });

export const getStripsDeliveryService: GetStripsDeliveryService = createService(
  getStripsDeliveryRequest,
  getStripsDeliveryTransform,
);
