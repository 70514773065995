import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { fetchOidcTokensStart } from 'src/core/session/oidc/oidc.action';
import {
  selectAccessToken,
  selectIsTtlValid,
  selectRefreshToken,
} from 'src/core/session/oidc/oidc.selector';
import { loginSuccess } from 'src/core/session/session.actions';
import { mapDispatchers } from 'src/utils';

import { OIDCSuccessComponent } from './success.component';

const dispatchers = mapDispatchers({
  onLoginSuccess: loginSuccess,
  fetchOidcTokens: (token: string) => fetchOidcTokensStart(token),
});

const connector = createStructuredSelector({
  accessToken: selectAccessToken,
  refreshToken: selectRefreshToken,
  isTtlValid: selectIsTtlValid,
});

export const OidcSuccessContainer = compose(
  connect(
    connector,
    dispatchers,
  ),
  withRouter,
)(OIDCSuccessComponent);
