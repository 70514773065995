import styled from 'styled-components';
import { minWidth } from 'styled-system';

import { Button } from 'src/components/button';
import { Column } from 'src/components/column/index';
import { Div } from 'src/components/div/index';
import { fadeIn } from 'src/theme/animations';
import { spacing } from 'src/theme/spacing';
import { transitionEasing, transitionSpeed } from 'src/theme/transitions';
import { combineRems, convertPxToRem } from 'src/utils/styles/rem-calc.utils';

const { fast } = transitionSpeed;
const { enter } = transitionEasing;

export const ModalOverlay = styled(Div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} ${fast} ${enter} 0s backwards;
  z-index: 1000;
  background-color: ${props => props.theme.colors.transparentGrayMedium};
`;
ModalOverlay.displayName = 'ModalOverlay';

export const ModalWrapper = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
ModalWrapper.displayName = 'ModalWrapper';

export const ModalCard = styled(Div)`
  border-radius: 0.1875rem;
  box-shadow: 0 0.5625rem 0.8125rem 0.125rem rgba(51, 51, 51, 0.14),
    0 0.1875rem 1rem 0.1875rem rgba(51, 51, 51, 0.12), 0 0.3125rem 0.375rem 0 rgba(51, 51, 51, 0.2);
  display: inline-block;
  position: relative;
  z-index: 420;
  min-height: 11.25rem;
  background: #fff;
  max-width: 60rem;
`;
ModalCard.displayName = 'ModalCard';

export const ModalBody = styled(Column)`
  min-width: 320px;
  ${minWidth};
`;
ModalBody.displayName = 'ModalBody';

export const CommonHeader = styled(Div)`
  display: flex;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.brandBlue};
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.spacing.three} ${props => props.theme.spacing.four};
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
`;
CommonHeader.displayName = 'CommonHeader';

export const GradientTopBarDiv = styled(Div)`
  background: rgb(132, 211, 240);
  height: 0.5em;
  text-align: center;
  width: 100%;
  background-image: ${({ theme }) => theme.colors.heroGradient};
  border-radius: 0.1875rem 0.1875rem 0 0;
`;
GradientTopBarDiv.displayName = 'GradientTopBarDiv';

export const ModalCloseButton = styled(Button)`
  background-color: transparent;
  position: absolute;
  right: 0;
  margin: ${({ theme }) => theme.spacing.two} ${({ theme }) => theme.spacing.two} 0 0;
  min-width: auto;
  &:hover {
    background-color: transparent;
    path {
      fill: ${({ theme }) => theme.colors.brandBlue};
    }
  }
`;
ModalCloseButton.displayName = 'ModalCloseButton';

export const ModalHeading = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.subheading};
  color: ${({ theme }) => theme.colors.brandBlue};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.two};
`;
ModalHeading.displayName = 'ModalHeading';

export const ModalDividerDiv = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.silverDark};
  height: 1px;
`;
ModalDividerDiv.displayName = 'ModalDividerDiv';

export const ModalWideContentDiv = styled(Column)`
  padding: ${spacing.five};
  flex: 1 0 auto;
  font-size: ${({ theme }) => theme.fontSize.p};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  min-width: ${convertPxToRem(500)};
  align-items: center;
`;
ModalWideContentDiv.displayName = 'ModalWideContentDiv';

export const ModalButtonDiv = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacing.four};
  font-size: ${({ theme }) => theme.fontSize.caption};
`;
ModalButtonDiv.displayName = 'ModalButtonDiv';

export const ModalOptionButton = styled(Button)`
  margin: 0 ${({ theme }) => theme.spacing.three};
`;
ModalOptionButton.displayName = 'ModalOptionButton';

export const ModalContentDiv = styled(Column)`
  padding: ${spacing.four} 0 ${spacing.five} 0;
  flex: 1 0 auto;
  font-size: ${({ theme }) => theme.fontSize.p};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;
ModalContentDiv.displayName = 'ModalContentDiv';

export const ModalSubheading = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.subheading};
  margin: 0;
  padding-bottom: ${combineRems(spacing.three, spacing.one)};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;
ModalSubheading.displayName = 'ModalSubheading';

export const ModalColumn = styled(Column)`
  padding: ${combineRems(spacing.four, spacing.two)} ${spacing.four} ${spacing.four} ${spacing.four};
`;
ModalColumn.displayName = 'ModalColumn';

export const ModalHalfColumn = styled(Column)`
  padding: ${combineRems(spacing.four, spacing.two)} ${spacing.four} ${spacing.four} ${spacing.four};
  flex-basis: ${convertPxToRem(352)};
  max-width: ${convertPxToRem(352)};
`;
ModalHalfColumn.displayName = 'ModalHalfColumn';
