import { getDynamicConfig, getJSON } from 'src/utils';

import { createService } from '../service';

import { PermissionData, PermissionOptions, PermissionsService } from './permissions.types';

const {
  REACT_APP_API_MULE,
  REACT_APP_GIGYA_TOKEN,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
} = getDynamicConfig();

const permissionsTransform = data => data;

/**
 * @param {string} patientId
 * @param {string} token
 * @returns {Promise<PermissionData>}
 *
 * Note: the token is without bearer attached to it because EC6 directly expect it without
 */
const permissionsRequest = ({
  gigyaUId,
  accessToken,
}: PermissionOptions): Promise<PermissionData> =>
  getJSON(`${REACT_APP_API_MULE}/ciam/api/v1/access/permissions/patients/${gigyaUId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ApiKey: REACT_APP_GIGYA_TOKEN,
      client_id: REACT_APP_CLIENT_ID,
      client_secret: REACT_APP_CLIENT_SECRET,
    },
  });

export const permissionsService: PermissionsService = createService(
  permissionsRequest,
  permissionsTransform,
);
