import { Action, PayloadAction } from 'src/app/store/app.types';
import { AccountInfo } from 'src/core/gigya/gigya.types';
import { Diff } from 'src/utils';

export enum UserActionType {
  FETCH_ACCOUNT_INFO_START = 'FETCH_ACCOUNT_INFO_START',
  FETCH_ACCOUNT_INFO_SUCCESS = 'FETCH_ACCOUNT_INFO_SUCCESS',
  FETCH_ACCOUNT_INFO_ERROR = 'FETCH_ACCOUNT_INFO_ERROR',
  RESET_ACCOUNT_INFO = 'RESET_ACCOUNT_INFO',
}

export type AccountInfoPayload = AccountInfo;

export type FetchAccountInfoStart = Action<UserActionType.FETCH_ACCOUNT_INFO_START>;
export type FetchAccountInfoSuccess = PayloadAction<
  UserActionType.FETCH_ACCOUNT_INFO_SUCCESS,
  AccountInfoPayload
>;
// TODO: Error state payload
export type FetchAccountInfoError = PayloadAction<UserActionType.FETCH_ACCOUNT_INFO_ERROR, any>;

export type ResetAccountInfo = Action<UserActionType.RESET_ACCOUNT_INFO>;

// State and action combinations
export type UserState = {
  isLoading?: boolean;
  accountInfo: AccountInfo;
  error?: any;
};

export type UserEpicActions = FetchAccountInfoStart;

export type UserActions =
  | FetchAccountInfoStart
  | FetchAccountInfoSuccess
  | FetchAccountInfoError
  | ResetAccountInfo;

export type UserReducerActions = Diff<UserActions, UserEpicActions>;
