import { NavLink } from 'react-router-dom';
import { zIndexes } from 'src/theme/z-index';
import styled, { css } from 'styled-components';

import NavigationOverlaySVG from 'src/assets/images/nav-bar-overlay.svg';
import { createStyledComponent } from 'src/theme';
import { AnimationHelper } from 'src/theme/animations';
import { transitionEasing, transitionSpeed } from 'src/theme/transitions';
import { convertPxToRem } from 'src/utils/styles';

import { Div } from '../div';
import { Span } from '../span';

export const NavigationBarWrapper = styled.div`
  animation: ${({ theme }) =>
    `${theme.animations.slideDownFadeIn} ${transitionSpeed.slow} ${transitionEasing.enter}`};
  background-image: ${({ theme }) => theme.colors.heroGradient};
  display: flex;
  height: auto;
  position: fixed;
  top: 0;
  z-index: ${zIndexes.routeNav};
  width: 100%;
  box-shadow: 0 2px 4px ${({ theme }) => theme.colors.transparentGrayMedium},
    0 3px 14px ${({ theme }) => theme.colors.transparentGrayMedium};
`;
NavigationBarWrapper.displayName = 'NavigationBarWrapper';

export const NavBarOverlay = styled.div`
  height: ${convertPxToRem(45)};
  background: url(${NavigationOverlaySVG}) repeat-x;
`;
NavBarOverlay.displayName = 'NavBarOverlay';

export const NavigationBarLinksList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: ${({ theme }) => theme.zIndexes.foreground};
`;
NavigationBarLinksList.displayName = 'NavigationBarLinksList';

export const NavigationBarLinksListItem = styled.li`
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.p};
  text-transform: uppercase;
`;
NavigationBarLinksListItem.displayName = 'NavigationBarLinksListItem';

export const NavigationBarLinkLabel = styled.span`
  transition: ${({ theme }) => theme.transitions.default};
`;
NavigationBarLinkLabel.displayName = 'NavigationBarLinkLabel';

export const NavigationBarLinkLabelName = styled.span`
  transition: ${({ theme }) => theme.transitions.default};
  word-break: break-all;
  max-width: 375px;
`;
NavigationBarLinkLabelName.displayName = 'NavigationBarLinkLabelName';

const NavItemBase = css`
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;

  svg {
    vertical-align: middle;
  }

  svg path {
    transition: ${({ theme }) => theme.transitions.default};
  }

  &:hover {
    ${NavigationBarLinkLabel}, svg path {
      opacity: 0.7;
    }
  }
  &[disabled] {
    pointer-events: none;
    ${NavigationBarLinkLabel}, svg path {
      opacity: 0.35;
    }
  }
`;
const NavItem = css`
  align-items: center;
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
export const NavigationWithDropdownLabel = createStyledComponent<'div', any>('div')`
  ${NavItemBase};
  ${NavItem};
  &:hover {
    ${NavigationBarLinkLabel}, svg path {
      opacity: 0.7;
    }
  }
`;
NavigationWithDropdownLabel.displayName = 'NavigationWithDropdownLabel';

export const NavigationWithDropdownContainer = createStyledComponent<'div', any>('div')`
display: flex;
flex-direction: column;
justify-content: center;
cursor: pointer;
`;
NavigationWithDropdownContainer.displayName = 'NavigationWithDropdownContainer';

export const NavigationBarLink = styled(NavLink)`
  ${NavItemBase};
  ${NavItem};
`;
NavigationBarLink.displayName = 'NavigationBarLink';

export const NavigationBarModalLink = createStyledComponent<'span', any>('span')`
  ${NavItemBase};
  ${NavItem};
  cursor: pointer;
`;
NavigationBarModalLink.displayName = 'NavigationBarModalLink';

export const Divider = styled(Span)`
  border-right: 1px solid ${({ theme }) => theme.colors.blueMarineAlpha20};
  height: ${convertPxToRem(15)};
`;
Divider.displayName = 'Divider';

export const NavigationBarProfileContainer = styled(AnimationHelper)`
  animation: ${({ theme }) =>
    `${theme.animations.slideOnscreenFadeIn} ${transitionSpeed.slow} ${transitionEasing.enter}`};
  display: -ms-flexbox;
`;
NavigationBarProfileContainer.displayName = 'NavigationBarProfileContainer';

export const NavigationBarProfileLink = styled(NavLink)`
  ${NavItemBase};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.p};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.small};
  position: relative;
  text-transform: uppercase;
  z-index: ${({ theme }) => theme.zIndexes.one};
`;
NavigationBarProfileLink.displayName = 'NavigationBarProfileLink';

export const LogoutIconShadow = styled(Div)`
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.3));
  transition: ${({ theme }) => theme.transitions.default};


  &:hover {
    filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.7));

`;
LogoutIconShadow.displayName = 'LogoutIconShadow';

export const PopoverListItem = styled.li``;
PopoverListItem.displayName = 'PopoverListItem';

export const PopoverListItemExternalLink = styled.a`
  border-left: 3px solid ${props => props.theme.colors.clear};
  color: ${props => props.theme.colors.grayDark};
  display: block;
  padding: 1rem 21px;
  padding-bottom: 10px;
  text-decoration: none;
  text-transform: capitalize;
  transition: 'all 187.5ms cubic-bezier(.4, 0, .2, 1)';
  width: 100%;

  &:hover {
    background-color: ${props => props.theme.colors.silverLight};
    color: ${props => props.theme.colors.charcoal};
  }

  &:active,
  &.selected {
    border-color: ${props => props.theme.colors.brandBlue};
    color: ${props => props.theme.colors.brandBlue};
  }
`;
PopoverListItemExternalLink.displayName = 'PopoverListItemExternalLink';

export const RowWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;
RowWrapper.displayName = 'RowWrapper';

export const PopoverListItemLinkTextSpan = styled.span`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  text-decoration: none;
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.grayDark};
  }
`;
PopoverListItemLinkTextSpan.displayName = 'PopoverListItemLinkTextSpan';

export const PopoverContainerDiv = styled.div`
  position: relative;
  top: 5px;
  z-index: ${zIndexes.popover};
`;

export const PopoverRectangleContainerTop = styled.div`
  background-color: #004d99;
  border-radius: 8px 8px 0 0;
  height: 4px;
  left: -0.8rem;
  position: absolute;
  width: 208px;
  box-shadow: 0 0.25rem 0.375rem 0.0625rem rgb(74 74 74 / 14%),
    0 0.0625rem 0.375rem 0.0625rem rgb(74 74 74 / 12%), 0 0.125rem 0.125rem 0 rgb(74 74 74 / 20%);
  display: inline-block;
  margin: 6px;
  margin-right: 35px;

  animation: bcCCNc 187.5ms cubic-bezier(0, 0, 0.2, 1) backwards;

  &:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 7px solid #004d99;
    position: absolute;
    top: -5px;
    left: 13.5px;
  }
`;

export const PopoverRectangleContainer = styled.div`
  background-color: white;
  border-radius: 0 0 8px 8px;
  left: -0.8rem;
  position: absolute;
  width: 208px;
  box-shadow: 0 0.25rem 0.375rem 0.0625rem rgb(74 74 74 / 14%),
    0 0.0625rem 0.375rem 0.0625rem rgb(74 74 74 / 12%), 0 0.125rem 0.125rem 0 rgb(74 74 74 / 20%);
  display: inline-block;
  margin: 6px;
  margin-right: 35px;
  margin-top: 0;
  padding-bottom: 8px;
  top: 10px;

  animation: bcCCNc 187.5ms cubic-bezier(0, 0, 0.2, 1) backwards;
`;

export const PopoverListItemNoLink = Div.extend`
  border-left: 3px solid ${props => props.theme.colors.clear};
  color: ${props => props.theme.colors.grayDark};
  display: block;
  padding: 1rem 21px;
  padding-bottom: 10px;
  text-decoration: none;
  text-transform: capitalize;
  transition: 'all 187.5ms cubic-bezier(.4, 0, .2, 1)';
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.silverLight};
    color: ${props => props.theme.colors.charcoal};
  }

  &:active,
  &.selected {
    border-color: ${props => props.theme.colors.brandBlue};
    color: ${props => props.theme.colors.brandBlue};
  }
`;
PopoverListItemNoLink.displayName = 'PopoverListItemNoLink';

export const PopoverList = styled.ul`
  list-style: none;
  padding: 0;
  color: ${props => props.theme.colors.grayDark};
  font-weight: ${props => props.theme.fontWeights.semiBold};
  font-size: ${props => props.theme.fontSize.p};
  display: inline-block;
  width: 100%;
  padding-left: 0;
`;
PopoverList.displayName = 'PopoverList';
