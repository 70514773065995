import { AUTHENTICATION_ROUTES } from '../authentication/authentication.navigation.constants';
import { DASHBOARD_ROUTES } from '../patient-dashboard/patient-dashboard.navigation.constants';
import { PROFILE_ROUTES } from '../profile/profile.navigation.constants';

import { NavigationRoutesState } from './navigation.types';

export const navigationRoutesInitialState: NavigationRoutesState = {
  authentication: AUTHENTICATION_ROUTES,
  dashboard: DASHBOARD_ROUTES,
  profile: PROFILE_ROUTES,
};
