import { pipe, values } from 'ramda';

import { now, subtractDays, toJSDate, toStartOfDay } from 'src/utils/dates';

import { PatientDashboardDateRangeFilters } from './store/date-filters.types';

const todayDateTime = toStartOfDay(now());

const TODAY = toJSDate(todayDateTime);

const WEEK = pipe(
  subtractDays(7),
  toJSDate,
)(todayDateTime);

const TWO_WEEKS = pipe(
  subtractDays(15),
  toJSDate,
)(todayDateTime);

const MONTH = pipe(
  subtractDays(30),
  toJSDate,
)(todayDateTime);

const TWO_MONTHS = pipe(
  subtractDays(60),
  toJSDate,
)(todayDateTime);

export const DATE_RANGE_EARLIEST = TWO_MONTHS;
export const DATE_RANGE_LATEST = TODAY;
export const DATE_RANGE_TODAY = TODAY;

const DateRangeFilters = {
  [PatientDashboardDateRangeFilters.TODAY]: {
    id: PatientDashboardDateRangeFilters.TODAY,
    label: 'dateFilter.selectDate.today',
    startDate: TODAY,
    endDate: TODAY,
  },
  [PatientDashboardDateRangeFilters.WEEK]: {
    id: PatientDashboardDateRangeFilters.WEEK,
    label: 'dateFilter.selectDate.seven',
    startDate: WEEK,
    endDate: TODAY,
  },
  [PatientDashboardDateRangeFilters.TWO_WEEKS]: {
    id: PatientDashboardDateRangeFilters.TWO_WEEKS,
    label: 'dateFilter.selectDate.fifteen',
    startDate: TWO_WEEKS,
    endDate: TODAY,
  },
  [PatientDashboardDateRangeFilters.MONTH]: {
    id: PatientDashboardDateRangeFilters.MONTH,
    label: 'dateFilter.selectDate.thirty',
    startDate: MONTH,
    endDate: TODAY,
  },
};

export const DASHBOARD_DATE_RANGE_FILTERS = values(DateRangeFilters);
export const DEFAULT_DATE_RANGE_FILTER = DateRangeFilters[PatientDashboardDateRangeFilters.TODAY];
