import { Action, PayloadAction } from 'src/app/store/app.types';

export enum MessageBroadcastActionTypes {
  BROADCAST_MESSAGE = 'BROADCAST_MESSAGE',
}

export type BroadcastMessage = PayloadAction<
  MessageBroadcastActionTypes.BROADCAST_MESSAGE,
  Action<any> | PayloadAction<any, any>
>;
