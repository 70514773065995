import * as React from 'react';
import { colors } from 'src/theme/colors';

import { SvgIcon } from '../icon.component';
import { DEFAULT_ICON_COLOR } from '../icon.constants';
import { calculateAspectRatio } from '../icon.utils';
type IconProps = {
  height?: number;
  fillColor?: string;
  strokeColor?: string;
  strokeWeight?: number;
};
export const BlockIcon: React.FunctionComponent<IconProps> = ({
  height = 10,
  fillColor = colors.clear,
  strokeColor = DEFAULT_ICON_COLOR,
  strokeWeight = 1.5,
}) => {
  const originalWidth = 10;
  const originalHeight = 10;
  const aspectRatio: any = calculateAspectRatio(originalWidth, originalHeight);
  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g>
        <path
          fill={strokeColor}
          d="M5,10 C2.23857625,10 0,7.76142375 0,5 C0,2.23857625 2.23857625,0 5,0 C7.76142375,0 10,2.23857625 10,5 C10,7.76142375 7.76142375,10 5,10 Z M5,8.4375 C6.89847883,8.4375 8.4375,6.89847883 8.4375,5 C8.4375,3.10152117 6.89847883,1.5625 5,1.5625 C3.10152117,1.5625 1.5625,3.10152117 1.5625,5 C1.5625,6.89847883 3.10152117,8.4375 5,8.4375 Z"
        />
        <path stroke={strokeColor} strokeLinecap="square" strokeWidth="1.5" d="M2,7.8 L7.4,2.6" />
      </g>
    </SvgIcon>
  );
};
