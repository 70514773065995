import * as React from 'react';

import { SvgIcon } from '../icon.component';
import { DEFAULT_ICON_COLOR } from '../icon.constants';
import { calculateAspectRatio } from '../icon.utils';

type IconProps = {
  height?: number;
  strokeColor?: string;
  strokeWeight?: number;
};

export const PauseIcon: React.FunctionComponent<IconProps> = ({
  height = 9,
  strokeColor = DEFAULT_ICON_COLOR,
  strokeWeight = 1.5,
}) => {
  const originalWidth = 7;
  const originalHeight = 9;
  const aspectRatio = calculateAspectRatio(originalWidth, originalHeight);

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <path
        stroke={strokeColor}
        strokeWidth={strokeWeight}
        strokeLinecap="round"
        d="M1.5 1.5v6M5.273 1.5v6"
      />
    </SvgIcon>
  );
};
