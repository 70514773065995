import { combineEpics, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';

import { State } from 'src/app/store/app.types';
import { selectAccessToken } from 'src/core/session/oidc/oidc.selector';
import { countryService } from 'src/services/country/country.service';
import { CountryService } from 'src/services/country/country.types';

import { fetchCountriesError, fetchCountriesSuccess } from './countries.actions';
import { CountriesActions, CountriesActionType } from './countries.types';

export const fetchCountriesEpic = (service: CountryService): Epic<CountriesActions, State> => (
  action$,
  store$,
) =>
  action$
    .ofType(CountriesActionType.FETCH_COUNTRIES_START)
    .debounceTime(150)
    .switchMap(() => {
      const accessToken = selectAccessToken(store$.getState());
      return Observable.fromPromise(service({ accessToken }))
        .map(data => fetchCountriesSuccess(data))
        .pipe(catchError(err => Observable.of(fetchCountriesError(err))));
    });

export const countriesEpics = combineEpics(fetchCountriesEpic(countryService));
