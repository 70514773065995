import { Action, PayloadAction } from 'src/app/store/app.types';

export enum PatientDashboardDateRangeFilters {
  TODAY = 'today',
  WEEK = '7 days',
  TWO_WEEKS = '15 days',
  MONTH = '30 days',
  CUSTOM = 'custom',
}

export enum DateRangeFilterActionType {
  SET_DATE_RANGE_FILTER = 'SET_DATE_RANGE_FILTER',
  RESET_DATE_RANGE_FILTER = 'RESET_DATE_RANGE_FILTER',
}

export type SelectDateRangeFilterPayload = {
  readonly filterId: PatientDashboardDateRangeFilters;
  readonly endDate: Date;
  readonly startDate: Date;
};

export type DateRangeFilterPayload = {
  readonly filterId: PatientDashboardDateRangeFilters;
  readonly endDate: string;
  readonly startDate: string;
};

export type DateFilterState = {
  readonly activeFilterId: PatientDashboardDateRangeFilters;
  readonly endDate: string;
  readonly startDate: string;
};

export type SetDateRangeFilterAction = PayloadAction<
  DateRangeFilterActionType.SET_DATE_RANGE_FILTER,
  DateRangeFilterPayload
>;

export type ResetDateRangeFilterAction = Action<DateRangeFilterActionType.RESET_DATE_RANGE_FILTER>;

export type DateRangeFilterActions = SetDateRangeFilterAction | ResetDateRangeFilterAction;

export type DateRangeFilterReducerActions = DateRangeFilterActions;
