import { ProfileSpacedTitle } from '../../scenes/profile/profile.style';
import styled from 'styled-components';

export const ProfileBadgeName = styled(ProfileSpacedTitle)`
  color: ${({ theme }) => theme.colors.charcoal};
  font-size: ${({ theme }) => theme.fontSize.title};
  text-align: center;
  text-transform: uppercase;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;
ProfileBadgeName.displayName = 'ProfileBadgeName';
