import * as React from 'react';
import { CheckmarkIcon } from 'src/assets/icons';
import { colors } from 'src/theme/colors';

import { Badge } from '../badge.component';

type SuccessBadgeProps = {
  size: number;
  fillColor?: string;
};

export const SuccessBadge: React.FunctionComponent<SuccessBadgeProps> = ({ size, fillColor }) => (
  <Badge
    icon={<CheckmarkIcon strokeWeight={1} height={size * 0.42} strokeColor={colors.white} />}
    bgColor={fillColor || colors.trafficGreen}
    size={size}
    emptyInnerCircle={false}
    disabled={false}
  />
);
