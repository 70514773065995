import { Permissions } from './permissions.types';

export const PERMISSIONS: Permissions = {
  ROLE_ETAPES_PROGRAM: 'ROLE_ETAPES_PROGRAM',
  ROLE_RPM_PROGRAM: 'ROLE_RPM_PROGRAM',
  ROLE_VISIT_MANAGEMENT: 'ROLE_VISIT_MANAGEMENT',
  PATIENT: 'ROLE_PATIENT',
  DATA_DOWNLOAD: 'ROLE_DATA_DOWNLOAD',
  GLUCOSE_STATISTICS: 'ROLE_EXPORT_GLUCOSE_STATISTICS',
  STRIP_MNG_PATIENT: 'ROLE_STRIP_MNG_PATIENT',
  HOME_DELIVERY_PATIENT: 'ROLE_DELIVERY_MGMT_PATIENT',
  ELECTRONIC_MEDICAL_RECORD: 'ROLE_ELECTRONIC_MEDICAL_RECORD',
};
