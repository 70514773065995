import * as React from 'react';

import { styleAvatarBadgeComponent } from './avatar.style';

import { AvatarStyleProps } from './avatar.types';

const AvatarBadgeComponent: React.FunctionComponent<AvatarStyleProps> = ({
  isReversed,
  ...props
}) => <div {...props} />;
export const AvatarBadge = styleAvatarBadgeComponent(AvatarBadgeComponent);
