import { Dispatch, Middleware, MiddlewareAPI } from 'redux';

import { MessageBroadcastActionTypes } from './message-broadcast.types';
import sourceId from './source-id';

export const STORAGE_KEY = 'BROADCAST-MESSAGE';

export const wrapAction = action => {
  return {
    action,
    sourceId,
    time: Date.now(),
  };
};

export const eventCallback = (dispatch: Dispatch<any>) => () => {
  const storedValue = localStorage.getItem(STORAGE_KEY);
  if (!storedValue) {
    return;
  }

  const parsedStoredValue = JSON.parse(storedValue);

  if (parsedStoredValue.sourceId !== sourceId) {
    dispatch(parsedStoredValue.action);
  }
};

export const messageBroadcastMiddleware: Middleware = <S>({ dispatch }: MiddlewareAPI<S>) => {
  if (!window) {
    throw Error('Middleware requires a window to sync');
  }

  window.addEventListener('storage', eventCallback(dispatch));

  return (next: Dispatch<S>): Dispatch<S> => (action: any): any => {
    if (action.type === MessageBroadcastActionTypes.BROADCAST_MESSAGE) {
      const stampedAction = wrapAction(action.payload);

      localStorage.setItem(STORAGE_KEY, JSON.stringify(stampedAction));
      // Need to space out the remove item, otherwise storage event doesn't register
      Promise.resolve(localStorage.removeItem(STORAGE_KEY));
    }
    next(action);
  };
};
