import styled from 'styled-components';

import { Div } from 'src/components/div';

export const GradientStrip = styled(Div)`
  position: relative;
  background: rgb(132, 211, 240);
  margin-top: 1em;
  margin-bottom: ${({ theme }) => theme.spacing.four};
  height: 0.3em;
  text-align: center;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(132, 211, 240, 1) 0%,
    rgba(158, 189, 240, 1) 25%,
    rgba(162, 164, 224, 1) 50%,
    rgba(189, 160, 240, 1) 75%,
    rgba(210, 140, 239, 1) 100%
  );
`;
GradientStrip.displayName = 'GradientStrip';

export const WhiteDot = styled(Div)`
  background: white;
  width: 1.1em;
  height: 1.1em;
  border-radius: 50%;
  display: flex;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
`;
WhiteDot.displayName = 'WhiteDot';

export const WhiteRing = styled(Div)`
  border: 0.2em solid white;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  box-shadow: inset 0 0 0.8em 0.2em rgba(0, 0, 0, 0.15);
`;
WhiteRing.displayName = 'WhiteRing';

export const OuterShadow = styled(Div)`
  width: 1.85em;
  height: 1.85em;
  border-radius: 50%;
  display: flex;
  box-shadow: 0 0 0.8em 0.2em rgba(0, 0, 0, 0.1);
`;
OuterShadow.displayName = 'OuterShadow';
