import { isEmpty } from 'ramda';
import * as React from 'react';

import { WithPermissions } from 'src/components/with-permissions/with-permissions.container';

import { PermissionsGuardProps } from './route-guard.types';

export const PermissionsGuard: React.FunctionComponent<PermissionsGuardProps> = ({
  hasPermissions,
  onError = ({ children }: any) => children,
  onSuccess = ({ children }: any) => children,
  onVerifying = () => null,
  ...props
}) => {
  if (!hasPermissions || isEmpty(hasPermissions)) {
    return onSuccess(props);
  }
  return (
    <WithPermissions
      hasPermissions={hasPermissions}
      onRender={() => onSuccess(props)}
      onAccessDenied={() => onError(props)}
    />
  );
};
