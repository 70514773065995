import { createAction, createPayloadAction } from 'src/app/store/app.actions';

import {
  FetchOidcTokensError,
  FetchOidcTokensStart,
  FetchOidcTokensSuccess,
  OidcActionTypes,
  OidcTokensPayload,
  RefreshOidcTokensError,
  RefreshOidcTokensStart,
  RefreshOidcTokensSuccess,
} from './oidc.types';

export const fetchOidcTokensStart = (tokenCode: string): FetchOidcTokensStart =>
  createPayloadAction(OidcActionTypes.OIDC_FETCH_TOKENS_START, tokenCode);

export const fetchOidcTokensSuccess = (payload: OidcTokensPayload): FetchOidcTokensSuccess =>
  createPayloadAction(OidcActionTypes.OIDC_FETCH_TOKENS_SUCCESS, payload);

export const fetchOidcTokensError = (error: any): FetchOidcTokensError =>
  createPayloadAction(OidcActionTypes.OIDC_FETCH_TOKENS_ERROR, error);

export const refreshOidcTokensStart = (): RefreshOidcTokensStart =>
  createAction(OidcActionTypes.REFRESH_OIDC_TOKENS_START);

export const refreshOidcTokensError = (error: any): RefreshOidcTokensError =>
  createPayloadAction(OidcActionTypes.REFRESH_OIDC_TOKENS_ERROR, error);

export const refreshOidcTokensSuccess = (payload: OidcTokensPayload): RefreshOidcTokensSuccess =>
  createPayloadAction(OidcActionTypes.REFRESH_OIDC_TOKENS_SUCCESS, payload);
