import { createSelector, createStructuredSelector } from 'reselect';

import { State } from 'src/app/store/app.types';
import { getIn, propOr } from 'src/utils';

export const selectStripsStock = (state: State) => getIn(['stripsStock'], state);

export const selectStripsStockRemaining = createSelector([selectStripsStock], stripsStockData =>
  propOr(undefined, 'remaining', stripsStockData),
);

export const selectStripsStockStartAmount = createSelector([selectStripsStock], stripsStockData =>
  propOr(undefined, 'startAmount', stripsStockData),
);

export const stripsStockConnector = createStructuredSelector({
  remaining: selectStripsStockRemaining,
  startAmount: selectStripsStockStartAmount,
});
