import * as React from 'react';
import { SvgIcon } from '../icon.component';

export const CECodeIcon = () => {
  const originalWidth = 32;
  const originalHeight = 32;
  return (
    <SvgIcon
      width={originalWidth}
      height={originalHeight}
      minX={0}
      minY={0}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.49 2.67L13.5091 2.67002V5.01513C10.1038 5.02082 7.34498 7.78315 7.34498 11.1898C7.34498 14.5964 10.1038 17.3587 13.5091 17.3644V19.6503H13.49C8.80107 19.6503 4.99991 15.8491 4.99991 11.1601C4.99991 6.47116 8.80107 2.67 13.49 2.67ZM26.6112 2.67002L26.5921 2.67C21.9032 2.67 18.102 6.47116 18.102 11.1601C18.102 15.8491 21.9032 19.6503 26.5921 19.6503H26.6112V17.3644C23.4566 17.3591 20.8569 14.9883 20.4911 11.931H25.6948V9.62936H20.6459C21.3363 6.97797 23.7447 5.01992 26.6112 5.01513V2.67002Z"
        fill="#333333"
      />
      <path
        d="M7.34585 21.9658C8.04569 21.9658 8.59275 22.2155 8.98702 22.7149C9.45687 23.3063 9.6918 24.287 9.6918 25.6572C9.6918 27.024 9.45523 28.0064 8.9821 28.6044C8.59111 29.0972 8.04569 29.3437 7.34585 29.3437C6.64273 29.3437 6.07596 29.0742 5.64554 28.5354C5.21512 27.9933 4.99991 27.0289 4.99991 25.6424C4.99991 24.2821 5.23647 23.303 5.70961 22.705C6.10059 22.2122 6.64602 21.9658 7.34585 21.9658ZM7.34585 23.1141C7.17828 23.1141 7.02879 23.1683 6.89736 23.2767C6.76593 23.3818 6.66408 23.5724 6.5918 23.8484C6.49651 24.2065 6.44887 24.8095 6.44887 25.6572C6.44887 26.5049 6.49159 27.0881 6.57701 27.4068C6.66244 27.7222 6.76922 27.9325 6.89736 28.0376C7.02879 28.1427 7.17828 28.1954 7.34585 28.1954C7.51342 28.1954 7.66292 28.1427 7.79434 28.0376C7.92576 27.9292 8.02762 27.737 8.0999 27.461C8.19519 27.1062 8.24283 26.5049 8.24283 25.6572C8.24283 24.8095 8.20012 24.2279 8.11469 23.9125C8.02927 23.5938 7.92084 23.3818 7.78941 23.2767C7.66127 23.1683 7.51342 23.1141 7.34585 23.1141Z"
        fill="#333333"
      />
      <path
        d="M14.3485 29.2204H12.9636V24.0012C12.4576 24.4744 11.8613 24.8242 11.1746 25.051V23.7942C11.536 23.6759 11.9286 23.4525 12.3525 23.124C12.7763 22.7921 13.067 22.406 13.2248 21.9658H14.3485V29.2204Z"
        fill="#333333"
      />
      <path
        d="M20.6601 27.9341V29.2204H15.8055C15.8581 28.7342 16.0159 28.2742 16.2787 27.8405C16.5416 27.4035 17.0607 26.8252 17.8361 26.1056C18.4603 25.5241 18.8432 25.1298 18.9844 24.9228C19.175 24.637 19.2703 24.3544 19.2703 24.0752C19.2703 23.7663 19.1865 23.5297 19.0189 23.3655C18.8547 23.1979 18.6263 23.1141 18.3338 23.1141C18.0447 23.1141 17.8147 23.2012 17.6439 23.3753C17.473 23.5494 17.3744 23.8386 17.3482 24.2427L15.9682 24.1047C16.0504 23.3425 16.3082 22.7954 16.742 22.4635C17.1757 22.1317 17.7178 21.9658 18.3683 21.9658C19.0813 21.9658 19.6415 22.158 20.049 22.5424C20.4564 22.9268 20.6601 23.4048 20.6601 23.9765C20.6601 24.3019 20.6009 24.6124 20.4827 24.9081C20.3677 25.2005 20.1837 25.5077 19.9307 25.8297C19.7631 26.0433 19.4609 26.3505 19.0238 26.7513C18.5868 27.1522 18.3092 27.4183 18.191 27.5497C18.076 27.6811 17.9823 27.8093 17.91 27.9341H20.6601Z"
        fill="#333333"
      />
      <path
        d="M21.9802 27.3033L23.3207 27.1407C23.3635 27.4823 23.4785 27.7435 23.6657 27.9243C23.853 28.105 24.0797 28.1954 24.3458 28.1954C24.6317 28.1954 24.8715 28.0869 25.0654 27.87C25.2625 27.6532 25.3611 27.3608 25.3611 26.9928C25.3611 26.6445 25.2675 26.3685 25.0802 26.1648C24.8929 25.9611 24.6645 25.8593 24.3952 25.8593C24.2177 25.8593 24.0058 25.8938 23.7593 25.9628L23.9122 24.8341C24.2867 24.844 24.5725 24.7635 24.7697 24.5926C24.9669 24.4185 25.0654 24.1885 25.0654 23.9027C25.0654 23.6595 24.9931 23.4657 24.8485 23.3211C24.704 23.1765 24.5118 23.1042 24.2719 23.1042C24.0353 23.1042 23.8333 23.1864 23.6657 23.3507C23.4981 23.5149 23.3963 23.7548 23.3601 24.0702L22.0837 23.8534C22.1724 23.4163 22.3055 23.0681 22.4829 22.8085C22.6636 22.5456 22.9133 22.3403 23.232 22.1925C23.554 22.0413 23.9138 21.9658 24.3113 21.9658C24.9915 21.9658 25.5369 22.1826 25.9476 22.6163C26.286 22.9712 26.4553 23.372 26.4553 23.8189C26.4553 24.453 26.1086 24.959 25.4153 25.3368C25.8293 25.4256 26.1595 25.6243 26.4059 25.9332C26.6556 26.242 26.7805 26.615 26.7805 27.0519C26.7805 27.686 26.5489 28.2265 26.0856 28.6734C25.6223 29.1202 25.0457 29.3437 24.3557 29.3437C23.7018 29.3437 23.1598 29.1564 22.7293 28.7818C22.2989 28.404 22.0492 27.9111 21.9802 27.3033Z"
        fill="#333333"
      />
    </SvgIcon>
  );
};
