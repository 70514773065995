import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { locales } from '@roche/rdcp-pp-client-translations';
import { FALLBACK_LOCALE } from 'src/i18n/constants';

export { translate } from './translate';

i18n.use(LanguageDetector).init({
  fallbackLng: FALLBACK_LOCALE,
  ns: ['translations'],
  defaultNS: 'translations',
  resources: locales,
  react: {
    wait: true,
    bindI18n: 'languageChanged loaded',
    bindI18nStore: 'added removed',
    nsMode: 'default',
  },
});

export default i18n;
