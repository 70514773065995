import { all, isEmpty, prop } from 'ramda';
import { createSelector } from 'reselect';

import { State } from 'src/app/store/app.types';
import { defaultAccountInfo, defaultData, defaultProfile } from 'src/core/user/user.constants';
import { isNotNil } from 'src/utils';
import { getIn, hasValue, pathOr, propOr } from 'src/utils/ramda';

export const selectUser = (state: State) => getIn(['user'], state);

export const selectAccountInfo = createSelector([selectUser], user =>
  propOr(defaultAccountInfo, 'accountInfo', user),
);

export const selectUID = createSelector([selectAccountInfo], user => prop('UID', user));

export const selectUnverifiedLoginEmails = createSelector([selectAccountInfo], accountInfo =>
  pathOr([], ['loginIDs', 'unverifiedEmails'], accountInfo),
);

export const selectVerifiedLoginEmails = createSelector([selectAccountInfo], accountInfo =>
  pathOr([], ['loginIDs', 'emails'], accountInfo),
);

export const selectLoginEmails = createSelector(
  [selectUnverifiedLoginEmails, selectVerifiedLoginEmails],
  (unverified, verified) => [...verified, ...unverified],
);

export const selectData = createSelector([selectAccountInfo], accountInfo =>
  propOr(defaultData, 'data', accountInfo),
);

export const selectProfile = createSelector(selectAccountInfo, profile =>
  propOr(defaultProfile, 'profile', profile),
);

export const selectHCPIsActive = createSelector([selectData], data =>
  propOr(false, 'HCPIsAccessible', data),
);

export const selectHCPIsAccessible = createSelector([selectData], data =>
  propOr(false, 'HCPIsActive', data),
);

export const selectEmail = createSelector([selectProfile], profile => prop('email', profile));

export const selectFirstName = createSelector([selectProfile], profile =>
  prop('firstName', profile),
);

export const selectLastName = createSelector([selectProfile], profile => prop('lastName', profile));

export const selectFullName = createSelector(
  [selectFirstName, selectLastName],
  (firstName, lastName) => (all(hasValue)([firstName, lastName]) ? `${firstName} ${lastName}` : ''),
);

export const selectBirthDay = createSelector([selectProfile], profile => prop('birthDay', profile));

export const selectBirthMonth = createSelector([selectProfile], profile =>
  prop('birthMonth', profile),
);

export const selectBirthYear = createSelector([selectProfile], profile =>
  prop('birthYear', profile),
);

export const selectGender = createSelector([selectProfile], profile => prop('gender', profile));

export const selectCountry = createSelector([selectData], user =>
  pathOr('', ['billAddressCountry'], user),
);

export const selectCountryCode = createSelector([selectData], user =>
  pathOr('', ['billAddressCountryCode'], user),
);

export const selectAddress = createSelector([selectData], user =>
  pathOr('', ['billAddressStreet'], user),
);

export const selectPostalCode = createSelector([selectData], user =>
  pathOr('', ['billAddressPostalCode'], user),
);

export const selectCity = createSelector([selectData], user =>
  pathOr('', ['billAddressCity'], user),
);

export const selectProvince = createSelector([selectData], user =>
  pathOr('', ['billAddressProvince'], user),
);

export const selectLanguage = createSelector([selectProfile], user =>
  pathOr('-', ['languages'], user),
);

export const selectPhoneNumber = createSelector([selectProfile], user => {
  const phones = getIn(['phones'], user);
  return !isEmpty(phones) && isNotNil(phones) ? phones[0].number : '';
});

export const selectPhotoUrl = createSelector([selectProfile], user => propOr('', 'photoURL', user));

export const selectHealthCareId = createSelector([selectData], details =>
  propOr('', 'nationalHealthNumber', details),
);

export const selectFHIRPatientId = createSelector([selectData], details =>
  propOr('', 'FHIR_Patient_ID', details),
);

export const SELECT_LOCALE_DEFAULT = '-';

export const selectLocale = createSelector([selectProfile], user =>
  pathOr(SELECT_LOCALE_DEFAULT, ['locale'], user),
);
