import styled, { css } from 'styled-components';

// TODO: Refactor using theming interface
const LibraryDatePickerOverrides = css`
  .SingleDatePicker_picker {
    position: relative;
    top: 0 !important;
  }

  .DayPicker__withBorder {
    border: none !important;
    box-shadow: none !important;
  }
`;

export const SingleDatePickerContainer = styled.div`
  .SingleDatePicker {
    display: block;
    margin: 0 auto;
  }

  .SingleDatePickerInput .DateInput {
    display: none;
  }

  .DayPicker__horizontal {
    margin: 0 auto;
  }

  ${LibraryDatePickerOverrides};
`;
SingleDatePickerContainer.displayName = 'SingleDatePickerContainer';
