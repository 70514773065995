import { combineEpics, Epic } from 'redux-observable';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { State } from 'src/app/store/app.types';
import { SessionActions, SessionActionType } from 'src/core/session/session.types';
import { UIActions } from 'src/ui/store/ui.types';
import { actionTypeFilter } from 'src/utils/rxjs';

import { hideAppNavigation, showAppNavigation } from './ui.actions';

type NavigationEpicActions = SessionActions | UIActions;

const showNavigationBarPipe = pipe(
  filter(actionTypeFilter(SessionActionType.VALIDATE_SESSION_SUCCESS)),
  map(showAppNavigation),
);

export const showNavigationBarEpic: Epic<NavigationEpicActions, State> = actions$ =>
  showNavigationBarPipe(actions$);

const hideNavigationBarPipe = pipe(
  filter(actionTypeFilter(SessionActionType.VALIDATE_SESSION_ERROR)),
  map(hideAppNavigation),
);

export const hideNavigationBarEpic: Epic<NavigationEpicActions, State> = actions$ =>
  hideNavigationBarPipe(actions$);

export const uiEpics = combineEpics(showNavigationBarEpic, hideNavigationBarEpic);
