import styled from 'styled-components';
import { flex, justifyContent, space } from 'styled-system';

import login from 'src/assets/images/login.jpg';
import { Block } from 'src/components/block';
import { Column } from 'src/components/column';
import { constructHeroGradient } from 'src/theme/colors';
import { convertPxToRem } from 'src/utils/styles';

export const LogoDiv = styled.div`
  position: absolute;
  padding-left: ${props => props.theme.spacing.four};
  padding-top: ${props => props.theme.spacing.four};
`;
LogoDiv.displayName = 'LogoDiv';

export const PageBody = styled.div`
  display: flex;
  min-height: 100vh;
  height: 100%;
`;
PageBody.displayName = 'PageBody';

export const ErrorBlock = styled(Block)`
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 6rem;
`;
ErrorBlock.displayName = 'ErrorBlock';

export const LoginColumn = styled(Column)`
  background-color: ${props => props.theme.colors.white};
  flex: 4;
  justify-content: center;
  padding-top: ${props => props.theme.spacing.five};
`;
LoginColumn.displayName = 'LoginColumn';

export const FormColumn = styled(Column)`
  background-color: ${props => props.theme.colors.white};
  display: flex;
  flex: 4;
  align-items: center;
  padding-left: ${props => props.theme.spacing.six};
  ${flex};
  ${justifyContent};
  ${space};
`;
FormColumn.displayName = 'FormColumn';

export const MainContent = styled.div`
  background-color: ${props => props.theme.colors.white};
`;
MainContent.displayName = 'MainContent';

export const DescriptionColumn = styled(Column)`
  display: flex;
  flex: 4;
  position: relative;
`;
DescriptionColumn.displayName = 'DescriptionColumn';

export const DescriptionContainer = styled(Column)`
  background-image: url(${props => props.backgroundImage || login});
  background-size: auto 158%;
  height: 100%;
  justify-content: center;

  &::after {
    position: absolute;
    background-image: ${constructHeroGradient(147)};
    content: '';
    display: block;
    height: 100%;
    opacity: 0.28;
    width: 100%;
    z-index: 1;
    top: 0;
  }
`;
DescriptionContainer.displayName = 'DescriptionContainer';

export const DescriptionContent = styled.div`
  padding-left: ${props => props.theme.spacing.five};
  padding-top: ${props => props.theme.spacing.five};
  position: relative;
  min-height: ${convertPxToRem(240)};
  z-index: 2;
`;
DescriptionContent.displayName = 'DescriptionContent';

export const DescriptionHeader = styled.h1`
  color: ${props => props.theme.colors.brandBlue};
  font-size: ${props => props.theme.fontSize.headline};
  font-weight: ${props => props.theme.fontWeights.semiBold};
  margin: 0 0 ${props => props.theme.spacing.four} ${props => props.theme.spacing.four};
`;
DescriptionHeader.displayName = 'DescriptionHeader';

export const SubdescriptionHeader = styled.h2`
  color: ${props => props.theme.colors.charcoal};
  font-size: ${props => props.theme.fontSize.title};
  font-weight: ${props => props.theme.fontWeights.regular};
  line-height: 1.6em;
  margin: 0 0 ${props => props.theme.spacing.four};
  max-width: ${convertPxToRem(360)};
`;
SubdescriptionHeader.displayName = 'SubdescriptionHeader';
