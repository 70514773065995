const storageKey = 'redux-local-tab-sync';

export const LISTENER_TYPES = [
  'persist/PERSIST',
  'SIGN_OUT_START',
  'REMOVE_ALL_MODALS',
  'SHOW_EXPIRING_MODAL',
  'OIDC_FETCH_TOKENS_SUCCESS',
  'REFRESH_OIDC_TOKENS_SUCCESS',
  'VALIDATE_SESSION_SUCCESS',
];

export const storageMiddleware = () => {
  return () => next => action => {
    if (!action.source && !!LISTENER_TYPES.find(i => i === action.type)) {
      try {
        const wrappedAction = Object.assign({ source: 'another tab' }, action);
        localStorage.setItem(storageKey, JSON.stringify(wrappedAction));
      } catch (error) {
        console.log(error);
      }
    }
    next(action);
  };
};

export const storageListener = store => {
  return () => {
    if (!!localStorage.getItem(storageKey)) {
      const wrappedAction = JSON.parse(`${localStorage.getItem(storageKey)}`);
      delete wrappedAction.source;
      store.dispatch(wrappedAction);
      localStorage.removeItem(storageKey);
    }
  };
};
