import * as React from 'react';

import { SvgIcon } from '../icon.component';
import { DEFAULT_ICON_COLOR } from '../icon.constants';
import { calculateAspectRatio } from '../icon.utils';

type IconProps = {
  height?: number;
  fillColor?: string;
};

export const ArrowIcon: React.FunctionComponent<IconProps> = ({
  height = 18,
  fillColor = DEFAULT_ICON_COLOR,
}) => {
  const originalWidth = 21;
  const originalHeight = 12;
  const aspectRatio: any = calculateAspectRatio(originalWidth, originalHeight);

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <path
        d="M20.141.35c-.463-.464-1.271-.466-1.733 0l-8.159 8.174L2.093.349C1.63-.115.823-.117.36.349a1.223 1.223 0 0 0 0 1.736l9.023 9.044c.228.229.544.36.866.36.328 0 .636-.128.868-.36l9.024-9.043a1.228 1.228 0 0 0 0-1.736"
        fill={fillColor}
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};
