import * as React from 'react';

import { SvgIcon } from 'src/assets/icons/icon.component';
import { calculateAspectRatio } from 'src/assets/icons/icon.utils';

type IconProps = {
  height?: number;
};

export const DTCConnect: React.FunctionComponent<IconProps> = ({ height = 101 }) => {
  const originalWidth = 451;
  const originalHeight = 101;
  const aspectRatio = calculateAspectRatio(originalWidth, originalHeight);

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <defs>
        <filter
          x="-121%"
          y="-1077.4%"
          width="341.9%"
          height="2254.9%"
          filterUnits="objectBoundingBox"
          id="a"
        >
          <feGaussianBlur stdDeviation="21.7728 0" in="SourceGraphic" />
        </filter>
        <filter
          x="-51.8%"
          y="-1077.4%"
          width="203.7%"
          height="2254.9%"
          filterUnits="objectBoundingBox"
          id="b"
        >
          <feGaussianBlur stdDeviation="21.7728 0" in="SourceGraphic" />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g fill="#06C" fillRule="nonzero">
          <path d="M225.846 37.151c0 3.428-2.995 6.217-6.678 6.217h-38.052V41h38.052c2.332 0 4.158-1.69 4.158-3.849 0-2.158-1.825-3.849-4.158-3.849h-43.344c-3.682 0-6.678-2.788-6.678-6.217 0-3.428 2.996-6.217 6.678-6.217h38.052v2.369h-38.052c-2.331 0-4.158 1.69-4.158 3.848 0 2.159 1.827 3.849 4.158 3.849h43.344c3.683 0 6.678 2.789 6.678 6.217z" />
          <path d="M175.373 39.905h-.029v3.74h.029c.066.35.284.623.547.623h3.246c.262 0 .48-.272.546-.623.013-.066.03-.13.03-.2v-3.339c0-.07-.017-.135-.03-.2-.065-.352-.284-.624-.546-.624h-3.246c-.263 0-.481.272-.547.623zm-.657 0h-3.77v3.74h3.77v-3.74zm-1.885 1.246v-.623h.628v.623h-.628zm0 1.87v-.623h.628v.624h-.628zM219.414 23.845h.03v-3.74h-.03c-.065-.351-.284-.624-.546-.624h-3.246c-.262 0-.48.273-.546.624-.013.065-.03.13-.03.2v3.339c0 .07.017.135.03.2.065.351.284.624.546.624h3.246c.262 0 .481-.272.546-.623zm.658 0h3.77v-3.74h-3.77v3.74zm1.885-1.247v.624h-.628v-.624h.628zm0-1.87v.623h-.628v-.623h.628z" />
        </g>
        <g transform="translate(50.346 .168)">
          <path
            d="M10.8.9C5.332.9.9 5.332.9 10.8v59.4c0 5.468 4.432 9.9 9.9 9.9h54c5.468 0 9.9-4.432 9.9-9.9V10.8c0-5.468-4.432-9.9-9.9-9.9h-54z"
            stroke="#06C"
            strokeWidth={1.8}
          />
          <ellipse
            stroke="#06C"
            strokeWidth={1.35}
            fill="#FFF"
            cx={14.58}
            cy={66.024}
            rx={4.185}
            ry={3.944}
          />
          <ellipse
            stroke="#06C"
            strokeWidth={1.35}
            fill="#FFF"
            cx={61.29}
            cy={66.024}
            rx={3.915}
            ry={3.944}
          />
          <ellipse
            stroke="#06C"
            strokeWidth={1.35}
            fill="#FFF"
            cx={38.07}
            cy={65.753}
            rx={6.075}
            ry={5.846}
          />
          <path
            d="M15.3 10.8a4.5 4.5 0 0 0-4.5 4.5v30.6a4.5 4.5 0 0 0 4.5 4.5h45a4.5 4.5 0 0 0 4.5-4.5V15.3a4.5 4.5 0 0 0-4.5-4.5h-45z"
            stroke="#06C"
            strokeWidth={1.8}
          />
          <path
            d="M37.848 19.04a.78.78 0 0 1 .519.239c3.85 4.196 7.533 9.181 7.533 13.994 0 4.784-3.598 8.75-8.1 8.75s-8.1-3.966-8.1-8.75c0-4.813 3.682-9.798 7.533-13.994a.786.786 0 0 1 .615-.24zm-.048 1.95c-3.516 3.954-6.557 8.491-6.557 12.283 0 4.029 2.969 7.218 6.557 7.218 3.588 0 6.557-3.19 6.557-7.218 0-3.792-3.04-8.329-6.557-12.282zm4.291 11.062c.349.036.65.336.687.682.289 2.386-1.25 4.94-3.447 6.129-.357.194-.86.049-1.055-.305-.196-.354-.05-.853.307-1.048 1.6-.865 2.85-3.065 2.664-4.596-.06-.457.383-.91.844-.862z"
            fill="#06C"
            fillRule="nonzero"
          />
        </g>
        <path
          d="M13.55 94.237h54a6.062 6.062 0 0 1-6.062 6.062H19.612a6.062 6.062 0 0 1-6.062-6.062z"
          fill="#E0E8F8"
          filter="url(#a)"
          transform="translate(49.396 -.469)"
        />
        <path
          d="M213.184 94.237h126a6.062 6.062 0 0 1-6.063 6.062H219.246a6.062 6.062 0 0 1-6.062-6.062z"
          fill="#E0E8F8"
          filter="url(#b)"
          transform="translate(49.396 -.469)"
        />
        <path
          d="M283.791 4.668c-4.253 0-6.043.346-7.895 1.336a8.914 8.914 0 0 0-3.714 3.714c-.99 1.852-1.336 3.642-1.336 7.895v45.833c0 2.058.16 2.883.615 3.735a4.007 4.007 0 0 0 1.672 1.672c.852.456 1.677.615 3.736.615h94.155c2.059 0 2.883-.16 3.735-.615a4.007 4.007 0 0 0 1.672-1.672c.456-.852.615-1.677.615-3.735V17.613c0-4.253-.345-6.043-1.336-7.895a8.914 8.914 0 0 0-3.713-3.714c-1.852-.99-3.642-1.336-7.896-1.336h-80.31z"
          stroke="#06C"
          strokeWidth={1.8}
        />
        <path
          d="M286.769 14.568c-2.059 0-2.884.16-3.736.615a4.007 4.007 0 0 0-1.672 1.672c-.455.852-.615 1.677-.615 3.736v32.955c0 2.058.16 2.883.615 3.735a4.007 4.007 0 0 0 1.672 1.672c.852.456 1.677.615 3.736.615h74.355c2.059 0 2.883-.16 3.735-.615a4.007 4.007 0 0 0 1.672-1.672c.456-.852.615-1.677.615-3.735V20.59c0-2.059-.159-2.884-.615-3.736a4.007 4.007 0 0 0-1.672-1.672c-.852-.455-1.676-.615-3.735-.615h-74.355z"
          stroke="#06C"
          strokeWidth={1.8}
        />
        <path
          d="M324.848 29.422v.018c0 .603.405 1.107.972 1.287a6.302 6.302 0 0 1 4.428 6.012c0 .468-.054.909-.153 1.341-.126.576.108 1.17.621 1.476l.01.009c.773.45 1.781.045 1.988-.819a8.745 8.745 0 0 0 .234-2.007 9.012 9.012 0 0 0-6.372-8.613c-.855-.261-1.728.396-1.728 1.296zm-1.854 13.545c-2.69-.387-4.878-2.574-5.274-5.256a6.297 6.297 0 0 1 4.347-6.984c.576-.171.981-.684.981-1.287v-.018c0-.9-.873-1.557-1.737-1.296a9.018 9.018 0 0 0-6.282 9.864c.531 3.942 3.717 7.128 7.66 7.659 2.825.378 5.435-.549 7.316-2.277a1.344 1.344 0 0 0-.234-2.151c-.522-.306-1.17-.207-1.62.198-1.323 1.206-3.159 1.845-5.157 1.548z"
          fill="#06C"
          fillRule="nonzero"
          stroke="#F7F9FD"
          strokeWidth={0.9}
        />
        <path
          d="M261.846 69.468a4.5 4.5 0 0 0-4.5 4.5v3.6a2.7 2.7 0 0 0 2.7 2.7h127.8a2.7 2.7 0 0 0 2.7-2.7v-3.6a4.5 4.5 0 0 0-4.5-4.5h-124.2z"
          stroke="#06C"
          strokeWidth={1.8}
        />
        <path
          d="M315.846 73.293h17.1a1.575 1.575 0 1 1 0 3.15h-17.1a1.575 1.575 0 1 1 0-3.15z"
          stroke="#06C"
          strokeWidth={1.35}
          fill="#FFF"
        />
        <text
          fontFamily="Nunito-Regular, Nunito"
          fontSize={10.8}
          fill="#06C"
          transform="translate(49.396 -.469)"
        >
          <tspan x={121.76} y={70.137}>
            CONNECT
          </tspan>
        </text>
      </g>
    </SvgIcon>
  );
};
