import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { refreshOidcTokensStart } from 'src/core/session/oidc/oidc.action';
import { signoutStart } from 'src/core/session/session.actions';
import { mapDispatchers } from 'src/utils/map-dispatchers';

import { TtlExpiringModal } from './ttl-expiring-modal.component';
import { TtlExpiringModalProps } from './ttl-expiring-modal.types';

const ttlExpiringConnector = createStructuredSelector({});

const dispatchers = mapDispatchers({
  onSignout: signoutStart,
  onStaySignedIn: refreshOidcTokensStart,
});

export const TtlExpiringModalContainer = compose<TtlExpiringModalProps, TtlExpiringModalProps>(
  connect(
    ttlExpiringConnector,
    dispatchers,
  ),
)(TtlExpiringModal);
