import { createAction, createPayloadAction } from 'src/app/store/app.actions';

import {
  AssociatedProfessional,
  FetchAssociatedProfessionalError,
  FetchAssociatedProfessionalStart,
  FetchAssociatedProfessionalSuccess,
  FetchPatientInfoError,
  FetchPatientInfoStart,
  FetchPatientInfoSuccess,
  PatientInfoActionType,
  PatientInfoPayload,
  ResetPatientInfo,
} from './patient-info.types';

export const fetchPatientInfoStart = (): FetchPatientInfoStart =>
  createAction(PatientInfoActionType.FETCH_PATIENT_INFO_START);

export const fetchPatientInfoSuccess = (payload: PatientInfoPayload): FetchPatientInfoSuccess =>
  createPayloadAction(PatientInfoActionType.FETCH_PATIENT_INFO_SUCCESS, payload);

export const fetchPatientInfoError = (error: any): FetchPatientInfoError =>
  createPayloadAction(PatientInfoActionType.FETCH_PATIENT_INFO_ERROR, error);

export const fetchAssociatedProfessionalStart = (): FetchAssociatedProfessionalStart =>
  createAction(PatientInfoActionType.FETCH_ASSOCIATED_PROFESSIONAL_START);

export const fetchAssociatedProfessionalSuccess = (
  data: AssociatedProfessional[],
): FetchAssociatedProfessionalSuccess =>
  createPayloadAction(PatientInfoActionType.FETCH_ASSOCIATED_PROFESSIONAL_SUCCESS, data);

export const fetchAssociatedProfessionalError = (error: any): FetchAssociatedProfessionalError =>
  createPayloadAction(PatientInfoActionType.FETCH_ASSOCIATED_PROFESSIONAL_ERROR, error);

export const resetPatientInfo = (): ResetPatientInfo =>
  createAction(PatientInfoActionType.RESET_PATIENT_INFO);
