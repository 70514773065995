import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, lifecycle } from 'recompose';

import { showAppNavigation } from 'src/ui/store';
import { mapDispatchers } from 'src/utils';

import { ProtectedRoute as ProtectedRouteComponent } from './protected-route.component';
import { protectedRouteConnector } from './protected-route.selectors';
import { ProtectedRouteProps } from './protected-route.types';

const dispatchers = mapDispatchers({
  showAppNavigation,
});

export const ProtectedRoute = compose<ProtectedRouteProps, any>(
  withRouter,
  connect(protectedRouteConnector, dispatchers),
  lifecycle<ProtectedRouteProps, ProtectedRouteProps>({
    componentWillMount() {
      if (!this.props.isNavigationVisible) {
        this.props.showAppNavigation();
      }
    },
  }),
)(ProtectedRouteComponent);
