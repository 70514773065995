import * as React from 'react';

import { styleButtonComponent } from './button.style';
import { ButtonProps, ButtonTypes } from './button.types';

export const ButtonComponent: React.FunctionComponent<ButtonProps> = ({
  children,
  className,
  disabled = false,
  label = children,
  onClick,
  type = ButtonTypes.BUTTON,
  id,
}) => (
  <button className={className} onClick={onClick} disabled={disabled} type={type} id={id}>
    {label}
  </button>
);

export const Button = styleButtonComponent(ButtonComponent);
