import { routerReducer } from 'react-router-redux';
import { combineReducers, Reducer } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { continueWhereYouLeftOffWorkaroundReducer } from 'src/core/continue-where-you-left-off-workaround/chrome-workaround.reducer';
import { countriesReducer } from 'src/core/countries/countries.reducer';
import { languagesReducer } from 'src/core/languages/languages.reducer';
import { modalsReducer } from 'src/core/modal/modal.reducers';
import { patientInfoReducer } from 'src/core/patient-info/patient-info.reducer';
import { permissionsReducer } from 'src/core/permissions/permissions.reducers';
import { sessionReducer } from 'src/core/session/session.reducers';
import { SessionActionType } from 'src/core/session/session.types';
import { userActivityReducer } from 'src/core/user-activity/user-activity.reducers';
import { userReducer } from 'src/core/user/user.reducer';
import { navigationReducer } from 'src/navigation/store/navigation.reducer';
import { uiReducers } from 'src/ui/store/ui.reducers';

import { ReducerEntry, Reducers, State, Store } from './app.types';
import { doesUserAcceptedCookies, isConsentActivated } from './app.utils';

const asyncReducers: Partial<Reducers> = {};

const persistConfig = key => ({
  key,
  storage,
  debug: true,
  whiteList: ['session', 'userActivity'],
});

const replaceReducers = (store: Store<State>) =>
  store.replaceReducer(createRootReducer() as Reducer<State>);

const moduleReducers: any = {};

export const injectModuleReducer = (
  store: Store<State>,
  namespace: string,
  reducerMap: { [key: string]: Reducer<any> },
) => {
  if (moduleReducers[namespace]) {
    moduleReducers[namespace] = {
      ...moduleReducers[namespace],
      ...reducerMap,
    };
  } else {
    moduleReducers[namespace] = reducerMap;
  }

  replaceReducers(store);
  store.persistor.persist();
};

const createModuleReducers = () =>
  Object.keys(moduleReducers).reduce((acc, key) => {
    acc[key] = combineReducers(moduleReducers[key]);
    return acc;
  }, {});

export const shouldPersistReducer = () => {
  return doesUserAcceptedCookies() && !isConsentActivated() ? false : true;
};

export const createRootReducer = () => {
  const appInitialState: Reducers = {
    ...asyncReducers,
    ...createModuleReducers(),
    router: routerReducer,
    user: userReducer,
    navigation: navigationReducer,
    ui: shouldPersistReducer() ? persistReducer(persistConfig('ui'), uiReducers) : uiReducers,
    modals: modalsReducer,
    permissions: permissionsReducer,
    session: shouldPersistReducer()
      ? persistReducer(persistConfig('session'), sessionReducer)
      : sessionReducer,
    patientInfo: patientInfoReducer,
    userActivity: shouldPersistReducer()
      ? persistReducer(persistConfig('userActivity'), userActivityReducer)
      : userActivityReducer,
    languages: languagesReducer,
    countries: countriesReducer,
    continueWhereYouLeftOffWorkaround: shouldPersistReducer()
      ? persistReducer(
          persistConfig('continueWhereYouLeftOffWorkaround'),
          continueWhereYouLeftOffWorkaroundReducer({
            resetEvents: [SessionActionType.SIGN_OUT_START, SessionActionType.LOGIN_SUCCESS],
          }),
        )
      : continueWhereYouLeftOffWorkaroundReducer({
          resetEvents: [SessionActionType.SIGN_OUT_START, SessionActionType.LOGIN_SUCCESS],
        }),
  };
  return combineReducers(appInitialState);
};

export const injectReducer = <ReducerState, ReducerKey extends keyof State>(
  store: Store<State>,
  { key, reducer }: ReducerEntry<ReducerState, ReducerKey>,
) => {
  if (typeof asyncReducers[key] !== 'undefined') {
    return;
  }
  // @ts-ignore
  asyncReducers[key] = reducer;
  replaceReducers(store);
  store.persistor.persist();
};
