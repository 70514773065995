import { equals, isNil } from 'ramda';
import * as React from 'react';
import { EditIcon } from 'src/assets/icons';
import { ButtonSizes, ButtonStyles } from 'src/components/button';
import { Column } from 'src/components/column';
import { LocalizedText } from 'src/components/localized-text';

import { LoadingRing } from '../../../components/loading-ring';
import { DeliveryDateTooltip } from '../components/delivery-date-tooltip';
import { GradientLine } from '../components/gradient-line';
import { StatusLabel } from '../components/status-label';
import { StripsDeliveryStatus } from '../store/strips-delivery.types';

import {
  ChangeDeliveryStatusButton,
  ChangeDeliveryStatusButtonTextSpan,
  styleDeliveryComponent,
} from './strips-delivery.styles';
import { StripsDeliveryProps } from './strips-delivery.types';

const deliveryStatusButtonTextMap = {
  [StripsDeliveryStatus.ACTIVE]: 'stripsDelivery.labels.changeDeliveryStatus',
  [StripsDeliveryStatus.PAUSED]: 'stripsDelivery.labels.resumeDelivery',
  [StripsDeliveryStatus.BLOCKED]: 'stripsDelivery.labels.changeDeliveryStatus',
};

const StripsDeliveryComponent: React.FunctionComponent<StripsDeliveryProps> = ({
  nextDeliveryDate,
  status,
  openStripDeliveryModal,
  updateStripsDeliveryStatusStart,
  routes,
}) =>
  status ? (
    <Column alignItems="center">
      <Column alignItems="center" pt={4} pl={4} pr={4}>
        <StatusLabel status={status} />
        <DeliveryDateTooltip status={status} nextDeliveryDate={nextDeliveryDate} />
      </Column>
      <GradientLine />
      <ChangeDeliveryStatusButton
        buttonStyle={ButtonStyles.LIGHT}
        disabled={equals(status, StripsDeliveryStatus.BLOCKED) || isNil(nextDeliveryDate)}
        size={ButtonSizes.XLARGE}
        onClick={() =>
          openStripDeliveryModal({
            nextDeliveryDate,
            updateStripsDeliveryStatusStart,
            status,
            routes,
          })
        }
      >
        <EditIcon />
        <ChangeDeliveryStatusButtonTextSpan>
          <LocalizedText label={deliveryStatusButtonTextMap[status]} />
        </ChangeDeliveryStatusButtonTextSpan>
      </ChangeDeliveryStatusButton>
    </Column>
  ) : (
    <Column alignItems="center" justifyContent="center" pt={4} pb={4}>
      <LoadingRing delay={100} />
    </Column>
  );

export const StripsDelivery = styleDeliveryComponent(StripsDeliveryComponent);
