import { isEmpty, join, map } from 'ramda';
import { createSelector } from 'reselect';

import { State } from 'src/app/store/app.types';
import {
  defaultAssociatedProfessional,
  defaultPatientInfoDetails,
  defaultPatientUser,
} from 'src/core/patient-info/patient-info.constants';
import { PatientInfoState } from 'src/core/patient-info/patient-info.types';
import { propOr } from 'src/utils/ramda';

const defaultPatientInfo: PatientInfoState = {
  associatedProfessional: defaultAssociatedProfessional,
  details: defaultPatientInfoDetails,
  user: defaultPatientUser,
};

export const selectPatientInfo = (state: State): PatientInfoState =>
  propOr(defaultPatientInfo, 'patientInfo', state);

export const selectPatientInfoError = createSelector([selectPatientInfo], patientInfo =>
  propOr(null, 'error', patientInfo),
);
export const selectPatientInfoDetails = createSelector([selectPatientInfo], patientInfo =>
  propOr(defaultPatientInfoDetails, 'details', patientInfo),
);

export const selectPatientInfoUser = createSelector([selectPatientInfo], patientInfo =>
  propOr(defaultPatientUser, 'user', patientInfo),
);

export const selectPatientId = createSelector([selectPatientInfoDetails], patientInfoDetails =>
  propOr('', 'id', patientInfoDetails),
);

export const selectPatientUserId = createSelector([selectPatientInfoUser], user =>
  propOr('', 'id', user),
);

export const selectPatientUserProfileType = createSelector([selectPatientInfoUser], user =>
  propOr('', 'profile', user),
);

export const selectDiabetesType = createSelector([selectPatientInfoDetails], details =>
  propOr('', 'diabetesType', details),
);

export const selectDiagnosisDate = createSelector([selectPatientInfoDetails], details =>
  propOr('', 'diagnosticDate', details),
);

export const selectAssociatedProfessional = createSelector([selectPatientInfo], patientInfo => {
  const defaultValue = '';
  const spacerWithComma = ', ';
  const { associatedProfessional = [] } = patientInfo;
  const professionals = professional => {
    const { name, surname, surname2 } = professional;
    return `${name} ${surname} ${surname2 ? surname2 : ''}`.trim();
  };
  return !associatedProfessional || isEmpty(associatedProfessional)
    ? defaultValue
    : `${join(spacerWithComma, map(professionals, associatedProfessional))}`;
});
