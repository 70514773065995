import { isNil } from 'ramda';

import { pass } from 'src/utils';

export const isPatient = (account): boolean => account && account.data.FHIR_UserType === 'Patient';
export const isProfessional = (account): boolean =>
  account && account.data.FHIR_UserType === 'Professional';
export const isHCPActive = (account): boolean =>
  pass(account && account.data.HCPIsActive.toString() === 'true');
export const isHCPAccessible = (account): boolean =>
  pass(account && account.data.HCPIsAccessible.toString() === 'true');
export const isErrorCodeValid = (account): boolean =>
  account && account.errorCode ? account.errorCode === 0 : true;

export const isTokenExpired = (timeout: number): boolean => timeout < Date.now();

export const isAccountValid = (account): boolean =>
  !isNil(account) &&
  account.UID &&
  isErrorCodeValid(account) &&
  account.data &&
  isPatient(account) &&
  isHCPAccessible(account) &&
  isHCPActive(account);

export const gigyaLangCodeTransformer = (langCode: string) => langCode.toLocaleLowerCase();
