import { equals } from 'ramda';
import * as React from 'react';
import { translate } from 'react-i18next';

import { XIcon } from 'src/assets/icons';
import { Step, Steps, Wizard } from 'src/components/wizard';
import { colors } from 'src/theme/colors';
import {
  GradientTopBarDiv,
  ModalBody,
  ModalCloseButton,
} from 'src/ui/modal/components/modal.style';

import { ChangeDeliveryDateContainer as ChangeDeliveryDate } from 'src/domains/strips-delivery/components/change-delivery-date/change-delivery-date.container';
import { ChangeDeliveryDateResults } from 'src/domains/strips-delivery/components/change-delivery-date/change-delivery-date.step';
import { ConfirmChangeDeliveryDateContainer as ConfirmChangeDeliveryDate } from 'src/domains/strips-delivery/components/change-delivery-date/confirm-change-delivery-date.container';
import { ChangePauseStripsDelivery } from 'src/domains/strips-delivery/components/change-pause-strips-delivery';
import {
  PauseStripsDelivery,
  PauseStripsDeliveryResult,
} from 'src/domains/strips-delivery/components/pause-strips-delivery';
import {
  ResumeStripsDelivery,
  ResumeStripsDeliveryResult,
} from 'src/domains/strips-delivery/components/resume-strips-delivery';
import { NextDeliveryDate } from 'src/domains/strips-delivery/scenes/strips-delivery.types';
import { StripsDeliveryStatus } from 'src/domains/strips-delivery/store/strips-delivery.types';

import { UPDATE_STRIPS_DELIVERY_WIZARD_ROUTES } from 'src/domains/strips-delivery/components/update-strips-delivery/update-strips-delivery.constants';
import { UpdateStripsDeliveryModalProps } from 'src/domains/strips-delivery/components/update-strips-delivery/update-strips-delivery.types';
import { I18nTranslate } from 'src/i18n/types';
import { getMonthTranslationKey } from 'src/utils/i18n/i18n.utils';

export const makeDateString = (t: I18nTranslate) => (nextDeliveryDate: NextDeliveryDate) =>
  `${t('dates.months.full.' + getMonthTranslationKey(nextDeliveryDate.month))} ${
    nextDeliveryDate.day
  } ${nextDeliveryDate.year}`;

const isPaused = equals(StripsDeliveryStatus.PAUSED);

// TODO: find better solution for opening strips modal on different step

// TODO: Find solution for testing Wizard lib *push* prop
/* istanbul ignore next */
export const UpdateStripsDeliveryModalComponent: React.FunctionComponent<UpdateStripsDeliveryModalProps> = ({
  data: { nextDeliveryDate, updateStripsDeliveryStatusStart, status },
  onModalClose,
  t,
}) => {
  const formattedNextDeliveryDate = makeDateString(t)(nextDeliveryDate);
  return (
    <ModalBody>
      <GradientTopBarDiv />
      <ModalCloseButton onClick={onModalClose}>
        <XIcon fillColor={colors.black} height={13} />
      </ModalCloseButton>
      <Wizard>
        <Steps>
          <Step
            id={UPDATE_STRIPS_DELIVERY_WIZARD_ROUTES.START}
            render={({ push }) => {
              if (isPaused(status)) {
                push(UPDATE_STRIPS_DELIVERY_WIZARD_ROUTES.RESUME);
              }
              return (
                <ChangePauseStripsDelivery
                  goToChangeDelivery={() => push(UPDATE_STRIPS_DELIVERY_WIZARD_ROUTES.CHANGE_DATE)}
                  goToPauseDelivery={() => push(UPDATE_STRIPS_DELIVERY_WIZARD_ROUTES.PAUSE)}
                  formattedNextDeliveryDate={formattedNextDeliveryDate}
                />
              );
            }}
          />
          <Step
            id={UPDATE_STRIPS_DELIVERY_WIZARD_ROUTES.CHANGE_DATE}
            render={({ push }) => (
              <ChangeDeliveryDate
                cancelChangeDelivery={onModalClose}
                goToChangeDeliveryDateConfirmation={() =>
                  push(UPDATE_STRIPS_DELIVERY_WIZARD_ROUTES.CHANGE_DATE_CONFIRM)
                }
              />
            )}
          />
          <Step
            id={UPDATE_STRIPS_DELIVERY_WIZARD_ROUTES.CHANGE_DATE_CONFIRM}
            render={({ push }) => (
              <ConfirmChangeDeliveryDate
                cancelChangeDelivery={onModalClose}
                goToChangeDeliveryDateResults={() =>
                  push(UPDATE_STRIPS_DELIVERY_WIZARD_ROUTES.CHANGE_DATE_RESULT)
                }
              />
            )}
          />
          <Step
            id={UPDATE_STRIPS_DELIVERY_WIZARD_ROUTES.CHANGE_DATE_RESULT}
            render={({ push }) => <ChangeDeliveryDateResults close={onModalClose} />}
          />
          <Step
            id={UPDATE_STRIPS_DELIVERY_WIZARD_ROUTES.PAUSE}
            render={({ push }) => (
              <PauseStripsDelivery
                close={onModalClose}
                onClickPauseDelivery={() =>
                  updateStripsDeliveryStatusStart({
                    status: StripsDeliveryStatus.PAUSED,
                    triggerOnSuccess: () => push(UPDATE_STRIPS_DELIVERY_WIZARD_ROUTES.PAUSE_RESULT),
                  })
                }
              />
            )}
          />
          <Step
            id={UPDATE_STRIPS_DELIVERY_WIZARD_ROUTES.PAUSE_RESULT}
            render={() => <PauseStripsDeliveryResult close={onModalClose} />}
          />
          <Step
            id={UPDATE_STRIPS_DELIVERY_WIZARD_ROUTES.RESUME}
            render={({ push }) => (
              <ResumeStripsDelivery
                onClickResumeDelivery={() =>
                  updateStripsDeliveryStatusStart({
                    status: StripsDeliveryStatus.ACTIVE,
                    triggerOnSuccess: () =>
                      push(UPDATE_STRIPS_DELIVERY_WIZARD_ROUTES.RESUME_RESULT),
                  })
                }
                formattedNextDeliveryDate={formattedNextDeliveryDate}
              />
            )}
          />
          <Step
            id={UPDATE_STRIPS_DELIVERY_WIZARD_ROUTES.RESUME_RESULT}
            render={({ push }) => (
              <ResumeStripsDeliveryResult
                close={onModalClose}
                formattedNextDeliveryDate={formattedNextDeliveryDate}
              />
            )}
          />
        </Steps>
      </Wizard>
    </ModalBody>
  );
};

export const UpdateStripsDeliveryModal = translate()(UpdateStripsDeliveryModalComponent);
