import { connect } from 'react-redux';
import { compose, withHandlers, withProps } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { SELECT_LOCALE_DEFAULT, selectLocale } from 'src/core/user/user.selectors';
import { FALLBACK_LOCALE } from 'src/i18n/constants';
import { mapDispatchers } from 'src/utils';

import { updateStripsDeliveryDateStart } from '../../store/strips-delivery.actions';
import {
  selectStripsDeliveryNextDeliveryDate,
  selectStripsDeliveryNextDeliveryDateForConfirmation,
} from '../../store/strips-delivery.selectors';
import { formatStripDeliveryDateString } from '../../store/strips-delivery.utils';

import {
  ConfirmChangeDeliveryDateContainerProps,
  ConfirmChangeDeliveryDateProps,
} from './change-delivery-date.types';
import { ConfirmChangeDeliveryDate } from './confirm-change-delivery-date.step';

const changeDeliveryDateSelector = createStructuredSelector({
  nextDeliveryDate: selectStripsDeliveryNextDeliveryDate,
  nextDeliveryDateToConfirm: selectStripsDeliveryNextDeliveryDateForConfirmation,
  locale: selectLocale,
});

const dispatchers = mapDispatchers({
  updateStripsDeliveryDate: updateStripsDeliveryDateStart,
});

export const onConfirmNextDeliveryDateHandler = ({
  updateStripsDeliveryDate,
  nextDeliveryDateToConfirm,
  goToChangeDeliveryDateResults,
}: ConfirmChangeDeliveryDateProps) => () =>
  updateStripsDeliveryDate({
    deliveryDate: nextDeliveryDateToConfirm,
    triggerOnSuccess: goToChangeDeliveryDateResults,
  });

const withDateConfirmationHandlers = withHandlers({
  onConfirmNextDeliveryDate: onConfirmNextDeliveryDateHandler,
});

const withDateConfirmationProps = withProps(({ nextDeliveryDateToConfirm, locale }) => ({
  formattedNextDeliveryDateToConfirm: formatStripDeliveryDateString(
    nextDeliveryDateToConfirm,
    locale === SELECT_LOCALE_DEFAULT ? FALLBACK_LOCALE : locale,
  ),
}));

export const ConfirmChangeDeliveryDateContainer = compose<
  ConfirmChangeDeliveryDateProps,
  ConfirmChangeDeliveryDateContainerProps
>(
  connect(
    changeDeliveryDateSelector,
    dispatchers,
  ),
  withDateConfirmationHandlers,
  withDateConfirmationProps,
)(ConfirmChangeDeliveryDate);
