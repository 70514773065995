import {
  UserActivityActions,
  UserActivityActionTypes,
  UserActivityState,
} from 'src/core/user-activity/user-activity.types';

export const INITIAL_SESSION_STATE: UserActivityState = {
  lastActive: Date.now(),
};

export const userActivityReducer = (
  state = INITIAL_SESSION_STATE,
  action: UserActivityActions,
): UserActivityState => {
  switch (action.type) {
    case UserActivityActionTypes.LAST_ACTIVE:
    case UserActivityActionTypes.RESET_LAST_ACTIVE:
      return {
        ...state,
        lastActive: Date.now(),
      };
    default:
      return state;
  }
};
