import * as React from 'react';

import { CheckmarkIcon } from 'src/assets/icons';
import { RenderIf } from 'src/utils/markup/render-if.utils';

import {
  calculateSpinDuration,
  LoadingRingContentDiv,
  LoadingRingIconSpan,
  LoadingRingLeftDiv,
  LoadingRingOuterDiv,
  LoadingRingProgressDiv,
  LoadingRingRightDiv,
} from './loading-ring.style';
import { LoadingRingProps } from './loading-ring.types';

export const LoadingRing: React.FunctionComponent<LoadingRingProps> = ({
  delay = 0,
  duration = 1000,
  infinite = true,
  size = 40,
  isComplete = false,
}) => {
  const spinDuration = calculateSpinDuration(duration, infinite);
  const progressProps: any = {
    delay,
    infinite,
    size,
    duration: spinDuration,
  };
  return (
    <LoadingRingOuterDiv size={size}>
      <LoadingRingLeftDiv duration={spinDuration} size={size}>
        <LoadingRingProgressDiv {...progressProps} />
      </LoadingRingLeftDiv>
      <LoadingRingRightDiv duration={spinDuration} size={size}>
        <LoadingRingProgressDiv {...progressProps} />
      </LoadingRingRightDiv>
      <RenderIf validate={isComplete}>
        <LoadingRingContentDiv delay={delay}>
          <LoadingRingIconSpan>
            <CheckmarkIcon height={size / 3} />
          </LoadingRingIconSpan>
        </LoadingRingContentDiv>
      </RenderIf>
    </LoadingRingOuterDiv>
  );
};
