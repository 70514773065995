import styled, { css } from 'styled-components';

import { Column } from 'src/components/column';
import { Div } from 'src/components/div';
import { breakpointsMap } from 'src/theme/breakpoints';
import { colors } from 'src/theme/colors';
import { spacing } from 'src/theme/spacing';
import { BreakpointSizes } from 'src/theme/theme.types';
import { zIndexes } from 'src/theme/z-index';
import { combineRems, convertPxToRem } from 'src/utils/styles';

import { StripsDeliveryStatus } from '../../store/strips-delivery.types';

const isIE10PlusMediaQuery = '(-ms-high-contrast: none), (-ms-high-contrast: active)';

export const ToolTipTriangle = css`
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -${spacing.three};
    left: 50%;
    border-color: ${colors.white} transparent transparent 
    border-style: solid;
    border-width: ${convertPxToRem(10)} ${spacing.three} 0 ${spacing.three};
    box-sizing: border-box;
    filter: drop-shadow(0 2px 6px rgba(0,0,0,0.08));
    transform: rotate(360deg);
`;

export const ToolTipTriangleIE11Fallback = css`
  filter: none;
  border: 0.7em solid black;
  border-color: transparent transparent ${colors.white} ${colors.white};
  transform-origin: 0 0;
  transform: rotate(-45deg);
  box-shadow: -4px 4px 10px 0px rgba(0, 0, 0, 0.07);
`;

export const DeliveryDateTooltipDiv = styled(Div)`
  box-shadow: 0 2px 12px 1px rgba(0, 0, 0, 0.08);
  margin-bottom: ${({ theme }) => combineRems(theme.spacing.two, theme.spacing.three)};
  box-sizing: border-box;
  position: relative;

  &:after {
    content: '';
    ${ToolTipTriangle};

    @media all and ${isIE10PlusMediaQuery} {
      ${ToolTipTriangleIE11Fallback};
    }
  }
`;
DeliveryDateTooltipDiv.displayName = 'DeliveryDateTooltipDiv';

export const DeliveryDateHeaderDiv = styled(Div)`
  border-top-left-radius: ${({ theme }) => theme.borderRadius[2]};
  border-top-right-radius: ${({ theme }) => theme.borderRadius[2]};
  background-color: ${({ theme }) => theme.colors.blueMarine};
  padding: ${({ theme }) => theme.spacing.two} ${({ theme }) => theme.spacing.four};
  display: flex;
  justify-content: center;
`;
DeliveryDateHeaderDiv.displayName = 'DeliveryDateHeaderDiv';

export const DeliveryDateTitle = styled.h2`
  color: ${colors.white};
  font-size: ${({ theme }) => theme.fontSize.caption};
  text-transform: uppercase;
  margin: 0;
  display: inline-block;
  padding-left: ${({ theme }) => theme.spacing.two};
`;
DeliveryDateTitle.displayName = 'DeliveryDateTitle';

export const DeliveryDateMonthDisplay = styled(Div)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSize.title};
  display: flex;
  justify-content: center;
  line-height: 1;
  margin: 0 auto ${({ theme }) => theme.spacing.one};
  text-align: center;
`;
DeliveryDateMonthDisplay.displayName = 'DeliveryDateMonthDisplay';

export const DeliveryDateDayDisplay = styled(Div)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSize.display1};
  line-height: 1;
  text-align: center;

  @media all and (min-width: ${breakpointsMap[BreakpointSizes.LARGE]}) {
    font-size: ${({ theme }) => theme.fontSize.display2};
  }
`;
DeliveryDateDayDisplay.displayName = 'DeliveryDateDayDisplay';

export const TooltipContentWrapper = styled(Column)`
  align-items: center;
  background-color: ${colors.white};
  padding: ${spacing.three};
  position: relative;
  z-index: ${zIndexes.foreground};
`;
TooltipContentWrapper.displayName = 'TooltipContentWrapper';

export const dateDisplayColorMap = {
  [StripsDeliveryStatus.ACTIVE]: colors.black,
  [StripsDeliveryStatus.PAUSED]: colors.silverDark,
};

export const TooltipInfoWrapperDiv = styled(Div)`
  background-color: ${colors.blueMarineAlpha10};
  font-size: ${({ theme }) => theme.fontSize.p};
  text-align: center;
`;
TooltipInfoWrapperDiv.displayName = 'TooltipInfoWrapperDiv';
