import { pipe } from 'ramda';

import { FALLBACK_LOCALE } from 'src/i18n/constants';
import {
  convertISO,
  convertJSDateGMT,
  toFormat,
  toJSDate,
  toStartOfDay,
  toString,
  toUTC,
} from 'src/utils/dates';

const STRIPS_DELIVERY_DATE_FORMAT = 'LLLL d, yyyy';

export const convertNextDeliveryJSDateToString = (date: Date): string =>
  pipe(
    convertJSDateGMT,
    toStartOfDay,
    toString,
  )(date);

export const convertNextDeliveryStringToJSDate = (dateString: string): Date =>
  pipe(
    convertISO,
    toUTC,
    toJSDate,
  )(dateString);

export const formatStripDeliveryDateString = (
  dateString: string,
  locale: string = FALLBACK_LOCALE,
): string =>
  pipe(
    convertISO,
    toUTC,
    toFormat(STRIPS_DELIVERY_DATE_FORMAT, locale),
  )(dateString);
