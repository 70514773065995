import { pipe } from 'ramda';
import * as React from 'react';
import { translate } from 'react-i18next';

import { CheckmarkIcon } from 'src/assets/icons';
import { ButtonSizes } from 'src/components/button';
import { ConcentricCircles } from 'src/components/concentric-circles';
import { DatePickerSingle } from 'src/components/date-picker/date-picker-single.component';
import { Div } from 'src/components/div';
import { LocalizedText } from 'src/components/localized-text';
import { Span } from 'src/components/span';
import { colors } from 'src/theme/colors';
import { spacing } from 'src/theme/spacing';
import { addDays, now, toJSDate, toStartOfDay } from 'src/utils/dates';
import { combineRems, convertPxToRem } from 'src/utils/styles/rem-calc.utils';

import {
  ModalButtonDiv,
  ModalColumn,
  ModalDividerDiv,
  ModalHeading,
  ModalOptionButton,
  ModalWideContentDiv,
} from 'src/ui/modal/components';

import { SingleDatePickerContainer } from 'src/domains/strips-delivery/components/change-delivery-date/change-delivery-date.style';
import { ChangeDeliveryDateProps } from 'src/domains/strips-delivery/components/change-delivery-date/change-delivery-date.types';
import { I18nTranslate } from 'src/i18n/types';

const DATE_PICKER_MIN_WIDTH = convertPxToRem(320);
const START_OF_DAY_TOMORROW = pipe(toStartOfDay, addDays(1), toJSDate)(now());

export const ChangeDeliveryDate: React.FunctionComponent<ChangeDeliveryDateProps> = ({
  cancelChangeDelivery,
  nextDeliveryJSDate,
  onChangeNextDeliveryDate,
}) => (
  <ModalColumn>
    <ModalHeading>
      <LocalizedText label="stripsDelivery.titles.newDeliveryDate" />
    </ModalHeading>
    <ModalDividerDiv />
    <Div minWidth={DATE_PICKER_MIN_WIDTH} pt={combineRems(spacing.one, spacing.three)}>
      <SingleDatePickerContainer>
        <DatePickerSingle
          date={nextDeliveryJSDate}
          onCancel={cancelChangeDelivery}
          onDateChange={onChangeNextDeliveryDate}
          earliestDate={START_OF_DAY_TOMORROW}
          lastDate={nextDeliveryJSDate}
          alwaysOpen
        />
      </SingleDatePickerContainer>
    </Div>
  </ModalColumn>
);

type ChangeDeliveryDateResultsComponentProps = {
  close: () => void;
  t: I18nTranslate;
};

export const ChangeDeliveryDateResultsComponent: React.FunctionComponent<ChangeDeliveryDateResultsComponentProps> = ({
  close,
  t,
}) => (
  <ModalColumn>
    <ModalHeading>
      <LocalizedText label="stripsDelivery.titles.changeDelivery" />
    </ModalHeading>

    <ModalDividerDiv />
    <ModalWideContentDiv>
      <Div display="flex" alignItems="center">
        <ConcentricCircles
          height={56}
          innerCircleColor={colors.trafficGreen}
          borderOpacity={0.3}
          borderThickness={4}
        >
          <CheckmarkIcon strokeColor={colors.white} strokeWeight={1} height={23} />
        </ConcentricCircles>
        <Span pl={3}>
          <LocalizedText label="stripsDelivery.labels.changeDeliveryDateSuccess" />
        </Span>
      </Div>
    </ModalWideContentDiv>
    <ModalDividerDiv />
    <ModalButtonDiv>
      <ModalOptionButton
        onClick={close}
        label={t('stripsDelivery.labels.close')}
        size={ButtonSizes.SMALL}
      />
    </ModalButtonDiv>
  </ModalColumn>
);

export const ChangeDeliveryDateResults = translate()(ChangeDeliveryDateResultsComponent);
