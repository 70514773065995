import { ensureNever, setIn } from 'src/utils/index';

import { OidcActionTypes, OidcReducerActions } from 'src/core/session/oidc/oidc.types';

import { SessionActionType, SessionReducerActions, SessionState } from './session.types';

export const INITIAL_SESSION_STATE: SessionState = {
  legacyToken: '',
  error: null,
  accessToken: '',
  refreshToken: '',
  ttl: -1,
  ttlLength: -1,
  isSignedOut: true,
};

export const sessionReducer = (
  state = INITIAL_SESSION_STATE,
  action: SessionReducerActions | OidcReducerActions,
): SessionState => {
  switch (action.type) {
    case SessionActionType.LOGIN_SUCCESS:
      return {
        ...state,
        isSignedOut: false,
      };
    case SessionActionType.VALIDATE_SESSION_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case SessionActionType.VALIDATE_SESSION_ERROR:
      return {
        ...state,
        legacyToken: '',
        error: action.payload,
        accessToken: '',
        refreshToken: '',
      };
    case SessionActionType.SIGN_OUT_ERROR:
    case SessionActionType.SIGN_OUT_SUCCESS:
      return {
        ...state,
        isSignedOut: true,
        legacyToken: '',
        accessToken: '',
        refreshToken: '',
        ttl: -1,
        ttlLength: -1,
      };
    case OidcActionTypes.OIDC_FETCH_TOKENS_SUCCESS:
    case OidcActionTypes.REFRESH_OIDC_TOKENS_SUCCESS:
      return {
        ...state,
        error: null,
        ...action.payload,
      };
    case OidcActionTypes.REFRESH_OIDC_TOKENS_ERROR:
    case OidcActionTypes.OIDC_FETCH_TOKENS_ERROR:
      return setIn(['error'], action.payload, state);
    default:
      ensureNever(action);
      return state;
  }
};
