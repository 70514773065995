import { createBrowserHistory } from 'history';
import { identity } from 'ramda';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, compose, createStore, Store as ReduxStore } from 'redux';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import { persistStore } from 'redux-persist';
import { storageMiddleware } from 'src/app/store/storage';
import { isDevEnv } from 'src/utils';

import { messageBroadcastMiddleware } from '../../middleware/message-broadcast';

import { appEpic } from './app.epics';
import { createRootReducer } from './app.reducer';
import { Persistor, State, Store } from './app.types';

export const history = createBrowserHistory();

const epicMiddleware = createEpicMiddleware(appEpic);

const middlewares = [
  messageBroadcastMiddleware,
  storageMiddleware(),
  epicMiddleware,
  routerMiddleware(history),
];

/* istanbul ignore if */
if (isDevEnv()) {
  const colors = {
    default: '#383838',
    clear_: '#7C394B',
    _error: '#E33231',
    _success: '#4CAF50',
    _start: '#03A9F4',
    undefined: '#8ADBDE',
    gigya: '#B25FD6',
    redirect: '#12505F',
  };
  const createColorCache = () => {
    const colorCache = {};
    return title => {
      if (colorCache[title]) {
        return colorCache[title];
      }
      let color;
      Object.keys(colors).forEach(key => {
        if (!color && title.toLowerCase().match(key)) {
          color = colors[key];
          colorCache[title] = color;
        }
      });
      return color ? color : colors.default;
    };
  };

  const cachedColorMapper = createColorCache();
  const logger = createLogger({
    collapsed: true,
    duration: true,
    colors: {
      title: action => action.type && cachedColorMapper(action.type),
      prevState: () => '#E33231',
      action: () => '#03A9F4',
      nextState: () => '#4CAF50',
      error: () => '#E33231',
    },
  });
  middlewares.push(logger);
}

const composeEnhancers = isDevEnv()
  ? typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose
  : identity;

const reduxStore: ReduxStore<State> = createStore(
  createRootReducer(),
  // @ts-ignore
  composeEnhancers(applyMiddleware(...middlewares)),
);

export const persistor: Persistor = persistStore(reduxStore) as Persistor;

export const store: Store<State> = { ...reduxStore, persistor };
