import { connect } from 'react-redux';
import { compose, withHandlers, withProps } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { mapDispatchers } from 'src/utils';

import { setLocalNextDeliveryDate } from '../../store/strips-delivery.actions';
import { selectStripsDeliveryNextDeliveryDate } from '../../store/strips-delivery.selectors';
import {
  convertNextDeliveryJSDateToString,
  convertNextDeliveryStringToJSDate,
} from '../../store/strips-delivery.utils';

import { ChangeDeliveryDate } from './change-delivery-date.step';
import {
  ChangeDeliveryContainerProps,
  ChangeDeliveryDateProps,
} from './change-delivery-date.types';

const changeDeliveryDateSelector = createStructuredSelector({
  nextDeliveryDate: selectStripsDeliveryNextDeliveryDate,
});

const dispatchers = mapDispatchers({
  setLocalNextDeliveryDate,
});

export const onChangeNextDeliveryDateHandler = (props: ChangeDeliveryDateProps) => (date: Date) => {
  props.setLocalNextDeliveryDate({
    nextDeliveryDate: convertNextDeliveryJSDateToString(date),
  });
  props.goToChangeDeliveryDateConfirmation();
};

const withDatePickerHandlers = withHandlers({
  onChangeNextDeliveryDate: onChangeNextDeliveryDateHandler,
});

const withDatePickerProps = withProps(({ nextDeliveryDate }) => ({
  nextDeliveryJSDate: convertNextDeliveryStringToJSDate(nextDeliveryDate),
}));

export const ChangeDeliveryDateContainer = compose<
  ChangeDeliveryDateProps,
  ChangeDeliveryContainerProps
>(
  connect(
    changeDeliveryDateSelector,
    dispatchers,
  ),
  withDatePickerProps,
  withDatePickerHandlers,
)(ChangeDeliveryDate);
