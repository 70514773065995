import { createAction, createPayloadAction } from 'src/app/store/app.actions';

import { GetStripsDeliveryData } from 'src/services/strips-delivery/get-strips-delivery/get-strips-delivery.types';

import {
  FetchStripsDeliveryError,
  FetchStripsDeliveryStart,
  FetchStripsDeliverySuccess,
  ResetLocalNextDeliveryDate,
  ResetStripsDelivery,
  SetLocalNextDeliveryDate,
  SetLocalNextDeliveryDatePayload,
  StripsDeliveryActionType,
  UpdateStripsDeliveryDateError,
  UpdateStripsDeliveryDateStart,
  UpdateStripsDeliveryDateStartPayload,
  UpdateStripsDeliveryDateSuccess,
  UpdateStripsDeliveryDateSuccessPayload,
  UpdateStripsDeliveryStatusError,
  UpdateStripsDeliveryStatusStart,
  UpdateStripsDeliveryStatusStartPayload,
  UpdateStripsDeliveryStatusSuccess,
  UpdateStripsDeliveryStatusSuccessPayload,
} from './strips-delivery.types';

export const fetchStripsDeliveryStart = (): FetchStripsDeliveryStart =>
  createAction(StripsDeliveryActionType.FETCH_STRIPS_DELIVERY_START);

export const fetchStripsDeliverySuccess = (
  stripsDeliveryData: GetStripsDeliveryData,
): FetchStripsDeliverySuccess =>
  createPayloadAction(StripsDeliveryActionType.FETCH_STRIPS_DELIVERY_SUCCESS, stripsDeliveryData);

export const fetchStripsDeliveryError = (error: Error): FetchStripsDeliveryError =>
  createPayloadAction(StripsDeliveryActionType.FETCH_STRIPS_DELIVERY_ERROR, error);

export const updateStripsDeliveryStatusStart = (
  payload: UpdateStripsDeliveryStatusStartPayload,
): UpdateStripsDeliveryStatusStart =>
  createPayloadAction(StripsDeliveryActionType.UPDATE_STRIPS_DELIVERY_STATUS_START, payload);

export const updateStripsDeliveryStatusSuccess = (
  payload: UpdateStripsDeliveryStatusSuccessPayload,
): UpdateStripsDeliveryStatusSuccess =>
  createPayloadAction(StripsDeliveryActionType.UPDATE_STRIPS_DELIVERY_STATUS_SUCCESS, payload);

export const updateStripsDeliveryStatusError = (error: Error): UpdateStripsDeliveryStatusError =>
  createPayloadAction(StripsDeliveryActionType.UPDATE_STRIPS_DELIVERY_STATUS_ERROR, error);

export const updateStripsDeliveryDateStart = (
  payload: UpdateStripsDeliveryDateStartPayload,
): UpdateStripsDeliveryDateStart =>
  createPayloadAction(StripsDeliveryActionType.UPDATE_STRIPS_DELIVERY_DATE_START, payload);

export const updateStripsDeliveryDateSuccess = (
  payload: UpdateStripsDeliveryDateSuccessPayload,
): UpdateStripsDeliveryDateSuccess =>
  createPayloadAction(StripsDeliveryActionType.UPDATE_STRIPS_DELIVERY_DATE_SUCCESS, payload);

export const updateStripsDeliveryDateError = (error: Error): UpdateStripsDeliveryDateError =>
  createPayloadAction(StripsDeliveryActionType.UPDATE_STRIPS_DELIVERY_DATE_ERROR, error);

export const setLocalNextDeliveryDate = (
  payload: SetLocalNextDeliveryDatePayload,
): SetLocalNextDeliveryDate =>
  createPayloadAction(StripsDeliveryActionType.SET_LOCAL_NEXT_DELIVERY_DATE, payload);

export const resetLocalNextDeliveryDate = (): ResetLocalNextDeliveryDate =>
  createAction(StripsDeliveryActionType.RESET_LOCAL_NEXT_DELIVERY_DATE);

export const resetStripsDelivery = (): ResetStripsDelivery =>
  createAction(StripsDeliveryActionType.RESET_STRIPS_DELIVERY);
