import { ensureNever, setIn } from 'src/utils';

import { CountriesActionType, CountriesReducerActions, CountriesState } from './countries.types';

export const initialCountriesState: CountriesState = {
  isLoading: false,
  countries: [],
  error: '',
};

export const countriesReducer = (
  state = initialCountriesState,
  action: CountriesReducerActions,
): CountriesState => {
  switch (action.type) {
    case CountriesActionType.FETCH_COUNTRIES_START:
      return {
        ...initialCountriesState,
        isLoading: true,
      };
    case CountriesActionType.FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        isLoading: false,
      };
    case CountriesActionType.FETCH_COUNTRIES_ERROR:
      return setIn(['error'], action.payload, state);
    default:
      ensureNever(action);
      return state;
  }
};
