import { BROWSER_IS_OPEN } from './chrome-workaround.constants';
import { ContinueWhereYouLeftOffWorkaroundState } from './chrome-workaround.types';
const INITIAL_STATE: ContinueWhereYouLeftOffWorkaroundState = {
  lastTimeBrowserWasOpen: 0,
};

export const continueWhereYouLeftOffWorkaroundReducer = ({ resetEvents }) => (
  state = INITIAL_STATE,
  action,
): ContinueWhereYouLeftOffWorkaroundState => {
  if (action.type === BROWSER_IS_OPEN) {
    return {
      ...state,
      lastTimeBrowserWasOpen: action.payload,
    };
  }
  if (resetEvents.includes(action.type)) {
    return INITIAL_STATE;
  }
  return state;
};
