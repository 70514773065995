import moment from 'moment';
import { isNil, pipe } from 'ramda';

import {
  convertJSDate,
  convertJSDateGMT,
  isBetween,
  now,
  subtractMonths,
  toJSDate,
  toStartOfMonth,
  toUTC,
} from 'src/utils/dates';
import { LuxonDateTime } from 'src/utils/dates/dates.types';
import { hasValue } from 'src/utils/ramda';

import { CheckIfDateOutOfRangePayload, DatePickerDate, MomentDateTime } from './date-picker.types';

export const createNewMomentDate = () => pipe(toUTC, convertDateTimeToMoment)(now());

// Convert in/out of Moment format for DatePicker

export const convertJSDateToMoment = (date: Date): MomentDateTime => moment(date);
export const convertMomentToJSDate = (momentDate: MomentDateTime): Date => momentDate.toDate();

export const convertMomentToDateTime = (momentDate: MomentDateTime): LuxonDateTime =>
  pipe(convertJSDate, toUTC)(momentDate.toDate());

export const convertDateTimeToMoment = (dateTime: LuxonDateTime): MomentDateTime =>
  pipe(toJSDate, convertJSDateToMoment)(dateTime);

// Conversion with null to keep existing date logic from HCP
// TODO: Investigate possibility of removing *some* of these null cases

export const convertJSDateToMomentOrNull = (date?: Date) =>
  isNil(date) ? null : convertJSDateToMoment(date);

export const convertMomentToJSDateOrNull = (momentDate?: DatePickerDate): Date | null =>
  momentDate && hasValue(momentDate) ? convertMomentToJSDate(momentDate) : null;

// Date math: convert to DateTime and leverage Luxon for operations

export const subtractMonthFromMoment = () => (momentDate: MomentDateTime): MomentDateTime =>
  pipe(convertMomentToDateTime, subtractMonths(1), convertDateTimeToMoment)(momentDate);

export const convertJSDateToStartOfMonthMoment = (date: Date = new Date()): MomentDateTime =>
  pipe(convertJSDate, toStartOfMonth, convertDateTimeToMoment)(date);

export const convertJSDateToPreviousMonthMoment = (endDate: Date = new Date()): MomentDateTime =>
  pipe(convertJSDateGMT, subtractMonths(1), convertDateTimeToMoment)(endDate);

const TIME_RESET_OPTIONS = { hour: 0, minute: 0, second: 0, millisecond: 0 };

export const isDateOutOfRange = ({
  dateToValidate,
  earliestDate,
  lastDate,
}: CheckIfDateOutOfRangePayload): boolean =>
  dateToValidate && earliestDate && lastDate
    ? !isBetween(
        convertJSDateGMT(dateToValidate, {}).set(TIME_RESET_OPTIONS),
        convertJSDateGMT(earliestDate, {}).set(TIME_RESET_OPTIONS),
        convertJSDateGMT(lastDate, {}).set(TIME_RESET_OPTIONS),
      )
    : false;
