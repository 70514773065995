import * as React from 'react';
import { Route, Switch } from 'react-router';

import { SmartRoute } from 'src/components/smart-route';
import { ErrorContainer } from 'src/domains/authentication/scenes/error/error.container';
import { ResetPasswordContainer } from 'src/domains/authentication/scenes/reset-password';
import { OIDCRootContainer } from 'src/domains/authentication/scenes/root';
import { OidcSuccessContainer } from 'src/domains/authentication/scenes/success';

import { AUTHENTICATION_ROUTES } from './authentication.navigation.constants';

export const AuthenticationNavigation = () => (
  <Switch>
    <Route key="login" exact path={AUTHENTICATION_ROUTES.login} component={OIDCRootContainer} />
    <Route key="root" exact path={AUTHENTICATION_ROUTES.root} component={OIDCRootContainer} />
    <Route key="error" exact path={AUTHENTICATION_ROUTES.error} component={ErrorContainer} />
    <SmartRoute
      hideNavigation
      key="success"
      exact
      path={AUTHENTICATION_ROUTES.success}
      component={OidcSuccessContainer}
    />
    <Route
      key="reset-password"
      exact
      path={AUTHENTICATION_ROUTES.resetPassword}
      component={ResetPasswordContainer}
    />
  </Switch>
);
