import 'custom-event-polyfill';
const manifestPath = '/modules/manifests/manifest.json';

interface ModuleJson {
  version: string;
  'entry-point': string;
  uri: string;
  hash: string;
}

interface ModuleEntries {
  [moduleName: string]: ModuleJson;
}

interface ManifestJson {
  modules: ModuleEntries;
}

export const Manifest = {
  isValid: (manifest: ManifestJson): boolean => {
    if (manifest.modules === undefined) {
      console.error('Could not load module manifest');
      return false;
    }
    return true;
  },

  fetch: async (): Promise<ManifestJson> => {
    const response = await fetch(manifestPath);
    if (response.status >= 200 && response.status < 300) {
      const body = await response.text();
      try {
        return JSON.parse(body);
      } catch (e) {
        const msg = 'Could not parse manifest. Manifest content: ' + body;
        console.error(msg);
        return { modules: {} };
      }
    }
    console.error('Could not load manifest: ' + response.status);
    return { modules: {} };
  },

  setupModules: async () => {
    const manifest = await Manifest.fetch();
    if (!Manifest.isValid(manifest)) {
      return;
    }
    ModuleLoader.setupLoaderFor(manifest.modules);
  },
};

const getBaseUrl = (): string =>
  process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3001';

export const ModuleLoader = {
  isValid: (module: ModuleJson): boolean =>
    ![module.version, module['entry-point'], module.uri, module.hash].some(
      values => values === undefined,
    ),

  fetchModuleHash: async (moduleUrl: string) => {
    const response = await fetch(moduleUrl, {
      method: 'HEAD',
      mode: 'no-cors',
    });
    const hash = response.headers.get('x-amz-meta-sha256');
    if (hash === null) {
      return '';
    }
    return hash;
  },

  load: async (moduleName: string, module: ModuleJson) => {
    const moduleUrl = getBaseUrl() + module.uri + module.version + module['entry-point'];
    const bundleHash = await ModuleLoader.fetchModuleHash(moduleUrl);
    if (module.hash !== bundleHash) {
      ModuleLoader.couldNotLoadError(moduleName);
      return;
    }
    const scriptElement = document.createElement('script');
    scriptElement.src = moduleUrl;
    document.body.appendChild(scriptElement);
  },

  couldNotLoadError: (moduleName: string) => console.error(`Could not load module "${moduleName}"`),

  emitLoadModuleEvent: (moduleName: string) => {
    const loadEvent = new CustomEvent('LoadModule' + moduleName, {
      bubbles: true,
    });
    document.body.dispatchEvent(loadEvent);
  },

  setupModule: (moduleName: string, module: ModuleJson) => {
    const loadModuleEventHandler = async () => {
      document.body.removeEventListener('LoadModule' + moduleName, loadModuleEventHandler);
      await ModuleLoader.load(moduleName, module);
    };

    document.body.addEventListener('LoadModule' + moduleName, loadModuleEventHandler);
  },

  setupLoaderFor(modules: ModuleEntries) {
    for (const moduleName of Object.keys(modules)) {
      const module = modules[moduleName];
      if (!ModuleLoader.isValid(module)) {
        ModuleLoader.couldNotLoadError(moduleName);
        continue;
      }

      ModuleLoader.setupModule(moduleName, module);
    }
  },
};
