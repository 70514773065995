import * as React from 'react';

import { SvgIcon } from '../icon.component';
import { BASE_ICON_COLOR } from '../icon.constants';
import { calculateAspectRatio } from '../icon.utils';

type IconProps = {
  height?: number;
  fillColor?: string;
};

export const EditIcon: React.FunctionComponent<IconProps> = ({
  height = 13,
  fillColor = BASE_ICON_COLOR,
}) => {
  const originalWidth = 13;
  const originalHeight = 13;
  const aspectRatio = calculateAspectRatio(originalWidth, originalHeight);

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <path
        d="M7.824 4.054l.613.613-6.04 6.04h-.613v-.613l6.04-6.04zm2.4-4.013a.667.667 0 0 0-.467.193l-1.22 1.22 2.5 2.5 1.22-1.22c.26-.26.26-.68 0-.94l-1.56-1.56a.655.655 0 0 0-.473-.193zm-2.4 2.126L.45 9.541v2.5h2.5l7.374-7.374-2.5-2.5z"
        fill={fillColor}
      />
    </SvgIcon>
  );
};
