import * as React from 'react';

import { SvgIcon } from '../icon.component';
import { DEFAULT_ICON_COLOR } from '../icon.constants';
import { calculateAspectRatio } from '../icon.utils';

type IconProps = {
  height?: number;
  fillColor?: string;
};

export const CalendarOutlineIcon: React.FunctionComponent<IconProps> = ({
  height = 23,
  fillColor = DEFAULT_ICON_COLOR,
}) => {
  const originalWidth = 21;
  const originalHeight = 23;
  const aspectRatio = calculateAspectRatio(originalWidth, originalHeight);

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <path
        d="M18.716 2.919h-1v-1c0-.55-.45-1-1-1s-1 .45-1 1v1h-10v-1c0-.55-.45-1-1-1s-1 .45-1 1v1h-1c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-1 18h-14c-.55 0-1-.45-1-1v-12h16v12c0 .55-.45 1-1 1z"
        fill={fillColor}
      />
    </SvgIcon>
  );
};
