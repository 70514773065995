import * as React from 'react';
import { Redirect, Route } from 'react-router';

import { Div } from 'src/components/div';
import { LoadingRing } from 'src/components/loading-ring/loading-ring.component';
import { PatientGuard } from 'src/components/route-guard/patient-guard.component';
import { PermissionsGuard } from 'src/components/route-guard/permissions-guard.component';
import { SessionGuard } from 'src/components/route-guard/session-guard.component';

import { ProtectedRouteProps } from './protected-route.types';

const LoadingPage = () => (
  <Div height="100%" minHeight="40rem" display="flex" alignItems="center" justifyContent="center">
    <LoadingRing />
  </Div>
);

export const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({
  exact,
  path,
  routes,
  isSignedOut,
  isSessionValid,
  hasPermissions,
  component: Component,
  ...props
}) => (
  <Route
    path={path}
    exact={exact}
    render={() => {
      return !isSignedOut && isSessionValid ? (
        <PermissionsGuard hasPermissions={hasPermissions}>
          <SessionGuard
            onVerifying={LoadingPage}
            onError={() => <Redirect to={routes.authentication.error} />}
          >
            <PatientGuard
              onVerifying={LoadingPage}
              onError={() => <Redirect to={routes.authentication.error} />}
            >
              <Component {...props} />
            </PatientGuard>
          </SessionGuard>
        </PermissionsGuard>
      ) : (
        <Redirect
          to={{
            pathname: routes.authentication.login,
          }}
        />
      );
    }}
  />
);
