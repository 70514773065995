import * as React from 'react';

import { ErrorProps } from './error.types';

export class OIDCErrorComponent extends React.Component<ErrorProps, {}> {
  public static displayName = 'OIDCErrorComponent';
  public componentDidMount() {
    this.props.addModal();
  }

  public componentWillUnmount() {
    this.props.removeModal();
  }

  public render() {
    return <div />;
  }
}
