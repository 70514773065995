import * as React from 'react';
import { Switch } from 'react-router-dom';
import { PatientDashboardBundle } from 'src/bundles/patient-dashboard';
import { ProtectedRoute } from 'src/components/protected-route';

import { DASHBOARD_ROUTE_DEFINITIONS } from './patient-dashboard.navigation.constants';

export const PatientDashboardNavigation = () => (
  <Switch>
    <ProtectedRoute
      key="patient-dashboard"
      exact
      path={DASHBOARD_ROUTE_DEFINITIONS.patient}
      component={PatientDashboardBundle}
    />
  </Switch>
);
