import * as React from 'react';

import { StyledSystemProps } from 'src/theme/theme.types';
import { hasValue } from 'src/utils/ramda';
import { convertPxToRem } from 'src/utils/styles/rem-calc.utils';

import { AvatarBadge } from './avatar-badge.component';
import {
  AvatarPhotoDiv,
  AvatarPlaceholderDiv,
  AvatarPlaceholderSpan,
  AvatarWrapperDiv,
} from './avatar.style';

import { AvatarProps } from './avatar.types';

export const getInitialFromTitle = (title: string): string => (hasValue(title) ? title[0] : '');

export const Avatar: React.FunctionComponent<AvatarProps & StyledSystemProps> = ({
  image,
  title,
  placeholderFontSize = convertPxToRem(80),
  placeholderIsReversed = false,
  className,
  ...avatarProps
}) => (
  <AvatarBadge isReversed={placeholderIsReversed}>
    <AvatarWrapperDiv>
      {hasValue(image) ? (
        <AvatarPhotoDiv backgroundImage={image} />
      ) : (
        <AvatarPlaceholderDiv>
          <AvatarPlaceholderSpan fontSize={placeholderFontSize}>
            {getInitialFromTitle(title)}
          </AvatarPlaceholderSpan>
        </AvatarPlaceholderDiv>
      )}
    </AvatarWrapperDiv>
  </AvatarBadge>
);
