import { getDynamicConfig, getJSON } from 'src/utils';

import { createService } from 'src/services/service';

import { OidcService } from './oidc.types';

const {
  REACT_APP_API_GATEWAY,
  REACT_APP_GIGYA_TOKEN,
  REACT_APP_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
} = getDynamicConfig();

const oidcTokensRequest = (tokenCode: string) =>
  getJSON(`${REACT_APP_API_GATEWAY}/Gigya/oidc/token`, {
    method: 'POST',
    body: {
      tokenType: 'CODE',
      token: tokenCode,
      apiKey: REACT_APP_GIGYA_TOKEN,
      redirectUri: `${REACT_APP_URL}/auth/success`,
    } as any,
    headers: {
      ApiKey: REACT_APP_GIGYA_TOKEN,
      client_id: REACT_APP_CLIENT_ID,
      client_secret: REACT_APP_CLIENT_SECRET,
    },
  });

const oidcRefreshRequest = (refreshToken: string) =>
  getJSON(`${REACT_APP_API_GATEWAY}/Gigya/oidc/token`, {
    method: 'POST',
    body: {
      tokenType: 'REFRESH',
      token: refreshToken,
      apiKey: REACT_APP_GIGYA_TOKEN,
    } as any,
    headers: {
      ApiKey: REACT_APP_GIGYA_TOKEN,
      client_id: REACT_APP_CLIENT_ID,
      client_secret: REACT_APP_CLIENT_SECRET,
    },
  });

const oidcTokensResponseTransform = oidcTokensData => {
  const { access_token, refresh_token, exp, id_token, expires_in } = oidcTokensData;
  return {
    accessToken: access_token,
    refreshToken: refresh_token,
    ttl: exp,
    ttlLength: expires_in,
    legacyToken: id_token,
  };
};

export const oidcTokensService = ({ devMode = false }): OidcService => {
  if (devMode) {
    return createService(() => Promise.resolve({} as any), oidcTokensResponseTransform);
  }
  return createService(oidcTokensRequest, oidcTokensResponseTransform);
};

export const oidcRefreshService = ({ devMode = false }): OidcService => {
  if (devMode) {
    return createService(() => Promise.resolve({} as any), oidcTokensResponseTransform);
  }

  return createService(oidcRefreshRequest, oidcTokensResponseTransform);
};
