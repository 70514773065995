import { equals, isNil } from 'ramda';
import * as React from 'react';

import { Portal } from 'src/components/portal';

import { ModalCard, ModalOverlay, ModalWrapper } from './modal.style';

export class Modal extends React.Component<any, {}> {
  public closeAction = 'jello-dialog-close-action';

  public componentDidUpdate() {
    this.setListeners();
  }

  public componentWillUnmount() {
    this.removeListeners();
  }

  public render() {
    const { isOpen, type, modalTypes } = this.props;
    return (
      <Portal rootId="modal-root">
        {isOpen && type !== modalTypes.PRODUCT_INFO && (
          <ModalOverlay>
            <ModalWrapper>
              <ModalCard>{this.renderModalComponent(type, this.props)}</ModalCard>
            </ModalWrapper>
          </ModalOverlay>
        )}
        {isOpen && type === modalTypes.PRODUCT_INFO && (
          <ModalWrapper>
            <ModalCard>{this.renderModalComponent(type, this.props)}</ModalCard>
          </ModalWrapper>
        )}
      </Portal>
    );
  }

  private renderModalComponent = (type, props) => {
    const { modalTypes, modalMap, data } = props;
    if (equals(type, modalTypes.CUSTOM)) {
      const CustomComponent = data.customComponent;
      return !isNil(CustomComponent) ? <CustomComponent {...props} /> : null;
    }
    const ModalComponent = modalMap[type];
    return !isNil(ModalComponent) ? <ModalComponent {...props} /> : null;
  };

  private getJelloModal = () => document.querySelector('[id^="jello-dialog-"]');

  private setListeners = () => {
    const jelloModal = this.getJelloModal();
    if (jelloModal) {
      jelloModal.addEventListener(this.closeAction, () => this.hideModal());
    }
    window.addEventListener('popstate', () => this.hideModal());
  };

  private removeListeners = () => {
    const jelloModal = this.getJelloModal();
    if (jelloModal) {
      jelloModal.removeEventListener(this.closeAction, () => this.hideModal());
    }
    window.removeEventListener('popstate', () => this.hideModal());
  };

  private hideModal = () => {
    const { type, isOpen, removeAllModals, modalTypes } = this.props;
    if (isOpen && type === modalTypes.PRODUCT_INFO) {
      removeAllModals();
    }
  };
}
