import { Action, PayloadAction } from 'src/app/store/app.types';

export enum ModalTypes {
  STRIP_DELIVERY = 'stripDelivery',
  DTC = 'DTC',
  CUSTOM = 'custom',
  TTL_EXPIRING = 'ttlExpiring',
  LOGIN_ERROR = 'loginError',
  CHANGE_PASSWORD_CONFIRMATION = 'changePasswordConfirmation',
  GENERIC_INFO = 'genericInfo',
  PRODUCT_INFO = 'productInfo',
}

export enum ModalActionTypes {
  ADD_MODAL = 'ADD_MODAL',
  REMOVE_MODAL_BY_KEY = 'REMOVE_MODAL_BY_KEY',
  REMOVE_ALL_MODALS = 'REMOVE_ALL_MODALS',
  UPDATE_MODAL = 'UPDATE_MODAL',
  GENERIC_INFO = 'GENERIC_INFO',
  SHOW_EXPIRING_MODAL = 'SHOW_EXPIRING_MODAL',
}

export enum LOADING_MODAL_STATUS {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type Modal = {
  key: string;
  data: any | null;
};

export type AddModalAction = PayloadAction<
  ModalActionTypes.ADD_MODAL | ModalActionTypes.SHOW_EXPIRING_MODAL,
  Modal
>;

export type RemoveModalByKeyPayload = {
  key: string;
};

export type RemoveModalByKeyAction = PayloadAction<
  ModalActionTypes.REMOVE_MODAL_BY_KEY,
  RemoveModalByKeyPayload
>;

export type UpdateModalAction = PayloadAction<ModalActionTypes.UPDATE_MODAL, Modal>;

export type RemoveAllModalsAction = Action<ModalActionTypes.REMOVE_ALL_MODALS>;

export type ModalsState = Modal[];
