import { BaseStyleProps } from '../component.types';

export enum ButtonStyles {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LIGHT = 'light',
  BLUE_GRADIENT = 'blueGradient',
  CLEAR = 'clear',
  IMG = 'img',
}

export enum ButtonSizes {
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
}

export enum ButtonTypes {
  BUTTON = 'button',
  RESET = 'reset',
  SUBMIT = 'submit',
}

export type ButtonProps = BaseStyleProps & {
  buttonStyle?: ButtonStyles;
  disabled?: boolean;
  label?: string;
  onClick?: () => void;
  size?: ButtonSizes;
  type?: ButtonTypes;
  id?: string;
};
