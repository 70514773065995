import * as React from 'react';
import { translate } from 'react-i18next';

import { ButtonSizes, ButtonStyles } from 'src/components/button';

import {
  GradientTopBarDiv,
  ModalBody,
  ModalButtonDiv,
  ModalColumn,
  ModalDividerDiv,
  ModalHeading,
  ModalOptionButton,
} from 'src/ui/modal/components/modal.style';

import { LocalizedText } from 'src/components/localized-text';
import {
  TtlExpiringHighlight,
  TtlExpiringMessage,
  TtlExpiringModalContentDiv,
} from 'src/domains/authentication/components/oidc/ttl-expiring-modal.style';
import {
  TtlExpiringModalProps,
  TtlExpiringModalState,
} from 'src/domains/authentication/components/oidc/ttl-expiring-modal.types';

export class TtlExpiringModalComponent extends React.Component<
  TtlExpiringModalProps,
  TtlExpiringModalState
> {
  private interval?: number;

  constructor(props: TtlExpiringModalProps) {
    super(props);

    this.state = {
      timeRemaining: 60,
    };
  }

  public componentDidMount() {
    this.interval = window.setInterval(
      () =>
        this.setState(({ timeRemaining }) => {
          timeRemaining--;
          if (timeRemaining === 0) {
            window.clearInterval(this.interval);
            this.interval = undefined;
          }
          return { timeRemaining };
        }),
      1000,
    );
  }

  public componentWillUnmount() {
    if (this.interval) {
      window.clearInterval(this.interval);
    }
  }

  public componentDidUpdate() {
    if (this.state.timeRemaining === 0) {
      this.props.onSignout();
    }
  }

  public render() {
    const { onSignout, onStaySignedIn, t } = this.props;

    return (
      <ModalBody>
        <GradientTopBarDiv />
        <ModalColumn>
          <ModalHeading>
            <LocalizedText label="oidc.ttlExpiring.heading" />
          </ModalHeading>
          <ModalDividerDiv />
          <TtlExpiringModalContentDiv>
            <TtlExpiringMessage>
              <LocalizedText label="oidc.ttlExpiring.body1" />
              <TtlExpiringHighlight> {this.state.timeRemaining} </TtlExpiringHighlight>
              <LocalizedText label="oidc.ttlExpiring.body2" />
            </TtlExpiringMessage>
          </TtlExpiringModalContentDiv>
          <ModalDividerDiv />
          <ModalButtonDiv>
            <ModalOptionButton
              buttonStyle={ButtonStyles.CLEAR}
              label={t('oidc.ttlExpiring.button.signOut')}
              onClick={onSignout}
              size={ButtonSizes.SMALL}
            />
            <ModalOptionButton
              buttonStyle={ButtonStyles.PRIMARY}
              label={t('oidc.ttlExpiring.button.stay')}
              onClick={onStaySignedIn}
              size={ButtonSizes.SMALL}
            />
          </ModalButtonDiv>
        </ModalColumn>
      </ModalBody>
    );
  }
}

export const TtlExpiringModal = translate()(TtlExpiringModalComponent);
