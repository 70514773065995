import * as React from 'react';
import { connect } from 'react-redux';
import { selectIsPatientAllowedVisitModule } from 'src/core/permissions/permissions.selectors';
import { selectAccessToken } from 'src/core/session/oidc/oidc.selector';
import { selectFHIRPatientId, selectFullName, selectLocale } from 'src/core/user/user.selectors';
import { DASHBOARD_ROUTES } from 'src/navigation/patient-dashboard/patient-dashboard.navigation.constants';
import { selectLocationPathname } from 'src/navigation/store/navigation.selectors';
import { getDynamicConfig } from 'src/utils';

import { ModuleLoader } from '../load-modules';

interface Props {
  patientLanguage: string;
  patientName: string;
  accessToken: string;
  allowedForPatient: boolean;
  patientId: string;
  currentPath: string;
  apiKey: string;
  clientId: string;
  clientSecret: string;
  apiUrl: string;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'visit-module-anchor': VisitModuleAnchorAttributes;
    }

    interface VisitModuleAnchorAttributes extends React.Attributes {
      'patient-id': string;
      'access-token': string;
      'api-key': string;
      'client-id': string;
      'client-secret': string;
      'api-url': string;
      'patient-language': string;
    }
  }
}

const inPatientDashboard = (props: Props) => props.currentPath === DASHBOARD_ROUTES.patient;

const visitModuleComponentRequiredProps = [
  'patientId',
  'accessToken',
  'apiKey',
  'apiUrl',
  'clientId',
  'clientSecret',
];

export const hasAllRequiredProps = (props: any, requiredProps: string[]): boolean => {
  if (!requiredProps.every(requiredProp => props[requiredProp])) {
    console.error(
      'Could not load Visit Management module, required parameters are missing:',
      requiredProps.filter(requiredProp => !props[requiredProp]),
    );
    return false;
  }
  return true;
};

const VisitModuleComponent = (props: Props) => {
  if (
    !inPatientDashboard(props) ||
    !props.allowedForPatient ||
    !hasAllRequiredProps(props, visitModuleComponentRequiredProps)
  ) {
    return null;
  }

  ModuleLoader.emitLoadModuleEvent('visit-module-pp-client');
  ModuleLoader.emitLoadModuleEvent('rdcp-pp-vc-client-module');

  return (
    <visit-module-anchor
      patient-id={props.patientId}
      access-token={props.accessToken}
      patient-language={props.patientLanguage}
      patient-name={props.patientName}
      api-key={props.apiKey}
      client-id={props.clientId}
      client-secret={props.clientSecret}
      api-url={props.apiUrl}
    />
  );
};

const mapStateToProps = (state, ownProps): Props => {
  const config = getDynamicConfig();

  return {
    allowedForPatient: selectIsPatientAllowedVisitModule(state),
    patientId: selectFHIRPatientId(state),
    accessToken: selectAccessToken(state),
    patientLanguage: selectLocale(state),
    patientName: selectFullName(state),
    currentPath: selectLocationPathname(state),
    apiKey: config.REACT_APP_GIGYA_TOKEN,
    clientId: config.REACT_APP_CLIENT_ID,
    clientSecret: config.REACT_APP_CLIENT_SECRET,
    apiUrl: config.REACT_APP_API_MULE,
    ...ownProps,
  };
};

const VisitModule = connect(mapStateToProps)(VisitModuleComponent);

export default VisitModule;
