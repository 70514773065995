import { Action, PayloadAction } from 'src/app/store/app.types';

export type Language = {
  id: number | null;
  languageId: string;
  labelProperty: string;
  labelText: string;
  languageCountryIsoCode: string;
  locale: string;
  isoCode: string;
  key: string;
  value: string;
};

export type LanguageOption = {
  value: string;
  label: string;
};

export enum LanguagesActionType {
  FETCH_LANGUAGES_START = 'FETCH_LANGUAGES_START',
  FETCH_LANGUAGES_SUCCESS = 'FETCH_LANGUAGES_SUCCESS',
  FETCH_LANGUAGES_ERROR = 'FETCH_LANGUAGES_ERROR',
}

export type LanguagesPayload = LanguageOption[];

export type FetchLanguagesStart = Action<LanguagesActionType.FETCH_LANGUAGES_START>;
export type FetchLanguagesSuccess = PayloadAction<
  LanguagesActionType.FETCH_LANGUAGES_SUCCESS,
  LanguagesPayload
>;
export type FetchLanguagesError = PayloadAction<LanguagesActionType.FETCH_LANGUAGES_ERROR, any>;

export type LanguagesState = {
  isLoading: boolean;
  languages: LanguageOption[];
  error: any;
};

export type LanguagesEpicActions = FetchLanguagesStart;

export type LanguagesActions = FetchLanguagesStart | FetchLanguagesSuccess | FetchLanguagesError;

export type LanguagesReducerActions = FetchLanguagesSuccess | FetchLanguagesError;
