import * as React from 'react';
import { Switch } from 'react-router';

import { ProfileBundle, ProfileEditBundle } from 'src/bundles/profile';
import { ProtectedRoute } from 'src/components/protected-route';
import { ChangePassword } from 'src/domains/profile/scenes/change-password';

import { PROFILE_ROUTES } from './profile.navigation.constants';

export const ProfileNavigation = () => (
  <Switch>
    <ProtectedRoute key="dashboard" exact path={PROFILE_ROUTES.view} component={ProfileBundle} />
    <ProtectedRoute
      key="profile-edit"
      exact
      path={PROFILE_ROUTES.edit}
      component={ProfileEditBundle}
    />
    <ProtectedRoute
      key="change-password"
      exact
      path={PROFILE_ROUTES.changePassword}
      component={ChangePassword}
    />
  </Switch>
);
