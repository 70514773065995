import {
  AddModalAction,
  ModalActionTypes,
  RemoveAllModalsAction,
  RemoveModalByKeyAction,
  UpdateModalAction,
} from './modal.types';

export const addModal = ({ key, data }): AddModalAction => ({
  type: ModalActionTypes.ADD_MODAL,
  payload: {
    key,
    data,
  },
});

export const showExpiringModal = ({ key, data }): AddModalAction => ({
  type: ModalActionTypes.SHOW_EXPIRING_MODAL,
  payload: {
    key,
    data,
  },
});

export const removeModalByKey = ({ key }): RemoveModalByKeyAction => ({
  type: ModalActionTypes.REMOVE_MODAL_BY_KEY,
  payload: {
    key,
  },
});

export const updateModal = ({ key, data }): UpdateModalAction => ({
  type: ModalActionTypes.UPDATE_MODAL,
  payload: {
    key,
    data,
  },
});

export const removeAllModals = (): RemoveAllModalsAction => ({
  type: ModalActionTypes.REMOVE_ALL_MODALS,
});
