import { combineEpics, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { State } from 'src/app/store/app.types';
import { selectAccessToken } from 'src/core/session/oidc/oidc.selector';
import { SessionActions, SessionActionType } from 'src/core/session/session.types';
import { selectLocale } from 'src/core/user/user.selectors';
import i18nConfig from 'src/i18n';
import { getAccountInfoService } from 'src/services/get-account-info';
import { GetAccountInfoService } from 'src/services/get-account-info/get-account-info.types';

import { fetchAccountInfoError, fetchAccountInfoSuccess } from './user.actions';
import { UserActions, UserActionType } from './user.types';

export const fetchUserEpic = (
  service: GetAccountInfoService,
): Epic<UserActions | SessionActions, State> => (action$, store$) =>
  action$
    .ofType(UserActionType.FETCH_ACCOUNT_INFO_START, SessionActionType.VALIDATE_SESSION_SUCCESS)
    .debounceTime(1000)
    .switchMap(() => {
      const accessToken = selectAccessToken(store$.getState());
      return Observable.fromPromise(service({ accessToken }))
        .map(account => fetchAccountInfoSuccess(account))
        .pipe(catchError(err => [fetchAccountInfoError({ err })]));
    });

export const languageEpic = (): Epic<any, State> => {
  const currentLocale = null;
  return (action$, store$) =>
    action$
      .ofType(UserActionType.FETCH_ACCOUNT_INFO_SUCCESS)
      .debounceTime(1000)
      .switchMap(() => {
        const language = selectLocale(store$.getState());
        if (language && language !== currentLocale) {
          i18nConfig.changeLanguage(language);
          return [
            {
              type: 'CHANGE_LOCALE',
            },
          ];
        }
        return [
          {
            type: 'CHANGE_LOCALE_ERROR',
          },
        ];
      });
};
export const userEpics = combineEpics(
  fetchUserEpic(getAccountInfoService({ devMode: false })),
  languageEpic(),
);
