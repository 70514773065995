import 'react-dates/lib/css/_datepicker.css';
import 'react-select/dist/react-select.css';
import { injectGlobal } from 'styled-components';

import { DatePickerStyles } from 'src/components/date-picker/date-picker.style';
import { breakpoints } from 'src/theme/breakpoints';
import { colors } from 'src/theme/colors';
import { BASE_FONT_SIZE } from 'src/theme/typography';

// tslint:disable-next-line
injectGlobal`
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: ${BASE_FONT_SIZE}px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(127deg, rgba(19, 177, 229, 0.1), rgba(169, 26, 231, 0.1));
  color: ${colors.black};
  margin: 0;
  min-width: ${breakpoints[0]}px;
  padding: 0;
}

body,
button,
input,
optgroup,
select,
textarea {
  font-family: "Nunito", -apple-system,BlinkMacSystemFont, "Segoe UI", sans-serif;
}
::selection {
  background: ${colors.brandBlue};
  color: ${colors.white};
}

#root {
  ${DatePickerStyles};
}
`;
