import { Action, PayloadAction } from 'src/app/store/app.types';
import { Diff, ValueOf } from 'src/utils';

// Permission types
export type Permissions = {
  readonly ROLE_ETAPES_PROGRAM: 'ROLE_ETAPES_PROGRAM';
  readonly ROLE_RPM_PROGRAM: 'ROLE_RPM_PROGRAM';
  readonly ROLE_VISIT_MANAGEMENT: 'ROLE_VISIT_MANAGEMENT';
  readonly PATIENT: 'ROLE_PATIENT';
  readonly DATA_DOWNLOAD: 'ROLE_DATA_DOWNLOAD';
  readonly GLUCOSE_STATISTICS: 'ROLE_EXPORT_GLUCOSE_STATISTICS';
  readonly STRIP_MNG_PATIENT: 'ROLE_STRIP_MNG_PATIENT';
  readonly HOME_DELIVERY_PATIENT: 'ROLE_DELIVERY_MGMT_PATIENT';
  readonly ELECTRONIC_MEDICAL_RECORD: 'ROLE_ELECTRONIC_MEDICAL_RECORD';
};

export type Permission = ValueOf<Permissions>;

export type TransformedPermissions = {
  readonly hasPatient: 'ROLE_PATIENT';
  readonly hasDataDownload: 'ROLE_DATA_DOWNLOAD';
  readonly hasGlucoseStatistics: 'ROLE_EXPORT_GLUCOSE_STATISTICS';
  readonly hasStripMngPatient: 'ROLE_STRIP_MNG_PATIENT';
  readonly hasDeliveryMgmtPatient: 'ROLE_DELIVERY_MGMT_PATIENT';
};

// Permission call types
export enum PermissionActionType {
  FETCH_PERMISSIONS_START = 'FETCH_PERMISSIONS_START',
  FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS',
  FETCH_PERMISSIONS_ERROR = 'FETCH_PERMISSIONS_ERROR',
  RESET_PERMISSIONS = 'RESET_PERMISSIONS',
}

// Utils types
export type GetPermissions = {
  readonly current: Partial<Permission[]>;
  readonly permissions: Permission[];
};

export type HasPermissions = {
  readonly toValidate: Permission[];
  readonly current: Partial<Permission[]>;
};

// Permissions call
export type PermissionsStartPayload = string;

export type PermissionsPayload = Partial<Permission[]>;

export type FetchPermissionsStart = PayloadAction<
  PermissionActionType.FETCH_PERMISSIONS_START,
  PermissionsStartPayload
>;
export type FetchPermissionsSuccess = PayloadAction<
  PermissionActionType.FETCH_PERMISSIONS_SUCCESS,
  PermissionsPayload
>;
// TODO: Error state payload
export type FetchPermissionsError = PayloadAction<
  PermissionActionType.FETCH_PERMISSIONS_ERROR,
  any
>;

export type ResetPermissions = Action<PermissionActionType.RESET_PERMISSIONS>;

// State and action combinations
export type PermissionState = PermissionsPayload;

export type PermissionEpicActions = FetchPermissionsStart;

export type PermissionActions =
  | FetchPermissionsStart
  | FetchPermissionsSuccess
  | FetchPermissionsError
  | ResetPermissions;

export type PermissionReducerActions = Diff<PermissionActions, PermissionEpicActions>;
