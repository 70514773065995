import * as React from 'react';

import { BlockIcon, CheckmarkIcon, PauseIcon } from 'src/assets/icons';
import { LocalizedText } from 'src/components/localized-text';
import { colors } from 'src/theme/colors';

import { StripsDeliveryStatus } from '../../store/strips-delivery.types';

import {
  statusLabelBgColorMap,
  StatusLabelDiv,
  statusLabelIconBgColorMap,
  StatusLabelIconCircle,
  StatusLabelTextSpan,
} from './status-label.style';

type StatusLabelProps = {
  readonly status: StripsDeliveryStatus;
};

export const StatusLabelIconMap = {
  [StripsDeliveryStatus.ACTIVE]: <CheckmarkIcon strokeColor={colors.white} strokeWeight={2} />,
  [StripsDeliveryStatus.PAUSED]: <PauseIcon strokeColor={colors.white} />,
  [StripsDeliveryStatus.BLOCKED]: <BlockIcon strokeColor={colors.white} />,
};

export const statusLabels = {
  [StripsDeliveryStatus.ACTIVE]: 'stripsDelivery.labels.active',
  [StripsDeliveryStatus.PAUSED]: 'stripsDelivery.labels.paused',
  [StripsDeliveryStatus.BLOCKED]: 'stripsDelivery.labels.blocked',
};

export const StatusLabel: React.FunctionComponent<StatusLabelProps> = ({ status }) => (
  <StatusLabelDiv bg={statusLabelBgColorMap[status]}>
    <StatusLabelIconCircle bg={statusLabelIconBgColorMap[status]}>
      {StatusLabelIconMap[status]}
    </StatusLabelIconCircle>
    <StatusLabelTextSpan>
      <LocalizedText label={statusLabels[status]} />
    </StatusLabelTextSpan>
  </StatusLabelDiv>
);
