import { Action, PayloadAction } from 'src/app/store/app.types';
import { Diff } from 'src/utils';

export enum StripsStockActionType {
  SET_STRIPS_STOCK = 'SET_STRIPS_STOCK',
  FETCH_STRIPS_STOCK_START = 'FETCH_STRIPS_STOCK_START',
  FETCH_STRIPS_STOCK_SUCCESS = 'FETCH_STRIPS_STOCK_SUCCESS',
  FETCH_STRIPS_STOCK_ERROR = 'FETCH_STRIPS_STOCK_ERROR',
  RESET_STRIPS_STOCK = 'RESET_STRIPS_STOCK',
}

export type StripsStockData = {
  remaining: number;
  startAmount: number;
};

export type StripsStockState = {
  remaining?: number;
  startAmount?: number;
};

export type FetchStripsStockStart = Action<StripsStockActionType.FETCH_STRIPS_STOCK_START>;

export type FetchStripsStockSuccess = PayloadAction<
  StripsStockActionType.FETCH_STRIPS_STOCK_SUCCESS,
  StripsStockData
>;
export type FetchStripsStockError = PayloadAction<
  StripsStockActionType.FETCH_STRIPS_STOCK_ERROR,
  any
>;

export type SetStripsStockAction = PayloadAction<
  StripsStockActionType.SET_STRIPS_STOCK,
  StripsStockData
>;

export type ResetStripsStock = Action<StripsStockActionType.RESET_STRIPS_STOCK>;

// epic only actions
export type StripsStockEpicActions = FetchStripsStockStart;

export type StripsStockActions =
  | FetchStripsStockStart
  | FetchStripsStockSuccess
  | FetchStripsStockError
  | SetStripsStockAction
  | ResetStripsStock;

export type StripsStockReducerActions = Diff<StripsStockActions, StripsStockEpicActions>;
